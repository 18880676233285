/* eslint-disable max-lines */

import { colors, desktop, fonts, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    maxWidth: '70rem',
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    ...tablet({
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    }),
  },
  logo: {
    width: '14rem',
    height: '12.6rem',
    marginBottom: '2rem',
    background: colors.pureWhite,
    borderRadius: '2rem',
    display: 'flex',
    padding: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'cover',
      width: '100%',
      borderRadius: '1.5rem',
    },
    ...tablet({
      padding: 0,
      marginRight: '2rem',
      '& div': {
        alignItems: 'flex-start',
      },
    }),
    ...desktop({
      display: 'none',
    }),
  },
  headerData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    ...tablet({
      alignItems: 'flex-start',
      marginBottom: '4rem',
    }),
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '1rem',
    ...tablet({
      justifyContent: 'flex-start',
    }),
  },
  type: {
    marginRight: '1rem',
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.4rem',
    lineHeight: '2.6rem',
    textAlign: 'center',
    color: colors.nightRider,
    marginBottom: '2rem',
    textTransform: 'uppercase',
    ...tablet({
      maxWidth: '46rem',
      textAlign: 'left',
      fontSize: '3.2rem',
      lineHeight: '3.8rem',
      marginBottom: '.7rem',
    }),
  },
  domains: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '1.3rem',
    ...tablet({
      flexDirection: 'row',
    }),
  },
  domain: {
    background: colors.aliceBlue,
    borderRadius: '.4rem',
    textAlign: 'center',
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    color: colors.darkBlue,
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    padding: '.4rem 1rem',
    marginBottom: '.7rem',
    ...tablet({
      marginRight: '1rem',
    }),
  },
  companyName: {
    display: 'none',
    ...tablet({
      display: 'block',
      fontFamily: fonts.FiraSansRegular.fontFamily,
      fontWeight: fontWeights.regular,
      fontSize: '1.1rem',
      lineHeight: '1.3rem',
      textAlign: 'left',
      textTransform: 'uppercase',
      color: colors.nightRider,
    }),
  },
  sharing: {
    display: 'block',
    marginBottom: '4rem',
    ...tablet({
      width: '24rem',
      display: 'none',
    }),
    ...desktop({
      width: '20rem',
    }),
  },
  metrics: {
    marginBottom: '4rem',
    ...tablet({
      display: 'none',
    }),
  },
  descriptionContainer: {
    padding: 0,
  },
  description: {
    marginBottom: '3rem',
    ...tablet({
      fontSize: '1.5rem',
      lineHeight: '2rem',
    }),
  },
  certificationsContainer: {
    marginBottom: '3.7rem',
  },
  certificationsTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  certificationsIcon: {
    width: '2.4rem',
    height: '2.4rem',
    marginRight: '1rem',
  },
  certificationsTitle: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.8rem',
    lineHeight: '2.6rem',
    textAlign: 'left',
    color: colors.nightRider,
    marginBottom: '1.1rem',
  },
  certifications: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: '1.3rem',
    ...tablet({
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    }),
  },
  certification: {
    background: colors.aliceBlue,
    borderRadius: '.4rem',
    textAlign: 'center',
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    color: colors.nightRider,
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    padding: '.4rem 1rem',
    marginBottom: '.7rem',
    ...tablet({
      marginRight: '1rem',
    }),
  },
  contactsContainer: {
    marginBottom: '3.2rem',
  },
  contactsTitle: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.0rem',
    lineHeight: '2.4rem',
    textAlign: 'left',
    color: colors.nightRider,
    marginBottom: '2.5rem',
    textTransform: 'uppercase',
  },
  contacts: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: '1.3rem',
    ...tablet({
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
    }),
  },
  contactsOnlyPremium: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.3rem',
  },
  onlyPremium: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.8rem',
    lineHeight: '2.2rem',
    textAlign: 'left',
    color: colors.grey,
    marginBottom: '2.5rem',
  },
  contact: {
    paddingRight: '2rem',
    marginBottom: '.7rem',
    ...tablet({
      marginRight: '3rem',
    }),
  },
  contactInfoSimple: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    color: colors.nightRider,
    fontSize: '1.5rem',
    lineHeight: '2.0rem',
    marginBottom: '0.2rem',
  },
  contactInfoBold: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.bold,
    color: colors.darkBlue,
    fontSize: '1.5rem',
    lineHeight: '2.0rem',
    marginBottom: '0.1rem',
  },
  contactMembersContainer: {
    marginBottom: '7.8rem',
  },
  contactMembersTitle: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.0rem',
    lineHeight: '2.4rem',
    textAlign: 'left',
    color: colors.nightRider,
    marginBottom: '2.5rem',
    textTransform: 'uppercase',
  },
  contactMembers: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  contactMember: {
    minHeight: '9.4rem',
    padding: '1rem',
    backgroundColor: colors.honeydew,
    borderRadius: '0.5rem',
    marginBottom: '.7rem',
    ...tablet({
      width: '22rem',
      marginRight: '1rem',
    }),
  },
  contactMemberName: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    color: colors.darkBlue,
    fontSize: '1.6rem',
    lineHeight: '2.0rem',
    marginBottom: '0.8rem',
  },
  contactMemberFunctionDescription: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    color: colors.nightRider,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    marginBottom: '0.8rem',
  },
  contactMemberSeeProfile: {
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
  },
  video: {
    marginBottom: '1.5rem',
  },
  gallery: {
    marginBottom: '3rem',
  },
  filesTitle: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.0rem',
    lineHeight: '2.4rem',
    textAlign: 'left',
    color: colors.nightRider,
    marginBottom: '2.5rem',
    textTransform: 'uppercase',
  },
  files: {
    maxWidth: '70rem',
    margin: '0 auto',
    marginBottom: '3rem',
  },
  apply: {
    marginBottom: '2rem',
    float: 'right',
  },
}
