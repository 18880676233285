import { fonts, colors } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '30rem',
    borderRadius: '1rem',
    background: colors.pureWhite,
    transition: 'box-shadow 0.2s, transform 0.2s',
    overflow: 'hidden',
    '&:hover': {
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
      transform: 'translateY(-2px)',
    },
  },
  wrapper: {
    padding: '1.5rem',
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    minHeight: '10rem',
  },
  logo: {
    width: '8rem',
    height: '7rem',
    background: colors.pureWhite,
    borderRadius: '1rem',
    display: 'flex',
    overflow: 'hidden',
  },
  content: {
    padding: '0.5rem 0.5rem 0.5rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  name: {
    ...fonts.FiraSansBold,
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    marginTop: '0',
    marginBottom: '0.3rem',
    color: colors.nightRider,
    textTransform: 'uppercase',
  },
  sector: {
    ...fonts.FiraSansRegular,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    color: colors.darkBlue,
    marginBottom: '0.3rem',
    textTransform: 'uppercase',
  },
  city: {
    ...fonts.FiraSansRegular,
    fontWeight: fontWeights.regular,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    color: colors.grey,
    display: 'flex',
    alignItems: 'center',
  },
  cityIcon: {

  },
  cityLabel: {},
  smallIcon: {
    '& + *': {
      marginLeft: '0.5rem',
    },
    alignSelf: 'flex-end',
    '& svg': {
      height: '2.4rem',
    },
  },
  labelTooltip: {
    ...fonts.FiraSansRegular,
    fontWeight: fontWeights.regular,
    padding: '0.2rem 1rem',
  },
  boxOgcn: {
    border: '2px solid black',
    borderRadius: '50%',
    alignSelf: 'flex-end',
  },
  iconOgcn: {
    display: 'flex',
    justifyContent: 'center',
    width: '2rem',
    height: '2rem',
    '& svg': {
      display: 'flex',
      alignSelf: 'center',
      width: '1.5rem',
      height: '1.5rem',
    },
  },
}
