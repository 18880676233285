import { colors, fonts, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    padding: '1.5rem',
    background: colors.pureWhite,
    border: ['.1rem', 'solid', colors.gainsboro],
    borderRadius: '1rem',
    width: '100%',
    ...tablet({
      maxWidth: '44rem',
    }),
  },
  connected: {
    // backgroundColor: Color(colors.honeydew).fade(0.5).string(),
    // ...tablet({
    //   maxWidth: '22rem',
    // }),
  },
  wrapper: {
    display: 'flex',
  },
  picture: {
    width: '9rem',
    height: '8rem',
    overflow: 'hidden',
  },
  infos: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '0 1rem',
  },
  infosDetails: {
    extend: 'infos',
    flex: 0,
    flexDirection: 'column',
  },
  name: {
    ...fonts.FiraSansBold,
    fontSize: '1.6rem',
    lineHeight: '120%',
    color: colors.nightRider,
    marginBottom: '1rem',
  },
  city: {
    ...fonts.FiraSansRegular,
    fontWeight: fontWeights.regular,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    color: colors.grey,
    display: 'flex',
    alignItems: 'center',
  },
  cityIcon: {},
  cityLabel: {},
  boxOgcn: {
    marginLeft: '1rem',
    border: '2px solid black',
    borderRadius: '50%',
  },
  iconOgcn: {
    display: 'flex',
    justifyContent: 'center',
    width: '3.5rem',
    height: '3.5rem',
    '& svg': {
      display: 'flex',
      alignSelf: 'center',
      width: '2.5rem',
      height: '2.5rem',
    },
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    marginTop: '1rem',
    borderTop: ['.1rem', 'solid', colors.gainsboro],
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: colors.nightRider,
  },
  bodyMessage: {
    //
  },
  messageButton: {
    marginLeft: '1rem',
  },
  footer: {
    marginTop: '1rem',
    borderTop: ['.1rem', 'solid', colors.gainsboro],
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    textDecoration: 'none',
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    textTransform: 'uppercase',
    color: colors.darkBlue,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    marginRight: '1rem',
    '&:last-of-type': {
      margin: 0,
    },
    '&$restore': {
      filter: 'saturate(0)',
    },
  },
  restore: {
    //
  },
}
