import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import A from '../A'
import { iconsKeys } from '../Icon/Icon.assets'
import Icon from '../Icon'
import { colors } from '../../theme'


const PaginationPrevNext = (props) => (
  <li className={cx({ disable: props.disabled })}>
    {!props.disabled ? (
      props.route ? (
        <A
          href={props.page <= 1 ? props.routeBase : props.route}
          routeParams={{ ...props.routeParams, ...(props.page <= 1 ? {} : { page: props.page }) }}
          title={props.icon === iconsKeys.PaginationFirst ? 'pagination début' : 'pagination fin'}
          onClick={props.onClick}
        >
          <Icon
            icon={iconsKeys[props.icon]}
            classname={props.classes.icon}
            color={colors.darkBlue}
          />
        </A>
      ) : (
        <a
          title={props.icon === iconsKeys.PaginationFirst ? 'pagination début' : 'pagination fin'}
          onClick={props.onClick}
          role="presentation"
        >
          <Icon
            icon={iconsKeys[props.icon]}
            classname={props.classes.icon}
            color={colors.darkBlue}
          />
        </a>
      )
    ) : (
      <>
        <span className={props.classes.iconContainer}>
          <Icon
            icon={iconsKeys[props.icon]}
            classname={props.classes.icon}
            color={colors.darkBlue}
          />
        </span>
      </>
    )}
  </li>
)

PaginationPrevNext.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  page: PropTypes.number.isRequired,
  route: PropTypes.string.isRequired,
  routeBase: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  routeParams: PropTypes.any, // eslint-disable-line
  icon: PropTypes.oneOf(['PaginationFirst', 'PaginationLast']).isRequired,
  onClick: PropTypes.func,
}

PaginationPrevNext.defaultProps = {
  classes: null,
  disabled: false,
  routeParams: {},
  onClick: () => null,
}


export default PaginationPrevNext
