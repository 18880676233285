// eslint-disable-next-line
import moment from 'moment'

const Token = (token) => (
  // eslint-disable-next-line
  token && token.access_token && ({
    token_type: token.token_type,
    expires: token.expires || moment().add(token.expires_in, 's').toDate(),
    access_token: token.access_token,
    refresh_token: token.refresh_token,
    impersonate: token.impersonate ?? false,
  })
)

Token.types = {
  Bearer: 'Bearer',
}

export default Token
