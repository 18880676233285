import cx from 'classnames'
import _partition from 'lodash/partition'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import FooterContact from '../FooterContact'
import FooterMenu from '../FooterMenu'
import FooterSocial from '../FooterSocial'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

function Footer(props) {
  const { className, socialProps, contactProps, menus } = props
  const classes = useStyles(props)

  const [rightMenus, bottomMenus] = _partition(menus, (menu) => menu.type !== 'LEGALS')

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.colWrapper}>
        <div className={classes.rowWrapper}>
          <div className={classes.left}>
            <div className={classes.top}>
              <Icon
                icon={iconsKeys.LogoWhite}
                className={classes.logo}
              />
              <Icon
                icon={iconsKeys.CciFullLogo}
                className={classes.cciLogo}
              />
            </div>
            <div className={classes.bottomLeft}>
              <FooterContact
                {...contactProps}
                className={classes.contact}
              />
            </div>
          </div>
          <div className={classes.right}>
            <div className={cx(classes.top, classes.topRight)}>
              <FooterSocial
                {...socialProps}
                className={classes.social}
              />
            </div>
            <div className={classes.bottom}>
              {
                rightMenus.map((menu, i) => (
                  <FooterMenu
                    {...menu}
                    key={i}
                    className={cx(menu.className, classes.menu)}
                  />
                ))
              }
            </div>
          </div>
        </div>
        <div className={classes.legals}>
          {bottomMenus.map((menu, i) => (
            <FooterMenu
              {...menu}
              horizontal
              key={i}
              className={cx(menu.className, classes.menu)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

Footer.styles = styles

Footer.propTypes = {
  className: PropTypes.string,
  socialProps: PropTypes.shape(FooterSocial.propTypes),
  contactProps: PropTypes.shape(FooterContact.propTypes),
  menus: PropTypes.arrayOf(PropTypes.shape(FooterMenu.propTypes)),
  legals: PropTypes.shape({
    copyright: PropTypes.string,
    notice: PropTypes.string,
    noticeLink: PropTypes.string,
    privacy: PropTypes.string,
    privacyLink: PropTypes.string,
    gdpr: PropTypes.string,
    handleGDPRClick: PropTypes.func,
  }),
}

Footer.defaultProps = {
  className: '',
  socialProps: FooterSocial.defaultProps,
  contactProps: FooterContact.defaultProps,
  menus: [],
  legals: {},
}

export default withMemo()(Footer)
