const PORT = (process.env.PORT !== '80' && process.env.PORT)

export default {
  APP_ENV: process.env.APP_ENV || 'production',
  APP_VERSION: process.env.APP_VERSION || '',
  BANNER: process.env.BANNER || false,
  PORT,
  APP_URL: process.env.APP_URL,
  APP_PREVIEW_URL: process.env.APP_PREVIEW_URL,
  SECURE_COOKIE: process.env.SECURE_COOKIE === 'true',
  COOKIE_NAME: process.env.COOKIE_NAME || 'auth_token',
  REMEMBER_COOKIE_NAME: process.env.REMEMBER_COOKIE_NAME || 'auth_token_remember_me',
  REDIRECTION_COOKIE_NAME: process.env.REDIRECTION_COOKIE_NAME || 'after_auth_redirection',
  COOKIE_DOMAIN: process.env.COOKIE_DOMAIN || process.env.APP_URL,
  IS_PREVIEW: process.env.IS_PREVIEW === 'true',
  COMPLETE_PROFILE_ATTEMPT: 'complete_profile_attempt',
  REGISTER_URL: `${process.env.APP_URL }/inscription`,
  USE_PWA: process.env.USE_PWA === '1',
  UNREGISTER_SW: process.env.USE_PWA !== '1' || process.env.APP_ENV === 'local',
}
