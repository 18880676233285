/* eslint-disable max-lines */
import React from 'react'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import PropTypes from 'prop-types'

import Text from '../Text'
import FormLabel from '../../FormLabel'
import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import withMemo from '../../../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const MultiText = (props) => {
  const classes = useStyles(props)
  const {
    name, label, required, error, help,
    length, onChange, onBlur, value, placeholder, ...others
  } = props

  const fields = Array.from({ length }).map((field, i) => ({ value: ((value && value[i]) || ''), placeholder: ((placeholder && placeholder[i]) || '') }))

  const handleChange = (i, v) => {
    fields[i].value = v
    onChange({ name, value: fields.map((f) => f.value) })
  }

  const handleBlur = ({ event }) => ({ name, event })

  return (
    <div className={classes.container}>
      {label !== '' && (
        <FormLabel
          className={cx(classes.label)}
          required={required}
          error={!!error}
        >
          {label}
        </FormLabel>
      )}
      <div className={classes.wrapper}>
        {
          fields.map((field, i) => (
            <div
              className={classes.field}
              key={i}
            >
              <Text
                {...others}
                name={`${i}`}
                value={field.value}
                placeholder={field.placeholder}
                onBlur={handleBlur}
                onChange={({ value: v }) => handleChange(i, v)}
              />
            </div>
          ))
        }
      </div>
      <FormErrorText
        className={cx(classes.errorText)}
        text={error}
      />
      <FormHelpText
        className={cx(classes.helpText)}
        text={help}
        error={!!error}
      />
    </div>
  )
}

MultiText.styles = styles

MultiText.propTypes = {
  classNames: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.oneOf(['email', 'password', 'text', 'number']),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
  help: PropTypes.string,
  placeholder: PropTypes.arrayOf(PropTypes.string),
  maxChar: PropTypes.number,
  length: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  onChange: PropTypes.func, // {name, value, event}
  onBlur: PropTypes.func, // {name, event}
  onFocus: PropTypes.func, // {name, event}
  inputRef: PropTypes.func,
  endOrnament: PropTypes.node,
  startOrnament: PropTypes.node,
}

MultiText.defaultProps = {
  classNames: {},
  className: null,
  id: null,
  inputClassName: null,
  value: [],
  label: '',
  type: 'text',
  required: false,
  disabled: false,
  readOnly: false,
  error: null,
  help: null,
  placeholder: [],
  maxChar: null,
  length: 3,
  inputProps: null,
  onChange: () => undefined,
  onBlur: () => undefined,
  onFocus: () => undefined,
  inputRef: () => undefined,
  endOrnament: null,
  startOrnament: null,
}

MultiText.defaultValue = []

export default withMemo()(MultiText)
