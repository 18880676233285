import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { sizes } from '../../theme'
import { getWindowSize } from '../../utils/WindowSize'
import A from '../A'
import CloudinaryImage from '../CloudinaryImage'

import styles from './styles'


const defaultMap = 'defaults/company-map'
const useStyles = createUseStyles(styles)

const Map = (props) => {
  const classes = useStyles(props)
  const { className, image, link, linkClassName } = props

  const [isDesktop, setIsDesktop] = useState(false)

  function handleResize() {
    const windowSize = getWindowSize()

    if (!isDesktop && windowSize.width >= sizes.tablet) {
      setIsDesktop(true)
    } else if (isDesktop && windowSize.width < sizes.tablet) {
      setIsDesktop(false)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <A
      href={link}
      target="_blank"
      title="Map"
      className={cx(classes.link, linkClassName)}
    >
      <CloudinaryImage
        id={image && image !== '' ? image : defaultMap}
        covered
        className={cx(classes.container, className)}
        options={{
          width: isDesktop ? '200' : '745',
          height: isDesktop ? '190' : '150',
          crop: 'lpad',
          background: 'auto',
        }}
        lazyLoad
      />
    </A>
  )
}

Map.propTypes = {
  className: PropTypes.string,
  linkClassName: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
}

Map.defaultProps = {
  className: '',
  linkClassName: '',
  image: null,
  link: null,
}

export default withMemo()(Map)
