import { fonts, colors, tablet, desktop } from '../../theme'


export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '13rem',
    textAlign: 'center',
    ...tablet({
      width: '14rem',
    }),
    ...desktop({
      width: '22rem',
    }),
  },
  image: {
    maxWidth: '10.8rem',
    margin: '0 auto 2rem',
    ...tablet({
      margin: '0 auto 1.7rem',
    }),
    ...desktop({
      margin: '0 auto 3.7rem',
    }),
  },
  title: {
    color: colors.egyptianBlue,
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    lineHeight: '2.4rem',
    textAlign: 'center',
    marginBottom: '0.9rem',
    margin: '0 auto',
    ...desktop({
      fontSize: '2rem',
      lineHeight: '2.4rem',
      maxWidth: '18rem',
    }),
  },
}
