import Color from 'color'

import { fonts, colors, transition } from '../../theme'


export default {
  container: {
    background: Color(colors.darkBlue).fade(0.85).string(),
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '.5rem .6rem .4rem',
    borderRadius: '.3rem',
    textDecoration: 'none',
    ...transition('all'),
    '&:hover': {
      background: Color(colors.darkBlue).fade(0).string(),
      '& $label': {
        color: colors.pureWhite,
      },
    },
  },
  icon: {
    width: '1.3rem',
    height: '1.3rem',
    marginRight: '.7rem',
    marginBottom: '.2rem',
    '& span': {
      minWidth: '1.3rem',
      minHeight: '1.3rem',
    },
  },
  label: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    color: colors.nightRider,
    ...transition('color'),
  },
}
