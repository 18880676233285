import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import A from '../A'
import CloudinaryImage from '../CloudinaryImage'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const defaultLogo = 'defaults/logo'
const useStyles = createUseStyles(styles)

const TopCompaniesCard = (props) => {
  const classes = useStyles(props)
  const { className, classNames, link, logo, title, location, offers, offersLabel, route, routeParams } = props

  const renderContent = () => (
    <div className={cx(classes.container, className)}>
      <div className={classes.logo}>
        <CloudinaryImage
          id={logo || defaultLogo}
          options={{
            width: '140',
            height: '80',
            crop: 'lpad',
          }}
        />
      </div>
      <div className={classes.title}>{title}</div>
      <div className={classes.location}>{location}</div>
      <div className={classes.offers}>
        {`${offers} ${offersLabel}`}
      </div>
    </div>
  )

  return (
    <A
      href={link}
      route={route}
      routeParams={routeParams}
      className={cx(classes.wrapper, classNames.bigWrapper)}
      title={title}
    >
      {renderContent()}
    </A>
  )
}

TopCompaniesCard.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.objectOf(PropTypes.string),
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  logo: PropTypes.string,
  title: PropTypes.string.isRequired,
  offersLabel: PropTypes.string,
  location: PropTypes.string.isRequired,
  offers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

TopCompaniesCard.defaultProps = {
  className: '',
  offersLabel: '',
  link: null,
  route: null,
  routeParams: null,
  logo: '',
  classNames: {},
}

export default withMemo()(TopCompaniesCard)
