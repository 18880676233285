/* eslint-disable max-lines */
import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import moment from 'moment'
import removeMD from 'remove-markdown'
import { useTranslation } from 'react-i18next'

import A from '../A'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import withMemo from '../../decorators/WithMemo'
import Category from '../../data/models/Category'
import User from '../../data/models/User'
import CategoryTag from '../CategoryTag'
import { offerType } from '../../graphql/enums/offerType'

import styles from './styles'


const useStyles = createUseStyles(styles)

const OfferCard = (props) => {
  const classes = useStyles(props)
  const {
    className,
    link,
    route,
    routeParams,
    title,
    type,
    introduction,
    categories,
    city,
    deadline,
    date,
    budget,
    reference,
    isCurrentOGCN,
    user,
  } = props

  const intro = useMemo(() => removeMD(introduction), [introduction])
  const { t } = useTranslation()
  const deadlineDate = useMemo(() => {
    const d = moment(deadline, 'YYYY-MM-DD')

    return d.isValid() && d.toDate()
  }, [deadline])
  const publicationDate = useMemo(() => {
    const d = moment(date, 'YYYY-MM-DD')

    return d.isValid() && d.toDate()
  }, [date])

  const isPublic = type === offerType.OFFER_TYPE_CALL_FOR_TENDER

  return (
    <div className={cx(classes.container, className)}>
      <A
        href={link}
        route={route}
        routeParams={routeParams}
        target={isPublic ? '_blank' : null}
        className={cx(classes.wrapper)}
        title={title}
        id={reference}
      >
        <div className={classes.head}>
          {isCurrentOGCN && user?.company?.is_ogcn && (
            <div className={classes.boxOgcn}>
              <Icon
                icon={iconsKeys.OgcnLight}
                className={classes.iconOgcn}
              />
            </div>
          )}
          <div className={classes.type}>
            {t(`offerType_${type}`)}
          </div>
          <div className={classes.top}>
            <div className={classes.categories}>
              {
                categories?.map((category, key) => (
                  <CategoryTag
                    className={classes.category}
                    key={key}
                    {...category}
                  />
                ))
              }
            </div>
            {type === offerType.OFFER_TYPE_CALL_FOR_TENDER && (
              <div className={classes.tag}>
                <Icon
                  icon={iconsKeys.Annonce}
                  className={classes.tagIcon}
                />
                <div className={classes.tagName}>{t('offerCard_tag')}</div>
              </div>
            )}
          </div>
          <h2 className={classes.title}>{title}</h2>
          {introduction && <div className={classes.introduction}>{intro}</div>}
          <div className={classes.infos}>
            {city && city !== '' && (
              <div className={classes.city}>
                <Icon
                  icon={iconsKeys.Pinpoint}
                  className={classes.cityIcon}
                />
                <span className={classes.cityLabel}>{city}</span>
              </div>
            )}
            {publicationDate && (
              <div
                className={classes.publicationDate}
              >
                {t('offerCard_published', { date: publicationDate })}
              </div>
            )}
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.left}>
            {deadlineDate && (
              <div className={classes.deadline}>
                <Icon
                  icon={iconsKeys.Calendar}
                  className={classes.deadlineIcon}
                />
                <span className={classes.deadlineLabel}>{t('offerCard_deadline', { date: deadlineDate })}</span>
              </div>
            )}
            {!!budget && <div className={classes.budget}>{t('offerCard_budget', { budget })}</div>}
          </div>
          <div className={classes.more}>
            <div className={classes.moreLabel}>{t('offerCard_more')}</div>
            <Icon
              className={classes.moreIcon}
              icon={iconsKeys.SmallArrowRight}
            />
          </div>
        </div>
      </A>
    </div>
  )
}

OfferCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  reference: PropTypes.string,
  introduction: PropTypes.string,
  deadline: PropTypes.string,
  budget: PropTypes.number,
  type: PropTypes.string,
  city: PropTypes.string,
  categories: PropTypes.arrayOf(Category.propTypes),
  link: PropTypes.string,
  date: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  isCurrentOGCN: PropTypes.bool,
  user: PropTypes.shape(User.propTypes),
}

OfferCard.defaultProps = {
  className: '',
  title: null,
  reference: null,
  introduction: null,
  deadline: null,
  budget: null,
  type: null,
  city: null,
  categories: null,
  link: null,
  date: null,
  route: null,
  routeParams: null,
  isCurrentOGCN: false,
  user: null,
}

export default withMemo()(OfferCard)
