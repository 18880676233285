import { colors, fonts, tablet } from '../../theme'


export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    position: 'relative',
    border: `.1rem solid ${colors.honeydew}`,
    backgroundColor: colors.pureWhite,
    width: '4rem',
    height: '4rem',
    marginLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '1rem',
  },
  image: {
    borderRadius: '1rem',
    overflow: 'hidden',
  },
  notif: {
    position: 'absolute',
    backgroundColor: colors.darkOrange,
    borderRadius: '1.6rem',
    width: '1.6rem',
    height: '1.6rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    ...fonts.FiraSansRegular,
    fontSize: '1.1rem',
    color: colors.pureWhite,
    top: '-.7rem',
    right: '-.7rem',
  },
  infos: {
    display: 'none',
    ...tablet({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    }),
  },
  name: {
    ...fonts.FiraSansMedium,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    color: colors.nightRider,
    marginBottom: '.4rem',
  },
  logout: {
    ...fonts.FiraSansRegular,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    color: colors.darkBlue,
    cursor: 'pointer',
  },
}
