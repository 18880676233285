import cloudinary from 'cloudinary-core'

import { Router } from '../routes'


export function assetUrl(asset, options = {}) {
  const cl = new cloudinary.Cloudinary({
    cloud_name: process.env.CLOUDINARY_CLOUD_NAME,
    secure: true,
  })

  if (asset.charAt(0) === '/' || asset.indexOf('http') >= 0) {
    return asset
  }

  return cl.url(asset.split('.')[0], {
    ...{
      fetchFormat: 'auto',
      quality: 'auto',
      width: 'auto',
      crop: 'scale',
      gravity: 'center',
    },
    ...options,
  })
}

export function getGoogleMapApiUrl(lat, long) {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${long}`
}

export function getYoutubeId(id) {
  if (!id) {
    return id
  }

  if (id.indexOf('/') === -1) {
    if (id.indexOf('&') === -1) {
      return id
    }
    return id.substring(0, id.indexOf('&'))
  }

  /* eslint-disable-next-line */
  const exp = new RegExp('(?:youtube(?:-nocookie)?\.com/(?:[^/]+/.+/|(?:v|e(?:mbed)?)/|.*[?&]v=)|youtu\.be/)([^"&?/ ]{11})', 'i')
  const match = id.match(exp)

  if (match) {
    return match[1]
  }
  return id
}

export function getYoutubeThumbnailUrl(videoUrl) {
  const videoId = getYoutubeId(videoUrl)

  return videoId ? `//img.youtube.com/vi/${videoId}/sddefault.jpg` : null
}


export function redirect(res, route, statusCode = 301) {
  if (res) {
    res.redirect(statusCode, route)
  } else if (process.browser) {
    Router.replaceRoute(route)
  }
}

export function routeParamSlug(full_slug) {
  if (!full_slug || Array.isArray(full_slug)) {
    return full_slug
  }

  const slug = full_slug.replace(/^\//, '')

  return slug.indexOf('/') !== -1 ? slug.split('/') : slug
}

export function addLeadingSlash(url) {
  if (url?.startsWith('/')) {
    return url
  }
  return `/${url}`
}

export function pageParamSlug(full_slug) {
  if (!full_slug || Array.isArray(full_slug)) {
    return full_slug
  }

  const slug = full_slug.replace(/^\/*/, '').replace(/\/*$/, '').replace(/\/\/+/g, '/')

  return slug.indexOf('/') !== -1 ? slug.split('/') : slug
}

export function getParamsFromUrl(url) {
  const params = {}

  if (!url) {
    return params
  }

  const query = url.split('?')[1]

  if (!query) {
    return params
  }

  query.split('&').forEach((param) => {
    const [key, value] = param.split('=')

    params[key] = value
  })

  return params
}
