import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import CloudinaryImage from '../CloudinaryImage'
import VideoPlayer from '../VideoPlayer'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const CompanyModal = (props) => {
  const classes = useStyles(props)
  const { className, title, isVideo, src, onCloseClick, onPrevClick, onNextClick, noNav } = props

  const renderPicture = () => (
    <CloudinaryImage
      id={src}
      className={classes.media}
      lazyLoad
    />
  )

  const renderVideo = () => (
    <VideoPlayer video={src} />
  )

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.header}>
        {title && <div className={classes.title}>{title}</div>}
        <Icon
          icon={iconsKeys.Close}
          color={colors.nightRider}
          onClick={onCloseClick}
          className={cx(classes.icon, classes.close)}
        />
      </div>
      {isVideo ? renderVideo() : renderPicture()}
      {!noNav && (
        <div className={classes.footer}>
          <Icon
            icon={iconsKeys.GalPrev}
            onClick={onPrevClick}
            className={classes.icon}
            iconProps={{ className: classes.nav }}
          />
          <Icon
            icon={iconsKeys.GalNext}
            onClick={onNextClick}
            className={classes.icon}
            iconProps={{ className: classes.nav }}
          />
        </div>
      )}
    </div>
  )
}

CompanyModal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  isVideo: PropTypes.bool,
  src: PropTypes.string.isRequired,
  noNav: PropTypes.bool,
  onCloseClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  onNextClick: PropTypes.func,
}

CompanyModal.defaultProps = {
  className: '',
  title: null,
  isVideo: false,
  noNav: false,
  onCloseClick: () => {},
  onPrevClick: () => {},
  onNextClick: () => {},
}

export default withMemo()(CompanyModal)
