import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'chat'

//
// Initial state
//
const initialState = {
  currentChannel: '',
  unreadMessagesCount: 0,
  listen: false,
}

//
// Actions
//
export const actions = {
  setCurrentChannel: createAction('Auth: setCurrentChannel', (currentChannel) => currentChannel),
  setUnreadMessagesCount: createAction('Auth: setUnreadMessagesCount', (unreadMessagesCount) => unreadMessagesCount),
  listen: createAction('Auth: listen', (listen) => listen),
  sendNotification: createAction('Auth: sendNotification', (data) => ({ data })),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setCurrentChannel]: (state, currentChannel) => Immutable.static.merge(state, {
    currentChannel,
  }),
  [actions.setUnreadMessagesCount]: (state, unreadMessagesCount) => Immutable.static.merge(state, {
    unreadMessagesCount,
  }),
  [actions.listen]: (state, listen) => Immutable.static.merge(state, {
    listen,
  }),
  [actions.sendNotification]: (state, data) => Immutable.static.merge(state, {
    data,
  }),
}, initialState)

//
// Selectors
//
const root = (state) => state[key]
const currentChannel = (state) => root(state)?.currentChannel
const unreadMessagesCount = (state) => root(state)?.unreadMessagesCount
const listen = (state) => root(state)?.listen

export const selectors = {
  currentChannel,
  unreadMessagesCount,
  listen,
}
