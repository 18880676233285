import PropTypes from 'prop-types'

import routes, { Router } from '../../routes'
import { offerType } from '../../graphql/enums/offerType'

import Category from './Category'
import User from './User'
import Candidacy from './Candidacy'


const mustUseLink = (type, link) => (type === offerType.OFFER_TYPE_CALL_FOR_TENDER || type === offerType.OFFER_TYPE_PROJECT) && link

const OfferCard = (props) => (props && {
  id: props.id,
  title: props.label || props.title,
  reference: props.reference,
  full_slug: props.full_slug,
  introduction: props.introduction,
  deadline: props.maximal_execution_at || props.deadline,
  companyName: props.company_name,
  budget: +props.budget,
  type: props.type,
  city: props.city,
  user: props.user ? User(props.user) : null,
  categories: props.categories?.map(Category),
  link: mustUseLink(props.type, props.link)
    ? props.link
    : Router.getRouteUrl(routes.offer, { slug: props.full_slug }),
  route: routes.offer.name,
  routeParams: { slug: props.full_slug },
  myCandidacy: Candidacy(props?.myCandidacy),
  date: props.published_at || props.date,
})

OfferCard.propTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  reference: PropTypes.string,
  introduction: PropTypes.string,
  deadline: PropTypes.string,
  budget: PropTypes.number,
  type: PropTypes.string,
  city: PropTypes.string,
  user: PropTypes.shape({
    fullName: PropTypes.string,
    avatar: PropTypes.string,
    slug: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(Category.propTypes),
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.string,
  }),
  date: PropTypes.string,
})

export default OfferCard
