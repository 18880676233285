import { fonts, colors, desktop } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    margin: 0,
    padding: '1rem',
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2rem',
    lineHeight: '2.4rem',
    textTransform: 'uppercase',
    color: colors.nightRider,
    marginBottom: '1.2rem',
  },
  text: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    color: colors.grey,
    paddingBottom: '4rem',
    marginBottom: '2rem',
    borderBottom: ['.1rem', 'solid', colors.gainsboro],
  },
  fieldContainer: {
    minHeight: '10rem',
    display: 'flex',
    alignItems: 'center',
    padding: '2rem 0',
    borderTop: ['.1rem', 'solid', colors.gainsboro],
    '&:last-child': {
      borderBottom: ['.1rem', 'solid', colors.gainsboro],
    },
  },
  fieldContainerCheckBox: {
    display: 'flex',
    alignItems: 'center',
  },
  fieldContainerItem: {
    width: '100%',
  },
  subFieldContainerItem: {
    marginTop: '1.5rem',
  },
  checkBoxTitle: {
    display: 'block',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    margin: '2rem 0',
  },
  checkBoxGroup: {
    width: '100%',
    backgroundColor: colors.whiteGrey,
    marginBottom: '1rem',
    padding: '2rem 1.5rem',
  },
  checkBox: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      width: '100%',
      ...fonts.FiraSansRegular,
      fontSize: '1.4rem',
      color: colors.nightRider,
    },
    '& div': {
      marginBottom: 0,
    },
    ...desktop({
      flexDirection: 'row',
    }),
  },
  subCheckBox: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      marginRight: '1.5rem',
      '& input[type="checkbox"][value="true"] + div + span': {
        color: colors.darkBlue,
      },
    },
    '& span': {
      width: '100%',
      ...fonts.FiraSansRegular,
      fontSize: '1.2rem',
      color: colors.grey,
    },
    '& div': {
      marginBottom: 0,
    },
    ...desktop({
      flexDirection: 'row',
    }),
  },
  success: {
    extend: 'errors',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '1rem',
  },
  successText: {
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    padding: '0.5rem 0',
    color: colors.darkBlue,
  },
  successIconAsset: {
    width: '2.4rem',
    height: '2.4rem',
  },
}
