export function enableAutoBlankExternalLinks(md) {
  const defaultRender = md.renderer.rules.link_open
    || ((tokens, idx, options, env, self) => self.renderToken(tokens, idx, options))

  // eslint-disable-next-line no-param-reassign
  md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
    const targetIndex = tokens[idx].attrIndex('target')
    const href = tokens[idx].attrGet('href')

    if (targetIndex < 0 && href && href.indexOf('http') === 0) {
      tokens[idx].attrPush(['target', '_blank']) // add new attribute
    }
    return defaultRender(tokens, idx, options, env, self)
  }
}


export function configure(md, config) {
  if (config) {
    const { autoBlankExternalLinks } = config

    if (autoBlankExternalLinks) {
      enableAutoBlankExternalLinks(md)
    }
  }

  return md
}
