import { aspectRatio, colors, fonts, placeholder, tablet } from '../../../../theme'


export default {
  container: {},
  label: {
    marginBottom: '1rem',
    display: 'block',
  },
  legend: {
    color: colors.nightRider,
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    marginBottom: '2rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    flexWrap: 'wrap',
    margin: '-1rem',
  },
  file: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    width: '50%',
    ...tablet({
      width: `${1 / 3 * 100}%`,
    }),
  },
  fileContainer: {
    border: `1px solid ${colors.gainsboro}`,
    position: 'relative',
    width: '100%',
    ...aspectRatio(220, 160),
  },
  uploadWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadIcon: {
    width: '25%',
    margin: '0.5rem 0',
    ...tablet({
      margin: '1rem 0',
    }),
    '& > svg': {
      width: '10rem',
      height: '4rem',
    },
  },
  uploadText: {
    margin: '0.5rem',
    ...tablet({
      margin: '1rem',
    }),
    textAlign: 'center',
    color: colors.darkBlue,
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
  uploadPreview: {
    width: '5rem',
    height: '7rem',
    position: 'absolute',
    top: '1rem',
    left: '1rem',
    borderRadius: '0.2rem',
    overflow: 'hidden',
    boxShadow: '0 1px 4px rgba(0,0,0,.2)',
    backgroundColor: colors.pureWhite,
  },
  fileName: {
    color: colors.nightRider,
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    margin: '1rem 2rem',
    width: '22rem',
    height: '16rem',
  },
  progress: {
    left: 0,
    bottom: 0,
    right: 0,
    height: 2,
    zIndex: 0,
    position: 'absolute',
    transform: 'scaleX(0)',
    transformOrigin: 'left',
    transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    pointerEvents: 'none',
    backgroundColor: colors.darkBlue,
    opacity: 1,
  },
  input: {
    border: `1px solid ${colors.gainsboro}`,
    borderRadius: '5px',
    padding: '1rem 1.2rem',
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    marginTop: '0.5rem',
    color: colors.pureBlack,
    ...placeholder(colors.grey),
  },
  preview: {
    marginTop: '1rem',
    fontSize: '1.4rem',
  },
  delete: {
    marginTop: '1rem',
    fontSize: '1.4rem',
  },
  browse: {
    backgroundColor: colors.aliceBlue,
    cursor: 'pointer',
    userSelect: 'none',
  },
  browseWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  browseIcon: {
    width: '25%',
    margin: '0.5rem 0',
    ...tablet({
      margin: '1rem 0',
    }),
    '& > svg': {
      width: '8rem',
      height: '6rem',
    },
  },
  browseLabel: {
    ...fonts.FiraSansRegular,
    color: colors.darkBlue,
    fontSize: '1.4rem',
    textAlign: 'center',
  },
  remaining: {
    ...fonts.FiraSansRegular,
    color: colors.grey,
    fontSize: '1.2rem',
    textAlign: 'center',
    padding: '0.5rem 0',
  },
}
