import { colors, fonts, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    color: colors.nightRider,
    padding: '1rem',
  },
  fields: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    ...tablet({
    }),
  },
  field: {
    padding: '1rem',
    width: '100%',
  },
  firstField: {
    '& label span': {
      fontFamily: fonts.FiraSansBold.fontFamily,
      fontWeight: fontWeights.bold,
      color: colors.darkBlue,
      fontSize: '2.0rem',
      lineHeight: '2.4rem',
      textTransform: 'uppercase',
    },
  },
  errors: {
    textAlign: 'center',
    padding: '1rem',
  },
  error: {
    color: colors.error,
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    padding: '0.5rem 0',
  },
  submitContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    textAlign: 'center',
    padding: '1rem 0',
    flexWrap: 'wrap',
  },
  button: {
    margin: '1rem',
  },
  successText: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    color: colors.darkBlue,
    fontSize: '2.2rem',
    lineHeight: '3.0rem',
    marginTop: '2rem',
  },
  backButton: {
    marginTop: '3rem',
    marginBottom: '2rem',
  },
}
