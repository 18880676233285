import PropTypes from 'prop-types'

import FileLibrary from '../../components/FileLibrary'

import OfferCard from './OfferCard'
import FileEntry from './FileEntry'


export const CandidacyStatus = {
  STATUS_CANDIDACY_SELECTED: 'STATUS_CANDIDACY_SELECTED',
  STATUS_CANDIDACY_REJECTED: 'STATUS_CANDIDACY_REJECTED',
}

const Candidacy = (props) => (props && {
  id: props.id,
  message: props.message,
  status: props.status,
  appliedAt: props.applied_at || props?.appliedAt,
  files: (Array.isArray(props?.files) ? {
    files: props?.files?.map(FileEntry),
  } : props?.files) || { files: null },
  offer: OfferCard(props?.offer),
  user: props?.user,
})

Candidacy.propTypes = PropTypes.shape({
  id: PropTypes.string,
  message: PropTypes.string,
  status: PropTypes.oneOf(Object.values(CandidacyStatus)),
  appliedAt: PropTypes.string,
  files: PropTypes.shape(FileLibrary.propTypes),
  offer: OfferCard.propTypes,
  user: PropTypes.object,
})

export default Candidacy
