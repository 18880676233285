/* eslint-disable max-lines */

import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import app from '../../config/app'
import withMemo from '../../decorators/WithMemo'
import { selectors as authSelectors } from '../../redux/auth/redux'
import routes, { Router } from '../../routes'
import { colors } from '../../theme'
import { setLocalStorageValue } from '../../utils/ReactUtils'
import A from '../A'
import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import { imageTypes } from '../CloudinaryImage/types'
import CommentFormWrapper from '../CommentForm/wrapper'
import CompanyDetailsMain from '../CompanyDetailsMain'
import CompanyDetailsSidebar from '../CompanyDetailsSidebar'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import ModalWithHeader from '../ModalWithHeader'
import Sharing from '../Sharing'
import SideColumnLayout from '../SideColumnLayout'
import RegisterModal from '../RegisterModal'

import styles from './styles'


const defaultAvatar = 'defaults/avatar'
const useStyles = createUseStyles(styles)

const CompanyDetails = (props) => {
  const classes = useStyles(props)
  const {
    className,
    company,
    sharing,
  } = props

  const isConnected = useSelector(authSelectors.isConnected)

  const { t } = useTranslation()

  const {
    handleResetPostCommentForm,
    commentMadeByUser,
    canComment,
    comments,
    modalTitle,
    commentsLabel,
    anonymousLabel,
    publishComment,
    recommendationLabel,
    noCommentLabel,
    backLabel,
    backClick,
    backRouteParams,
    companyLink,
    companyRoute,
    companyRouteParams,
  } = company

  const [isModalOpen, setModalOpen] = useState(false)

  const handleModalOpen = useCallback(() => {
    setModalOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setModalOpen(false)
    handleResetPostCommentForm()
  }, [handleResetPostCommentForm])

  const getDataLayerScript = () => `
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        type_entreprise: '${company.domains[0]?.name}',
        'nom-entreprise': '${company.title.toLocaleLowerCase()}',
        departement_entreprise: '${company.address?.zip?.substring(0, 2)}'
      })
    `

  const renderSideContent = () => (
    <CompanyDetailsSidebar
      company={company}
      className={classes.sidebar}
      isConnected={isConnected}
    />
  )

  const renderMain = () => (
    <CompanyDetailsMain
      company={company}
      sharing={sharing}
      className={classes.main}
      isConnected={isConnected}
      handleModalOpen={handleModalOpen}
    />
  )

  const renderModal = () => {
    if (!isConnected) {
      return (
        <RegisterModal
          modalTitle={t('companyDetails_comment_title_unauthenticated')}
          modalText={t('companyDetails_comment_text_unauthenticated')}
          modalCta={t('companyDetails_comment_cta_unauthenticated')}
          isOpen={!!isModalOpen}
          onClose={handleModalClose}
        />
      )
    }

    return (
      <ModalWithHeader
        className={classes.modal}
        onClose={handleModalClose}
        isOpen={!!isModalOpen}
        title={modalTitle}
      >
        <CommentFormWrapper modalCloseHandler={handleModalClose} />
      </ModalWithHeader>
    )
  }

  const isProfileComplete = useSelector(authSelectors.isProfileComplete)

  const onPublishClick = () => {
    if (!isProfileComplete) {
      setLocalStorageValue(app.COMPLETE_PROFILE_ATTEMPT, true)
      Router.replaceRoute(routes.userProfile.name)
    } else {
      handleModalOpen()
    }
  }

  const renderAvatar = useCallback((comment) => (
    <CloudinaryImage
      lazyLoad
      className={classes.commentAvatarImage}
      id={(comment.user?.anonymous ? null : comment.user?.avatar) || defaultAvatar}
      options={{
        width: '110',
        height: '110',
        crop: 'fill',
      }}
    />
  ), [classes.commentAvatarImage])

  const renderCompany = useCallback((comment) => (
    <>
      <Icon
        icon={iconsKeys.Company}
        color={colors.silver}
        className={classes.commentCompanyIcon}
      />
      <div className={classes.commentCompanyText}>
        {comment?.user?.company?.name}
      </div>
    </>
  ), [classes.commentCompanyIcon, classes.commentCompanyText])

  return (
    <div className={cx(classes.container, className)}>
      {renderModal()}
      {!!company?.banner && (
        <CloudinaryImage
          id={company.banner}
          type={imageTypes.Background}
          className={classes.headerContainer}
        >
          <div className={classes.headerWrapper}>
            {backClick !== null && backLabel !== null && (
              <ActionButton
                label={backLabel}
                onClick={backClick}
                routeParams={backRouteParams}
                color="white"
                icon={iconsKeys.SmallArrowLeft}
                colorIcon={colors.darkBlue}
                colorIconHover={colors.darkBlue}
                className={classes.headerButton}
              />
            )}
          </div>
        </CloudinaryImage>
      )}
      <div className={classes.wrapper}>
        <SideColumnLayout
          columnContent={renderSideContent()}
          mainContent={renderMain()}
        />
      </div>
      <div className={classes.commentsContainer}>
        <div className={classes.commentsWrapper}>
          <div className={classes.commentsHeader}>
            <div className={classes.commentsTitle}>
              {commentsLabel + (comments.length ? (` (${comments.length})`) : '')}
            </div>
            {isConnected && canComment && !commentMadeByUser && (
              <ActionButton
                isBig
                color="primary"
                label={publishComment}
                onClick={onPublishClick}
                className={classes.commentsPublishCta}
              />
            )}
          </div>
          {isConnected ? (
            <div className={classes.comments}>
              {!!comments && !comments?.length || comments == null
                ? (
                  <div className={classes.noComments}>
                    {noCommentLabel}
                  </div>
                ) : comments && comments?.length > 0 && comments?.map((comment) => (
                  <div
                    key={comment.id}
                    className={classes.comment}
                  >
                    <div className={classes.commentLeft}>
                      {comment?.user.anonymous ? (
                        <div className={classes.commentAvatar}>
                          {renderAvatar(comment)}
                        </div>
                      ) : (
                        <A
                          href={comment?.profileLink}
                          route={comment?.profileRoute}
                          routeParams={comment?.profileRouteParams}
                          className={classes.commentAvatar}
                        >
                          {renderAvatar(comment)}
                        </A>
                      )}
                      <div className={classes.commentInfoText}>
                        {comment?.user.anonymous ? (
                          <div className={classes.commentName}>
                            {anonymousLabel}
                          </div>
                        ) : (
                          <A
                            href={comment?.profileLink}
                            route={comment?.profileRoute}
                            routeParams={comment?.profileRouteParams}
                            className={classes.commentName}
                          >
                            {`${comment?.user?.first_name} ${comment?.user?.last_name}`}
                          </A>
                        )}
                        {comment.user.companyFunction && !comment.user.anonymous && (
                          <div className={classes.commentFunctionDescription}>
                            {comment?.user.companyFunction?.name}
                          </div>
                        )}
                        {!comment.user.anonymous && (
                          <A
                            href={companyLink}
                            route={companyRoute}
                            routeParams={companyRouteParams}
                            className={classes.commentCompany}
                          >
                            {renderCompany(comment)}
                          </A>
                        )}
                      </div>
                    </div>
                    <div className={classes.commentRight}>
                      <div className={classes.commentText}>
                        {comment?.content}
                      </div>
                      <div className={classes.bottomInfo}>
                        {comment?.is_recommendation ? (
                          <div className={classes.commentRecommendation}>
                            <Icon
                              icon={iconsKeys.FlagDone}
                              color={colors.darkBlue}
                              className={classes.commentRecommendationIcon}
                            />
                            <div className={classes.commentRecommendationText}>
                              {recommendationLabel}
                            </div>
                          </div>
                        ) : <div />}
                        {comment?.id === commentMadeByUser?.id && (
                          <ActionButton
                            isDisabled={!comment.canDelete}
                            icon={iconsKeys.Trash}
                            className={classes.deleteButton}
                            onClick={comment.handleDelete}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div className={classes.noComments}>
              {t('companyDetails_comment_unauthenticated')}
            </div>
          )}

        </div>
      </div>
      <script dangerouslySetInnerHTML={{ __html: getDataLayerScript() }} />
    </div>
  )
}

CompanyDetails.propTypes = {
  className: PropTypes.string,
  company: PropTypes.shape(CompanyDetailsMain.propTypes),
  sharing: PropTypes.shape(Sharing.propTypes),
}

CompanyDetails.defaultProps = {
  className: '',
  company: null,
  sharing: null,
}

export default withMemo()(CompanyDetails)
