import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import CompanyCard from '../CompanyCard'
import CloudinaryImage from '../CloudinaryImage'
import OfferSource from '../OfferSource'
import ActionButton from '../ActionButton'
import { imageTypes } from '../CloudinaryImage/types'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const defaultLogo = 'defaults/logo'
const defaultBanner = 'defaults/company-banner'
const useStyles = createUseStyles(styles)

const CompanyCardHighlight = (props) => {
  const classes = useStyles(props)
  const {
    className, link, ctaLabel, isFeatured, title, sector,
    location, logo, offers, cover, route, routeParams,
  } = props

  return (
    <CloudinaryImage
      id={cover || defaultBanner}
      type={imageTypes.Background}
      covered
      className={cx(classes.container, className)}
      options={{
        width: '940',
        height: '300',
        crop: 'fill',
      }}
      lazyLoad
    >
      <div className={classes.overlay} />
      <div className={classes.logo}>
        <CloudinaryImage
          id={logo || defaultLogo}
          options={{
            width: '68',
            height: '60',
            crop: 'lpad',
          }}
          lazyLoad
        />
      </div>
      <div className={classes.title}>{title}</div>
      <div className={classes.sector}>{sector}</div>
      <div className={classes.location}>{location}</div>
      <div className={classes.offers}>
        {`${offers} annonces actives`}
      </div>
      {link && ctaLabel && (
        <ActionButton
          link={link}
          label={ctaLabel}
          route={route}
          routeParams={routeParams}
          className={classes.ctaButton}
          color="white"
          isOutlined
          isSmall
        />
      )}
      {isFeatured && (
        <OfferSource
          source="featured"
          className={classes.featured}
          classNames={{ iconAssetProps: classes.iconAsset }}
        />
      )}
    </CloudinaryImage>
  )
}

CompanyCardHighlight.propTypes = {
  className: PropTypes.string,
  ...CompanyCard.propTypes,
  link: PropTypes.string,
  ctaLabel: PropTypes.string,
  isFeatured: PropTypes.bool,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
}

CompanyCardHighlight.defaultProps = {
  className: '',
  isFeatured: false,
  link: null,
  ctaLabel: null,
  route: null,
  routeParams: null,
}

export default withMemo()(CompanyCardHighlight)
