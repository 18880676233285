import { colors, fonts } from '../../../theme'


export default {
  container: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    color: colors.error,
  },
}
