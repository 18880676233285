import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const BurgerMenuButton = (props) => {
  const classes = useStyles(props)
  const { className, title, opened, onClick } = props

  return (
    <div
      className={cx(classes.container, className)}
      onClick={onClick}
      role="button"
      tabIndex="0"
      title={title}
    >
      <span className={cx(classes.bar, opened && classes.opened)} />
      <span className={cx(classes.bar, opened && classes.opened)} />
      <span className={cx(classes.bar, opened && classes.opened)} />
      <span className={cx(classes.bar, opened && classes.opened)} />
    </div>
  )
}

BurgerMenuButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  opened: PropTypes.bool,
}

BurgerMenuButton.defaultProps = {
  className: '',
  title: '',
  onClick: () => null,
  opened: false,
}

export default withMemo()(BurgerMenuButton)
