import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const TitleTextCTAPush = (props) => {
  const classes = useStyles(props)
  const { className, title, text, ctaLabel, ctaLink, route, routeParams, image } = props

  return (
    <div className={cx(classes.container, className)}>
      <CloudinaryImage
        id={image}
        className={classes.image}
        lazyLoad
      />
      <div className={classes.inner}>
        <div className={classes.content}>
          {title && <div className={classes.title}>{title}</div>}
          {text && <div className={classes.text}>{text}</div>}
        </div>
        {ctaLabel && ctaLink && (
          <ActionButton
            label={ctaLabel}
            link={ctaLink}
            route={route}
            routeParams={routeParams}
            color="white"
            isOutlined
            className={classes.cta}
          />
        )}
      </div>
    </div>
  )
}

TitleTextCTAPush.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  image: PropTypes.string,
}

TitleTextCTAPush.defaultProps = {
  className: '',
  image: null,
  ctaLabel: null,
  ctaLink: null,
  route: null,
  routeParams: null,
}

export default withMemo()(TitleTextCTAPush)
