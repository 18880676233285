import { colors, fonts, svgGlobal } from '../../theme'


export default {
  container: {
    padding: '2rem',
    background: colors.pureWhite,
    borderRadius: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    transition: 'all .2s ease',
    '&:hover': {
      boxShadow: '0px 5px 30px rgba(84, 84, 84, 0.2)',
      transform: 'translateY(-2px)',
    },
  },
  wrapper: {
    width: '100%',
    minWidth: '25rem',
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    width: '5.6rem',
    height: '5.6rem',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '1rem',
    },
  },
  user: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
  },
  name: {
    ...fonts.FiraSansBold,
    fontSize: '1.4rem',
    color: colors.nightRider,
    lineHeight: '1.6rem',
  },
  companyFunction: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    color: colors.darkBlue,
    lineHeight: '1.8rem',
  },
  companyName: {
    ...fonts.FiraSansRegular,
    fontSize: '1.1rem',
    color: colors.grey,
    lineHeight: '1.6rem',
  },
  right: {
    position: 'relative',
  },
  hoveredIcon: {
    display: 'none',
    position: 'absolute',
    top: '-5.5rem',
    left: '-2.5rem',
    backgroundColor: colors.darkBlue,
    borderRadius: '1rem',
    padding: '0.5rem',
    zIndex: '10',
    minWidth: 'max-content',
  },
  icon: {
    width: '2.5rem',
    height: 'auto',
    borderRadius: '50%',
    border: ({ companyDomain }) => `1px solid ${companyDomain.color || colors.grey}`,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: ({ companyDomain }) => companyDomain.color || colors.grey,
      ...svgGlobal(colors.pureWhite),
    },
  },
  foot: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  matching: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0.5rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.honeydew,
      borderRadius: '0.8rem',
    },
  },
  hoveredDomain: {
    display: 'none',
    position: 'absolute',
    top: '-1.5rem',
    left: '-5rem',
    backgroundColor: colors.darkBlue,
    borderRadius: '1rem',
    padding: '0.5rem',
    zIndex: '10',
    minWidth: '14rem',
    transform: 'translateY(-100%)',
  },
  hoveredBox: {
    width: '100%',
    height: '100%',
    position: 'relative',
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '100%',
      left: '50%',
      marginLeft: '-2rem',
      width: '0',
      height: '0',
      borderTop: `solid 2rem ${colors.darkBlue}`,
      borderLeft: 'solid 2rem transparent',
      borderRight: 'solid 2rem transparent',
    },
  },
  show: {
    display: 'flex',
  },
  item: {
    ...fonts.FiraSansRegular,
    fontSize: '1.1rem',
    color: colors.pureWhite,
    lineHeight: '1.6rem',
    backgroundColor: colors.frenchBlue,
    padding: '0.5rem 1rem',
    borderRadius: '0.3rem',
    marginBottom: '0.5rem',
    '&:not(:last-child)': {
      marginRight: '0.5rem',
    },
  },
  matchingIcon: {
    width: '2.4rem',
    height: 'auto',
  },
  matchingPercentage: {
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    color: colors.darkBlue,
    marginLeft: '1rem',
  },
  contactButton: {
    padding: '1rem',
  },
}
