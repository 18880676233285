import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Image from '../Image'
import { getCloudinaryInstance, getCloudinaryUrl } from '../../helpers/CloudinaryHelpers'
import withMemo from '../../decorators/WithMemo'

import { imageTypes } from './types'


const CloudinaryImage = (props) => {
  const { id, alt, className, lazyLoad, covered, srcSet, children, options, type, autoFormat, round } = props
  const [cl] = useState(getCloudinaryInstance())

  const [defaultOptions, setDefaultOptions] = useState({
    fetchFormat: 'auto',
    quality: 'auto',
    dpr: 2,
    width: 'auto',
    crop: 'scale',
    gravity: 'center',
  })

  useEffect(() => {
    setDefaultOptions({ ...defaultOptions, dpr: window.devicePixelRatio })
    cl.responsive()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getUrl = (opts = {}) => getCloudinaryUrl(id, autoFormat, round, { ...defaultOptions, ...opts })


  if (!id) {
    return null
  }

  const childrenWithProps = React.Children.map(children, (child) => {
    if (child) {
      return React.cloneElement(child, { getUrl })
    }
  })

  return (
    <Image
      src={getUrl(options)}
      alt={alt}
      className={className}
      lazyLoad={lazyLoad}
      covered={covered}
      srcSet={srcSet}
      type={type}
    >
      {type === imageTypes.Background && children}
      {type !== imageTypes.Background && children && childrenWithProps}
    </Image>
  )
}

CloudinaryImage.propTypes = {
  id: PropTypes.string,
  options: PropTypes.objectOf(PropTypes.any),
  alt: PropTypes.string,
  className: PropTypes.string,
  lazyLoad: PropTypes.bool,
  covered: PropTypes.bool,
  round: PropTypes.bool,
  srcSet: PropTypes.string,
  children: PropTypes.node,
  autoFormat: PropTypes.bool,
  type: PropTypes.string,
}

CloudinaryImage.defaultProps = {
  options: {},
  id: null,
  alt: null,
  className: null,
  lazyLoad: false,
  covered: false,
  round: false,
  srcSet: '',
  children: null,
  autoFormat: true,
  type: imageTypes.Image,
}

CloudinaryImage.types = imageTypes

export default withMemo()(CloudinaryImage)
