import { colors, fonts, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    backgroundColor: colors.nightRider,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    display: 'block',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.grayTranslucent,
    padding: '20rem 2rem 0rem',
    ...tablet({
      padding: '25rem 2rem 0rem',
    }),
    /*
    marginBottom: '27.3rem',
    ...tablet({
      marginBottom: '16.2rem',
    }),
     */
  },
  title: {
    width: '100%',
    maxWidth: '94rem',
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.1rem',
    lineHeight: '2.5rem',
    color: colors.pureWhite,
    marginBottom: '3rem',
    textAlign: 'center',
    ...tablet({
      textAlign: 'left',
      fontSize: '3rem',
      lineHeight: '3.6rem',
    }),
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 2.4rem 3.5rem rgba(0,0,0,0.25)',
    backgroundColor: colors.pureWhite,
    borderRadius: '2rem',
    padding: '3.7rem 4.5rem 3rem',
    maxWidth: '35rem',
    marginBottom: '-27.3rem',
    ...tablet({
      padding: '5.5rem 6.9rem 4.4rem 5.1rem',
      maxWidth: '104rem',
      marginBottom: '-16.2rem',
    }),
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...tablet({
      flexDirection: 'row',
      justifyContent: 'space-between',
      overflowY: 'auto',
    }),
  },
  cta: {
    alignSelf: 'center',
    marginTop: '4.5rem',
    textAlign: 'center',
    borderRadius: '20rem',
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
  },
}
