/* eslint-disable max-lines */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'

import { selectors as AuthSelectors } from '../../redux/auth/redux'
import withMemo from '../../decorators/WithMemo'
import FilterRelationBox from '../FilterRelationBox'
import { OfferCard } from '../index'
import Pagination from '../Pagination'
import PushPremium from '../PushPremium'
import PushMessage from '../PushMessage'
import RelationListCard from '../RelationListCard'
import ActionButton from '../ActionButton'

import styles from './styles'


const useStyles = createUseStyles(styles)

// eslint-disable-next-line complexity
const RelationsResults = (props) => {
  const {
    className,
    filterBoxProps,
    entries,
    emptyText,
    pagination,
    handleSubmit,
    entriesTitle,
    searchButtonLabel,
    pushPublishProps,
    handleContactClick,
    contactButtonLabel,
    isPremium,
    pushPremiumProps,
    allTranslationForFilter,
  } = props

  const isConnected = useSelector(AuthSelectors.isConnected)
  const [filterValues, setFilterValues] = useState(filterBoxProps.values || [])
  const [isFilterOpen, setFilterOpen] = useState(Array(filterBoxProps.filters.length).fill(false))
  const classes = useStyles({ ...props, isConnected })

  const filterContainerRef = React.createRef()
  const filterSubmenuRef = React.createRef()

  const handleFilterChange = useCallback((name, value) => {
    setFilterValues((before) => [
      ...before.filter((item) => item.name !== name),
      {
        name,
        value,
      },
    ])
  }, [])

  useEffect(() => {
    setFilterValues(filterBoxProps.values)
  }, [filterBoxProps.values])

  const handleToggleFiltering = (i) => {
    setFilterOpen((before) => [
      ...before.slice(0, i).map((item) => false),
      !before[i],
      ...before.slice(i + 1).map((item) => false),
    ])
  }

  const doSearch = useCallback(() => {
    handleSubmit({
      filterValues,
    })
  }, [handleSubmit, filterValues])

  const hasParent = (target, parent, currentRef) => {
    if (!target) {
      return false
    }
    if (target === parent || target.parentElement === currentRef) {
      return true
    }
    return hasParent(target.parentElement, parent, currentRef)
  }

  const handleWindowClick = (e) => {
    if (isFilterOpen.some((item) => item)) {
      if (!hasParent(e.target, filterContainerRef.current, filterSubmenuRef.current)) {
        setFilterOpen((before) => [
          ...before.map((item) => false),
        ])
      }
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleWindowClick)

    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  })

  const renderEntries = useMemo(() => (
    <RelationListCard
      title={entriesTitle}
      cards={entries}
      handleContactClick={handleContactClick}
      contactButtonLabel={contactButtonLabel}
    />
  ), [contactButtonLabel, entries, entriesTitle, handleContactClick])

  const renderEmpty = useMemo(() => (
    <p
      className={classes.empty}
      dangerouslySetInnerHTML={{ __html: emptyText }}
    />
  ), [classes.empty, emptyText])


  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.header}>
        <FilterRelationBox
          {...filterBoxProps}
          className={classes.filterBlock}
          values={filterValues}
          onChange={({ name, value }) => handleFilterChange(name, value)}
          containerRef={filterContainerRef}
          submenuRef={filterSubmenuRef}
          isOpen={isFilterOpen}
          toggleSelect={handleToggleFiltering}
          searchButton={{
            onClick: doSearch,
            label: searchButtonLabel,
          }}
          allTranslationForFilter={allTranslationForFilter}
        />
      </div>
      <div className={classes.content}>
        <div className={classes.wrapper}>

          <div className={classes.results}>
            {!isPremium ? (
              <ActionButton
                classNane={classes.button}
                label={pushPremiumProps.ctaLabel}
                route={pushPremiumProps.ctaRoute}
                icon={pushPremiumProps.ctaIcon}
                iconRight
                color="primary"
              />
            ) : entries?.length > 0 ? renderEntries : renderEmpty}
          </div>

          {entries?.length > 0 && isPremium && (
            <Pagination {...pagination} />
          )}
        </div>
        {Boolean(pushPublishProps) && (
          <PushPremium
            {...pushPublishProps}
          />
        )}
      </div>
    </div>
  )
}

RelationsResults.propTypes = {
  className: PropTypes.string,
  filterBoxProps: PropTypes.shape(FilterRelationBox.propTypes),
  entries: PropTypes.arrayOf(PropTypes.shape(OfferCard.propTypes)),
  emptyText: PropTypes.string,
  pagination: PropTypes.shape(Pagination.propTypes),
  handleSubmit: PropTypes.func,
  order: PropTypes.string,
  entriesTitle: PropTypes.string,
  searchButtonLabel: PropTypes.string,
  pushPublishProps: PropTypes.shape(PushMessage.propTypes),
  handleContactClick: PropTypes.func,
  contactButtonLabel: PropTypes.string,
  isPremium: PropTypes.bool,
  pushPremiumProps: PropTypes.shape(ActionButton.propTypes),
  allTranslationForFilter: PropTypes.string,
}

RelationsResults.defaultProps = {
  className: '',
  filterBoxProps: null,
  entries: [],
  emptyText: null,
  pagination: null,
  handleSubmit: () => undefined,
  order: '',
  entriesTitle: '',
  searchButtonLabel: '',
  pushPublishProps: null,
  handleContactClick: null,
  contactButtonLabel: '',
  isPremium: false,
  pushPremiumProps: null,
  allTranslationForFilter: '',
}

export default withMemo()(RelationsResults)
