import { fonts, colors, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    padding: '4rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '70rem',
    margin: '0 auto',
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2rem',
    lineHeight: '2.6rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: '2rem',
    width: '100%',
    color: colors.nightRider,
    ...tablet({
      textAlign: 'left',
    }),
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    marginBottom: '2rem',
  },
}
