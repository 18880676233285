import { fonts, colors, tablet, transition } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    height: ({ poste }) => (poste !== null ? '15.2rem' : '13rem'),
    padding: '2.5rem 0',
    background: colors.honeydew,
    ...tablet({
      height: '15rem',
      padding: '3.5rem 0',
    }),
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    color: colors.nightRider,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '2rem',
    lineHeight: '2.4rem',
    ...tablet({
      fontSize: '2.4rem',
      lineHeight: '2.6rem',
    }),
  },
  poste: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    color: colors.nightRider,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    paddingTop: '.5rem',
  },
  linksContainer: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '94rem',
    position: 'relative',
    listStyle: 'none',
    padding: 0,
    zIndex: 1,
    marginTop: '2.2rem',
    overflowX: 'scroll',
    ...tablet({
      overflowX: 'inherit',
    }),
  },
  linksWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    transitionProperty: 'transform',
    boxSizing: 'content-box',
    transform: 'translate3d(0, 0, 0)',
  },
  swiperOff: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& $link': {
      margin: '0 2rem',
    },
  },
  link: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    maxWidth: '12.6rem',
    position: 'relative',
    transitionProperty: 'transform',
    padding: '.7rem 1.2rem',
    color: colors.nightRider,
    borderRadius: '2rem',
    border: ['.1rem', 'solid', 'transparent'],
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: 'min-content',
    ...transition('all'),
    '&:not($active):hover': {
      backgroundColor: colors.pureWhite,
    },
  },
  notif: {
    position: 'absolute',
    backgroundColor: colors.darkOrange,
    borderRadius: '1.6rem',
    width: '1.6rem',
    height: '1.6rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    ...fonts.FiraSansRegular,
    fontSize: '1.1rem',
    color: colors.pureWhite,
    top: '0.5rem',
    right: '-.7rem',
    ...tablet({
      top: '-0.7rem',
    }),
  },
  outlined: {
    border: ['.1rem', 'solid', colors.nightRider],
  },
  active: {
    background: colors.nightRider,
    color: colors.pureWhite,
  },
}
