/* eslint-disable max-lines */
import { fonts, colors, sizes, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
  },
  paddingTop: {
    paddingTop: '3rem',
  },
  image: {
    position: 'relative',
    marginBottom: '2rem',
  },
  imagePortrait: {
    extend: 'image',
    marginBottom: '4rem',
  },
  creditsDefault: {
    position: 'absolute',
    bottom: '1rem',
    right: '1rem',
    left: 'auto',
    top: 'auto',
    backgroundColor: colors.pureWhite,
    ...fonts.FiraSansRegular,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    color: colors.grey,
    padding: '.5rem .8rem',
  },
  creditsPortrait: {
    extend: 'creditsDefault',
    backgroundColor: colors.honeydew,
    left: 0,
    right: 0,
    bottom: 0,
    top: 'auto',
  },
  typeLabel: {
    width: '100%',
    textAlign: 'left',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: colors.burntOrange,
    textTransform: 'uppercase',
    letterSpacing: '.1rem',
    marginBottom: '3rem',
    padding: '0 2rem',
  },
  layout: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '6rem',
    flexDirection: 'column',
    ...tablet({
      flexDirection: 'row',
      flexWrap: 'nowrap',
    }),
  },
  main: {
    flex: 1,
    padding: '0 2rem',
  },
  sidebar: {
    padding: '0 2rem',
    width: '100%',
    marginTop: '4rem',
    ...tablet({
      marginTop: 0,
      width: '26rem',
    }),
  },
  title: {
    margin: 0,
    ...fonts.FiraSansBold,
    fontSize: '3rem',
    lineHeight: '4rem',
    color: colors.nightRider,
    marginBottom: '2rem',
  },
  description: {
    margin: 0,
    ...fonts.FiraSansRegular,
    fontSize: '1.8rem',
    lineHeight: '2.6rem',
    color: colors.darkBlue,
    marginBottom: '2.5rem',
  },
  text: {
    '& h1': {
      fontFamily: fonts.FiraSansBold.fontFamily,
      fontWeight: fontWeights.bold,
      fontSize: '3.2rem',
      lineHeight: '3.8rem',
      color: colors.darkBlue,
      textTransform: 'uppercase',
      margin: 0,
      marginTop: '2rem',
      marginBottom: '3rem',
    },
    '& h2': {
      fontFamily: fonts.FiraSansBold.fontFamily,
      fontWeight: fontWeights.bold,
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      color: colors.nightRider,
      textTransform: 'uppercase',
    },
    '& h3': {
      fontFamily: fonts.FiraSansBold.fontFamily,
      fontWeight: fontWeights.bold,
      fontSize: '1.4rem',
      lineHeight: 'auto',
      color: colors.darkBlue,
      textTransform: 'uppercase',
    },
    '& h5': {
      fontFamily: fonts.FiraSansRegular.fontFamily,
      fontWeight: fontWeights.regular,
      fontSize: '1.4rem',
      lineHeight: 'auto',
      color: colors.nightRider,
      textTransform: 'uppercase',
    },
    '& p': {
      fontFamily: fonts.FiraSansRegular.fontFamily,
      fontWeight: fontWeights.regular,
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
      color: colors.nightRider,
    },
    '& a, & a:visited, & a:active': {
      textDecoration: 'underline',
      color: colors.darkBlue,
    },
    '& strong': {
      fontFamily: fonts.FiraSansBold.fontFamily,
      fontWeight: fontWeights.bold,
    },
    '& ul': {
      listStyle: 'none',
    },
    '& li': {
      fontFamily: fonts.FiraSansRegular.fontFamily,
      fontWeight: fontWeights.regular,
      fontSize: '1.4rem',
      lineHeight: '2rem',
      color: colors.nightRider,
      paddingLeft: '2rem',
      position: 'relative',
      '&:not(:last-child)': {
        paddingBottom: '1rem',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        background: colors.burntOrange,
        width: '.3rem',
        height: '.3rem',
        borderRadius: '50%',
        left: '1.2rem',
        top: '.7rem',
      },
    },
  },
  footer: {
    padding: '0 2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '8rem',
  },
  creditsContainer: {
    backgroundColor: colors.aliceBlue,
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  creditsContainerTop: {
    textAlign: 'left',
  },
  author: {
    ...fonts.FiraSansMedium,
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    color: colors.nightRider,
    marginBottom: '.4rem',
    '& strong': {
      color: colors.darkBlue,
    },
  },
  date: {
    extend: 'author',
    ...fonts.FiraSansRegular,
    lineHeight: '1.8rem',
    color: colors.grey,
  },
  sharing: {
    marginTop: '3rem',
  },
  prevNextLink: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(50% - 1rem)',
  },
  prevNextPostTitle: {
    ...fonts.FiraSansMedium,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: colors.nightRider,
    marginTop: '2rem',
  },
  prevNextPostTitle2: {
    extend: 'prevNextPostTitle',
    textAlign: 'right',
  },
  linkedPostsTitle: {
    margin: 0,
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    lineHeight: '2.4rem',
    textTransform: 'uppercase',
    color: colors.darkBlue,
    textAlign: 'center',
    padding: '0 2rem',
  },
}
