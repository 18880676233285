import { fonts, colors, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: colors.aliceBlue,
    borderRadius: '2rem',
    marginTop: '4rem',
    ...tablet({
      marginTop: '0',
      maxWidth: '20rem',
      borderRadius: '0 0 2rem 2rem',
      background: 'transparent',
    }),
  },
  logo: {
    width: '14rem',
    height: '8rem',
    overflow: 'hidden',
    borderRadius: '2rem',
    background: colors.pureWhite,
    padding: '1rem',
    marginTop: '-2.4rem',
    '& img': {
      minWidth: '100%',
      maxWidth: '100%',
      minHeight: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    },
    ...tablet({
      marginTop: '0',
      marginBottom: '2rem',
      width: '100%',
      height: '14rem',
      padding: '1.7rem 1.4rem',
    }),
  },
  title: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.2rem',
    lineHeight: '1.3rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.nightRider,
    margin: '1.3rem 0 1.7rem',
    ...tablet({
      display: 'none',
    }),
  },
  details: {
    width: '100%',
  },
  map: {
    width: '100%',
    ...tablet({
      height: '19rem',
      borderRadius: '2rem 2rem 0 0',
    }),
  },
  address: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: colors.darkBlue,
    padding: '1.2rem',
    color: colors.pureWhite,
    ...tablet({
      alignItems: 'flex-start',
    }),
  },
  addressTitle: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    textAlign: 'left',
    textTransform: 'uppercase',
    marginBottom: '.8rem',
  },
  fullAddress: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.1rem',
    lineHeight: '1.2rem',
    textAlign: 'center',
    color: colors.aliceBlue,
    ...tablet({
      textAlign: 'left',
    }),
  },
  social: {
    background: colors.darkBlue,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '1.2rem 1.2rem 2.6rem',
    color: colors.pureWhite,
    borderRadius: '0 0 2rem 2rem',
  },
  socialTitle: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: '1.3rem',
  },
  socialIcons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2.4rem',
  },
  socialIcon: {
    margin: '0 1rem',
  },
  website: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
