import colors from './colors'


export default {
  body: {
    padding: 0,
    margin: 0,
    fontSize: '1.6rem',
    width: '100%',
  },
  html: {
    fontSize: '62.5%',
  },
  'body, html': {
    backgroundColor: colors.pureWhite,
  },
  p: {
    margin: 0,
    padding: 0,
  },
  a: {
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
  'ol, ul, li': {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  img: {
    verticalAlign: 'middle',
  },
  'input, select, textarea, button': {
    '&:focus': {
      outline: 'none',
    },
  },
  'input::-ms-clear': {
    display: 'none',
  },
  '*': {
    boxSizing: 'border-box',
    '&:before, &:after': {
      boxSizing: 'border-box',
    },
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },
  button: {
    padding: 0,
    border: 0,
    background: 'none',
    font: 'inherit',
    color: 'inherit',
    margin: 0,
    width: 'auto',
    overflow: 'visible',
    lineHeight: 'normal',
  },
}
