import { colors, fonts, tablet, transition } from '../../theme'


export default ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem 0',
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '1rem',
    background: 'rgba(255, 255, 255, 0.2)',
    padding: '.5rem 0',
    position: 'relative',
    ...tablet({
      padding: '.5rem',
    }),
  },
  label: {
    display: 'flex',
    backgroundColor: colors.pureWhite,
    borderRadius: '2rem',
    borderBottomLeftRadius: ({ isOpen }) => isOpen && 0,
    borderBottomRightRadius: ({ isOpen }) => isOpen && 0,
    border: `.1rem solid ${colors.darkBlue}`,
    cursor: 'pointer',
    padding: '1.1rem 3rem 1rem 1.5rem',
    ...fonts.FiraSansMedium,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    textAlign: 'left',
    color: colors.darkBlue,
    '&:after': {
      transition: 'all .2s ease',
      transform: ({ isOpen }) => (isOpen ? 'rotate(180deg)' : ''),
      transformOrigin: 'center center',
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '6px 5px 0 5px',
      borderColor: `${colors.darkBlue} transparent transparent transparent`,
      borderRadius: 3,
      top: 0,
      right: '3rem',
      bottom: 0,
      width: 10,
      height: 6,
      margin: 'auto',
    },
  },
  count: {
    marginLeft: '.5rem',
    ...fonts.FiraSansMedium,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: colors.primary,
  },
  entries: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: '100%',
    top: '4.5rem',
    zIndex: 10,
    maxHeight: '20rem',
    overflowY: 'scroll',
    borderBottomLeftRadius: '2rem',
    borderBottomRightRadius: '2rem',
    borderBottom: `.1rem solid ${colors.darkBlue}`,
    scrollbarWidth: '7px',
    ...tablet({
      width: '98%',
      '@supports (-moz-appearance: none)': {
        width: '96%',
      },
    }),
    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0, 0, 0, .5)',
      WebkitBoxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  li: {
    position: 'relative',
    padding: '1.1rem 4rem 1rem 1.5rem',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    textAlign: 'left',
    color: colors.nightRider,
    backgroundColor: colors.pureWhite,
    ...transition('all'),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderTop: `.1px solid ${colors.gainsboro}`,
    borderLeft: `.1px solid ${colors.darkBlue}`,
    borderRight: `.1px solid ${colors.darkBlue}`,
    '&:hover': {
      backgroundColor: colors.honeydew,
    },
  },
  liSelected: {
    cursor: 'default',
    color: colors.darkBlue,
  },
  icon: {
    position: 'absolute',
    right: '1rem',
    top: 'auto',
    bottom: 'auto',
    left: 'auto',
    width: '2.4rem',
    height: '2.4rem',
  },
})
