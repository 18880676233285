/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import MarkdownText from '../MarkdownText'
import PostCard from '../PostCard'
import PostFragment from '../PostsResults'
import Sharing from '../Sharing'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors, sizes } from '../../theme'
import { imageTypes } from '../CloudinaryImage/types'
import { getWindowSize } from '../../utils/WindowSize'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const defaultImage = 'defaults/article-banner'
const useStyles = createUseStyles(styles)

const PostDetail = (props) => {
  const classes = useStyles(props)
  const {
    className,
    title,
    description,
    text,
    image,
    credits,
    author,
    share,
    publishedAt,
    type,
    typeLabel,
    textProps,
    previousPost,
    nextPost,
    linkedPosts,
  } = props

  const [isDesktop, setIsDesktop] = useState(false)

  function handleResize() {
    const windowSize = getWindowSize()

    if (!isDesktop && windowSize.width >= sizes.tablet) {
      setIsDesktop(true)
    } else if (isDesktop && windowSize.width < sizes.tablet) {
      setIsDesktop(false)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  function renderCoverImage() {
    return (
      <CloudinaryImage
        id={image || defaultImage}
        className={classes.image}
        type={imageTypes.Image}
        covered
        options={{
          width: isDesktop ? '940' : '767',
          height: isDesktop ? '410' : '431',
          crop: 'fill',
        }}
        lazyLoad
      >
        {credits && credits !== '' && (
          <span
            className={classes.creditsDefault}
            dangerouslySetInnerHTML={{ __html: `${textProps.creditsLabel} ${credits}` }}
          />
        )}
      </CloudinaryImage>
    )
  }

  function renderPortraitImage() {
    return (
      <CloudinaryImage
        id={image || defaultImage}
        className={classes.imagePortrait}
        type={imageTypes.Image}
        covered
        options={{
          width: isDesktop ? '220' : '320',
          height: isDesktop ? '380' : '180',
          crop: 'fill',
        }}
        lazyLoad
      >
        {credits && credits !== '' && (
          <span
            className={classes.creditsPortrait}
            dangerouslySetInnerHTML={{ __html: `${textProps.creditsLabel} ${credits}` }}
          />
        )}
      </CloudinaryImage>
    )
  }

  function renderHeader() {
    return (
      <header>
        {image && (type !== 'PORTRAIT' || (type === 'PORTRAIT' && !isDesktop)) && renderCoverImage()}
      </header>
    )
  }

  function renderMain() {
    return (
      <div className={classes.main}>
        {title && (
          <h1
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {description && (
          <h2
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {text && (
          <MarkdownText
            text={text}
            className={classes.text}
          />
        )}
      </div>
    )
  }

  function renderSidebar() {
    let label = ''

    if (type === 'PORTRAIT') {
      label += textProps.authorLabelPortrait
    } else {
      label += textProps.authorLabelDefault
    }

    if (author.firstName !== '') {
      label += ` <strong>${author.firstName}</strong>`
    }

    if (author.lastName !== '') {
      label += ` <strong>${author.lastName}</strong>`
    }

    if (author.company !== '') {
      label += ` - ${author.company}`
    }

    return (
      <div className={classes.sidebar}>
        {image && type === 'PORTRAIT' && isDesktop && renderPortraitImage()}
        <div className={classes.creditsContainer}>
          <div className={classes.creditsContainerTop}>
            {label !== '' && (
              <div
                className={classes.author}
                dangerouslySetInnerHTML={{ __html: label }}
              />
            )}
            {publishedAt && (
              <div
                className={classes.date}
                dangerouslySetInnerHTML={{ __html: `${textProps.dateLabel} ${publishedAt}` }}
              />
            )}
          </div>
          <Sharing
            {...share}
            className={classes.sharing}
          />
        </div>
      </div>
    )
  }

  function renderFooter() {
    return (
      <div className={classes.footer}>
        <div className={classes.prevNextLink}>
          <ActionButton
            link={previousPost.link}
            label={textProps.previousPostLabel}
            icon={iconsKeys.ArrowLeft}
            isOutlined
            isDisabled={!previousPost || !previousPost.link}
            colorIcon={!previousPost || !previousPost.link ? colors.silver : colors.darkBlue}
            colorIconHover={colors.pureWhite}
          />
          {previousPost && (
            <span
              className={classes.prevNextPostTitle}
              dangerouslySetInnerHTML={{ __html: previousPost.title }}
            />
          )}
        </div>
        <div className={classes.prevNextLink}>
          <ActionButton
            link={nextPost.link}
            label={textProps.nextPostLabel}
            icon={iconsKeys.ArrowRight}
            isOutlined
            isDisabled={!nextPost || !nextPost.link}
            colorIcon={!nextPost || !nextPost.link ? colors.silver : colors.darkBlue}
            colorIconHover={colors.pureWhite}
            iconRight
          />
          {nextPost && (
            <span
              className={classes.prevNextPostTitle2}
              dangerouslySetInnerHTML={{ __html: nextPost.title }}
            />
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={cx(classes.container, ((type === 'PORTRAIT' && isDesktop) || !image) && classes.paddingTop, className)}>
      {renderHeader()}
      {typeLabel && (
        <div
          className={classes.typeLabel}
          dangerouslySetInnerHTML={{ __html: typeLabel }}
        />
      )}
      <div className={classes.layout}>
        {renderMain()}
        {renderSidebar()}
      </div>
      <footer>
        {renderFooter()}
      </footer>
      {linkedPosts && linkedPosts.length >= 1 && (
        <div className={classes.linkedPosts}>
          <h5
            dangerouslySetInnerHTML={{ __html: textProps.linkedPostsTitle }}
            className={classes.linkedPostsTitle}
          />
          <PostFragment
            posts={linkedPosts}
          />
        </div>
      )}
    </div>
  )
}

PostDetail.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
  credits: PropTypes.string,
  author: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.string,
  }),
  share: PropTypes.shape(Sharing.propTypes),
  publishedAt: PropTypes.string,
  linkedPosts: PropTypes.arrayOf(PropTypes.shape(PostCard.propTypes)),
  textProps: PropTypes.shape(PropTypes.string),
  type: PropTypes.oneOf(['BREF', 'PORTRAIT', 'IMAGE', 'FEATURED']),
  typeLabel: PropTypes.string,
  previousPost: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
  }),
  nextPost: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
  }),
}

PostDetail.defaultProps = {
  className: '',
  title: null,
  description: null,
  image: null,
  text: null,
  credits: null,
  author: null,
  share: null,
  publishedAt: null,
  linkedPosts: [],
  textProps: null,
  type: 'IMAGE',
  typeLabel: null,
  previousPost: null,
  nextPost: null,
}

export default withMemo()(PostDetail)
