import { colors, desktop, fonts } from '../../../theme'


export default {
  channel: {

  },
  channelActive: {
    backgroundColor: colors.darkBlue,
    '& p': {
      color: colors.pureWhite,
    },

    ...desktop({
      backgroundColor: colors.whiteGrey,
      '& $channelName': {
        color: 'initial',
      },
      '& $channelDescription': {
        color: colors.silver,
      },
    }),
  },

  channelContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  channelAvatar: {
    display: 'block',
    width: '5rem',
    height: '5rem',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    margin: '0 1rem 0 0',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },

  channelInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  channelName: {
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    color: colors.black,
    marginBottom: '0.2rem',
  },
  channelDescription: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    color: colors.silver,
  },
  unreadItem: {
    display: 'flex',
    alignItems: 'center',
  },
  unreadCount: {
    ...fonts.FiraSansRegular,
    color: colors.pureWhite,
    fontSize: '1.2rem',
    display: 'block',
    padding: '0.6rem 0.8rem',
    backgroundColor: colors.tangerine,
    borderRadius: '0.5rem',
  },
}
