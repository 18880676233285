import { fullDesktopMediaQuery, desktopMediaQuery, tabletMediaQuery, maxDesktopMediaQuery } from './sizes'


const postcssJs = require('postcss-js')
const autoprefixer = require('autoprefixer')


export const prefixer = postcssJs.sync([autoprefixer])

/*
 * Mixins
 */

export const transition = (trans = 'all', duration = '0.2s', effect = 'ease') => ({
  ...Array.isArray(trans) ? {
    transition: trans.map((v) => `${v} ${duration} ${effect}`).join(', '),
  } : {
    transition: `${trans} ${duration} ${effect}`,
  },
})

export const svgGlobal = (color, transitionProperties = null) => ({
  '& *[fill]:not([fill="none"]):not(.no-fill)': {
    fill: color,
    extend: transitionProperties
      ? transition('fill', transitionProperties.duration, transitionProperties.effect)
      : null,
  },
  '& *[stroke]:not([stroke="none"]):not(.no-stroke)': {
    stroke: color,
    extend: transitionProperties
      ? transition('stroke', transitionProperties.duration, transitionProperties.effect)
      : null,
  },
  '& path': {
    extend: transitionProperties
      ? transition('fill', transitionProperties.duration, transitionProperties.effect)
      : null,
  },
})

export const placeholder = (color, font = {}) => {
  const getPlaceHolder = (c, f) => ({
    '& [placeholder], &::placeholder': {
      color: c,
      ...f,
    },
  })

  if (typeof color === 'function') {
    return {
      // ...prefixer(getPlaceHolder(color({}), font)),
      ...getPlaceHolder(color, font),
    }
  }

  return prefixer(getPlaceHolder(color, font))
}

export const aspectRatio = (width, height) => ({
  width: '100%',
  paddingTop: `${(height / width) * 100}%`,
  position: 'relative',
  height: 0,
  '& > *': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
})

/*
 * This project use mobile first integration
 * Default media is mobile
 */

export const tablet = (content) => ({
  [tabletMediaQuery]: {
    ...content,
  },
})

export const desktop = (content) => ({
  [desktopMediaQuery]: {
    ...content,
  },
})

export const fullDesktop = (content) => ({
  [fullDesktopMediaQuery]: {
    ...content,
  },
})

export const maxDesktop = (content) => ({
  [maxDesktopMediaQuery]: {
    ...content,
  },
})
