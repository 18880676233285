export const padWithZeros = (num, size) => {
  let s = num.toString()

  while (s.length < size) {
    s = `0${s}`
  }
  return s
}

export const formatSize = (size) => {
  if (size < 1024) {
    return `${size} bytes`
  } if (size < 1024 * 1024) {
    return `${(size / 1024.0).toFixed(0)} KB`
  } if (size < 1024 * 1024 * 1024) {
    return `${(size / 1024.0 / 1024.0).toFixed(1)} MB`
  }
  return `${(size / 1024.0 / 1024.0 / 1024.0).toFixed(1)} GB`
}
