import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import ActionButton from '../ActionButton'
import Paragraph from '../Paragraph'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const RegisterPush = (props) => {
  const classes = useStyles(props)
  const { className, title, text, ctaLabel, ctaRoute, ctaRouteParams, ctaLink, isCard } = props

  return (
    <div className={cx(classes.container, className, isCard ? classes.card : null)}>
      {title && <div className={classes.title}>{title}</div>}
      {text && (
        <Paragraph
          classNames={{ parentContainer: classes.textContainer }}
          className={classes.text}
          text={text}
        />
      )}
      {(ctaLink || ctaRoute) && ctaLabel && (
        <ActionButton
          label={ctaLabel}
          link={ctaLink}
          route={ctaRoute}
          routeParams={ctaRouteParams}
          color="primary"
          isOutlined
          className={classes.cta}
        />
      )}
    </div>
  )
}

RegisterPush.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaRoute: PropTypes.string,
  ctaRouteParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  isCard: PropTypes.bool,
}

RegisterPush.defaultProps = {
  className: '',
  text: null,
  title: null,
  ctaLink: null,
  ctaLabel: null,
  ctaRoute: null,
  ctaRouteParams: null,
  isCard: false,
}

export default withMemo()(RegisterPush)
