import { colors, fonts } from '../../theme'


export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    ...fonts.FiraSansRegular,
    color: colors.pureWhite,
    fontSize: '1.4rem',
  },
  title: {
    ...fonts.FiraSansBold,
    textTransform: 'uppercase',
    color: colors.pureWhite,
    marginBottom: '1.4rem',
  },
  address: {
    marginBottom: '1.4rem',
    color: colors.pureWhite,
  },
  tel: {
    marginBottom: '1.4rem',
  },
  telLabel: {
    paddingRight: '0.5rem',
  },
  mail: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  mailIcon: {
    verticalAlign: 'middle',
    marginRight: '0.5rem',
    position: 'relative',
    width: '1.4rem',
    height: '1.4rem',
  },
  link: {
    color: colors.pureWhite,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}
