import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import A from '../A'
import Swiper from '../../utils/Swiper'
import sizes from '../../theme/sizes'
import { getWindowSize } from '../../utils/WindowSize'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const AuthMenu = (props) => {
  const classes = useStyles(props)
  const { className, title, links, poste } = props
  const [swiper, setSwiper] = useState(null)
  const [isSwiperDestroyed, setIsSwiperDestroyed] = useState(false)
  const initialSlide = links.findIndex((link) => link.isActive) || 0

  const removeSwiper = () => {
    if (swiper && !isSwiperDestroyed) {
      swiper.destroy()
      setSwiper(null)
      setIsSwiperDestroyed(true)
    }
  }

  const initSwiper = () => {
    if (!swiper) {
      /* eslint-disable-next-line */
      const s = new Swiper('.swiper-container', {
        initialSlide,
        wrapperClass: classes.linksWrapper,
        slideClass: classes.link,
        direction: 'horizontal',
        grabCursor: true,
        spaceBetween: 30,
        freeMode: true,
        freeModeMomentum: false,
        slidesPerView: 5,
        slidesPerGroup: 1,
        centeredSlides: true,
        breakpoints: {
          [sizes.tablet - 1]: {
            slidesPerView: 3,
            centeredSlides: true,
          },
          [sizes.desktop - 1]: {
            slidesPerView: 5,
          },
        },
      })
      setSwiper(s)
    }
  }

  const handleResize = () => {
    const windowSize = getWindowSize()

    if (windowSize.width >= sizes.tablet) {
      removeSwiper()
    } else {
      initSwiper()
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const renderLinks = () => links.map((l, index) => {
    const { label, link, isOutlined, isActive, count } = l
    const outlinedClass = isOutlined ? classes.outlined : null
    const activeClass = isActive ? classes.active : null

    return (
      <A
        href={!isActive ? link : ''}
        className={cx(classes.link, outlinedClass, activeClass)}
        key={`link-${index}`}
        title={label}
      >
        {label}
        {count > 0 && (
          <span className={classes.notif}>
            {count}
          </span>
        )}
      </A>
    )
  })

  return (
    <div className={cx(classes.container, className)}>
      {title !== null && (
        <div className={classes.title}>
          {title}
        </div>
      )}
      {poste !== null && (
        <div className={classes.poste}>
          {poste}
        </div>
      )}
      <div className={cx(classes.linksContainer, 'swiper-container')}>
        <div className={cx(classes.linksWrapper, !swiper ? classes.swiperOff : null)}>
          {renderLinks()}
        </div>
      </div>
    </div>
  )
}

AuthMenu.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  poste: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
    isActive: PropTypes.bool,
    isOutlined: PropTypes.bool,
  })),
}

AuthMenu.defaultProps = {
  className: '',
  title: null,
  poste: null,
  links: [],
}

export default withMemo()(AuthMenu)
