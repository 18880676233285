import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import A from '../A'
import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import CompanyDetailsMain from '../CompanyDetailsMain'
import CompanyMetrics from '../CompanyMetrics'
import Icon from '../Icon'
import Map from '../Map'

import styles from './styles'


const defaultLogo = 'defaults/logo'
const useStyles = createUseStyles(styles)

const CompanyDetailsSidebar = (props) => {
  const classes = useStyles(props)
  const { className, company, isConnected } = props
  const {
    logo,
    metrics,
    map,
    addresses,
    phone,
    phoneLabel,
    website,
    links,
    socialIconsLabel,
    websiteLabel,
  } = company

  const patternValidUrl = /^((http|https):\/\/)/

  const correctedWebsite = website !== null && !patternValidUrl.test(website) ? `https://${website}` : website


  const renderMetrics = () => (
    <CompanyMetrics
      metrics={metrics}
      className={classes.metrics}
    />
  )

  const renderAddresses = () => addresses.map((address, index) => {
    let fullAddress = ''

    if (address.street !== 'null') {
      fullAddress += address.street
    }

    if (address.zip !== null) {
      fullAddress += `<br />${address.zip}`
    }

    if (address.city !== null) {
      fullAddress += ` ${address.city}`
    }

    if (fullAddress === '') {
      return null
    }

    return (
      <div
        className={classes.address}
        key={`address-${index}`}
      >
        {address?.title !== null && <div className={classes.addressTitle}>{address.title}</div>}
        <A
          className={classes.addressLink}
          href={address.link}
          target="_blank"
          title={fullAddress}
        >
          <div
            className={classes.fullAddress}
            dangerouslySetInnerHTML={{ __html: fullAddress }}
          />
        </A>
      </div>
    )
  })

  const renderCompanySocialIcons = () => links.map((element) => {
    const { id, icon, link } = element

    return (
      <Icon
        key={id}
        icon={icon}
        link={link}
        target="_blank"
        color={colors.pureWhite}
        className={classes.socialIcon}
      />
    )
  })

  const renderCloudinary = () => (
    <div className={classes.logo}>
      <CloudinaryImage
        id={logo || defaultLogo}
        options={{
          width: '180',
          height: '160',
          crop: 'lpad',
        }}
        lazyLoad
      />
    </div>
  )

  const render = () => {
    if (!isConnected) {
      return (
        <div className={cx(classes.container, className)}>
          {renderCloudinary()}
        </div>
      )
    }

    return (
      <div className={cx(classes.container, className)}>
        {renderCloudinary()}
        {!!metrics && renderMetrics()}
        <div className={classes.details}>
          {map && (
            <Map
              {...map}
              className={classes.map}
            />
          )}
          {!!addresses && addresses.length >= 1 && renderAddresses()}
          {!!phone && !!phoneLabel && (
            <div className={classes.phone}>
              <div className={classes.phoneLabel}>
                {phoneLabel}
              </div>
              <div className={classes.phoneText}>
                {phone}
              </div>
            </div>
          )}
          {((!!links && links.length >= 1) || (!!correctedWebsite && !!websiteLabel)) && (
            <div className={classes.social}>
              <div className={classes.socialTitle}>{socialIconsLabel}</div>
              <div className={classes.socialIcons}>
                {!!links && renderCompanySocialIcons()}
              </div>
              {!!correctedWebsite && (
                <div className={classes.website}>
                  <ActionButton
                    label={websiteLabel}
                    link={correctedWebsite}
                    color="white"
                    target="_blank"
                    isOutlined
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  return render()
}

CompanyDetailsSidebar.propTypes = {
  className: PropTypes.string,
  company: PropTypes.shape(CompanyDetailsMain.propTypes),
  isConnected: PropTypes.bool,
}

CompanyDetailsSidebar.defaultProps = {
  className: '',
  company: null,
  isConnected: true,
}

export default withMemo()(CompanyDetailsSidebar)
