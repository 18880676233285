import { colors, fonts } from '../../theme'


export default ({
  container: {
    opacity: 0.6,
    maxWidth: '4.8rem',
  },
})

export const selectStyles = (props) => ({
  control: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    borderWidth: '.1rem',
    borderRadius: '1.2rem',
    borderStyle: 'solid',
    borderColor: colors.darkBlue,
    minHeight: '2.4rem',
    padding: 0,
    ...state.isFocused && {
      borderWidth: '.1rem',
      borderColor: colors.darkBlue,
      boxShadow: 'none',
    },
    ...state.menuIsOpen && {
      borderWidth: '.1rem',
      borderRadius: '1.2rem 1.2rem 0 0',
      borderBottomColor: 'transparent',
      boxShadow: 'none',
    },
    '&:hover': {
      borderColor: colors.darkBlue,
    },
  }),
  container: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'transparent',
    position: 'absolute',
    right: '1.8rem',
    padding: 0,
    '& svg': {
      opacity: 0,
      display: 'none',
    },
    '&:after': {
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
      transformOrigin: 'center center',
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '6px 5px 0 5px',
      borderColor: `${colors.darkBlue} transparent transparent transparent`,
      borderRadius: 3,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: 10,
      height: 6,
      margin: 'auto',
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colors.darkBlue,
    padding: 0,
    paddingLeft: '.8rem',
    textTransform: 'uppercase',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    margin: 0,
    overflow: 'auto',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: colors.darkBlue,
    padding: 0,
    margin: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: '-.6rem',
    borderRadius: '1.2rem',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    border: `solid .1rem ${colors.darkBlue}`,
    borderTop: 0,
    boxShadow: 'none',
    borderColor: colors.darkBlue,
    overflow: 'hidden',
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: '.4rem',
    paddingBottom: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    color: colors.darkBlue,
    padding: '.2rem 0 .2rem .8rem',
    textAlign: 'left',
    textTransform: 'uppercase',
    cursor: 'pointer',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    ...state.isSelected && {
      backgroundColor: 'transparent',
    },
    ...state.isFocused && {
      backgroundColor: colors.aliceBlue,
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  }),
})
