const API_URL = process.env.API_URL
const API_URL_CLIENT = process.env.API_URL_CLIENT || API_URL
const GRAPH_API_URL = process.env.GRAPH_API_URL || `${API_URL}/graphql`
const GRAPH_API_URL_CLIENT = process.env.GRAPH_API_URL_CLIENT || `${API_URL_CLIENT}/graphql`
const BACKEND_URL = process.env.BACKEND_URL
const UPLOAD_ENDPOINT_IMAGE = `${API_URL}/api/image/upload`
const UPLOAD_ENDPOINT_IMAGE_CLIENT = `${API_URL_CLIENT}/api/image/upload`
const UPLOAD_ENDPOINT_FILE = `${API_URL}/api/file/upload`
const UPLOAD_ENDPOINT_FILE_CLIENT = `${API_URL_CLIENT}/api/file/upload`
const GEOCODING_API_URL = 'https://nominatim.openstreetmap.org'
const LINKEDIN_BASE_URI = 'https://www.linkedin.com/oauth/v2'
const LINKEDIN_CLIENT = process.env.LINKEDIN_CLIENT
const LINKEDIN_STATE = process.env.LINKEDIN_STATE


export default {
  API_URL: process.browser ? API_URL : API_URL_CLIENT,
  GRAPH_API_URL: process.browser ? GRAPH_API_URL_CLIENT : GRAPH_API_URL,
  UPLOAD_ENDPOINT_IMAGE: process.browser ? UPLOAD_ENDPOINT_IMAGE_CLIENT : UPLOAD_ENDPOINT_IMAGE,
  UPLOAD_ENDPOINT_FILE: process.browser ? UPLOAD_ENDPOINT_FILE_CLIENT : UPLOAD_ENDPOINT_FILE,
  BACKEND_URL,
  GEOCODING_API_URL,
  LINKEDIN_BASE_URI,
  LINKEDIN_CLIENT,
  LINKEDIN_STATE,
}
