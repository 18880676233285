import { fonts, colors, tablet, desktop } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    background: colors.darkBlue,
    position: 'relative',
  },
  inner: {
    padding: '4.5rem 1.5rem 5.3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...tablet({
      flexDirection: 'row',
      alignItems: 'center',
      padding: '4.5rem 0',
      margin: '0 auto',
      height: '20rem',
      maxWidth: '62rem',
    }),
    ...desktop({
      margin: '0 auto',
      maxWidth: '73rem',
    }),
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...tablet({
      alignItems: 'flex-start',
      marginRight: '4rem',
      maxWidth: '46rem',
    }),
    ...desktop({
      maxWidth: '64rem',
    }),
  },
  title: {
    color: colors.pureWhite,
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.2rem',
    lineHeight: '2.6rem',
    marginBottom: '.8rem',
    textAlign: 'center',
  },
  text: {
    color: colors.gray6,
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.5rem',
    lineHeight: '2.2rem',
    textAlign: 'center',
    marginBottom: '3.4rem',
    ...tablet({
      marginBottom: '0',
      textAlign: 'left',
    }),
  },
  cta: {},
  image: {
    position: 'absolute',
    width: '35rem',
    height: '35rem',
    left: '0',
    top: '50%',
    transform: 'translate(-40%, -50%)',
    ...tablet({
      transform: 'translate(-20%, -50%)',
    }),
    ...desktop({
      left: '50%',
      transform: 'translate(-150%, -50%)',
    }),
  },
}
