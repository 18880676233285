import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import Errors from '../../data/models/Errors'
import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'company'


//
// Initial state
//
const initialState = {
  slug: null,
  data: null,
  loaded: false,

  postComment: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setSlug: createAction('Company: setSlug', (slug) => ({ slug })),
  setData: createAction('Company: setData', (data) => ({ data })),
  setLoaded: createAction('Company: setLoaded', (loaded) => ({ loaded })),

  postComment: createAction('userProfile: postComment', (values) => ({ values })),
  postCommentSuccess: createAction('userProfile: postCommentSuccess', (data) => data),
  postCommentError: createAction('userProfile: postCommentError', (errors) => Errors(errors)),
  postCommentReset: createAction('userProfile: postCommentReset'),

  deleteComment: createAction('userProfile: deleteComment', (values) => ({ values })),
  deleteCommentSuccess: createAction('userProfile: deleteCommentSuccess', (data) => data),
  deleteCommentError: createAction('userProfile: deleteCommentError', (errors) => Errors(errors)),
  deleteCommentReset: createAction('userProfile: deleteCommentReset'),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setSlug]: (state, { slug }) => Immutable.static.merge(state, { slug }),
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),

  ...requestReducers(actions, 'postComment'),

  ...requestReducers(actions, 'deleteComment'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const slug = (state) => root(state).slug
const data = (state) => root(state).data
const postComment = (state) => root(state).postComment
const deleteComment = (state) => root(state).deleteComment

export const selectors = {
  isLoaded,
  slug,
  data,
  postComment,
  deleteComment,
}
