import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'
import Errors from '../../data/models/Errors'


export const key = 'userMessages'


//
// Initial state
//
const initialState = {
  slug: null,
  data: null,
  offers: null,
  loaded: false,
  contactProfile: null,
  users: [],
  getUsersByAutocompletion: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setSlug: createAction('userMessages: setSlug', (slug) => ({ slug })),
  setData: createAction('userMessages: setData', (data) => ({ data })),
  setLoaded: createAction('userMessages: setLoaded', (loaded) => ({ loaded })),
  //
  setContactProfile: createAction('userMessages: setContactProfile', (contactProfile) => ({ contactProfile })),
  //
  setOffers: createAction('Page: setOffers', (offers) => ({ offers })),
  //
  setUsers: createAction('userMessages: setUsers', (users) => ({ users })),
  //
  getUsersByAutocompletion: createAction('userMessages: getUsersByAutocompletion', (values) => ({ values })),
  getUsersByAutocompletionSuccess: createAction('userMessages: getUsersByAutocompletionSuccess', (data) => data),
  getUsersByAutocompletionError: createAction('userMessages: getUsersByAutocompletionError', (errors) => Errors(errors)),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setSlug]: (state, { slug }) => Immutable.static.merge(state, { slug }),
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  [actions.setContactProfile]: (state, { contactProfile }) => Immutable.static.merge(state, { contactProfile }),
  [actions.setOffers]: (state, { offers }) => Immutable.static.merge(state, { offers }),
  [actions.setUsers]: (state, { users }) => Immutable.static.merge(state, { users }),
  ...requestReducers(actions, 'getUsersByAutocompletion'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const slug = (state) => root(state).slug
const data = (state) => root(state).data
const contactProfile = (state) => root(state).contactProfile
const offers = (state) => root(state).offers
const users = (state) => root(state).users
const getUsersByAutocompletion = (state) => root(state).getUsersByAutocompletion


export const selectors = {
  isLoaded,
  slug,
  data,
  contactProfile,
  offers,
  users,
  getUsersByAutocompletion,
}
