import { colors } from '../../theme'


export default {
  container: {
  },
  link: {
    color: colors.darkBlue,
  },
}
