import { fonts, colors, svgGlobal, transition } from '../../theme'


export default {
  container: {
    userSelect: 'none',
    cursor: 'pointer',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '2rem',
    transition: 'color .3s, borderColor .3s, backgroundColor .3s',
    textDecoration: 'none',
    fontSize: '1.2rem',
    color: colors.pureWhite,
    ...fonts.FiraSansRegular,
    ...transition('opacity'),
    '&:hover': {
      '& $icon': {
        ...svgGlobal(colors.pureWhite),
      },
    },
  },
  mobileMenu: {
    '& $label': {
      color: colors.darkBlue,
    },
    '& $icon': {
      width: '2.5rem',
      height: '2.5rem',
      marginRight: '.6rem',
      ...svgGlobal(colors.darkBlue),
    },
    '&:hover': {
      opacity: 0.8,
      '& $icon': {
        ...svgGlobal(colors.darkBlue),
      },
    },
  },
  icon: {
    width: '2.4rem',
    height: '2.4rem',
    marginRight: '1.2rem',
    ...svgGlobal(colors.darkBlue),
  },
  iconAsset: {},
  label: {},
}
