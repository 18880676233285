import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import ActionButton from '../ActionButton'
import MemberCard from '../MemberCard'
import Pagination from '../Pagination'

import styles from './styles'


const useStyles = createUseStyles(styles)

const MemberCardBlock = (props) => {
  const classes = useStyles(props)
  const { className, title, buttons, cards, pagination } = props

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.content}>
        {title ? <div className={classes.title}>{title}</div> : null}
        {buttons && buttons.length > 0 ? (
          <div className={classes.buttons}>
            {buttons.map((item, i) => (
              <ActionButton
                key={i}
                {...item}
                isOutlined={!item.active ?? true}
              />
            ))}
          </div>
        ) : null}
        <div className={classes.cards}>
          {cards.map((item, i) => (
            <MemberCard
              key={i}
              {...item}
            />
          ))}
        </div>
      </div>

      {pagination && <Pagination {...pagination} />}
    </div>
  )
}

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape(ActionButton.propTypes)),
  cards: PropTypes.arrayOf(MemberCard.propTypes),
  pagination: Pagination.propTypes,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
}

MemberCardBlock.propTypes = propTypes

MemberCardBlock.defaultProps = {
  className: '',
  title: null,
  buttons: null,
  cards: null,
  pagination: null,
  routeParams: null,
}

export default withMemo()(MemberCardBlock)
