import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import A from '../A'
import withMemo from '../../decorators/WithMemo'
import Icon from '../Icon'

import styles from './styles'


const useStyles = createUseStyles(styles)

const CategoryTag = (props) => {
  const { className, name,
    // eslint-disable-next-line no-unused-vars
    color,
    icon, link, route, routeParams } = props
  const classes = useStyles(props)

  return (
    <A
      href={link}
      route={route}
      routeParams={routeParams}
      className={cx(classes.container, className)}
      title={name}
    >
      {Boolean(icon) && (
        <Icon
          className={classes.icon}
          icon={icon}
        />
      )}
      <div className={classes.name}>{name}</div>
    </A>
  )
}

CategoryTag.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
}

CategoryTag.defaultProps = {
  className: '',
  name: null,
  color: null,
  icon: null,
  link: null,
  route: null,
  routeParams: null,
}

export default withMemo()(CategoryTag)
