import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import A from '../A'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FooterMenuButton = (props) => {
  const classes = useStyles(props)
  const {
    label,
    horizontal,
    link,
    route,
    routeParams,
    target,
    onClick,
    seoTitle,
    isBold,
    isDisabled,
    isSelected,
  } = props

  const renderDefault = () => (
    <div
      className={cx(classes.cta, isBold && classes.bold)}
      onClick={!isDisabled ? onClick : undefined}
      dangerouslySetInnerHTML={{ __html: label }}
    />
  )

  const renderAsLink = () => (
    <div
      role="presentation"
      onClick={onClick}
    >
      <A
        href={route || link}
        routeParams={routeParams}
        target={target}
        className={cx(classes.cta, isBold && classes.bold)}
        title={seoTitle || label}
      >
        <span dangerouslySetInnerHTML={{ __html: label }} />
      </A>
    </div>
  )

  return (
    <div className={cx(classes.container, horizontal && classes.containerNoMargin)}>
      {link && !isDisabled && !isSelected ? renderAsLink() : renderDefault()}
    </div>
  )
}

FooterMenuButton.propTypes = {
  label: PropTypes.string.isRequired,
  horizontal: PropTypes.bool,
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  seoTitle: PropTypes.string,
  target: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  isBold: PropTypes.bool,
  onClick: PropTypes.func,
}

FooterMenuButton.defaultProps = {
  link: null,
  horizontal: false,
  seoTitle: null,
  route: null,
  routeParams: null,
  target: null,
  isDisabled: false,
  isSelected: false,
  isBold: false,
  onClick: () => undefined,
}

export default withMemo()(FooterMenuButton)
