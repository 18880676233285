export default {
  image: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    '&.covered img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      fontFamily: "'object-fit: cover;'",
    },
    '&.storybook-cover': {
      width: '300px',
      height: '300px',
      border: '2px red solid',
    },
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}
