import { colors, fonts, placeholder } from '../../../../theme'


export default {
  container: {},
  wrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
  },
  label: {},
  inputContainer: {
    border: [[1, 'solid', 'transparent']],
    borderRadius: '1rem',
    borderColor: ({ focused, error }) => (
      (focused && !error && colors.primary)
      || (error && colors.error)
      || colors.gainsboro
    ),
    background: colors.pureWhite,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '0',
  },
  input: {
    background: 'transparent',
    flex: 1,
    height: '100%',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.4rem',
    color: ({ disabled }) => (
      (disabled && colors.silver)
      || colors.nightRider
    ),
    ...placeholder(({ disabled }) => (
      (disabled && colors.silver)
      || colors.grey
    )),
    overflow: 'hidden',
    padding: [['0.9rem', ' 1rem']],

    '& > span': {
      display: 'flex',
      flexFlow: 'row wrap',
    },

    '& .react-tagsinput-tag': {
      backgroundColor: colors.nightRider,
      borderRadius: 2,
      color: colors.pureWhite,
      display: 'inline-block',
      padding: 5,
      margin: '2px 5px 2px',
    },

    '& .react-tagsinput-remove': {
      cursor: 'pointer',
      fontWeight: 'bold',
    },

    '& .react-tagsinput-tag a:before': {
      content: '" ×"',
    },

    '& .react-tagsinput-input': {
      background: 'transparent',
      border: 0,
      outline: 'none',
      width: '100%',
      minWidth: 80,
      flex: 1,
      display: 'flex',
      padding: [['.2rem', ' .5rem']],
      fontSize: '1.4rem',
    },
  },
  errorText: {},
  helpText: {},
}
