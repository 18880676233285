import { desktop } from '../../theme'


export default {
  container: {
    width: '30rem',
    height: '15rem',
    ...desktop({
      width: '20rem',
      height: '19rem',
    }),
  },
  link: {
    //
  },
}
