import ClassNames from 'classnames'
import _values from 'lodash/values'
import PropTypes from 'prop-types'
import React from 'react'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import A from '../A'

import { icons, iconsKeys } from './Icon.assets'
import useStyles from './styles'


function Icon(props) {
  const {
    className,
    classname,
    style,
    icon,
    color,
    link,
    target,
    onClick,
    seoTitle,
    iconProps,
    ...otherProps
  } = props

  const classes = useStyles(props)

  const IconComponent = icons[icon]

  if (!IconComponent) {
    return null
  }

  const renderAsLink = (
    <div
      className={ClassNames(classes.icon, className, classname, color)}
      style={style}
      onClick={onClick}
      role="presentation"
      {...otherProps}
    >
      <A
        href={link}
        title={seoTitle}
        target={target}
        rel="noreferrer"
      >
        <IconComponent {...iconProps} />
      </A>
    </div>
  )

  const renderDefault = (
    <span
      className={ClassNames(classes.icon, className, classname, color)}
      style={style}
      onClick={onClick}
      role="presentation"
      title={seoTitle}
      {...otherProps}
    >
      <IconComponent {...iconProps} />
    </span>
  )

  return link ? renderAsLink : renderDefault
}

Icon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  icon: PropTypes.oneOf(_values(iconsKeys)).isRequired,
  color: PropTypes.oneOf(_values(colors)),
  style: PropTypes.objectOf(PropTypes.string),
  classname: PropTypes.string,
  className: PropTypes.string,
  iconProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  onClick: PropTypes.func,
  link: PropTypes.string,
  target: PropTypes.string,
  seoTitle: PropTypes.string,
}

Icon.defaultProps = {
  classes: null,
  style: null,
  color: null,
  classname: null,
  className: null,
  onClick: () => undefined,
  link: null,
  target: null,
  seoTitle: null,
  iconProps: {
    className: null,
  },
}

export default withMemo()(Icon)
