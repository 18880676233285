import { fonts, colors, tablet, aspectRatio } from '../../../../theme'


export default {
  container: {},
  label: {
    marginBottom: '1rem',
    display: 'block',
  },
  legend: {
    color: colors.nightRider,
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    marginBottom: '2rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    flexWrap: 'wrap',
    margin: '-1rem',
  },
  file: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    width: '50%',
    ...tablet({
      width: `${1 / 3 * 100}%`,
    }),
  },
  imageContainer: {
    border: `1px solid ${colors.gainsboro}`,
    position: 'relative',
    width: '100%',
    ...aspectRatio(220, 160),
  },
  image: {
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  progress: {
    left: 0,
    bottom: 0,
    right: 0,
    height: 2,
    zIndex: 0,
    position: 'absolute',
    transform: 'scaleX(0)',
    transformOrigin: 'left',
    transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    pointerEvents: 'none',
    backgroundColor: colors.darkBlue,
    opacity: 1,
  },
  checkbox: {
    alignSelf: 'flex-start',
    padding: '1rem 0',
  },
  delete: {
    marginTop: '1rem',
    fontSize: '1.4rem',
  },
  browse: {
    backgroundColor: colors.aliceBlue,
    cursor: 'pointer',
    userSelect: 'none',
  },
  browseWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  browseIcon: {
    width: '25%',
    margin: '0.5rem 0',
    ...tablet({
      margin: '1rem 0',
    }),
  },
  browseLabel: {
    ...fonts.FiraSansRegular,
    color: colors.darkBlue,
    fontSize: '1.4rem',
    textAlign: 'center',
  },
  remaining: {
    ...fonts.FiraSansRegular,
    color: colors.grey,
    fontSize: '1.2rem',
    textAlign: 'center',
    padding: '0.5rem 0',
  },
}
