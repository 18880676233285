export default {
  CDI: 'cdi',
  CDD: 'cdd',
  STAGE: 'stage',
  APPRENTISSAGE: 'apprentissage',
  DEFAULT: 'default',
}

export const resolveType = (type) => ({
  CONTRACT_TYPE_CDI: 'cdi',
  CONTRACT_TYPE_CDD: 'cdd',
  CONTRACT_TYPE_INTERN: 'stage',
  CONTRACT_TYPE_APPRENTICE: 'apprentissage',
}[type] || null)
