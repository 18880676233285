import { colors, fonts, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    height: '32rem',
    background: 'center url("/images/register-push/card-bg.jpg")',
    borderRadius: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    position: 'relative',
    ...tablet({
      width: '20rem',
    }),
  },
  inner: {
    width: '100%',
    height: '20rem',
    zIndex: '3',
    padding: '2rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  icon: {
    marginBottom: '1.1rem',
    '& img': {
      width: '2.4rem',
    },
  },
  text: {
    color: colors.pureWhite,
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    textAlign: 'left',
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    marginBottom: '1.5rem',
    '& b,strong': {
      fontFamily: fonts.FiraSansBold.fontFamily,
      fontWeight: fontWeights.bold,
      fontSize: '1.6rem',
      lineHeight: '1.8rem',
    },
  },
  cta: {
    margin: '0 auto',
  },
  background: {
    zIndex: '2',
    width: '120%',
    height: '20rem',
    background: colors.darkBlue,
    opacity: '.8',
    position: 'absolute',
    transform: 'rotate(7deg)',
    bottom: '-2rem',
    left: '-2rem',
  },
}
