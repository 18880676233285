import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import AppConfig from '../../config/app'


export const key = 'app'

export const pageTypeKey = {
  HOME: 'HOME',
  LOGIN: 'LOGIN',
  FORGOT: 'FORGOT',
  RESET: 'RESET',
  MANAGER_ACTIVATION: 'MANAGER_ACTIVATION',
  ON_BOARDING: 'ON_BOARDING',
  RSS: 'RSS',
  OFFERS: 'OFFERS',
  EVENTS: 'EVENTS',
  DIRECTORY: 'DIRECTORY',
  POSTS: 'POSTS',
  ARTICLES: 'ARTICLES',
}

export const pageType = {
  HOME: 'SERVICE_CONTAINER_BLOCK',
  LOGIN: 'LOGIN_BLOCK',
  FORGOT: 'FORGOT_PASSWORD_BLOCK',
  RESET: 'RESET_PASSWORD_BLOCK',
  MANAGER_ACTIVATION: 'MANAGER_ACTIVATION_BLOCK',
  ON_BOARDING: 'ON_BOARDING_BLOCK',
  RSS: 'RSS_CONTAINER_BLOCK',
  OFFERS: 'OFFER_LIST_BLOCK',
  EVENTS: 'EVENT_LIST_BLOCK',
  DIRECTORY: 'DIRECTORY_BLOCK',
  POSTS: 'POST_CONTAINER_BLOCK',
  RELATIONS: 'RELATION_MATCH_LIST_BLOCK',
  ARTICLES: 'ARTICLE_CONTAINER_BLOCK',
}

export const resourceType = {
  PAGE: 'page',
  OFFER: 'offer',
  COMPANY: 'company',
  EVENT: 'event',
  PROFILE: 'profile',
  POST: 'post',
  ARTICLE: 'article',
}

//
// Initial state
//
const initialState = {
  route: null,
  preview: AppConfig.IS_PREVIEW,
  resource: {
    type: null,
    id: null,
  },
  menus: null,
  menuOpen: false,
  pages: {},
  breadcrumb: null,
  bannerBlock: null,
  searchBlock: null,
  certifications: null,
  categories: null,
  companyFunctions: null,
  sectors: null,
  cookiesSettings: false,
  seo: null,
  jsonld: null,
  onBoardingStep: 0,
  onBoardingType: null,
  articleCategories: null,
}

//
// Actions
//
export const actions = {
  setRoute: createAction('App: setRoute', (route) => route),
  mount: createAction('App: mount', null),
  setPreview: createAction('App: setPreview', (preview) => preview),
  setResource: createAction('App: setResource', (type, id) => ({
    type,
    id,
  })),
  setMenus: createAction('App: setMenus', (menus) => menus),
  setMenuOpen: createAction('App: setMenuOpen', (menuOpen) => menuOpen),
  setPages: createAction('App: setPages', (pages) => pages),
  setBreadcrumb: createAction('App: setBreadcrumb', (breadcrumb) => breadcrumb),
  setBannerBlock: createAction('App: setBannerBlock', (bannerBlock) => bannerBlock),
  setSearchBlock: createAction('App: setSearchBlock', (searchBlock) => searchBlock),
  setCertifications: createAction('App: setCertifications', (certifications) => certifications),
  setCategories: createAction('App: setCategories', (categories) => categories),
  setCompanyFunctions: createAction('App: setCompanyFunctions', (companyFunctions) => companyFunctions),
  setSectors: createAction('App: setSectors', (sectors) => sectors),
  scrollTop: createAction('App: scrollTop'),
  openCookiesSettings: createAction('App: Open cookies settings'),
  closeCookiesSettings: createAction('App: Close cookies settings'),
  setSeo: createAction('App: setSeo', (seo) => seo),
  setJsonld: createAction('App: setJsonld', (jsonld) => jsonld),
  setOnBoardingStep: createAction('App: setOnBoardingStep', (onBoardingStep) => onBoardingStep),
  setOnBoardingType: createAction('App: setOnBoardingType', (onBoardingType) => onBoardingType),
  setArticleCategories: createAction('App: setArticleCategories', (articleCategories) => articleCategories),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setRoute]: (state, route) => Immutable.merge(state, { route }),
  [actions.setPreview]: (state, preview) => Immutable.merge(state, { preview }),
  [actions.setResource]: (state, resource) => Immutable.merge(state, { resource }),
  [actions.setMenus]: (state, menus) => Immutable.merge(state, { menus }),
  [actions.setMenuOpen]: (state, menuOpen) => Immutable.merge(state, { menuOpen }),
  [actions.setPages]: (state, pages) => Immutable.merge(state, { pages }),
  [actions.setBreadcrumb]: (state, breadcrumb) => Immutable.merge(state, { breadcrumb }),
  [actions.setBannerBlock]: (state, bannerBlock) => Immutable.merge(state, { bannerBlock }),
  [actions.setSearchBlock]: (state, searchBlock) => Immutable.merge(state, { searchBlock }),
  [actions.setCertifications]: (state, certifications) => Immutable.merge(state, { certifications }),
  [actions.setCategories]: (state, categories) => Immutable.merge(state, { categories }),
  [actions.setCompanyFunctions]: (state, companyFunctions) => Immutable.merge(state, { companyFunctions }),
  [actions.setSectors]: (state, sectors) => Immutable.merge(state, { sectors }),
  [actions.scrollTop]: (state, url) => {
    if (
      !state.pages
      || (
        !((state.pages[pageType.OFFERS]) && url.indexOf(state.pages[pageType.OFFERS].link) === 0)
        && !((state.pages[pageType.DIRECTORY]) && url.indexOf(state.pages[pageType.DIRECTORY].link) === 0)
      )
    ) {
      window.scrollTo(0, 0)
    }
    return state
  },
  [actions.openCookiesSettings]: (state) => Immutable.merge(state, { cookiesSettings: true }),
  [actions.closeCookiesSettings]: (state) => Immutable.merge(state, { cookiesSettings: false }),
  [actions.setSeo]: (state, seo) => Immutable.merge(state, { seo }),
  [actions.setJsonld]: (state, jsonld) => Immutable.merge(state, { jsonld }),
  // eslint-disable-next-line max-len
  [actions.setOnBoardingStep]: (state, onBoardingStep) => Immutable.merge(state, { onBoardingStep }),
  // eslint-disable-next-line max-len
  [actions.setOnBoardingType]: (state, onBoardingType) => Immutable.merge(state, { onBoardingType }),
  [actions.setArticleCategories]: (state, articleCategories) => Immutable.merge(state, { articleCategories }),
}, initialState)

//
// Selectors
//
const root = (state) => state[key]
const route = (state) => root(state).route
const preview = (state) => root(state).preview
const resource = (state) => root(state).resource
const menus = (state) => root(state).menus
const menuOpen = (state) => root(state).menuOpen
const pages = (state) => root(state).pages
const page = (state) => (id) => pages(state)[id] || {}
const breadcrumb = (state) => root(state).breadcrumb
const bannerBlock = (state) => root(state).bannerBlock
const searchBlock = (state) => root(state).searchBlock
const certifications = (state) => root(state).certifications || []
const categories = (state) => root(state).categories || []
const companyFunctions = (state) => root(state).companyFunctions || []
const sectors = (state) => root(state).sectors || []
const cookiesSettings = (state) => root(state).cookiesSettings
const seo = (state) => root(state).seo
const jsonld = (state) => root(state).jsonld
const onBoardingStep = (state) => root(state).onBoardingStep
const onBoardingType = (state) => root(state).onBoardingType
const articleCategories = (state) => root(state).articleCategories

export const selectors = {
  route,
  preview,
  resource,
  menus,
  menuOpen,
  pages,
  page,
  breadcrumb,
  bannerBlock,
  searchBlock,
  certifications,
  categories,
  companyFunctions,
  sectors,
  cookiesSettings,
  seo,
  jsonld,
  onBoardingStep,
  onBoardingType,
  articleCategories,
}
