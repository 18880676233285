import { colors } from '../../../../theme'


export default {
  container: {},
  icon: {
    cursor: 'pointer',
    '&:hover': {
      '& *': {
        stroke: colors.darkBlue,
        strokeWidth: 0.25,
      },
    },
  },
}
