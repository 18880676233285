import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import RelationCard from '../RelationCard'

import styles from './styles'


const useStyles = createUseStyles(styles)

const RelationListCard = (props) => {
  const classes = useStyles(props)
  const { className, title, cards, handleContactClick, contactButtonLabel } = props

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.title}>{title}</div>
        <div className={classes.cards}>
          {cards
              && cards.map((card, i) => (
                <RelationCard
                  key={i}
                  {...card}
                  contactClick={handleContactClick}
                  contactButtonLabel={contactButtonLabel}
                />
              ))}
        </div>

      </div>
    </div>
  )
}

RelationListCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(RelationCard.propTypes)),
  handleContactClick: PropTypes.func,
  contactButtonLabel: PropTypes.string,
}

RelationListCard.defaultProps = {
  className: '',
  title: '',
  cards: [],
  handleContactClick: null,
  contactButtonLabel: '',
}

export default withMemo()(RelationListCard)
