import { colors, fonts, tablet } from '../../theme'


export default {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: colors.honeydew,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '94rem',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: '4rem 2rem',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    lineHeight: '2.6rem',
    margin: '2rem 0 5rem',
    width: '100%',
    color: colors.nightRider,
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    ...tablet({
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'stretch',
      justifyContent: 'center',
      marginLeft: '-1rem',
      marginRight: '-1rem',
      '& > *': {
        maxWidth: '28rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      },
    }),
  },
  card: {
    marginBottom: '2rem',
    width: '100%',
  },
}
