import PropTypes from 'prop-types'


const FileEntry = (props) => (props && {
  id: props.id,
  key: props.pdf_url,
  title: props.title,
  path: props.pdf_url,
  image: props.preview_url,
  customName: props.title,
})

FileEntry.propTypes = PropTypes.shape({
  id: PropTypes.string,
  key: PropTypes.string,
  text: PropTypes.string,
  path: PropTypes.string,
  image: PropTypes.string,
  customName: PropTypes.string,
})

export default FileEntry
