import { fonts, colors } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: colors.pureWhite,
    borderRadius: '1rem',
    padding: '.6rem',
  },
  icon: {
    maxWidth: '1.3rem',
    minWidth: '1.3rem',
    maxHeight: '1.3rem',
    minHeight: '1.3rem',
    marginRight: '.6rem',
  },
  number: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.1rem',
    lineHeight: '1.4rem',
    color: colors.darkBlue,
  },
}
