import { colors, fonts } from '../../theme'


export default ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '1rem',
    background: 'rgba(255, 255, 255, 0.2)',
    padding: '1.5rem',
  },
  label: {
    ...fonts.FiraSansBold,
    fontSize: '1.8rem',
    lineHeight: '120%',
    color: colors.pureWhite,
    marginBottom: '1.5rem',
  },
  entries: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  entry: {
    padding: '0.5rem 1rem',
    background: colors.transparent,
    color: colors.pureWhite,
    margin: '.25rem',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    borderRadius: '1.8rem',
    border: `1px solid ${colors.pureWhite}`,
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'all 150ms ease',
    '&:hover': {
      color: colors.darkBlue,
      background: colors.pureWhite,
    },
  },
  selected: {
    color: colors.darkBlue,
    background: colors.pureWhite,
  },
})
