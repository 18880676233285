/* eslint-disable jsx-a11y/no-static-element-interactions */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const MemberCard = (props) => {
  const classes = useStyles(props)
  const { className, profil, favoriteCta, contactCta } = props

  return (
    <div
      className={cx(classes.container, className)}
      onClick={profil.onClick}
    >
      <div className={classes.top}>
        <div className={classes.infos}>
          <CloudinaryImage
            id={profil.image ?? '/images/default-avatar.png'}
            className={classes.image}
            alt={profil.image}
          />
          <div>
            <div className={classes.name}>{profil?.name}</div>
            <div className={classes.job}>{profil?.job}</div>
            <div className={classes.company}>{profil?.company}</div>
          </div>
        </div>
        {profil.jobCategory && (
          <Icon
            style={{ border: `1px solid ${profil.jobCategory.color}` }}
            icon={profil.jobCategory.icon}
            color={profil.jobCategory.color}
            className={classes.jobCategory}
          />
        )}
      </div>
      <div className={classes.description}>{profil?.description}</div>
      <div className={classes.bottom}>
        {favoriteCta && (
          <ActionButton
            label={favoriteCta.label}
            onClick={favoriteCta.onClick}
            icon={iconsKeys.Heart}
            color="primary"
            isOutlined={!favoriteCta.isFavorite}
            iconRight
            colorIcon={!favoriteCta.isFavorite ? colors.primary : colors.pureWhite}
            colorIconHover={colors.pureWhite}
            className={classes.favoriteCta}
          />
        )}
        {contactCta && (
          <ActionButton
            label={contactCta.label}
            onClick={contactCta.onClick}
            icon={iconsKeys.Chat}
            color="secondary"
            isOutlined
            iconRight
            colorIcon={colors.darkBlue}
            colorIconHover={colors.pureWhite}
            className={classes.contactCta}
          />
        )}
      </div>
    </div>
  )
}

const propTypes = {
  className: PropTypes.string,
  profil: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    job: PropTypes.string,
    jobCategory: PropTypes.shape({
      icon: PropTypes.string,
      color: PropTypes.string,
    }),
    company: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
  }),
  favoriteCta: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    isFavorite: PropTypes.bool,
  }),
  contactCta: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
}

MemberCard.propTypes = propTypes

MemberCard.defaultProps = {
  className: '',
  profil: null,
  favoriteCta: null,
  contactCta: null,
  routeParams: null,
}

export default withMemo()(MemberCard)
