import { colors, desktop, fonts, sizes, svgGlobal, tablet } from '../../theme'


export default ({
  container: {
    position: 'relative',
    width: '100%',
    background: colors.darkBlue,
    padding: 0,
    margin: 0,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    borderWidth: '1px 0',
    borderColor: 'rgba(118, 118, 118, 0.2)',
    borderStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
  },
  button: {
    padding: '2.8rem',
    display: 'flex',
    cursor: 'pointer',
  },
  buttonLabel: {
    cursor: 'pointer',
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    lineHeight: '120%',
    textAlign: 'left',
    color: colors.pureWhite,
    position: 'relative',
    paddingRight: '1rem',
  },
  buttonIcon: {
    transition: 'all .2s ease',
    transform: ({ isOpen }) => (isOpen ? 'rotate(180deg)' : ''),
    transformOrigin: 'center center',
    ...svgGlobal(colors.tangerine),
    width: '2.8rem',
    height: '2.8rem',
  },
  submenu: {
    width: '100%',
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxWidth: sizes.desktop,
    flexWrap: 'wrap',
  },
  filter: {
    width: '100%',
    ...tablet({
      width: '50%',
    }),
    ...desktop({
      width: 'auto',
      flex: 1,
    }),
  },
})
