import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import OtherSkill from '../OtherSkill'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const OtherSkills = (props) => {
  const classes = useStyles(props)
  const { className, title, skills } = props

  const renderSkills = () => skills.map((skill, index) => (
    <OtherSkill
      {...skill}
      key={`skill-${index}`}
      className={classes.skill}
    />
  ))

  return (
    <div className={cx(classes.container, className)}>
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.skillsWrapper}>
        {renderSkills()}
      </div>
    </div>
  )
}

OtherSkills.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  skills: PropTypes.arrayOf(PropTypes.shape(OtherSkill.propTypes)),
}

OtherSkills.defaultProps = {
  className: '',
  skills: [],
}

export default withMemo()(OtherSkills)
