import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const KeyNumbers = (props) => {
  const classes = (props.useStyles ? props.useStyles : useStyles)(props)
  const { className, title, numbers, emptyLabel } = props

  const renderNumbers = () => numbers.map((number, index) => {
    const { label, value } = number

    return (
      <div
        className={classes.number}
        key={`number-${index}`}
      >
        <div className={classes.value}>{value}</div>
        <div className={classes.label}>{label}</div>
      </div>
    )
  })

  const countNumber = () => numbers.reduce((acc, val) => acc + val.value, 0)

  return (
    <div className={cx(classes.container, className)}>
      {title && <div className={classes.title}>{title}</div>}
      {countNumber() > 0 && renderNumbers()}
      {countNumber() === 0 && (
        <p
          className={classes.emptyLabel}
          dangerouslySetInnerHTML={{ __html: emptyLabel }}
        />
      )}
    </div>
  )
}

KeyNumbers.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  numbers: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  useStyles: PropTypes.func,
  emptyLabel: PropTypes.string,
}

KeyNumbers.defaultProps = {
  className: '',
  title: null,
  numbers: [],
  useStyles: null,
  emptyLabel: null,
}

export default withMemo()(KeyNumbers)
