import React from 'react'

import * as components from '../components/form/fields'

import { safeCall } from './React'


export const formFields = components

export const getDefaultValue = (component) => {
  const val = ((typeof component === 'string' ? formFields[component] : component) || formFields.Text)?.defaultValue

  if (val !== undefined) {
    return val
  }
  return ''
}
export const getFieldByName = (fields, name) => fields.find((field) => field.name === name)

export const defineField = ({ name, validation, component, ...otherProps }) => ({
  ...otherProps,
  name,
  validation: validation || false,
  component: component || formFields.Text,
  defaultValue: getDefaultValue(component),
})

export const defineFieldArray = (name, subfields) => subfields.map((subfield) => ({
  ...subfield,
  name: `${name}.*.${subfield.name}`,
}))

export const compileRules = (fields) => fields.filter(({ validation }) => !!validation)
  .reduce((r, { name, validation }) => ({ ...r, [name]: validation }), {})

export const compileInitialValues = (fields, values = {}) => ({
  ...fields.reduce((r, { name, defaultValue }) => ({ ...r, [name]: defaultValue }), {}),
  ...values,
})

export const getFieldTranslations = (t, name, props = ['label', 'help', 'placeholder']) => props.reduce(
  (r, prop) => ({ ...r, [prop]: t(`${name}_${prop}`) }), { id: name }
)

export const renderComponent = (properties, component) => {
  const Component = (typeof component === 'string' ? formFields[component] : component) || formFields.Text

  return <Component {...properties} />
}


export const generateFieldsProps = (fields, callback) => fields.reduce(
  (r, field) => ({
    ...r,
    [field.name]: safeCall(callback, field),
  }),
  {}
)



