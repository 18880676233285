import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import MarkdownText from '../MarkdownText'
import ProfileDetailsMain from '../ProfileDetailsMain'

import styles from './styles'


const defaultAvatar = 'defaults/avatar'
const defaultLogo = 'defaults/logo'
const useStyles = createUseStyles(styles)

const ProfileDetailsSidebar = (props) => {
  const classes = useStyles(props)
  const { className, profile } = props
  const {
    avatar,
    company,
    companyFunction,
    companyFunctionDescription,
    companyAddressTitle,
    links,
    fullName,
    seeCompanyLabel,
    seeCompanyLink,
    seeCompanyRoute,
    seeCompanyRouteParams,
  } = profile

  const renderAddress = () => {
    const { address } = company

    let fullAddress = ''

    if (address) {
      if (address.street) {
        fullAddress += address.street
      }

      if (address.zip) {
        if (fullAddress) {
          fullAddress += '<br />'
        }
        fullAddress += `${address.zip}`
      }

      if (address.city) {
        fullAddress += ` ${address.city}`
      }
    }

    if (!fullAddress) {
      return null
    }

    return (
      <div
        className={classes.companyAddress}
        dangerouslySetInnerHTML={{ __html: fullAddress }}
      />
    )
  }

  const renderProfileSocialIcons = () => links.map((element) => {
    const { id, icon, link } = element

    return (
      <Icon
        key={id}
        icon={icon}
        link={link}
        target="_blank"
        color={colors.darkBlue}
        className={classes.socialIcon}
      />
    )
  })

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.avatar}>
        <CloudinaryImage
          lazyLoad
          className={classes.avatarImage}
          id={avatar || defaultAvatar}
          options={{
            width: '180',
            height: '180',
            crop: 'fill',
          }}
        />
      </div>
      <div className={classes.name}>
        {fullName}
      </div>
      <div className={classes.title}>
        {companyFunction?.name}
      </div>
      {Boolean(links?.length) && (
        <div className={classes.social}>
          <div className={classes.socialIcons}>
            {renderProfileSocialIcons()}
          </div>
        </div>
      )}
      <MarkdownText
        text={companyFunctionDescription}
        className={classes.companyDescription}
      />
      <div className={classes.companyContainer}>
        <CloudinaryImage
          lazyLoad
          className={classes.companyLogo}
          id={company.logo || defaultLogo}
          options={{
            width: '170',
            height: '100',
            crop: 'fill',
          }}
        />
        <div className={classes.companyName}>
          {company?.name}
        </div>
        <div className={classes.companyLocation}>
          {companyAddressTitle}
        </div>
        {renderAddress()}
        {seeCompanyLink !== null && seeCompanyLabel !== null && (
          <ActionButton
            noBg
            iconRight
            label={seeCompanyLabel}
            link={seeCompanyLink}
            route={seeCompanyRoute}
            routeParams={seeCompanyRouteParams}
            color="white"
            icon={iconsKeys.ArrowRight}
            colorIcon={colors.darkBlue}
            className={classes.companyCta}
          />
        )}
      </div>
    </div>
  )
}

ProfileDetailsSidebar.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.shape(ProfileDetailsMain.propTypes),
}

ProfileDetailsSidebar.defaultProps = {
  className: '',
  profile: null,
}

export default withMemo()(ProfileDetailsSidebar)
