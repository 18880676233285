import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import FormFieldRadio from '../Radio'
import withMemo from '../../../../decorators/WithMemo'
import { generateUniqueId } from '../../../../utils/ComponentUtils'

import styles from './styles'


const useStyles = createUseStyles(styles)

function RadioGroupWithLabel(props) {
  const {
    className,
    name,
    id,
    label,
    value,
    options,
    help,
    error,
    onChange,
  } = props

  const thisId = useMemo(() => `checkboxGroup_${generateUniqueId()}`, [])

  const handleChange = (child) => {
    onChange({ name, value: child.name })
  }

  const classes = { ...useStyles(props), ...props.classes }

  return (
    <div
      className={cx(
        classes.container,
        className
      )}
    >
      <div className={classes.wrapper}>
        {label && <div className={classes.label}>{label}</div>}
        <div className={classes.inputs}>
          {
            options.map((option, i) => (
              <FormFieldRadio
                key={i}
                id={`${id || name || thisId}_${i}`}
                name={option.value}
                value={value === option.value}
                label={option.label}
                onChange={handleChange}
                className={classes.input}
                classNames={{
                  label: classes.inputLabel,
                  text: classes.inputText,
                }}
              />
            ))
          }
        </div>
      </div>
      <FormErrorText
        className={classes.errorText}
        text={error}
      />
      <FormHelpText
        className={classes.helpText}
        text={help}
        error={!!error}
      />
    </div>
  )
}

RadioGroupWithLabel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  })),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  error: PropTypes.string,
  help: PropTypes.string,
}

RadioGroupWithLabel.defaultProps = {
  classes: {},
  className: null,
  label: null,
  name: null,
  id: null,
  options: [],
  value: '',
  onChange: () => null,
  error: null,
  help: null,
}

export default withMemo()(RadioGroupWithLabel)
