/* eslint-disable max-lines */
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'

import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'
import Modal from '../Modal'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function ModalWithHeader(props) {
  const classes = useStyles(props)
  const {
    className,
    isOpen,
    onClose,
    title,
    children,
    wrapperClasses,
  } = props

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      classes={wrapperClasses}
    >
      <div className={cx(classes.container, className)}>
        <div className={classes.header}>
          {title && <div className={classes.title}>{title}</div>}
          <Icon
            icon={iconsKeys.Close}
            color={colors.nightRider}
            onClick={onClose}
            className={cx(classes.icon, classes.close)}
          />
        </div>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </Modal>
  )
}

ModalWithHeader.styles = styles

ModalWithHeader.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  wrapperClasses: PropTypes.objectOf(PropTypes.string),
}

ModalWithHeader.defaultProps = {
  className: '',
  isOpen: false,
  onClose: () => null,
  title: null,
  children: null,
  wrapperClasses: {},
}

export default withMemo()(ModalWithHeader)
