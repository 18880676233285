import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../../decorators/WithMemo'
import Icon from '../../Icon'
import { iconsKeys } from '../../Icon/Icon.assets'
import { colors } from '../../../theme'

import styles from './styles'


const useStyles = createUseStyles(styles)


const getFileExtension = (filename) => {
  const isImage = (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(filename)

  if (isImage) {
    return 'image'
  }
  return 'file'
}

const Message = (props) => {
  const classes = useStyles(props)
  const {
    className,
    time,
    user,
    message,
  } = props

  const [avatar, setAvatar] = useState(user?.profileUrl || '/images/companies/default-logo.png')
  const [name, setName] = useState(user?.name || ' ')

  useEffect(() => {
    if (user?.profileUrl) {
      setAvatar(user?.profileUrl)
    }
    if (user?.name) {
      setName(user?.name)
    }
  }, [user])

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.avatar}>
        <img
          className={classes.avatarImage}
          src={avatar}
          alt={name}
          onError={(e) => {
            e.target.onerror = null
            e.target.src = '/images/companies/default-logo.png'
          }}
        />
      </div>
      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.name}>
            {name}
          </div>
          <div className={classes.time}>
            {time}
          </div>
        </div>
        {message.message.type === 'text' || message.message?.type === 'default' ? (
          <div className={classes.message}>
            {message.message.text}
          </div>
        ) : (
          <div
            className={cx(classes.message, classes.file)}
            role="button"
            tabIndex={0}
            onClick={() => {
              window.open(message.message?.file?.url, '_blank')
            }}
          >
            {getFileExtension(message.message?.file?.name) === 'image' ? (
              <img
                className={classes.fileImage}
                src={message.message?.file?.url}
                alt={message.message?.file?.name}
              />
            ) : (
              <div className={classes.fileDoc}>
                <Icon
                  icon={iconsKeys.Files}
                  color={colors.nightRider}
                  className={classes.fileIcon}
                />
                <span className={classes.fileName}>
                  {message.message?.file?.name}
                </span>
                <Icon
                  icon={iconsKeys.Download}
                  color={colors.nightRider}
                  className={classes.downloadIcon}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

Message.propTypes = {
  className: PropTypes.string,
  // isOwn: PropTypes.bool,
  time: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
    externalId: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    profileUrl: PropTypes.string,
    updated: PropTypes.string,
    eTag: PropTypes.string,
    custom: PropTypes.shape({
      company: PropTypes.string,
    }),
  }),
  message: PropTypes.shape({
    channel: PropTypes.string,
    timetoken: PropTypes.string,
    messageType: PropTypes.string,
    uuid: PropTypes.string,
    message: PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string,
      // related to text message
      message: PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        id: PropTypes.string,
        createdAt: PropTypes.string,
      }),
      // related to file message
      file: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
  }),
}

Message.defaultProps = {
  className: '',
  // isOwn: false,
  time: '',
  user: null,
  message: null,
}

export default withMemo()(Message)
