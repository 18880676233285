import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const SideColumnLayout = (props) => {
  const classes = useStyles(props)
  const { className, columnPosition, columnContent, mainContent, reverse, largerColumn } = props

  return (
    <div className={cx(
      classes.container,
      reverse && classes.containerReverse,
      className
    )}
    >
      <div className={cx(
        classes.sideColumn,
        columnPosition === 'right' && classes.sideColumnRight,
        largerColumn && classes.sideColumnLarger
      )}
      >
        {columnContent}
      </div>
      <div className={classes.main}>
        {mainContent}
      </div>
    </div>
  )
}

SideColumnLayout.propTypes = {
  className: PropTypes.string,
  columnPosition: PropTypes.oneOf(['left', 'right']),
  columnContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  mainContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  reverse: PropTypes.bool,
  largerColumn: PropTypes.bool,
}

SideColumnLayout.defaultProps = {
  className: '',
  columnPosition: 'left',
  reverse: false,
  largerColumn: false,
}

export default withMemo()(SideColumnLayout)
