import { colors, fonts, tablet } from '../../theme'


export default {
  container: {
    width: '100%',
  },
  fields: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    ...tablet({
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    }),
  },
  field: {
    padding: '1rem 0',
    width: '100%',
  },

  field_label: {
  },
  otherFields: {
    width: '100%',
  },

  errors: {
    textAlign: 'center',
    padding: '1rem',
  },
  error: {
    color: colors.error,
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    padding: '0.5rem 0',
  },
  submitContainer: {
    textAlign: 'center',
    padding: '1rem 0',
  },
  button: {
  },
  loading: {
    marginTop: '3rem',
  },
}
