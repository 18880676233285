/* eslint-disable max-lines */
import cx from 'classnames'
import _uniqBy from 'lodash/uniqBy'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Resumable from 'resumablejs'

import withMemo from '../../../../decorators/WithMemo'
import { colors } from '../../../../theme'
import ActionButton from '../../../ActionButton'
import Icon from '../../../Icon'
import { iconsKeys } from '../../../Icon/Icon.assets'
import Image from '../../../Image'
import MarkdownText from '../../../MarkdownText'
import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import FormLabel from '../../FormLabel'
import Input from '../Input'
import { formatSize } from '../../../../helpers/NumberHelpers'

import styles from './styles'


const useStyles = createUseStyles(styles)

const UploadFiles = (props) => {
  const classes = { ...useStyles(props), ...props.classes }
  const {
    className,
    name,
    label,
    browseLabel,
    nameLabel,
    previewLabel,
    deleteLabel,
    legend,
    help,
    error,
    required,
    value,
    onError,
    onChange,
    onStateChange,
    errorMessages,
    config: { maxFiles, token, accept, minFileSize, maxFileSize, endpoint, chunkSize, ...conf },
  } = props

  const [files, setFiles] = useState(value)
  const [localError, setLocalError] = useState()

  const localErrorText = useMemo(() => localError?.data?.event?.original?.message
      ?? (
        localError
          ? errorMessages[localError?.type]?.replace('{{maxFileSize}}', formatSize(maxFileSize))
          : null
      ), [localError, errorMessages, maxFileSize])

  const dropNode = useRef(null)
  const buttonNode = useRef(null)

  const getId = useCallback((f) => f.id || f.uniqueIdentifier, [])

  const updateValue = useCallback(
    (f) => {
      const val = (f || files)
        .filter((file) => !file.progress || file.progress() === 1)
        .map((file) => ({
          id: file.id,
          key: file.key,
          path: file.path,
          title: file.customName ?? file.fileName,
        }))

      onChange({
        name,
        value: val,
      })
    },
    [onChange, name, files]
  )

  const handleNameChange = useCallback(
    (file, text) => {
      setFiles((before) => {
        const newFiles = before.map((f) => {
          const isCurrent = getId(file) === getId(f)

          if (!isCurrent) {
            return f
          }

          return {
            ...f,
            customName: text,
          }
        })

        updateValue(newFiles)
        return newFiles
      })
    },
    [getId, updateValue]
  )

  const handlePreview = useCallback((file) => {
    if (file) {
      window.open(file.path)
    }
  }, [])

  const handleDelete = useCallback(
    (file) => {
      if (file) {
        if (file.cancel) {
          file.cancel()
        }
        setFiles((before) => {
          const newFiles = before.filter((f) => getId(file) !== getId(f))

          updateValue(newFiles)
          return newFiles
        })
      }
    },
    [getId, updateValue]
  )

  useEffect(() => {
    let mounted = true
    const setError = (type, data) => {
      setLocalError({ type, data })
      onError({ type, data })
    }

    const minFileSizeErrorCallback = (f) => {
      setError(UploadFiles.errorTypes.MIN_FILE_SIZE_ERROR)
      setError(UploadFiles.errorTypes.MIN_FILE_SIZE_ERROR, {
        size: f.size,
        minFileSize,
      })
    }

    const maxFileSizeErrorCallback = (f) => {
      setError(UploadFiles.errorTypes.MAX_FILE_SIZE_ERROR, {
        size: f.size,
        maxFileSize,
      })
    }

    const maxFilesErrorCallback = () => null

    const onFileError = (originalFile, message) => {
      let result

      try {
        result = JSON.parse(message)
      } catch (e) {
        result = {}
      }

      const { errors: { file } } = result

      if (file.length) {
        setError(UploadFiles.errorTypes.FILE_ERROR, {
          file: originalFile,
          event: {
            original: {
              message: file[0],
            },
          },
        })
      }
      handleDelete(originalFile)
    }

    const res = new Resumable({
      ...(token && { headers: { Authorization: `Bearer ${token}` } }),
      target: endpoint,
      chunkSize,
      method: 'POST',
      fileType: accept,
      maxFileSize,
      minFileSize,
      minFileSizeErrorCallback,
      maxFileSizeErrorCallback,
      maxFilesErrorCallback,
      maxFiles: 1,
      ...conf,
    })

    res.assignBrowse(buttonNode.current)
    res.assignDrop(dropNode.current)

    res.on('fileAdded', (file, event) => {
      const newFile = {
        ...file,
        id: getId(file),
      }

      setFiles((before) => _uniqBy([...before, newFile], (v) => v.id))
      onStateChange(UploadFiles.states.IN_PROGRESS)
      res.upload()
    })

    res.on('fileSuccess', (file, event) => {
      let result

      try {
        result = JSON.parse(event)
      } catch (e) {
        result = {}
      }

      if (result?.original?.error) {
        onFileError(file, result)
      } else {
        setError(null)
      }

      const path = URL.createObjectURL(file.file)
      const key = result.file

      const update = (notify = false) => {
        setFiles((before) => {
          const newFiles = before.map((f) => {
            if (getId(file) === getId(f)) {
              return {
                ...f,
                ...file,
                key,
                path,
                id: getId(file),
              }
            }
            return f
          })

          if (notify) {
            updateValue(newFiles)
          }

          return newFiles
        })
      }

      update()

      setTimeout(() => {
        if (mounted) {
          update(true)
        }
      }, 0)
    })

    res.on('fileError', onFileError)

    res.on('fileProgress', (file, event) => {
      setFiles((before) => {
        const newFiles = before.map((f) => {
          if (getId(file) === getId(f)) {
            return {
              ...f,
              ...file,
            }
          }
          return f
        })

        return newFiles
      })
    })

    res.on('progress', () => {})

    res.on('complete', () => {
      onStateChange(UploadFiles.states.COMPLETE)
      updateValue()
    })

    return () => {
      mounted = false
      if (res.isUploading()) {
        res.cancel()
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div
      ref={dropNode}
      className={cx(classes.container, className)}
    >
      <FormLabel
        className={classes.label}
        required={required}
        error={!!error}
      >
        {label}
      </FormLabel>

      <MarkdownText
        className={classes.legend}
        text={legend}
        inline
      />

      <div className={classes.wrapper}>
        {!!files
          && files.map((file) => (
            <div
              key={getId(file)}
              className={classes.file}
            >
              <div className={classes.fileContainer}>
                {file.path && (
                <div className={classes.uploadWrapper}>
                  <Icon
                    icon={iconsKeys.Check}
                    className={classes.uploadIcon}
                    color={colors.darkBlue}
                  />
                  <div
                    className={classes.uploadText}
                  >
                    {file.fileName ?? file.customName}
                  </div>
                  {!!file.image && (
                    <Image
                      src={file.image}
                      className={classes.uploadPreview}
                    />
                  )}
                </div>
                )}
                <div
                  className={classes.progress}
                  style={{
                    transform: `scaleX(${file.progress ? file.progress() : 0})`,
                    opacity: file.progress && file.progress() < 1 ? 1 : 0,
                  }}
                />
              </div>
              <Input
                name="name"
                className={classes.input}
                placeholder={nameLabel}
                value={file.customName ?? file.fileName}
                onChange={({ value: text }) => handleNameChange(file, text)}
                inputProps={{
                  maxLength: 250,
                }}
              />
              <ActionButton
                className={classes.preview}
                label={previewLabel}
                onClick={() => handlePreview(file)}
                isSmall
                color="secondary"
              />
              <ActionButton
                className={classes.delete}
                label={deleteLabel}
                onClick={() => handleDelete(file)}
                isSmall
              />
            </div>
          ))}

        {!!files && (
          <div
            className={classes.file}
            style={files.length >= maxFiles ? { display: 'none' } : null}
          >
            <span
              ref={buttonNode}
              className={cx(classes.fileContainer, classes.browse)}
            >
              <div className={classes.browseWrapper}>
                <Icon
                  icon={iconsKeys.Files}
                  className={classes.browseIcon}
                  color={colors.darkBlue}
                />
                <div className={classes.browseLabel}>{browseLabel}</div>
                {maxFiles < Number.MAX_SAFE_INTEGER && (
                  <div className={classes.remaining}>{`${files.length}/${maxFiles}`}</div>
                )}
              </div>
            </span>
          </div>
        )}
      </div>

      <FormErrorText
        className={classes.errorText}
        text={localErrorText}
      />
      <FormErrorText
        className={classes.errorText}
        text={error}
      />
      <FormHelpText
        className={classes.helpText}
        text={help}
        error={!!error}
      />
    </div>
  )
}

UploadFiles.errorTypes = {
  MIN_FILE_SIZE_ERROR: 'min_file_size_error',
  MAX_FILE_SIZE_ERROR: 'max_file_size_error',
  FILE_ERROR: 'file_error',
}

UploadFiles.states = {
  EMPTY: 'empty',
  COMPLETE: 'complete',
  IN_PROGRESS: 'in_progress',
  ERROR: 'error',
}

UploadFiles.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  browseLabel: PropTypes.string,
  nameLabel: PropTypes.string,
  previewLabel: PropTypes.string,
  deleteLabel: PropTypes.string,
  legend: PropTypes.string,
  help: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onStateChange: PropTypes.func,
  required: PropTypes.bool,
  config: PropTypes.shape({
    token: PropTypes.string,
    endpoint: PropTypes.string.isRequired,
    assetPath: PropTypes.string,
    chunkSize: PropTypes.number,
    accept: PropTypes.array,
    minFileSize: PropTypes.number,
    maxFileSize: PropTypes.number,
    maxFiles: PropTypes.number,
  }),
  errorMessages: PropTypes.objectOf(PropTypes.string),
}

UploadFiles.defaultValue = []

UploadFiles.defaultProps = {
  classes: {},
  className: null,
  browseLabel: '',
  nameLabel: '',
  previewLabel: '',
  deleteLabel: '',
  label: null,
  legend: null,
  help: null,
  error: null,
  value: [],
  onChange: () => undefined,
  onError: () => undefined,
  onStateChange: () => undefined,
  required: false,
  config: {
    token: null,
    chunkSize: 1024 * 1024 * 2,
    // Cloudinary formats
    accept: ['pdf'],
    testChunks: false,
    minFileSize: 0,
    maxFileSize: 1024 * 1024 * 10,
    simultaneousUploads: 1,
    maxFiles: Number.MAX_SAFE_INTEGER,
  },
  errorMessages: {
    [UploadFiles.errorTypes.MAX_FILE_SIZE_ERROR]: 'Le fichier est trop volumineux, veuillez respecter la limite de {{maxFileSize}}.',
  },
}

export default withMemo()(UploadFiles)
