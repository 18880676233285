/* eslint-disable max-lines */
import React from 'react'
import { createUseStyles } from 'react-jss'

import Icon from '../../../Icon'
import { iconsKeys } from '../../../Icon/Icon.assets'
import colors from '../../../../theme/colors'
import Text from '../Text'
import withMemo from '../../../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const ClearableText = (props) => {
  const classes = useStyles(props)

  const { name, ...others } = props

  return (
    <Text
      {...others}
      name={name}
      endOrnament={(
        <Icon
          color={colors.darkBlue}
          icon={iconsKeys.Close}
          onClick={() => props.onChange({ name, value: '' })}
          classname={classes.icon}
        />
      )}
    />
  )
}

ClearableText.styles = styles

ClearableText.propTypes = Text.propTypes

ClearableText.defaultProps = Text.defaultProps

export default withMemo()(ClearableText)
