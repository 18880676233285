/* eslint-disable max-lines */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import {
  Chat,
  MessageList,
  MessageInput
} from '@pubnub/react-chat-components/dist/index'
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'
import Lottie from 'react-lottie'

import withMemo from '../../decorators/WithMemo'
import spinnerData from '../../theme/lottie/spinner/spinner.json'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'
import Message from './Message'
import ChannelList from './ChannelList'


const useStyles = createUseStyles(styles)

// eslint-disable-next-line complexity
const UserMessaging = (props) => {
  const classes = useStyles(props)
  const {
    className,
    channels,
    currentChannel,
    onChannelSwitched,
    messagePlaceholder,
    users,
    sendButtonLabel,
    channelsWithMetadata,
    currentChannelMeta,
    noMessage,
    onSend,
    comingFromContactBtn,
    triggerPubnubReload,
    onRemoveChannel,
  } = props

  const [showChannels, setShowChannels] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (comingFromContactBtn) {
      setShowChannels(false)
    }
  }, [comingFromContactBtn])

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: spinnerData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    let interval = null

    setIsLoading(true)
    if (channels && currentChannel && users && currentChannelMeta) {
      setIsLoading(false)
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        triggerPubnubReload()
      }, 20000)
    }
    const to = setTimeout(() => setIsLoading(false), 10000)

    return () => {
      clearTimeout(to)
      clearInterval(interval)
    }
  }, [channels, currentChannel, currentChannelMeta, triggerPubnubReload, users])

  return (
    <div className={cx(classes.container, className)}>
      {channels && currentChannel && users && currentChannelMeta && (
        <Chat
          currentChannel={currentChannel}
          channels={channels}
          users={users}
        >
          <div className={classes.messagingBox}>
            {channelsWithMetadata && (
              <div className={showChannels ? classes.channelListShown : classes.channelList}>
                <button
                  className={classes.close}
                  onClick={() => setShowChannels(false)}
                  type="button"
                >
                  ✕
                </button>

                <ChannelList
                  currentChannel={currentChannel}
                  channelsWithMetadata={channelsWithMetadata}
                  onChannelSwitched={(channel) => {
                    onChannelSwitched(channel)
                    setShowChannels(false)
                  }}
                />
              </div>
            )}
            <div className={classes.messageBox}>
              {currentChannelMeta.name && (
                <div className={classes.messageBoxHeader}>
                  <button
                    className={classes.hamburger}
                    onClick={() => setShowChannels(true)}
                    type="button"
                  >
                    ☰
                  </button>
                  <div className={classes.messageBoxHeaderContent}>
                    <div>
                      <p className={classes.messageBoxHeaderTitle}>{currentChannelMeta.name}</p>
                      {currentChannelMeta.description && (
                        <p className={classes.messageBoxHeaderDescription}>{currentChannelMeta.description}</p>
                      )}
                    </div>
                    <Icon
                      className={classes.removeIcon}
                      icon={iconsKeys.Trash}
                      width={20}
                      onClick={() => onRemoveChannel(currentChannel)}
                    />
                  </div>
                  <hr className={classes.separator} />
                </div>
              )}
              <MessageList
                fetchMessages={50}
                messageRenderer={(msgProps) => (
                  <Message
                    {...msgProps}
                  />
                )}
              />
              <div className={classes.messageBoxInput}>
                <MessageInput
                  placeholder={messagePlaceholder}
                  sendButton={sendButtonLabel}
                  emojiPicker={<Picker />}
                  onSend={onSend}
                  fileUpload="all"
                />
              </div>
            </div>
          </div>
        </Chat>
      ) || isLoading && (
        <div className={classes.loading}>
          <Lottie
            options={lottieOptions}
            height={40}
            width={40}
          />
        </div>
      )
      || (
        <h4 className={classes.noMessage}>{noMessage}</h4>
      )}

    </div>
  )
}

UserMessaging.propTypes = {
  className: PropTypes.string,
  channels: PropTypes.arrayOf(
    PropTypes.string
  ),
  currentChannel: PropTypes.string,
  onChannelSwitched: PropTypes.func,
  messagePlaceholder: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    eTag: PropTypes.string,
    email: PropTypes.string,
    externalId: PropTypes.string,
    profileUrl: PropTypes.string,
    updated: PropTypes.string,
    custom: PropTypes.objectOf(PropTypes.shape({
      company: PropTypes.string,
    })),
  })),
  sendButtonLabel: PropTypes.string,
  channelsWithMetadata: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    eTag: PropTypes.string,
    email: PropTypes.string,
    externalId: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    profileUrl: PropTypes.string,
    updated: PropTypes.string,
    custom: PropTypes.objectOf(PropTypes.shape({
      company: PropTypes.string,
      profileUrl: PropTypes.string,
      thumb: PropTypes.string,
    })),
  })),
  currentChannelMeta: PropTypes.objectOf(PropTypes.shape({
    description: PropTypes.string,
    eTag: PropTypes.string,
    email: PropTypes.string,
    externalId: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    profileUrl: PropTypes.string,
    updated: PropTypes.string,
    custom: PropTypes.objectOf(PropTypes.shape({
      company: PropTypes.string,
      profileUrl: PropTypes.string,
      thumb: PropTypes.string,
    })),
  })),
  noMessage: PropTypes.string,
  onSend: PropTypes.func,
  comingFromContactBtn: PropTypes.bool,
  triggerPubnubReload: PropTypes.func,
  onRemoveChannel: PropTypes.func,
}

UserMessaging.defaultProps = {
  className: '',
  channels: null,
  currentChannel: '',
  onChannelSwitched: null,
  messagePlaceholder: '',
  users: null,
  sendButtonLabel: '',
  channelsWithMetadata: null,
  currentChannelMeta: null,
  noMessage: '',
  onSend: () => {},
  comingFromContactBtn: false,
  triggerPubnubReload: () => {},
  onRemoveChannel: () => {},
}

export default withMemo()(UserMessaging)
