import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'
import Errors from '../../data/models/Errors'


export const key = 'offerDetails'


//
// Initial state
//
const initialState = {
  slug: null,
  data: null,
  loaded: false,
  applyToOffer: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setSlug: createAction('Offer: setSlug', (slug) => ({ slug })),
  setData: createAction('Offer: setData', (data) => ({ data })),
  setLoaded: createAction('Offer: setLoaded', (loaded) => ({ loaded })),
  //
  applyToOffer: createAction('Offer: applyToOffer', (values) => ({ values })),
  applyToOfferSuccess: createAction('Offer: applyToOfferSuccess', (data) => data),
  applyToOfferError: createAction('Offer: applyToOfferError', (errors) => Errors(errors)),
  applyToOfferReset: createAction('userProfile: applyToOfferReset'),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setSlug]: (state, { slug }) => Immutable.static.merge(state, { slug }),
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  ...requestReducers(actions, 'applyToOffer'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const slug = (state) => root(state).slug
const data = (state) => root(state).data
const applyToOffer = (state) => root(state).applyToOffer

export const selectors = {
  isLoaded,
  slug,
  data,
  applyToOffer,
}
