import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import ReactSelect from 'react-select'

import { generateUniqueId } from '../../utils/ComponentUtils'
import withMemo from '../../decorators/WithMemo'

import styles, { selectStyles } from './styles'


const useStyles = createUseStyles(styles)

const LanguageSwitcher = (props) => {
  const classes = useStyles(props)
  const {
    className,
    name,
    options,
    value,
    onChange,
    id,
  } = props

  const [thisId] = useState(`language_switcher_${generateUniqueId()}`)

  const handleChange = (selectedOption) => {
    if (onChange !== null) {
      onChange({ name, value: selectedOption.value })
    }
  }

  let selectedOption = null

  const fullOptions = options

  let renderOptions = []

  if (value !== null && value !== '') {
    selectedOption = options.reduce((res, option) => {
      if (option.value === value) {
        return option
      }
      return res
    }, null)
    fullOptions.reduce((res, option) => {
      if (option.value !== value) {
        renderOptions.push({ label: option.label, value: option.value })
      }
      return res
    }, null)
  } else {
    renderOptions = fullOptions
  }

  return (
    <div className={cx(classes.container, className)}>
      <ReactSelect
        className={cx(classes.select)}
        classNamePrefix="react-select"
        styles={selectStyles(props)}
        aria-label={name}
        name={name}
        inputId={id || thisId}
        instanceId={`${name}_instance`}
        options={renderOptions}
        value={selectedOption}
        onChange={handleChange}
        isMulti={false}
        isSearchable={false}
      />
    </div>
  )
}

LanguageSwitcher.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
}

LanguageSwitcher.defaultProps = {
  className: '',
  id: null,
  value: null,
  onChange: null,
}

export default withMemo()(LanguageSwitcher)
