import { colors, desktop, fonts, sizes, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    height: 'auto',
    margin: '0 auto',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  wrapper: {
    backgroundColor: colors.grayTranslucent,
    width: '100%',
  },
  content: {
    maxWidth: `${sizes.maxDesktop / 10}rem`,
    margin: '0 auto',
  },
  containerHomepage: {
    extend: 'container',
    minHeight: '44rem',
    padding: '18.1rem 1.5rem 0',
    ...tablet({
      padding: '6rem 7.2rem 0',
      alignItems: 'flex-start',
    }),
    ...desktop({
      minHeight: '60rem',
      padding: '14rem 31rem 0',
    }),
  },
  containerSM: {
    extend: 'container',
    minHeight: '12rem',
    alignItems: 'center',
    justifyContent: 'center',
    '&.hasSearch': {
      minHeight: '15rem',
    },
    ...tablet({
      padding: '4rem 25rem 3rem',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      // minHeight: '17rem',
      '&.hasSearch': {
        paddingBottom: '13rem',
      },
      '&.hasTitle': {
        paddingBottom: '15rem',
      },
      '&.hasStats': {
        paddingBottom: '17rem',
      },
      '&.hasTitle.hasStats': {
        paddingBottom: '18rem',
      },
    }),
  },
  containerMD: {
    extend: 'container',
    minHeight: '15rem',
    padding: '5.5rem 1.5rem',
    ...tablet({
      alignItems: 'flex-start',
      padding: '10rem 25rem',
      minHeight: '24rem',
      '&.hasTitle': {
        paddingBottom: '15rem',
      },
      '&.hasStats': {
        paddingBottom: '16rem',
      },
      '&.hasTitle.hasStats': {
        paddingBottom: '18rem',
      },
    }),
  },
  containerXL: {
    extend: 'container',
    minHeight: '32rem',
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.4rem',
    lineHeight: '2.6rem',
    textAlign: 'center',
    color: colors.pureWhite,
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    ...tablet({
      maxWidth: '40rem',
      textAlign: 'left',
      fontSize: '3rem',
      lineHeight: '120%',
    }),
  },
  subtitle: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '2.4rem',
    lineHeight: '2.6rem',
    textAlign: 'center',
    color: colors.pureWhite,
    ...tablet({
      maxWidth: '40rem',
      textAlign: 'left',
      fontSize: '3.2rem',
      lineHeight: '3.8rem',
    }),
  },
  cta: {
    marginTop: '2.4rem',
    ...tablet({
      marginTop: '3rem',
    }),
  },
  searchBlock: {
    position: 'absolute',
    zIndex: 4,
    left: 0,
    right: 0,
    bottom: 0,
    transform: 'translateY(80%)',
    ...tablet({
      transform: 'translateY(50%)',
    }),
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}
