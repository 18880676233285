/* eslint-disable max-lines */
import cx from 'classnames'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { compileInitialValues, compileRules, defineField, formFields } from '../../helpers/FormHelpers'
import { validateAll } from '../../helpers/ValidationHelpers'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import { Field } from '../form/Generator/Field'
import Submit from '../form/Submit'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


export const fields = [
  defineField({ name: 'content', validation: 'required', component: formFields.Textarea }),
  defineField({ name: 'recommendation', validation: ['required'], type: 'checkbox', component: formFields.Checkbox }),
]

const generatedFields = Array.from(fields).slice(0, 2)
const rules = compileRules(fields)

const useStyles = createUseStyles(styles)

const CommentForm = (props) => {
  const classes = useStyles(props)
  const {
    className,
    submitLabel,
    successLabel,
    backLabel,
    onBackClick,
    onSubmit,
    fieldsProps,
    messages,
    submitErrors,
    successText,
    pendingText,
    submitSuccess,
    submitPending,
  } = props

  //
  // Handlers
  //
  const handleValidate = (values) => validateAll(values, rules, messages)
  const handleSubmit = (values) => onSubmit(values)

  //
  // Renderers
  //
  const renderForm = (formikProps) => (
    <Form
      noValidate
      autoComplete="off"
      onSubmit={formikProps.handleSubmit}
    >
      {!submitSuccess && (
        <div className={classes.fields}>
          {
            generatedFields
              .map((field, i) => (
                <div
                  key={i}
                  className={cx(classes.field, i === 0 && classes.firstField)}
                >
                  <Field
                    fieldConfig={field}
                    fieldsProps={fieldsProps}
                    formikProps={formikProps}
                  />
                </div>
              ))
          }
        </div>
      )}

      {submitErrors && (
        <div className={classes.errors}>
          {submitErrors.map((error, i) => (
            <div
              className={classes.error}
              key={i}
            >
              {error.label}
            </div>
          ))}
        </div>
      )}

      {submitSuccess && (
        <>
          <div className={classes.successText}>{successLabel}</div>
          <ActionButton
            color="primary"
            label={backLabel}
            className={classes.backButton}
            onClick={onBackClick}
          />
        </>
      )}

      {!submitSuccess && (
        <div className={classes.submitContainer}>
          <Submit
            isBig
            isOutlined={false}
            successLabel={successText}
            pendingLabel={pendingText}
            label={submitLabel}
            isDisabled={!formikProps.dirty || submitPending || submitSuccess}
            iconRight
            colorIcon={colors.pureWhite}
            icon={submitPending
              ? iconsKeys.Refresh : (!formikProps.dirty && submitSuccess)
                ? iconsKeys.Check : iconsKeys.ArrowRight}
          />
        </div>
      )}
    </Form>
  )

  return (
    <div className={cx(classes.container, className)}>
      <Formik
        enableReinitialize
        validate={handleValidate}
        onSubmit={handleSubmit}
        initialValues={compileInitialValues(fields)}
      >
        {renderForm}
      </Formik>
    </div>
  )
}

CommentForm.propTypes = {
  className: PropTypes.string,
  submitLabel: PropTypes.string,
  successLabel: PropTypes.string,
  backLabel: PropTypes.string,
  onBackClick: PropTypes.func,
  onSubmit: PropTypes.func,
  fieldsProps: PropTypes.objectOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    help: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, value: PropTypes.string })),
  })),
  messages: PropTypes.objectOf(PropTypes.string),
  submitErrors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    field: PropTypes.string,
  })),
  successText: PropTypes.string,
  pendingText: PropTypes.string,
  submitSuccess: PropTypes.bool,
  submitPending: PropTypes.bool,
}

CommentForm.defaultProps = {
  className: '',
  fieldsProps: {},
  messages: {},
  submitLabel: '',
  successLabel: '',
  backLabel: '',
  onBackClick: () => null,
  onSubmit: () => null,
  submitErrors: null,
  successText: '',
  pendingText: '',
  submitSuccess: false,
  submitPending: false,
}

export default withMemo()(CommentForm)
