import { fonts, colors, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    background: colors.darkBlue,
    borderRadius: '2rem',
    padding: '2rem 3rem',
    width: '100%',
    margin: '0 auto',
    color: colors.pureWhite,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...tablet({
      maxWidth: '40rem',
    }),
  },
  title: {
    fontFamily: fonts.FiraSansMedium.fontFamily,
    fontWeight: fontWeights.medium,
    fontSize: '1.8rem',
    lineHeight: '2.2rem',
    textTransform: 'uppercase',
    marginBottom: '1rem',
  },
  number: {
    marginBottom: '1.6rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    ...tablet({
      fontSize: '4.8rem',
      lineHeight: '5.8rem',
    }),
  },
  value: {
    fontFamily: fonts.FiraSansExtraLight.fontFamily,
    fontWeight: fontWeights.extraLight,
    fontSize: '3.6rem',
    lineHeight: '4.3rem',
    textTransform: 'uppercase',
  },
  label: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    opacity: '.7',
  },
  emptyLabel: {
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    color: colors.pureWhite,
    flex: 1,
    marginTop: '2.6rem',
  },
}
