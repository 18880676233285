/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import MenuEntry from '../MenuEntry'
import { iconsKeys } from '../Icon/Icon.assets'
import { getWindowSize } from '../../utils/WindowSize'
import { colors, sizes } from '../../theme'
import BurgerMenuButton from '../BurgerMenuButton'
import Icon from '../Icon'
import ActionButton from '../ActionButton'
import FooterSocial from '../FooterSocial'
import A from '../A'
import AuthLoginButton from '../AuthLoginButton'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function Header(props) {
  const classes = useStyles(props)
  const {
    logoLink,
    logoTitle,
    userLink,
    userRoute,
    userRouteParams,
    userLabel,
    menuLinks,
    footerSocialProps,
    footerLinks,
    isLogged,
    userProps,
  } = props

  const [opened, setOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(true)

  const handleResize = () => {
    const windowSize = getWindowSize()

    setIsMobile(windowSize.width < sizes.desktop)

    if (windowSize.width >= sizes.desktop) {
      document.querySelector('body').style.overflow = 'visible'
      document.querySelector('body').style.position = 'static'
      document.querySelector('html').style.overflow = 'visible'
    } else if (opened) {
      document.querySelector('body').style.overflow = 'hidden'
      document.querySelector('body').style.position = 'fixed'
      document.querySelector('html').style.overflow = 'hidden'
    }
  }

  const toggleMenu = useCallback((isOpened) => {
    if (!isMobile) {
      return
    }

    setOpen(isOpened)
    if (isOpened) {
      document.querySelector('body').style.overflow = 'hidden'
      document.querySelector('body').style.position = 'fixed'
      document.querySelector('html').style.overflow = 'hidden'
    } else {
      document.querySelector('body').style.overflow = 'visible'
      document.querySelector('body').style.position = 'static'
      document.querySelector('html').style.overflow = 'visible'
    }
  }, [isMobile])

  const renderMenuLinks = useCallback(() => menuLinks.map((menuEntry, i) => (
    <MenuEntry
      {...menuEntry}
      key={i}
      classnames={{ container: classes.menuEntry }}
      isMenuMobile={isMobile && opened}
      onClick={() => toggleMenu(!opened)}
    />
  )), [menuLinks, isMobile, opened, classes, toggleMenu])

  const renderFooterLink = useCallback((footerLink, i) => (
    <A
      key={i}
      href={footerLink.route || footerLink.link}
      routeParams={footerLink.routeParams}
      className={classes.footerLink}
      onClick={() => toggleMenu(!opened)}
      title={footerLink.label}
    >
      {footerLink.label}
    </A>
  ), [toggleMenu, opened, classes])

  const renderFooterSeparator = useCallback((footerLink, i) => (
    <div
      className={classes.footerSeparator}
      key={i}
    >
      <div className={classes.footerSeparatorLabel}>{footerLink.label}</div>
      <div className={classes.footerSeparatorContent}>
        {footerLink.entries?.map(renderFooterLink)}
      </div>
    </div>
  ), [classes, renderFooterLink])

  const renderFooterLinks = useCallback(() => footerLinks.map((footerLink, i) => (
    footerLink?.type === 'SEPARATOR' ? (renderFooterSeparator(footerLink, i)) : (renderFooterLink(footerLink, i))
  )), [footerLinks, renderFooterSeparator, renderFooterLink])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  const userButtonProps = {
    icon: iconsKeys.Profil,
    color: 'secondary',
    colorIcon: colors.darkBlue,
    colorIconHover: colors.pureWhite,
    isOutlined: true,
    link: userLink,
    route: userRoute,
    routeParams: userRouteParams,
  }

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <BurgerMenuButton
          className={classes.burgerMenuButton}
          onClick={() => toggleMenu(!opened)}
          opened={opened}
        />
        {logoLink && (
          <Icon
            icon={iconsKeys.Logo}
            link={logoLink}
            onClick={opened ? () => toggleMenu(!opened) : null}
            classname={classes.logo}
            iconProps={{ className: classes.logoAsset }}
            seoTitle={logoTitle}
          />
        )}
        {menuLinks.length >= 1 && (
          <div className={cx(classes.menu, opened && classes.menuOpened)}>
            <div className={classes.menuList}>
              {renderMenuLinks()}
            </div>
            {(footerSocialProps || footerLinks.length >= 1) && (
              <div className={classes.footerMobile}>
                {footerSocialProps && (
                  <>
                    <div
                      className={classes.footerSocialLabel}
                      dangerouslySetInnerHTML={{ __html: footerSocialProps.label }}
                    />
                    <FooterSocial
                      {...footerSocialProps}
                      isInMobileMenu
                    />
                  </>
                )}
                {footerLinks.length >= 1 && (
                  <div className={classes.footerLinks}>
                    {renderFooterLinks()}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {!isLogged && userLink && (
          <>
            <ActionButton
              {...userButtonProps}
              title={userLabel}
              className={cx(classes.userButton, classes.isMobile)}
            />
            <ActionButton
              {...userButtonProps}
              label={userLabel}
              className={cx(classes.userButton, classes.isDesktop)}
            />
          </>
        )}
        {isLogged && userProps !== null && (
          <AuthLoginButton
            {...userProps}
            className={classes.userLoggedButton}
          />
        )}
      </div>
    </div>
  )
}

Header.styles = styles

Header.propTypes = {
  logoLink: PropTypes.string.isRequired,
  logoTitle: PropTypes.string,
  userLink: PropTypes.string.isRequired,
  userRoute: PropTypes.string,
  userRouteParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  userLabel: PropTypes.string.isRequired,
  menuLinks: PropTypes.arrayOf(PropTypes.shape(MenuEntry.propTypes)),
  footerSocialProps: PropTypes.shape(FooterSocial.propTypes),
  footerLinks: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  })),
  isLogged: PropTypes.bool,
  userProps: PropTypes.shape(AuthLoginButton.propTypes),
}

Header.defaultProps = {
  logoTitle: null,
  menuLinks: [],
  footerSocialProps: null,
  footerLinks: [],
  isLogged: false,
  userProps: null,
  userRoute: null,
  userRouteParams: null,
}

export default withMemo()(Header)
