import { colors, desktop, fonts, sizes, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    margin: '5rem auto',
    maxWidth: `${sizes.desktop / 10}rem`,
    padding: '0 1rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    ...desktop({
      flexDirection: 'row',
    }),
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    ...desktop({
      marginRight: '3rem',
    }),
  },
  title: {
    '& > p': {
      fontFamily: fonts.FiraSansBold.fontFamily,
      fontWeight: fontWeights.bold,
      fontSize: '3rem',
      lineHeight: '3.6rem',
      color: colors.darkBlue,
      marginBottom: '2rem',
      '& strong': {
        color: colors.primary,
      },
    },
  },
  text: {
    ...fonts.FiraSansRegular,
    fontSize: '1.8rem',
    lineHeight: '2.7rem',
    color: colors.nightRider,
    marginBottom: '2rem',
  },
  image: {
    width: '100%',
    height: 'auto',
    '& img': {
      borderRadius: '2rem',
    },
    ...tablet({
      width: '52rem',
    }),
  },
  cta: {
    padding: '1.1rem 2.5rem',
    maxWidth: '20rem',
  },
}
