import { colors, desktop, fonts, tablet } from '../../theme'


export default ({
  container: {
    borderTop: `1px solid ${colors.darkBlue}`,
    height: '4.5rem',
    ...tablet({
      height: '7.9rem',
      width: '25%',
      borderTop: 0,
      borderLeft: `1px solid ${colors.darkBlue}`,
    }),
  },
  label: {
    display: 'none',
  },
})

export const selectStyles = (props) => ({
  control: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    borderWidth: 0,
    borderRadius: '0',
    borderStyle: 'solid',
    borderColor: 'transparent',
    height: '100%',
    background: 'transparent',
    ...state.isFocused && {
      boxShadow: 'none',
    },
    ...state.menuIsOpen && {
      boxShadow: 'none',
    },
    '&:hover': {
      borderLeftColor: colors.darkBlue,
    },
  }),
  container: (provided, state) => ({
    ...provided,
    padding: 0,
    height: '100%',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: colors.darkBlue,
    padding: 0,
    marginRight: '1rem',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    '&:hover': {
      color: colors.darkBlue,
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    margin: 0,
    color: colors.nightRider,
    paddingLeft: '1rem',
    ...desktop({
      paddingLeft: '2rem',
    }),
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colors.darkBlue,
    ...fonts.FiraSansMedium,
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    ...desktop({
      fontSize: '1.4rem',
      lineHeight: '1.4rem',
    }),
    margin: 0,
    overflow: 'hidden',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: colors.darkBlue,
    margin: 0,
    ...fonts.FiraSansMedium,
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    ...desktop({
      fontSize: '1.4rem',
      lineHeight: '1.4rem',
    }),
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: 0,
    borderRadius: 0,
    border: `solid .1rem ${colors.darkBlue}`,
    boxShadow: 'none',
    overflow: 'hidden',
    left: -1,
    right: -1,
    width: 'auto',
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: colors.honeydew,
  }),
  option: (provided, state) => ({
    ...provided,
    color: colors.nightRider,
    padding: '.4rem 1rem',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    cursor: 'pointer',
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    height: '4rem',
    ...tablet({
      fontSize: '1.2rem',
      lineHeight: '1.2rem',
      height: '4rem',
    }),
    ...desktop({
      fontSize: '1.4rem',
      lineHeight: '1.4rem',
      height: '4.5rem',
    }),
    ...state.isSelected && {
      backgroundColor: colors.aliceBlue,
      color: colors.darkBlue,
    },
    ...state.isFocused && {
      backgroundColor: colors.darkBlue,
      color: colors.pureWhite,
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  }),
})
