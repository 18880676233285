import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SeamlessImmutable from 'seamless-immutable'

import withMemo from '../../decorators/WithMemo'
import {
  getPagination,
  parseQueryParams,
  buildQueryWithSlug,
  getRelationFilterOptions
} from '../../helpers/FilterHelpers'
import { selectors as AppSelectors } from '../../redux/app/redux'
import routes, { Router } from '../../routes'
import { colors } from '../../theme'
import { iconsKeys } from '../Icon/Icon.assets'
import { actions as MessengerActions } from '../../pages/UserMessages/redux'
import { selectors as AuthSelectors } from '../../redux/auth/redux'
import { getAllValue } from '../../helpers/RelationsFiltersHelpers'

import { relationsPrefix } from './config'
import RelationsResults from './index'


function RelationsResultsWrapper(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const route = useSelector(AppSelectors.route)
  const {
    filters,
    relations, pagination,
    filterValues,
    filterConfig,
  } = props
  const allTranslationForFilter = getAllValue(t)

  const isPremium = useSelector(AuthSelectors.isActivePremium)

  const handleSubmit = useCallback((p, resetPage = false) => {
    const _p = {
      filterValues: p.filterValues.map((item) => {
        if (item.value[0] === allTranslationForFilter) {
          return {
            ...item,
            value: filterConfig.find((f) => f.slug === item.name).options.map((o) => o.value),
          }
        }
        return item
      }),
    }

    const href = buildQueryWithSlug(route, filterConfig, pagination, _p, resetPage)

    Router.pushRoute(href)
  }, [route, filterConfig, pagination, allTranslationForFilter])

  const handleContactClick = (id, name, avatar, company) => {
    dispatch(MessengerActions.setContactProfile({
      id,
      first_name: name.split(' ')[0],
      last_name: name.split(' ')[1],
      company: { name: company.name },
      avatar: avatar === '/images/default-avatar.png' ? null : avatar,
    }))

    Router.pushRoute(routes.userMessages.path)
  }

  const params = {
    ...filters,
    ...parseQueryParams(route?.asPath, route?.slug),
  }

  const filterOptions = useMemo(() => getRelationFilterOptions(t, filterConfig, relationsPrefix), [filterConfig, t])

  // Pagination params
  const paginationProps = useMemo(() => {
    const p = getPagination(pagination, route?.asPath, route?.query, params)

    return p
  }, [pagination, params, route])

  const selectedDomains = filterValues.map((f) => f.value)?.flat()

  const entries = relations ? relations?.map((relation) => {
    if (selectedDomains.length > 0) {
      const profileDomains = relation?.matchingDomains?.map((d) => d?.id)
      const commonDomains = selectedDomains.filter((d) => profileDomains?.includes(d))
      const matchingDomains = relation.matchingDomains.filter((d) => commonDomains.includes(d.id))
      const percentage = relation.percentage.toFixed(0)

      return {
        ...relation,
        matchingDomains,
        matchingPercentage: t('relationsResults_percentage', { percentage }),
      }
    }
    return relation
  }) : []

  const newEntries = selectedDomains.length > 0
    ? SeamlessImmutable.asMutable(entries).sort((a, b) => (a.percentage > b.percentage ? -1 : 1))
    : entries

  const data = {
    ...props,
    filterBoxProps: {
      placeholder: t('relationsResults_filterPlaceholder'),
      filters: filterOptions,
      values: filterValues,
    },
    entriesTitle: t('relationsResults_entriesTitle', { count: pagination?.total || 0 }),
    entries: filterValues.length > 0 ? newEntries : [],
    handleSubmit,
    emptyText: t('relationsResults_emptyLabel'),
    pagination: paginationProps,
    searchButtonLabel: t('relationsResults_searchButtonLabel'),
    handleContactClick,
    contactButtonLabel: t('profileDetails_messageLabel'),
    pushPublishProps: {
      text: t('postsResults_pushPublishText'),
      ctaLabel: t('relationsResults_pushPublishCta'),
      ctaLink: Router.getRouteUrl(routes.userPublishPost, {}),
      ctaRoute: routes.userPublishPost.name,
      ctaRouteParams: { },
      ctaProps: {
        icon: iconsKeys.SmallArrowRight,
        iconRight: true,
        colorIcon: colors.pureWhite,
        colorIconHover: colors.darkBlue,
        color: 'secondary',
      },
      isCard: false,
      hasModal: true,
      modalTitle: t('postsResults_publish_post_title_unauthenticated'),
      modalText: t('postsResults_publish_post_text_unauthenticated'),
      modalCta: t('postsResults_publish_post_cta_unauthenticated'),
    },
    isPremium,
    pushPremiumProps: {
      ctaLabel: t('pushPremium_cta_label'),
      ctaRoute: '/inscription?type=premium',
      ctaIcon: iconsKeys.ArrowRight,
    },
    allTranslationForFilter,
  }

  return (
    <RelationsResults
      {...data}
    />
  )
}

RelationsResultsWrapper.propTypes = RelationsResults.propTypes

RelationsResultsWrapper.defaultProps = RelationsResults.defaultProps

export default withMemo()(RelationsResultsWrapper)
