/* eslint-disable */
import Immutable from 'seamless-immutable'


export const requestInitialState = () => ({
  pending: false,
  success: false,
  complete: false,
  errors: null,
  data: null,
  params: null,
})

export const requestReducers = (actions, name) => ({
  [actions[name]]: (state, values) => Immutable.static.merge(state, {
    [name]: {
      pending: true,
      success: false,
      complete: false,
      error: null,
      data: null,
      params: values,
    },
  }),
  [actions[`${name}Success`]]: (state, data) => Immutable.static.setIn(state, [name], {
    pending: false,
    success: true,
    complete: true,
    errors: null,
    data,
  }),
  [actions[`${name}Error`]]: (state, errors) => Immutable.static.setIn(state, [name], {
    pending: false,
    success: false,
    complete: true,
    errors,
    data: null,
  }),
  ...(actions[`${name}Reset`] ? {
    [actions[`${name}Reset`]]: state => Immutable.static.setIn(state, [name], {
      pending: false,
      success: false,
      complete: false,
      errors: null,
      data: null,
      params: null,
    }),
  } : {}),
})
