import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import FileEntry from '../../data/models/FileEntry'
import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FileLibrary = (props) => {
  const classes = useStyles(props)
  const { className, files, ctaLabel } = props

  const renderFiles = () => files.map((item) => {
    const { id, path, title, image } = item

    return (
      <div
        className={classes.file}
        role="presentation"
        key={id}
      >
        <div className={classes.fileImage}>
          <CloudinaryImage
            id={image}
            covered
            options={{
              width: '77.5',
              height: '100',
              crop: 'lpad',
            }}
            lazyLoad
          />
        </div>
        <div className={classes.fileBody}>
          <div className={classes.fileName}>{title}</div>
          {path !== null && ctaLabel !== null && (
            <ActionButton
              noBg
              iconRight
              label={ctaLabel}
              link={path}
              color="white"
              icon={iconsKeys.ArrowRight}
              colorIcon={colors.darkBlue}
              className={classes.fileSee}
            />
          )}
        </div>
      </div>
    )
  })

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {renderFiles()}
      </div>
    </div>
  )
}

FileLibrary.propTypes = {
  className: PropTypes.string,
  files: PropTypes.arrayOf(FileEntry.propTypes),
  ctaLabel: PropTypes.string,
}

FileLibrary.defaultProps = {
  className: '',
  files: [],
  ctaLabel: '',
}

export default withMemo()(FileLibrary)
