import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import CloudinaryImage from '../CloudinaryImage'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Promise = (props) => {
  const classes = useStyles(props)
  const { className, title, image } = props

  return (
    <div className={cx(className, classes.container)}>
      <CloudinaryImage
        id={image}
        className={cx(classes.image)}
        options={{
          width: '100',
          height: '100',
          crop: 'fill',
        }}
        lazyLoad
      />
      <p className={cx(classes.title)}>
        {title}
      </p>
    </div>
  )
}

Promise.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string.isRequired,
}

Promise.defaultProps = {
  className: '',
  title: null,
}

export default withMemo()(Promise)
