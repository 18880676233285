import { colors, fonts, tablet } from '../../theme'


export default {
  container: {
    width: '100%',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    color: colors.nightRider,
    padding: '1rem 1rem 1.5rem',
  },
  intro: {
    ...fonts.FiraSansRegular,
    fontSize: '1.5rem',
    color: colors.nightRider,
    padding: '1rem 1rem 0',
  },
  explanation: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    color: colors.nightRider,
    padding: '1rem 1rem 3rem',
  },
  fields: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    ...tablet({
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    }),
  },
  field: {
    padding: '1rem',
    width: '100%',
    ...tablet({
      width: '50%',
    }),
  },
  fieldImage: {
    padding: '3rem 1rem 3rem',
    width: '100%',
  },
  fieldProStatus: {
    padding: '1rem 1rem 5rem',
    width: '100%',
    position: 'relative',
  },
  fieldDescription: {
    padding: '3rem 1rem 5rem',
    width: '100%',
  },
  socialNetworks: {
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 5rem',
    ...tablet({
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    }),
  },
  errors: {
    textAlign: 'center',
    padding: '1rem',
  },
  error: {
    color: colors.error,
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    padding: '0.5rem 0',
  },
  submitContainer: {
    textAlign: 'center',
    padding: '1rem 0',
    flexWrap: 'wrap',
  },
  button: {
    margin: '1rem',
  },
  subtitle: {
    ...fonts.FiraSansMedium,
    fontSize: '1.6rem',
    color: colors.nightRider,
    padding: '1rem 1rem 1.5rem',
    width: '100%',
  },
}
