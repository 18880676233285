import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/WithMemo'
import FilterRelationBoxEntry from '../FilterRelationBoxEntry'
import ActionButton from '../ActionButton'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FilterRelationBox = (props) => {
  const classes = useStyles(props)
  const {
    className,
    placeholder,
    filters,
    values,
    onChange,
    containerRef,
    submenuRef,
    isOpen,
    toggleSelect,
    searchButton,
    allTranslationForFilter,
  } = props


  const renderFilters = useMemo(() => filters?.map((filter, i) => {
    const currentValue = values.find((item) => item.name === filter.name)

    if (filter.visible === false) {
      return null
    }

    return (
      <FilterRelationBoxEntry
        {...filter}
        value={currentValue?.value}
        onChange={onChange}
        key={i}
        i={i}
        isMulti={filter?.isMulti}
        className={classes.filter}
        isOpen={isOpen[i]}
        toggleSelect={toggleSelect}
        allTranslationForFilter={allTranslationForFilter}
      />
    )
  }), [filters, values, onChange, classes.filter, isOpen, toggleSelect, allTranslationForFilter])

  if (!filters) {
    return null
  }

  return (
    <div
      className={cx(classes.container, className)}
      ref={containerRef}
    >
      <div className={classes.title}>{placeholder}</div>
      <div
        className={classes.submenu}
        ref={submenuRef}
      >
        {renderFilters}
      </div>
      <div className={classes.buttonBox}>
        <ActionButton
          label={searchButton.label}
          onClick={searchButton.onClick}
          color="secondary"
          className={classes.button}
        />
      </div>
    </div>
  )
}

FilterRelationBox.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['checkbox', 'radio']),
    isMulti: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    name: PropTypes.string,
  })),
  values: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  })),
  onChange: PropTypes.func,
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]),
  submenuRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]),
  isOpen: PropTypes.arrayOf(PropTypes.bool),
  toggleSelect: PropTypes.func,
  searchButton: {
    label: PropTypes.string,
    onClick: PropTypes.func,
  },
  allTranslationForFilter: PropTypes.string,
}

FilterRelationBox.defaultProps = {
  className: '',
  filters: [],
  values: [],
  onChange: null,
  containerRef: null,
  submenuRef: null,
  isOpen: [],
  toggleSelect: () => undefined,
  searchButton: null,
  allTranslationForFilter: '',
}

export default withMemo()(FilterRelationBox)
