import { colors, desktop, fonts } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    padding: 0,
    display: 'inline-block',
    cursor: (props) => (props.isDisabled ? 'inherit' : 'pointer'),
    marginBottom: '1.2rem',
  },
  containerNoMargin: {
    marginBottom: 0,
  },
  cta: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    color: colors.pureWhite,
    '&:hover': {
      textDecoration: (props) => (!props.isDisabled ? 'underline' : 'none'),
    },
    textDecoration: (props) => (props.isSelected ? 'underline' : 'none'),

    ...desktop({
      fontSize: '1.4rem',
      lineHeight: '1.7rem',
    }),
  },
  bold: {
    fontWeight: fontWeights.semiBold,
  },
}
