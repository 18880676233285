import { fonts, colors, desktop } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    padding: '4rem 1rem 4.5rem',
    background: colors.honeydew,
    ...desktop({
      padding: '5rem 0 7.4rem',
    }),
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.4rem',
    lineHeight: '2.6rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.darkBlue,
    marginBottom: '4rem',
  },
  cardContainer: {
    margin: '0 auto',
    maxWidth: '94rem',
    position: 'relative',
    overflow: 'hidden',
    listStyle: 'none',
    padding: 0,
    zIndex: 1,
  },
  cardWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    transitionProperty: 'transform',
    boxSizing: 'content-box',
    transform: 'translate3d(0px, 0, 0)',
    marginBottom: ['4rem', '!important'],
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '13rem',
    position: 'relative',
    transitionProperty: 'transform',
  },
  cta: {
    display: 'flex',
    justifyContent: 'center',
  },
}
