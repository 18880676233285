import { colors, desktop } from '../../theme'


export default {
  container: {
    background: colors.darkBlue,
    display: 'flex',
    flexDirection: 'row',
    padding: '0 1.3rem',
    ...desktop({
      padding: '0 3.3rem',
    }),
  },
  linkEntry: {
    marginRight: '.5rem',
  },
}
