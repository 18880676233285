import { colors, fonts, placeholder } from '../../../../theme'


export default {
  input: {
    flex: 1,
    border: 0,
    height: '100%',
    padding: [['1.1rem', '0rem', '1.1rem']],
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.4rem',
    color: ({ disabled }) => (
      (disabled && colors.silver)
      || colors.nightRider
    ),
    ...placeholder(({ disabled }) => (
      (disabled && colors.silver)
      || colors.grey
    )),
  },
}
