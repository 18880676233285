export const offerType = {
  OFFER_TYPE_PURCHASING_CONSULTATION: 'OFFER_TYPE_PURCHASING_CONSULTATION',
  OFFER_TYPE_CALL_FOR_TENDER: 'OFFER_TYPE_CALL_FOR_TENDER',
  OFFER_TYPE_CO_CONTRACTING: 'OFFER_TYPE_CO_CONTRACTING',
  OFFER_TYPE_CIRCULAR_ECONOMY: 'OFFER_TYPE_CIRCULAR_ECONOMY',
  OFFER_TYPE_SOURCING: 'OFFER_TYPE_SOURCING',
  OFFER_TYPE_OGCN: 'OFFER_TYPE_OGCN',
}

export const offerTypeArray = [
  offerType.OFFER_TYPE_PURCHASING_CONSULTATION,
  offerType.OFFER_TYPE_CO_CONTRACTING,
  offerType.OFFER_TYPE_CIRCULAR_ECONOMY,
  offerType.OFFER_TYPE_SOURCING,
  offerType.OFFER_TYPE_OGCN,
]

export const offerTypeFilterArray = [
  offerType.OFFER_TYPE_PURCHASING_CONSULTATION,
  offerType.OFFER_TYPE_CALL_FOR_TENDER,
  offerType.OFFER_TYPE_CO_CONTRACTING,
  offerType.OFFER_TYPE_CIRCULAR_ECONOMY,
  offerType.OFFER_TYPE_SOURCING,
  offerType.OFFER_TYPE_OGCN,
]

export const offerTypeOptions = (t) => offerTypeArray.map((value) => ({
  label: t(`offerType_${value}`),
  value,
}))

export const offerTypeTranslation = (t, value) => t(`offerType_${value}`)

const offerTypeSlugs = {
  [offerType.OFFER_TYPE_PURCHASING_CONSULTATION]: 'pc',
  [offerType.OFFER_TYPE_CALL_FOR_TENDER]: 'cft',
  [offerType.OFFER_TYPE_CO_CONTRACTING]: 'cc',
  [offerType.OFFER_TYPE_CIRCULAR_ECONOMY]: 'ce',
  [offerType.OFFER_TYPE_SOURCING]: 'sour',
  [offerType.OFFER_TYPE_OGCN]: 'ogcn',
}

export const offerTypeConfig = offerTypeFilterArray.map((value) => ({
  key: `offerType_${value}`,
  id: value,
  value: offerTypeSlugs[value],
}))
