import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import { useRouter } from 'next/router'

import withMemo from '../../decorators/WithMemo'
import A from '../A'
import MenuSubEntry from '../MenuSubEntry'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import { useIsPWA } from '../../hooks/useIsPWA'

import styles from './styles'


const useStyles = createUseStyles(styles)

// eslint-disable-next-line complexity
const MenuEntry = (props) => {
  const classes = useStyles(props)
  const {
    label,
    link,
    route,
    routeParams,
    theming,
    isSelected,
    isMenuMobile,
    onClick,
    classnames,
    entries,
    onlyOnPWA,
  } = props

  const router = useRouter()
  const [mouseTimeout, setMouseTimeout] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const isPWA = useIsPWA()



  const handleOpen = useCallback(() => setIsOpen(true), [])
  const handleClose = useCallback(() => setIsOpen(false), [])

  const handleMouseEnter = useCallback(() => {
    if (isMenuMobile) {
      return
    }
    if (mouseTimeout) {
      clearTimeout(mouseTimeout)
      setMouseTimeout(null)
    }
    handleOpen()
  }, [isMenuMobile, mouseTimeout, handleOpen])

  const handleMouseOut = useCallback(() => {
    if (isMenuMobile) {
      return
    }
    setMouseTimeout(setTimeout(() => handleClose(), 200))
  }, [isMenuMobile, handleClose])

  const renderSubEntries = useMemo(() => (
    <div
      className={cx(
        classes.entries,
        isMenuMobile && classes.isSubMenuMobile
      )}
    >
      {entries?.map((entry, i) => (
        <MenuSubEntry
          key={i}
          label={entry.label}
          link={entry.link}
          route={entry.route}
          routeParams={entry.routeParams}
          isSelected={router.asPath === entry.link}
          isMenuMobile={isMenuMobile}
          onClick={entry.onClick}
          classnames={entry.classnames}
        />
      ))}
    </div>
  ), [classes.entries, classes.isSubMenuMobile, entries, isMenuMobile, router.asPath])


  if (onlyOnPWA && !isPWA) {
    return null
  }

  return (
    <div
      className={cx(classes.wrapper, isOpen && classes.isOpen)}
      onMouseEnter={handleMouseEnter}
      onMouseOver={handleMouseEnter}
      onFocus={handleMouseEnter}
      onMouseLeave={handleMouseOut}
    >
      <A
        href={route || link}
        routeParams={routeParams}
        className={cx(
          classes.container,
          classnames.container,
          isMenuMobile && classes.isMenuMobile,
          isSelected && classes.selected,
          theming === 'dark' && classes.dark
        )}
        onClick={onClick}
        title={label}
      >
        <div className={classes.label}>
          {label}
          {entries && !isMenuMobile && (
            <Icon
              className={cx(classes.chevron, isOpen && classes.chevronOpen)}
              icon={iconsKeys.Chevron}
            />
          )}
        </div>
      </A>
      {entries && renderSubEntries}

    </div>
  )
}

MenuEntry.styles = styles

MenuEntry.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  theming: PropTypes.oneOf(['light', 'dark']),
  isSelected: PropTypes.bool,
  isMenuMobile: PropTypes.bool,
  onClick: PropTypes.func,
  classnames: PropTypes.objectOf(PropTypes.string),
  entries: PropTypes.arrayOf(PropTypes.shape(MenuSubEntry.propTypes)),
  onlyOnPWA: PropTypes.bool,
}

MenuEntry.defaultProps = {
  label: null,
  link: null,
  route: null,
  routeParams: null,
  theming: 'light',
  isSelected: false,
  isMenuMobile: false,
  onClick: null,
  classnames: {},
  entries: null,
  onlyOnPWA: false,
}

export default withMemo()(MenuEntry)
