export function generateUniqueId() {
  let time = Date.now()
  const last = generateUniqueId.last || 0

  if (last >= time) {
    time = last + 1
  }
  generateUniqueId.last = time
  return time.toString(36)
}
