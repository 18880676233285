import { colors, fonts, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    padding: '2rem',
    paddingBottom: '3rem',
    ...tablet({
      paddingBottom: '7rem',
    }),
    overflow: 'hidden',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  gradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%)',
    pointerEvents: 'none',
  },
  content: {
    display: 'flex',
    width: '100%',
    maxWidth: '94rem',
    zIndex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: '42rem',
    ...tablet({
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    }),
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...tablet({
      marginRight: '18rem',
      alignItems: 'flex-start',
    }),
  },
  highlightBadge: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.darkBlue,
    borderRadius: '0.5rem',
    marginBottom: '2rem',
    padding: '0.4rem 1rem',
  },
  badgeIcon: {
    marginRight: '0.5rem',
    width: '2.4rem',
    height: '2.4rem',
  },
  badgeText: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.semiBold,
    color: colors.pureWhite,
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.semiBold,
    color: colors.pureWhite,
    fontSize: '3rem',
    lineHeight: '3.6rem',
    textAlign: 'center',
    marginBottom: '4.2rem',
    textShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)',
    ...tablet({
      marginBottom: 0,
      maxWidth: '54rem',
      textAlign: 'left',
    }),
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '2rem',
    padding: '2.2rem',
    background: colors.honeydew,
    minWidth: '22rem',
    maxWidth: '42rem',
    ...tablet({
      minWidth: '32rem',
      maxWidth: '32rem',
    }),
    position: 'relative',
  },
  infoRow: {
    marginBottom: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  infoIcon: {
    marginRight: '0.5rem',
    width: '2.4rem',
    height: '2.4rem',
  },
  infoText: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    color: colors.nightRider,
    marginTop: '0.3rem',
  },
  cta: {
    marginTop: '2rem',
  },
  boxOgcn: {
    position: 'absolute',
    right: '2rem',
    bottom: '2.5rem',
    border: '2px solid black',
    borderRadius: '50%',
  },
  iconOgcn: {
    display: 'flex',
    justifyContent: 'center',
    width: '3.5rem',
    height: '3.5rem',
    '& svg': {
      display: 'flex',
      alignSelf: 'center',
      width: '2.5rem',
      height: '2.5rem',
    },
  },
}
