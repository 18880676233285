import { colors, fonts, tablet } from '../../theme'
import modalStyles from '../Modal/styles'


export default {
  container: {
    ...fonts.FiraSansRegular,
  },
  credits: {
    padding: '0 0 1.5rem',
    fontSize: '1.5rem',
  },
  candidateList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    ...tablet({
      justifyContent: 'space-between',
    }),
  },
  loading: {
    position: 'absolute',
    marginTop: '2rem',
  },
  candidateCard: {
    width: '100%',
    marginBottom: '1rem',
    ...tablet({
      maxWidth: '32rem',
    }),
  },
  candidateListLoading: {
    opacity: '0.2',
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '2rem',
    ...tablet({
      flexDirection: 'row',
      justifyContent: 'flex-start',
    }),
  },
  batchActions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: '2.5rem',
    ...tablet({
      marginTop: 0,
      justifyContent: 'flex-start',
    }),
  },
  selectAll: {
    ...tablet({
      minWidth: '16rem',
    }),
  },
  action: {
    ...tablet({
      marginLeft: '2rem',
    }),
  },
  alert: {
    maxWidth: '40rem',
  },
  alertContainer: {
    ...modalStyles.content,
    ...tablet({
      marginLeft: '28rem',
    }),
  },
  tabContent: {
    background: colors.honeydew,
    padding: '.2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tabContentAlternative: {
    background: colors.honeydew,
    padding: '1.6rem 1.2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}
