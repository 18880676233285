import PropTypes from 'prop-types'

import FileLibrary from '../../components/FileLibrary'
import offerDurations from '../../components/OfferType/durations'
import offerTypes from '../../components/OfferType/types'

import Category from './Category'
import FileEntry from './FileEntry'
import OfferCard from './OfferCard'


const Offer = (props) => props && {
  id: props.id,
  title: props.label,
  reference: props.reference,
  full_slug: props.full_slug,
  introduction: props.introduction,
  description: props.description,
  deadline: props.maximal_execution_at,
  companyName: props.company_name,
  isActive: props.is_active ?? true,
  budget: +props.budget || 0,
  type: props.type,
  city: props.city,
  files: {
    files: props.files.map(FileEntry),
  },
  user: {
    fullName: `${props.user?.first_name} ${props.user?.last_name}`,
    first_name: props.user?.first_name,
    last_name: props.user?.last_name,
    id: props.user?.id,
  },
  keywords: props.keywords,
  categories: props.categories?.map(Category),
  link: props.link,
  date: props.published_at,
  similar: props?.similar_offers?.data?.map(OfferCard),
}

Offer.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  reference: PropTypes.string,
  introduction: PropTypes.string,
  deadline: PropTypes.string,
  budget: PropTypes.number,
  type: PropTypes.string,
  city: PropTypes.string,
  files: PropTypes.shape(FileLibrary.propTypes),
  user: PropTypes.shape({
    fullName: PropTypes.string,
  }),
  keywords: PropTypes.arrayOf(PropTypes.string),
  categories: PropTypes.arrayOf(Category.propTypes),
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.string,
  }),
  date: PropTypes.string,
}

Offer.contractTypes = {
  CONTRACT_TYPE_CDI: offerTypes.CDI,
  CONTRACT_TYPE_CDD: offerTypes.CDD,
  CONTRACT_TYPE_INTERN: offerTypes.STAGE,
  CONTRACT_TYPE_APPRENTICE: offerTypes.APPRENTISSAGE,
}

Offer.durationType = {
  DURATION_DAYS: offerDurations.DAYS,
  DURATION_MONTHS: offerDurations.MONTHS,
  DURATION_YEARS: offerDurations.YEARS,
}

Offer.providerType = {
  COMPANY: 'COMPANY',
}

Offer.degreeLevelTypes = {
  INDIFFERENT: 'INDIFFERENT',
  DEGREE_LEVEL_CQP: 'DEGREE_LEVEL_CQP',
  DEGREE_LEVEL_CAP_BEP: 'DEGREE_LEVEL_CAP_BEP',
  DEGREE_LEVEL_BAC: 'DEGREE_LEVEL_BAC',
  DEGREE_LEVEL_BAC_2: 'DEGREE_LEVEL_BAC_2',
  DEGREE_LEVEL_BAC_3: 'DEGREE_LEVEL_BAC_3',
  DEGREE_LEVEL_BAC_4: 'DEGREE_LEVEL_BAC_4',
  DEGREE_LEVEL_BAC_5: 'DEGREE_LEVEL_BAC_5',
  DEGREE_LEVEL_BAC_8: 'DEGREE_LEVEL_BAC_8',
}

Offer.experienceTypes = {
  INDIFFERENT: 'INDIFFERENT',
  EXPERIENCE_JUNIOR: 'EXPERIENCE_JUNIOR',
  EXPERIENCE_CONFIRMED: 'EXPERIENCE_CONFIRMED',
  EXPERIENCE_SENIOR: 'EXPERIENCE_SENIOR',
  EXPERIENCE_EXPERT: 'EXPERIENCE_EXPERT',
}

export default Offer
