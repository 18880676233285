import { fonts, colors, desktop, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '4rem 0',
    margin: '0 1.5rem',
    ...tablet({
      margin: '0 3rem',
    }),
    ...desktop({
      margin: '0 4.5rem',
    }),
  },
  image: {
    '& img': {
      maxWidth: '12.5rem',
      marginBottom: '3rem',
    },
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2rem',
    lineHeight: '2.4rem',
    textTransform: 'uppercase',
    color: colors.egyptianBlue,
    marginBottom: '2rem',
    ...desktop({
      fontSize: '2.4rem',
      lineHeight: '2.6rem',
    }),
  },
  text: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    textAlign: 'center',
    color: colors.nightRider,
    maxWidth: '26rem',
    ...desktop({
      fontSize: '1.8rem',
      lineHeight: '2.6rem',
      maxWidth: '39rem',
    }),
  },
}
