import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import A from '../A'
import CloudinaryImage from '../CloudinaryImage'
import Swiper from '../../utils/Swiper'
import sizes from '../../theme/sizes'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Partners = (props) => {
  const classes = useStyles(props)
  const { className, title, cards } = props
  const [swiperInit, setSwiperInit] = useState(null)

  useEffect(() => {
    if (!swiperInit) {
      setSwiperInit(true)
      /* eslint-disable-next-line */
      new Swiper('.swiper-container', {
        wrapperClass: classes.cardWrapper,
        slideClass: classes.card,
        direction: 'horizontal',
        loop: true,
        grabCursor: true,
        autoplay: {
          delay: 3000,
        },
        slidesPerView: 6,
        slidesPerGroup: 1,
        spaceBetween: 25,
        centerInsufficientSlides: true,
        breakpoints: {
          [sizes.tablet - 1]: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          [sizes.desktop - 1]: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        },
      })
    }
  }, [classes.card, classes.cardWrapper, props, swiperInit])

  if (!cards || cards.length === 0) {
    return <></>
  }

  const renderCards = () => cards.map((card) => (
    <div
      className={classes.card}
      key={`partner-${card.id}`}
    >
      <A
        href={card.link}
        target="_blank"
        title={title}
      >
        <CloudinaryImage
          id={card.image}
          options={{
            width: '130',
            height: '90',
            crop: 'lpad',
          }}
        />
      </A>
    </div>
  ))

  return (
    <div className={cx(classes.container, className)}>
      {title && <h2 className={classes.title}>{title}</h2>}
      <div className={cx('swiper-container', classes.cardContainer)}>
        <div className={classes.cardWrapper}>
          {cards && renderCards()}
        </div>
      </div>
    </div>
  )
}

Partners.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    link: PropTypes.string,
  })),
}

Partners.defaultProps = {
  className: '',
  cards: null,
}

export default withMemo()(Partners)
