/* eslint-disable max-lines */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import ActionButton from '../ActionButton'
import MarkdownText from '../MarkdownText'
import ModalWithHeader from '../ModalWithHeader'
import FileLibrary from '../FileLibrary'

import styles from './styles'


const useStyles = createUseStyles(styles)

function ConfirmationModal(props) {
  const classes = useStyles(props)
  const {
    className,
    isOpen,
    title,
    text,
    subtext,
    description,
    files,
    filesLabel,
    seeFileLabel,
    onClose,
    onConfirm,
    cancelLabel,
    confirmLabel,
    canClose,
    canConfirm,
  } = props

  return (
    <ModalWithHeader
      className={cx(classes.container, className)}
      title={title}
      isOpen={isOpen}
      onClose={onClose}
    >
      <MarkdownText
        className={classes.text}
        text={text}
        inline
      />
      {!!subtext && <div className={classes.subtext}>{subtext}</div>}
      {!!description && <div className={classes.description}>{description}</div>}
      {Boolean(files?.files?.length) && (
        <>
          <h1 className={classes.filesTitle}>{filesLabel}</h1>
          <FileLibrary
            {...files}
            ctaLabel={seeFileLabel}
            className={classes.files}
          />
        </>
      )}
      <div className={classes.buttons}>
        {confirmLabel && onConfirm && (
          <ActionButton
            isDisabled={!canConfirm}
            className={classes.button}
            label={confirmLabel}
            onClick={onConfirm}
          />
        )}
        {cancelLabel && onClose && (
          <ActionButton
            isDisabled={!canClose}
            className={classes.button}
            label={cancelLabel}
            onClick={onClose}
            isOutlined
          />
        )}
      </div>
    </ModalWithHeader>
  )
}

ConfirmationModal.styles = styles

ConfirmationModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  subtext: PropTypes.string,
  description: PropTypes.string,
  files: PropTypes.array,
  filesLabel: PropTypes.string,
  seeFileLabel: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  canClose: PropTypes.bool,
  canConfirm: PropTypes.bool,
}

ConfirmationModal.defaultProps = {
  className: '',
  isOpen: false,
  title: null,
  text: null,
  subtext: null,
  description: null,
  files: [],
  filesLabel: '',
  seeFileLabel: '',
  onClose: () => null,
  onConfirm: () => null,
  cancelLabel: null,
  confirmLabel: null,
  canClose: true,
  canConfirm: true,
}

export default withMemo()(ConfirmationModal)
