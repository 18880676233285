/* eslint-disable max-lines */
import cx from 'classnames'
import _uniqBy from 'lodash/uniqBy'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Resumable from 'resumablejs'

import withMemo from '../../../../decorators/WithMemo'
import { colors } from '../../../../theme'
import ActionButton from '../../../ActionButton'
import CloudinaryImage from '../../../CloudinaryImage'
import Icon from '../../../Icon'
import { iconsKeys } from '../../../Icon/Icon.assets'
import MarkdownText from '../../../MarkdownText'
import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import FormLabel from '../../FormLabel'
import Checkbox from '../Checkbox'
import { formatSize } from '../../../../helpers/NumberHelpers'

import styles from './styles'


const useStyles = createUseStyles(styles)

const UploadGallery = (props) => {
  const classes = { ...useStyles(props), ...props.classes }
  const {
    className,
    name,
    label,
    browseLabel,
    deleteLabel,
    mainLabel,
    legend,
    help,
    error,
    required,
    value,
    onError,
    onChange,
    onStateChange,
    errorMessages,
    config: {
      maxFiles,
      token,
      accept,
      minFileSize,
      maxFileSize = UploadGallery.defaultProps.config.maxFileSize,
      endpoint,
      chunkSize,
      minWidth = UploadGallery.defaultProps.config.minWidth,
      maxWidth = UploadGallery.defaultProps.config.maxWidth,
      minHeight = UploadGallery.defaultProps.config.minHeight,
      maxHeight = UploadGallery.defaultProps.config.maxHeight,
      ...conf
    },
  } = props

  const [files, setFiles] = useState(value)

  const dropNode = useRef(null)
  const buttonNode = useRef(null)

  const getId = useCallback((f) => f.id || f.uniqueIdentifier, [])

  const [localError, setLocalError] = useState()
  const localErrorText = useMemo(() => (localError
    ? errorMessages[localError?.type]?.replace('{{maxFileSize}}', formatSize(maxFileSize))
    : null), [localError, errorMessages, maxFileSize])


  const updateValue = useCallback(
    (f) => {
      const val = (f || files)
        .filter((file) => !file.progress || file.progress() === 1)
        .map(({ id, key, path, main }) => ({
          id,
          key,
          path,
          main,
        }))

      onChange({
        name,
        value: val,
      })
    },
    [onChange, name, files]
  )

  const handleDelete = useCallback(
    (file) => {
      if (file) {
        if (file.cancel) {
          file.cancel()
        }
        setFiles((before) => {
          const newFiles = before.filter((f) => getId(file) !== getId(f))

          updateValue(newFiles)
          return newFiles
        })
      }
    },
    [getId, updateValue]
  )

  const handleMainChange = useCallback(
    (file) => {
      setFiles((before) => {
        const newFiles = before.map((f) => {
          const isCurrent = getId(file) === getId(f)

          return {
            ...f,
            main: f.main && isCurrent ? false : !!isCurrent,
          }
        })

        updateValue(newFiles)
        return newFiles
      })
    },
    [getId, updateValue]
  )

  useEffect(() => {
    let mounted = true
    const setError = (type, data) => {
      setLocalError({ type, data })
      onError({ type, data })
    }

    const minFileSizeErrorCallback = (f) => {
      setError(UploadGallery.errorTypes.MIN_FILE_SIZE_ERROR)
      setError(UploadGallery.errorTypes.MIN_FILE_SIZE_ERROR, {
        size: f.size,
        minFileSize,
      })
    }

    const maxFileSizeErrorCallback = (f) => {
      console.log('file size error')
      setError(UploadGallery.errorTypes.MAX_FILE_SIZE_ERROR, {
        size: f.size,
        maxFileSize,
      })
    }

    const maxFilesErrorCallback = () => null

    const res = new Resumable({
      ...(token && { headers: { Authorization: `Bearer ${token}` } }),
      target: endpoint,
      chunkSize,
      method: 'POST',
      fileType: accept,
      maxFileSize,
      minFileSize,
      minFileSizeErrorCallback,
      maxFileSizeErrorCallback,
      maxFilesErrorCallback,
      maxFiles: 1,
      ...conf,
    })

    res.assignBrowse(buttonNode.current)
    res.assignDrop(dropNode.current)

    res.on('fileAdded', (file, event) => {
      const newFile = {
        ...file,
        id: getId(file),
        main: false,
      }

      setFiles((before) => _uniqBy([...before, newFile], (v) => v.id))
      onStateChange(UploadGallery.states.IN_PROGRESS)
      res.upload()
    })

    res.on('fileSuccess', (file, event) => {
      let result

      try {
        result = JSON.parse(event)
      } catch (e) {
        result = {}
      }
      setError(null)

      const path = URL.createObjectURL(file.file)
      const key = result.file

      const update = (notify = false) => {
        setFiles((before) => {
          const newFiles = before.map((f) => {
            if (getId(file) === getId(f)) {
              return {
                ...f,
                ...file,
                key,
                path,
                id: getId(file),
              }
            }
            return f
          })

          if (notify) {
            updateValue(newFiles)
          }

          return newFiles
        })
      }

      update()

      const img = new Image()

      img.onload = () => {
        if (mounted) {
          if (
            img.width < minWidth
            || img.width > maxWidth
            || img.height < minHeight
            || img.height > maxHeight
          ) {
            setError(UploadGallery.errorTypes.IMAGE_SIZE_ERROR, img)
          } else {
            update(true)
          }
        }
      }
      img.src = path
    })

    res.on('fileError', (file, event) => {
      setError(UploadGallery.errorTypes.FILE_ERROR, {
        file,
        event,
      })
    })

    res.on('fileProgress', (file, event) => {
      setFiles((before) => {
        const newFiles = before.map((f) => {
          if (getId(file) === getId(f)) {
            return {
              ...f,
              ...file,
            }
          }
          return f
        })

        return newFiles
      })
    })

    res.on('progress', () => {})

    res.on('complete', () => {
      onStateChange(UploadGallery.states.COMPLETE)
      updateValue()
    })

    return () => {
      mounted = false
      if (res.isUploading()) {
        res.cancel()
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div
      ref={dropNode}
      className={cx(classes.container, className)}
    >
      <FormLabel
        className={classes.label}
        required={required}
        error={!!error}
      >
        {label}
      </FormLabel>

      <MarkdownText
        className={classes.legend}
        text={legend}
        inline
      />

      <div className={classes.wrapper}>
        {!!files
        && files.map((file) => (
          <div
            key={getId(file)}
            className={classes.file}
          >
            <div className={classes.imageContainer}>
              <div>
                {file.path && (
                  <CloudinaryImage
                    id={file.path}
                    className={classes.image}
                    options={{
                      width: '220',
                      height: '160',
                      crop: 'fill',
                    }}
                    lazyLoad
                  />
                )}
                <div
                  className={classes.progress}
                  style={{
                    transform: `scaleX(${file.progress ? file.progress() : 0})`,
                    opacity: file.progress && file.progress() < 1 ? 1 : 0,
                  }}
                />
              </div>
            </div>
            <Checkbox
              name="check"
              className={classes.checkbox}
              value={file.main}
              onChange={() => handleMainChange(file)}
              label={mainLabel}
            />
            <ActionButton
              className={classes.delete}
              label={deleteLabel}
              onClick={() => handleDelete(file)}
              isSmall
            />
          </div>
        ))}

        {!!files && (
          <div
            className={classes.file}
            style={files.length >= maxFiles ? { display: 'none' } : null}
          >
            <span
              ref={buttonNode}
              className={cx(classes.imageContainer, classes.browse)}
            >
              <div className={classes.browseWrapper}>
                <Icon
                  icon={iconsKeys.Image}
                  className={classes.browseIcon}
                  color={colors.darkBlue}
                />
                <div className={classes.browseLabel}>{browseLabel}</div>
                {maxFiles < Number.MAX_SAFE_INTEGER && (
                  <div className={classes.remaining}>{`${files.length}/${maxFiles}`}</div>
                )}
              </div>
            </span>
          </div>
        )}
      </div>

      <FormErrorText
        className={classes.errorText}
        text={localErrorText}
      />
      <FormErrorText
        className={classes.errorText}
        text={error}
      />
      <FormHelpText
        className={classes.helpText}
        text={help}
        error={!!error}
      />
    </div>
  )
}

UploadGallery.errorTypes = {
  MIN_FILE_SIZE_ERROR: 'min_file_size_error',
  MAX_FILE_SIZE_ERROR: 'max_file_size_error',
  IMAGE_SIZE_ERROR: 'image_size_error',
  FILE_ERROR: 'file_error',
}

UploadGallery.states = {
  EMPTY: 'empty',
  COMPLETE: 'complete',
  IN_PROGRESS: 'in_progress',
  ERROR: 'error',
}

UploadGallery.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  browseLabel: PropTypes.string,
  deleteLabel: PropTypes.string,
  mainLabel: PropTypes.string,
  legend: PropTypes.string,
  help: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      path: PropTypes.string,
      main: PropTypes.bool,
    })
  ),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onStateChange: PropTypes.func,
  required: PropTypes.bool,
  config: PropTypes.shape({
    token: PropTypes.string,
    endpoint: PropTypes.string.isRequired,
    assetPath: PropTypes.string,
    chunkSize: PropTypes.number,
    accept: PropTypes.array,
    minFileSize: PropTypes.number,
    maxFileSize: PropTypes.number,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    minHeight: PropTypes.number,
    maxHeight: PropTypes.number,
    maxFiles: PropTypes.number,
  }),
  errorMessages: PropTypes.objectOf(PropTypes.string),
}

UploadGallery.defaultValue = []

UploadGallery.defaultProps = {
  classes: {},
  className: null,
  browseLabel: '',
  deleteLabel: '',
  mainLabel: '',
  label: null,
  legend: null,
  help: null,
  error: null,
  value: [],
  onChange: () => undefined,
  onError: () => undefined,
  onStateChange: () => undefined,
  required: false,
  config: {
    token: null,
    chunkSize: 1024 * 512,
    accept: ['jpg', 'png', 'gif'],
    minFileSize: 0,
    maxFileSize: 5 * 1024 * 1024,
    minWidth: 0,
    maxWidth: Number.MAX_SAFE_INTEGER,
    minHeight: 0,
    maxHeight: Number.MAX_SAFE_INTEGER,
    maxFiles: Number.MAX_SAFE_INTEGER,
  },
  errorMessages: {
    [UploadGallery.errorTypes.MAX_FILE_SIZE_ERROR]: 'Le fichier est trop volumineux, veuillez respecter la limite de {{maxFileSize}}.',
  },
}

export default withMemo()(UploadGallery)
