import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import withMemo from '../../decorators/WithMemo'
import { getFieldTranslations } from '../../helpers/FormHelpers'
import { actions as CompanyActions, selectors as CompanySelectors } from '../../pages/Company/redux'
import { selectors as AuthSelectors } from '../../redux/auth/redux'

import CommentForm, { fields } from './index'


const CommentFormWrapper = (props) => {
  const dispatch = useDispatch()
  const { modalCloseHandler } = props

  const { t } = useTranslation()

  const data = useSelector(CompanySelectors.data)
  const user = useSelector(AuthSelectors.user)

  const postCommentSelector = useSelector(CompanySelectors.postComment)
  const submitErrors = postCommentSelector?.errors
  const submitSuccess = postCommentSelector?.success
  const submitPending = postCommentSelector?.pending
  const handleSubmit = (values) => dispatch(CompanyActions.postComment(values))

  const formProps = useMemo(() => {
    const fprops = {
      submitLabel: t('post_comment_submit_label'),
      successLabel: t('post_comment_success_label'),
      backLabel: t('post_comment_back_label'),
      onBackClick: modalCloseHandler,
      fieldsProps: fields.reduce(
        (r, field) => ({
          ...r,
          [field.name]: getFieldTranslations(t, `post_comment_${field.name}`),
        }), {}
      ),
      messages: {
        required: t('form_validation_required'),
      },
      onSubmit: handleSubmit,
    }

    return fprops
  }, [t, modalCloseHandler, handleSubmit])

  if (!user || !data) {
    return null
  }

  return (
    <>
      <CommentForm
        {...formProps}
        submitErrors={submitErrors}
        submitSuccess={submitSuccess}
        submitPending={submitPending}
        successText={t('form_submit_success')}
        pendingText={t('form_submit_pending')}
      />
    </>
  )
}

CommentFormWrapper.propTypes = {
  modalCloseHandler: PropTypes.func,
}

CommentFormWrapper.defaultProps = {
  modalCloseHandler: () => undefined,
}

export default withMemo()(CommentFormWrapper)
