import { fonts, colors, desktop } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    background: colors.aliceBlue,
    borderRadius: '2rem',
    padding: '1.4rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
    ...desktop({
      maxWidth: '20rem',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }),
  },
  metric: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 1.7rem',
    '&:not(:last-child)': {
      borderBottom: ['.1rem', 'solid', colors.pureWhite],
      marginBottom: '1rem',
      paddingBottom: '1.4rem',
      width: '90%',
    },
    ...desktop({
      width: '100%',
    }),
  },
  label: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.1rem',
    lineHeight: '1.2rem',
    textAlign: 'center',
    color: colors.nightRider,
    textTransform: 'uppercase',
  },
  value: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.6rem',
    lineHeight: '1.9rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.darkBlue,
  },
}
