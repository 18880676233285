import cx from 'classnames'
import _values from 'lodash/values'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import A from '../A'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const ActionButton = (props) => {
  const classes = { ...useStyles(props), ...props.classes }
  const {
    className,
    label,
    icon,
    isDisabled,
    isSuccess,
    link,
    route,
    routeParams,
    target,
    onClick,
    colorIcon,
    isOutlined,
    isSmall,
    isBig,
    noBg,
    colorIconHover,
    iconRight,
    title,
    classes: classesProp,
    tag,
    ...otherProps
  } = props

  const noBgClass = noBg ? classes.noBg : null
  const iconRightClass = iconRight ? classes.iconRight : null

  const Tag = tag || 'div'

  const renderIcon = icon && (
    <Icon
      icon={iconsKeys[icon]}
      classname={classes.icon}
      color={colorIcon}
      iconProps={{ className: classes.iconAsset }}
    />
  )

  const content = (
    <>
      {icon && renderIcon}
      {label}
    </>
  )

  const renderDefault = (
    <Tag
      {...otherProps}
      className={cx(classes.container, className, iconRightClass, noBgClass)}
      onClick={!isDisabled ? onClick : null}
      role="presentation"
    >
      {content}
    </Tag>
  )

  const renderAsLink = (
    <A
      {...otherProps}
      href={route || link}
      routeParams={routeParams}
      target={target}
      title={title || label}
      className={cx(classes.container, className, iconRightClass, noBgClass)}
    >
      {content}
    </A>
  )

  return !isDisabled && !isSuccess && (link || route) ? renderAsLink : renderDefault
}

ActionButton.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  label: PropTypes.string,
  isOutlined: PropTypes.bool,
  isSmall: PropTypes.bool,
  isBig: PropTypes.bool,
  icon: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'burntOrange', 'white', 'transparent']),
  colorIcon: PropTypes.oneOf(_values(colors)),
  colorIconHover: PropTypes.oneOf(_values(colors)),
  isDisabled: PropTypes.bool,
  isSuccess: PropTypes.bool,
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  target: PropTypes.string,
  onClick: PropTypes.func,
  iconRight: PropTypes.bool,
  noBg: PropTypes.bool,
  title: PropTypes.string,
  tag: PropTypes.string,
}

ActionButton.defaultProps = {
  className: '',
  classes: {},
  label: '',
  isOutlined: false,
  isSmall: false,
  isBig: false,
  icon: null,
  color: 'primary',
  colorIcon: null,
  colorIconHover: null,
  isDisabled: false,
  isSuccess: false,
  link: null,
  route: null,
  routeParams: null,
  target: null,
  onClick: () => null,
  iconRight: false,
  noBg: false,
  title: null,
  tag: 'div',
}

export default withMemo()(ActionButton)
