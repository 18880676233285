import { colors, desktop, fonts } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
  },
  headerContainer: {
    display: 'block',
    width: '100%',
    height: '32rem',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  headerGradient: {
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%)',
  },
  headerWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    maxWidth: '94rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '2rem 1.5rem 8rem',
    ...desktop({
      padding: '2rem 1.5rem 3rem',
    }),
  },
  headerButton: {
    marginBottom: '3rem',
  },
  headerTitle: {
    ...fonts.FiraSansBold,
    color: colors.pureWhite,
    maxWidth: '54rem',
    fontSize: '2.0rem',
    lineHeight: '2.4rem',
    margin: '0',
    ...desktop({
      fontSize: '3.2rem',
      lineHeight: '3.6rem',
    }),
  },
  wrapper: {
    padding: '5rem 1.5rem 0rem',
    ...desktop({
      padding: '5rem 1.5rem 0rem',
    }),
  },
  main: {
    //
  },
  sidebar: {
    marginTop: '-11.5rem',
    ...desktop({
      marginTop: '-11.5rem',
    }),
  },
  mapBlock: {
    width: '100%',
    maxWidth: '94rem',
    margin: 'auto',
    ...desktop({
      padding: '0 2rem',
    }),
  },
  mapLabel: {
    ...fonts.FiraSansBold,
    fontSize: '2.0rem',
    lineHeight: '2.4rem',
    color: colors.darkBlue,
    textTransform: 'uppercase',
    marginTop: '5rem',
  },
  mapContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2.7rem',
    borderRadius: '2.5rem',
    overflow: 'hidden',
    ...desktop({
      flexDirection: 'row',
    }),
  },
  mapAddressContainer: {
    padding: '3.1rem 8rem 2.5rem 3.7rem',
    backgroundColor: colors.darkBlue,
  },
  mapAddressBig: {
    ...fonts.FiraSansRegular,
    fontWeight: fontWeights.bold,
    fontSize: '1.8rem',
    lineHeight: '2.6rem',
    color: colors.pureWhite,
    marginBottom: '0.5rem',
  },
  mapAddress: {
    ...fonts.FiraSansRegular,
    fontSize: '1.5rem',
    lineHeight: '2.0rem',
    color: colors.pureWhite,
  },
  mapLink: {
    display: 'flex',
    flex: 1,
    maxHeight: '35rem',
  },
  map: {
    width: '100%',
    height: '18rem',
    ...desktop({
      height: '100%',
    }),
  },
  linkedEventsContainer: {
    margin: '0 -1.5rem',
    padding: '6rem 0em 10.5rem 0rem',
    backgroundColor: colors.honeydew,
    marginTop: '6.2rem',
    ...desktop({
      marginTop: '8rem',
    }),
  },
  linkedEventsWrapper: {
    width: '100%',
    maxWidth: '94rem',
    margin: 'auto',
    padding: '0 1.5rem',
  },
  linkedEventsTitle: {
    ...fonts.FiraSansRegular,
    fontWeight: fontWeights.bold,
    color: colors.nightRider,
    fontSize: '2.0rem',
    lineHeight: '2.4rem',
    ...desktop({
      fontSize: '3.0rem',
      lineHeight: '3.6rem',
    }),
  },
  linkedEvents: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '4.7rem',
    ...desktop({
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
    }),
  },
  linkedEvent: {
    //
  },
  linkedEventMargin: {
    marginTop: '3rem',
    ...desktop({
      marginTop: '0rem',
      marginLeft: '4rem',
    }),
  },
}
