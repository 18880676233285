import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import A from '../A'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const OtherSkill = (props) => {
  const classes = useStyles(props)
  const { className, label, link } = props

  return (
    <div className={cx(classes.container, className)}>
      <A
        className={classes.label}
        href={link}
        title={label}
      >
        {label}
      </A>
    </div>
  )
}

OtherSkill.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
}

OtherSkill.defaultProps = {
  className: '',
  link: null,
}

export default withMemo()(OtherSkill)
