import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'

import withMemo from '../../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function FormErrorText(props) {
  const {
    className,
    text,
    classes: propsClasses,
    ...others
  } = props

  const classes = { ...useStyles(props), ...props.classes }

  if (!text) {
    return null
  }

  return (
    <span
      {...others}
      className={cx(classes.container, className)}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}

FormErrorText.styles = styles

FormErrorText.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  text: PropTypes.string,
}

FormErrorText.defaultProps = {
  classes: {},
  className: null,
  text: '',
}

export default withMemo()(FormErrorText)
