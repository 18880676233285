/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'

import { generateUniqueId } from '../../../../utils/ComponentUtils'
import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import FormLabel from '../../FormLabel'
import withMemo from '../../../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function Textarea(props) {
  const {
    className,
    inputClassName,
    type,
    id,
    name,
    value,
    label,
    help,
    placeholder,
    required,
    disabled,
    error,
    maxChar,
    inputRef,
    readOnly,
    inputProps,
    onChange,
    onBlur,
    onFocus,
  } = props

  const [thisId] = useState(`form_text_${generateUniqueId()}`)
  const [focused, setFocused] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const el = ref && ref.current

    if (el) {
      if (el.offsetHeight < el.scrollHeight) {
        el.style.height = `${el.scrollHeight}px`
      }
    }
  }, [ref, value])

  const handleChange = (event) => {
    if (inputProps && inputProps.onChange) {
      inputProps.onChange(event)
    }
    onChange({
      name,
      value: event.currentTarget.value,
      event,
    })
  }

  const handleFocus = (event) => {
    setFocused(true)
    if (inputProps && inputProps.onFocus) {
      inputProps.onFocus(event)
    }
    onFocus({ name, event })
  }

  const handleBlur = (event) => {
    setFocused(false)
    if (inputProps && inputProps.onBlur) {
      inputProps.onBlur(event)
    }
    onBlur({ name, event })
  }

  const classes = { ...useStyles({ ...props, focused }), ...props.classes }

  const handleRef = (r) => {
    ref.current = r
    inputRef(r)
  }

  return (
    <div
      className={cx(
        classes.container,
        className
      )}
    >
      <>
        <label
          className={classes.wrapper}
          htmlFor={id || thisId}
        >
          <FormLabel
            className={classes.label}
            required={required}
            error={!!error}
          >
            {label}
          </FormLabel>
        </label>
        <div className={classes.inputContainer}>
          <textarea
            {...inputProps}
            id={id || thisId}
            ref={handleRef}
            className={cx(classes.input, inputClassName)}
            type={type}
            name={name}
            aria-label={name}
            value={value}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
            maxLength={maxChar}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        </div>
      </>
      <FormErrorText
        className={classes.errorText}
        text={error}
      />
      <FormHelpText
        className={classes.helpText}
        text={help}
        error={!!error}
      />
    </div>
  )
}

Textarea.styles = styles

Textarea.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.oneOf(['email', 'password', 'text', 'number']),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
  help: PropTypes.string,
  placeholder: PropTypes.string,
  maxChar: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  onChange: PropTypes.func, // {name, value, event}
  onBlur: PropTypes.func, // {name, event}
  onFocus: PropTypes.func, // {name, event}
  inputRef: PropTypes.func,
}

Textarea.defaultProps = {
  classes: {},
  className: null,
  id: null,
  inputClassName: null,
  value: '',
  label: '',
  type: 'text',
  required: false,
  disabled: false,
  readOnly: false,
  error: null,
  help: null,
  placeholder: null,
  maxChar: null,
  inputProps: null,
  onChange: () => undefined,
  onBlur: () => undefined,
  onFocus: () => undefined,
  inputRef: () => undefined,
}

Textarea.defaultValue = ''

export default withMemo()(Textarea)
