import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import ReactSelect from 'react-select'
import Immutable from 'seamless-immutable'

import withMemo from '../../../../decorators/WithMemo'
import { generateUniqueId } from '../../../../utils/ComponentUtils'
import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import FormLabel from '../../FormLabel'

import styles, { selectStyles } from './styles'


const useStyles = createUseStyles(styles)

function Select(props) {
  const {
    className,
    classNames,
    label,
    name,
    placeholder,
    options,
    value,
    disabled,
    isMulti,
    isSearchable,
    required,
    error,
    help,
    inputProps,
    onChange,
    onFocus,
    onBlur,
    id,
  } = props

  const [thisId] = useState(`form_select_${generateUniqueId()}`)
  const classes = useStyles(props)

  if (!Array.isArray(options) || !options.length) {
    return null
  }

  const handleChange = (selectedOption) => {
    if (inputProps && inputProps.onChange) {
      inputProps.onChange(selectedOption)
    }
    if (onChange !== null) {
      onChange({
        name,
        value: selectedOption.value,
      })
    }
  }

  const handleFocus = (event) => {
    if (onFocus !== null) {
      onFocus({
        name,
        event,
      })
    }
  }

  const handleBlur = (event) => {
    if (onBlur !== null) {
      onBlur({
        name,
        event,
      })
    }
  }

  let selectedOption = null

  if (value !== null && Array.isArray(options)) {
    selectedOption = options.reduce((res, option) => {
      if (option.value === value) {
        return option
      }
      return res
    }, null)
  }

  const mutableOptions = Array.isArray(options) && Immutable.isImmutable(options)
    ? Immutable.asMutable(options, { seep: true })
    : options

  return (
    <div className={cx(classes.container, className, classNames.container)}>
      <label
        className={cx(classes.wrapper, classNames.wrapper)}
        htmlFor={id || thisId}
      >
        <FormLabel
          className={cx(classes.label, classNames.label)}
          required={required}
          error={!!error}
        >
          {label}
        </FormLabel>
      </label>
      <div className={cx(classes.container, className)}>
        <ReactSelect
          className={cx(classes.select)}
          classNamePrefix="react-select"
          styles={selectStyles(props)}
          aria-label={name}
          name={name}
          inputId={id || thisId}
          instanceId={`${name}_instance`}
          placeholder={placeholder}
          options={mutableOptions || []}
          value={selectedOption}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          isDisabled={disabled}
          isMulti={isMulti}
          isSearchable={isSearchable}
        />
      </div>
      <FormHelpText
        className={classes.helpText}
        text={help}
      />
      <FormErrorText
        className={classes.errorText}
        text={error}
      />
    </div>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.objectOf(PropTypes.string),

  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),

  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  help: PropTypes.string,

  inputProps: PropTypes.object,

  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

Select.defaultProps = {
  className: '',
  classNames: {},

  id: null,
  placeholder: '',
  label: '',

  value: null,
  error: null,
  help: null,

  inputProps: null,

  required: false,
  disabled: false,
  isMulti: false,
  isSearchable: false,
  options: null,

  onChange: null,
  onFocus: null,
  onBlur: null,
}

Select.defaultValue = ''

export default withMemo()(Select)
