import { fonts, colors, sizes } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    padding: '3rem 2rem',
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
  },
  text: {
    textAlign: ({ align }) => (align ? align.toLowerCase() : 'left'),
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    color: colors.nightRider,
    '& img': {
      maxWidth: '100%',
    },
    '& h1': {
      fontFamily: fonts.FiraSansBold.fontFamily,
      fontWeight: fontWeights.bold,
      fontSize: '3.2rem',
      lineHeight: '3.8rem',
      color: colors.darkBlue,
      textTransform: 'uppercase',
      margin: 0,
      marginTop: '2rem',
      marginBottom: '3rem',
    },
    '& h2': {
      fontFamily: fonts.FiraSansBold.fontFamily,
      fontWeight: fontWeights.bold,
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
      color: colors.nightRider,
      textTransform: 'uppercase',
    },
    '& h3': {
      fontFamily: fonts.FiraSansBold.fontFamily,
      fontWeight: fontWeights.bold,
      fontSize: '1.4rem',
      lineHeight: 'auto',
      color: colors.darkBlue,
      textTransform: 'uppercase',
    },
    '& h5': {
      fontFamily: fonts.FiraSansRegular.fontFamily,
      fontWeight: fontWeights.regular,
      fontSize: '1.4rem',
      lineHeight: 'auto',
      color: colors.nightRider,
      textTransform: 'uppercase',
    },
    '& p': {
      fontFamily: fonts.FiraSansRegular.fontFamily,
      fontWeight: fontWeights.regular,
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
      color: colors.nightRider,
      paddingBottom: '1rem',
    },
    '& a, & a:visited, & a:active': {
      textDecoration: 'underline',
      color: colors.darkBlue,
    },
    '& strong': {
      fontFamily: fonts.FiraSansBold.fontFamily,
      fontWeight: fontWeights.bold,
    },
    '& ul': {
      listStyle: 'none',
    },
    '& li': {
      fontFamily: fonts.FiraSansRegular.fontFamily,
      fontWeight: fontWeights.regular,
      fontSize: '1.4rem',
      lineHeight: '2rem',
      color: colors.nightRider,
      paddingLeft: '2rem',
      position: 'relative',
      '&:not(:last-child)': {
        paddingBottom: '1rem',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        background: colors.burntOrange,
        width: '.3rem',
        height: '.3rem',
        borderRadius: '50%',
        left: '1.2rem',
        top: '.7rem',
      },
    },
    '& table': {
      borderSpacing: 0,
      borderCollapse: 'collapse',
      border: 0,
      marginBottom: '1rem',
      '& td': {
        verticalAlign: 'baseline',
        padding: '0.4rem',
        border: `1px solid ${colors.gainsboro}`,
      },
    },
  },
}
