import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import Promise from '../Promise'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)


const PromiseContainer = (props) => {
  const classes = useStyles(props)
  const { className, promises, text } = props

  const renderCards = () => promises.map((promise, index) => (
    <Promise
      {...promise}
      key={`promise-${index}`}
      className={classes.promise}
    />
  ))

  return (
    <div className={cx(classes.container, className)}>
      {text && (
        <h2
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      <div className={classes.promisesContainer}>
        {promises && renderCards()}
      </div>
    </div>
  )
}

PromiseContainer.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  promises: PropTypes.arrayOf(PropTypes.shape(Promise.propTypes)).isRequired,
}

PromiseContainer.defaultProps = {
  className: '',
  text: null,
}

export default withMemo()(PromiseContainer)
