import React, { Fragment, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import BreadcrumbEntry from '../BreadcrumbEntry'
import { getWindowSize } from '../../utils/WindowSize'
import { sizes } from '../../theme'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const LAST_ITEMS_MOBILE = 2
const MAX_CHAR_BY_ITEM = 30

const useStyles = createUseStyles(styles)

const Breadcrumb = (props) => {
  const classes = useStyles(props)
  const { className, entries } = props

  const [tab, setTab] = useState(entries)

  const handleResize = useCallback(() => {
    const windowSize = getWindowSize()

    if (!entries || !entries.length >= 1) {
      return
    }

    if (windowSize.width >= sizes.desktop) {
      setTab(entries)
    } else {
      setTab(entries.slice(entries.length - LAST_ITEMS_MOBILE, entries.length)
        .map((entry) => ({
          ...entry,
          label: entry.label && entry.label.length > MAX_CHAR_BY_ITEM
            ? `${entry.label.substr(0, MAX_CHAR_BY_ITEM)}...`
            : entry.label,
        })))
    }
  }, [entries])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    setTab(entries)
  }, [entries])

  if (!entries || entries.length === 0) {
    return null
  }

  return tab && tab.length > 0 && (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {tab.map((entry, key) => (
          <Fragment key={key}>
            <BreadcrumbEntry
              {...entry}
              isCurrent={key === tab.length - 1}
            />
          </Fragment>
        ))}
      </div>
    </div>
  )
}

Breadcrumb.propTypes = {
  className: PropTypes.string,
  entries: PropTypes.arrayOf(PropTypes.shape(BreadcrumbEntry.propTypes)),
}

Breadcrumb.defaultProps = {
  className: null,
  entries: null,
}

export default withMemo()(Breadcrumb)
