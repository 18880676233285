import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import MarkdownText from '../MarkdownText'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Paragraph = (props) => {
  const classes = useStyles(props)
  const { className, classNames, text, inline, tag } = props

  if (!text) {
    return <></>
  }

  return (
    <div className={cx(classes.container, classNames.parentContainer)}>
      <MarkdownText
        className={cx(classes.text, className)}
        text={text}
        tag={tag}
        inline={inline}
      />
    </div>
  )
}

Paragraph.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.objectOf(PropTypes.string),
  text: PropTypes.string.isRequired,
  tag: PropTypes.string,
  inline: PropTypes.bool,
}

Paragraph.defaultProps = {
  className: '',
  classNames: {},
  tag: 'div',
  inline: false,
}

export default withMemo()(Paragraph)
