import Candidacy from './Candidacy'
import Notification from './Notification'
import OfferCard from './OfferCard'


const User = (props) => {
  if (!props) {
    return null
  }

  const user = {
    id: props.id,
    // user_type: props.user_type,
    email: props.email,
    first_name: props.first_name,
    last_name: props.last_name,
    slug: props.slug,
    phone: props.phone,
    phoneProfessional: props.phone_professional,
    birthday: props.birthday,
    companyFunction: props.companyFunction,
    companyFunctionDescription: props.company_function_description?.trim().length > 1
      ? props.company_function_description
      : null,
    family_situation: props.family_situation,
    avatar: props?.company?.logo || props.avatar,
    description: props.description,
    status: props.status,
    title: props.title,
    experience: props.experience,
    degree_level: props.degree_level,
    qualities: props.qualities,
    cv: props.cv,
    address: props.address,
    address_2: props.address_2,
    city: props.city,
    zip_code: props.zip_code,
    certifications: props.certifications,
    degrees: props.degrees,
    jobs: props.jobs,
    ...props.company && {
      company: {
        ...props.company,
        ...props.company.offers && {
          offers: props.company.offers.map((offer) => OfferCard(offer)),
        },
      },
    },
    links: props.links || [],
    skills: props.skills,
    offers: props.offers,
    offer_notification_phone: props.offer_notification_phone,
    offer_notification_email: props.offer_notification_email,
    recruiter_contact_phone: props.recruiter_contact_phone,
    recruiter_contact_email: props.recruiter_contact_email,
    notification_scheduling: props.notification_scheduling,
    newsletter: props.newsletter,
    created_at: props.created_at,
    updated_at: props.updated_at,
    fullName: `${props.first_name} ${props.last_name}`,
    isOwner: props.company?.owner?.id === props.id,
    isManager: props.company?.owner?.id !== props.id,
    candidacies: ((props.candidacies?.data ? props.candidacies?.data : props.candidacies) || [])?.map(Candidacy),
    favoritesUsers: props.favoritesUsers,
  }

  user.notifications = User.transformNotifications(user, props?.notifications)

  return user
}

User.transformNotifications = (user, notifications) => {
  const isProfileComplete = !!user?.phoneProfessional
    && !!user?.companyFunction
    && !!user?.companyFunctionDescription

  const isCompanyComplete = !!user?.company?.name
    && !!user?.company?.address
    && !!user?.company?.zip_code
    && !!user?.company?.city
    && !!user?.company?.siret
    && !!user?.company?.workforce
    && !!user?.company?.description
    && !!user?.company?.phone
    && !!user?.company?.email
    && !!user?.company?.domains?.length
    && !!user?.company?.contactMembers?.length

  const isPremium = !!user?.company?.isActivePremium

  return notifications?.filter((notification) => {
    switch (notification.type) {
    case 'profile_incomplete':
      return !isProfileComplete
    case 'company_incomplete':
      return !isCompanyComplete
    case 'go_premium':
      return !isPremium
      // no default
    }
    return true
  })?.map(Notification)
}

User.types = {
  MEMBER: 'MEMBER',
  COMPANY: 'COMPANY',
}

export default User
