import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import A from '../A'
import CloudinaryImage from '../CloudinaryImage'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function AuthLoginButton(props) {
  const classes = useStyles(props)
  const {
    className,
    logo,
    accountLink,
    route,
    routeParams,
    fullName,
    logoutLabel,
    countNotifications,
    handleLogout,
  } = props

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.infos}>
        {fullName !== null && (
          <span className={classes.name}>
            {fullName}
          </span>
        )}
        {logoutLabel !== null && (
          <span
            role="presentation"
            className={classes.logout}
            onClick={handleLogout}
          >
            {logoutLabel}
          </span>
        )}
      </div>
      <A
        href={accountLink}
        route={route}
        routeParams={routeParams}
        className={classes.avatar}
        title={fullName}
      >
        {countNotifications > 0 && (
          <span className={classes.notif}>
            {countNotifications}
          </span>
        )}
        {logo !== null && (
          <CloudinaryImage
            id={logo}
            className={classes.image}
            options={{
              width: '40',
              height: '40',
              crop: 'fill',
              gravity: 'face',
            }}
            lazyLoad
            covered
          />
        )}
        {(!logo || logo === null) && (
          <Icon
            icon={iconsKeys.Profil}
            color={colors.gray6}
          />
        )}
      </A>
    </div>
  )
}

AuthLoginButton.styles = styles

AuthLoginButton.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
  fullName: PropTypes.string,
  logoutLabel: PropTypes.string,
  countNotifications: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accountLink: PropTypes.string,
  handleLogout: PropTypes.func,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
}

AuthLoginButton.defaultProps = {
  className: '',
  logo: null,
  fullName: null,
  logoutLabel: null,
  countNotifications: 0,
  accountLink: null,
  route: null,
  routeParams: null,
  handleLogout: () => undefined,
}

export default withMemo()(AuthLoginButton)
