import Color from 'color'

import { fonts, colors, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    maxWidth: '70rem',
  },
  header: {
    marginBottom: '.2rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tab: {
    height: '6.8rem',
    padding: '1rem',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: colors.pureWhite,
    cursor: 'pointer',
    userSelect: 'none',
    '&:not(:first-child)': {
      marginLeft: '.2rem',
    },
    ...tablet({
      alignItems: 'flex-start',
      padding: '1rem 1.5rem',
    }),
    '&:not($active):hover': {
      background: Color(colors.darkBlue).fade(0.95).string(),
    },
  },
  title: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.grey,
    maxWidth: '5rem',
    ...tablet({
      textAlign: 'left',
      lineHeight: '2rem',
      maxWidth: '100%',
    }),
  },
  amount: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.2rem',
    marginTop: '.2rem',
    lineHeight: '1.8rem',
    color: colors.nightRider,
    ...tablet({
      fontSize: '2rem',
    }),
  },
  active: {
    background: colors.darkBlue,
    ...tablet({
      padding: '1rem 1.5rem',
    }),
    '& $title, & $amount': {
      color: colors.pureWhite,
    },
  },
  content: {
    background: colors.honeydew,
    padding: '1.4rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}
