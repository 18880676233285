/* eslint-disable max-lines */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import CloudinaryImage from '../CloudinaryImage'
import MarkdownText from '../MarkdownText'
import Paragraph from '../Paragraph'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const ProfileDetailsMain = (props) => {
  const classes = useStyles(props)
  const { className, profile } = props
  const {
    companyFunction,
    companyFunctionDescription,
    fullName,
    contactLabel,
    emailLabel,
    email,
    phoneLabel,
    phone,
    phoneProfessionalLabel,
    phoneProfessional,
    relationsLabel,
    relationsDescription,
    domains,
    messageRoute,
    messageLabel,
    currentNeedsLabel,
  } = profile


  const renderContacts = () => {
    const contacts = [{
      label: emailLabel,
      value: email,
    }, {
      label: phoneLabel,
      value: phone,
    }, {
      label: phoneProfessionalLabel,
      value: phoneProfessional,
    }].filter(({ label, value }) => !!value)

    return !!contacts.length && (
      <>
        <div className={classes.contactHeading}>
          {contactLabel}
        </div>
        <div className={classes.contactBlocks}>
          {contacts.map(({ label, value }, i) => (
            <div
              key={i.toString()}
              className={classes.contactBlock}
            >
              <div className={classes.contactTitle}>
                {label}
              </div>
              <div className={classes.contactValue}>
                {value}
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }

  const renderDomains = () => !!domains.length && (
    <>
      <div className={classes.relationsHeading}>
        {relationsLabel}
      </div>
      <MarkdownText
        text={relationsDescription}
        className={classes.relationsDescription}
      />
      <div className={classes.domains}>
        {domains.map(({ image, color, name }, i) => (
          <div
            key={i.toString()}
            className={classes.domain}
            style={{ backgroundColor: color }}
          >
            <CloudinaryImage
              lazyLoad
              className={classes.domainIcon}
              id={image}
              options={{
                width: '24',
                height: '24',
                crop: 'lpad',
              }}
            />
            <div className={classes.domainLabel}>
              {name}
            </div>
          </div>
        ))}
      </div>
    </>
  )

  const renderCurrentNeeds = () => profile?.currentNeeds?.length > 0 && (
    <>
      <div className={classes.relationsHeading}>
        {currentNeedsLabel}
      </div>
      <MarkdownText
        text={profile.currentNeeds}
        className={classes.currentNeedsDescription}
      />
    </>
  )


  const renderDescription = () => (
    <Paragraph
      text={companyFunctionDescription}
      className={classes.companyDescription}
      classNames={{ parentContainer: classes.companyDescriptionContainer }}
    />
  )

  const renderHeader = () => (
    <div className={classes.header}>
      <div className={classes.headerData}>
        <h1 className={classes.title}>{fullName}</h1>
        {!!companyFunction && (
          <div className={classes.headerDetails}>
            <div className={classes.companyFunction}>
              {companyFunction.name}
            </div>
            <ActionButton
              isOutlined
              iconRight
              label={messageLabel}
              route={messageRoute}
              color="primary"
              icon={iconsKeys.Chat}
              colorIcon={colors.darkOrange}
              className={classes.messageCTA}
            />
          </div>
        )}
      </div>
    </div>
  )

  return (
    <div className={cx(classes.container, className)}>
      {renderHeader()}
      {renderDescription()}
      {renderContacts()}
      {renderCurrentNeeds()}
      {renderDomains()}
    </div>
  )
}

ProfileDetailsMain.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.shape({
    title: PropTypes.string,
    fullName: PropTypes.string,
    companyAddressTitle: PropTypes.string,
    seeCompanyLabel: PropTypes.string,
    seeCompanyLink: PropTypes.string,
    seeCompanyRoute: PropTypes.string,
    seeCompanyRouteParams: PropTypes.shape({
      slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }),
    contactLabel: PropTypes.string,
    emailLabel: PropTypes.string,
    email: PropTypes.string,
    phoneLabel: PropTypes.string,
    phone: PropTypes.string,
    phoneProfessionalLabel: PropTypes.string,
    phoneProfessional: PropTypes.string,
    relationsLabel: PropTypes.string,
    relationsDescription: PropTypes.string,
    companyFunction: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    companyFunctionDescription: PropTypes.string,
    domains: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.string,
      color: PropTypes.string,
    })),
    company: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.shape({
        title: PropTypes.string,
        street: PropTypes.string,
        zip: PropTypes.string,
        city: PropTypes.string,
      }),
    }),
    links: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      link: PropTypes.string,
      icon: PropTypes.string,
    })),
    messageRoute: PropTypes.string,
    messageLabel: PropTypes.string,
    currentNeeds: PropTypes.string,
    currentNeedsLabel: PropTypes.string,
  }),
}

ProfileDetailsMain.defaultProps = {
  className: '',
  profile: null,
}

export default withMemo()(ProfileDetailsMain)
