import { colors, fonts, tablet, fullDesktop } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '32rem',
    padding: '2rem',
    paddingBottom: '10.6rem',
    ...tablet({
      paddingBottom: '7rem',
    }),
    ...fullDesktop({
      paddingBottom: '3rem',
    }),
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  gradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 100%)',
    pointerEvents: 'none',
  },
  content: {
    height: '100%',
    width: '100%',
    maxWidth: '94rem',
    zIndex: 1,
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    ...tablet({
      alignItems: 'flex-start',
    }),
  },
  cta: {
    width: 'max-content',
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    color: colors.pureWhite,
    fontSize: '2rem',
    lineHeight: '120%',
    textAlign: 'center',
    textShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)',
    ...tablet({
      fontSize: '3.2rem',
      maxWidth: '54rem',
      textAlign: 'left',
    }),
  },
  right: {
    position: 'absolute',
    bottom: '-11.6rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background: colors.honeydew,
    borderRadius: '2rem',
    right: '1rem',
    left: '1rem',
    ...tablet({
      width: '30rem',
      right: '10rem',
      left: 'initial',
    }),
    ...fullDesktop({
      right: '25rem',
    }),
  },
  infoRow: {
    borderRadius: '2rem 2rem 0 0',
    padding: '1.5rem 2rem',
    backgroundColor: colors.darkBlue,
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  dateText: {
    textAlign: 'center',
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.2rem',
    color: colors.pureWhite,
  },
  timeText: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.4rem',
    color: colors.pureWhite,
  },
  locationRow: {
    padding: '2rem',
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  type: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.semiBold,
    fontSize: '1.8rem',
    color: colors.darkBlue,
    textTransform: 'uppercase',
  },
  location: {
    display: 'flex',
    gap: '1rem',
  },
  place: {
    fontFamily: fonts.FiraSansMedium.fontFamily,
    fontWeight: fontWeights.medium,
    fontSize: '1.6rem',
    color: colors.nightRider,
  },
}
