/* eslint-disable max-lines */
import { colors, fonts, svgGlobal, tablet } from '../../theme'


export default {
  container: {
    borderRadius: '2rem',
    backgroundColor: colors.pureWhite,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  containerBlue: {
    backgroundColor: colors.aliceBlue,
  },
  header: {
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: '2rem 2rem 1.5rem',
    ...tablet({
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    }),
  },
  author: {
    display: 'flex',
    flexDirection: 'row',
  },
  logo: {
    borderRadius: '0.5rem',
    width: '5rem',
    height: '5rem',
    overflow: 'hidden',
  },
  user: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
    marginTop: '0.6rem',
  },
  name: {
    ...fonts.FiraSansBold,
    fontSize: '1.6rem',
    lineHeight: '1.8rem',
    color: colors.nightRider,
  },
  company: {
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: colors.grey,
    marginTop: '0.4rem',
  },
  companySingle: {
    ...fonts.FiraSansBold,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: colors.grey,
    marginTop: '1rem',
  },
  infos: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
    ...tablet({
      flexDirection: 'row',
      alignItems: 'flex-end',
      textAlign: 'right',
      marginBottom: 0,
    }),
  },
  infosDetails: {
    extend: 'infos',
    width: '100%',
    ...tablet({
      flexDirection: 'column',
    }),
  },
  type: {
    ...fonts.FiraSansBold,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: colors.darkBlue,
    textTransform: 'uppercase',
  },
  boxOgcn: {
    marginRight: '2rem',
    border: '2px solid black',
    borderRadius: '50%',
  },
  iconOgcn: {
    display: 'flex',
    justifyContent: 'center',
    width: '3.5rem',
    height: '3.5rem',
    '& svg': {
      display: 'flex',
      alignSelf: 'center',
      width: '2.5rem',
      height: '2.5rem',
    },
  },
  publishDate: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    color: colors.grey,
    marginTop: '0.4rem',
    textAlign: 'right',
  },
  dateLabel: {
    display: 'none',
    ...tablet({
      display: 'inline',
    }),
  },
  shortDateLabel: {
    display: 'inline',
    ...tablet({
      display: 'none',
    }),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    ...tablet({
      flexDirection: 'row',
    }),
  },
  image: {
    // height: '18rem',
    ...tablet({
      width: '36rem',
    }),
  },
  body: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    borderTop: `.1rem solid ${colors.gainsboro}`,
    padding: '1rem 0',
    margin: '0 2rem',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '1.8rem',
    lineHeight: '2.6rem',
    color: colors.nightRider,
    margin: '0',
  },
  titleBig: {
    ...fonts.FiraSansRegular,
    fontSize: '2.4rem',
    lineHeight: '2.8rem',
    color: colors.darkBlue,
    margin: '1rem 0 2rem',
  },
  description: {
    ...fonts.FiraSansRegular,
    fontSize: '1.5rem',
    lineHeight: '2.0rem',
    color: colors.nightRider,
    marginTop: '1.5rem',
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '3.5rem',
    marginBottom: '1rem',
    padding: '.4rem 1rem',
    background: colors.aliceBlue,
    borderRadius: '.4rem',
    alignSelf: 'flex-start',
  },
  linkWhite: {
    background: colors.pureWhite,
  },
  linkText: {
    ...fonts.FiraSansRegular,
    color: colors.darkBlue,
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
  },
  linkIcon: {
    width: '1.5rem',
    height: '1.5rem',
    marginLeft: '1rem',
  },
  footer: {
    borderTop: `.1rem solid ${colors.gainsboro}`,
    padding: '2.5rem 2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    ...tablet({
      justifyContent: 'flex-end',
    }),
  },
  contact: {
    //
  },
  bottom: {
    display: 'flex',
    padding: '0rem 2rem 2rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    ...tablet({
    }),
  },
  buttons: {
    display: 'flex',
    margin: '.5rem -.5rem 0',
  },
  button: {
    margin: '.5rem',
    whiteSpace: 'nowrap',
    ...svgGlobal(colors.darkBlue),
    '&:hover': {
      ...svgGlobal(colors.pureWhite),
    },
  },
}
