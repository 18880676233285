/* eslint-disable max-lines */
import cx from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { PubNubProvider } from 'pubnub-react'

import Offer from '../../data/models/Offer'
import withMemo from '../../decorators/WithMemo'
import { offerType } from '../../graphql/enums/offerType'
import { selectors as OfferSelectors } from '../../pages/Offer/redux'
import routes from '../../routes'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import { bannerTypes } from '../Banner/types'
import CategoryTag from '../CategoryTag'
import FileLibrary from '../FileLibrary'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import { Banner, MarkdownText } from '../index'
import ModalWithHeader from '../ModalWithHeader'
import OfferApplyForm from '../OfferApplyForm'
import OfferSimilarOffers from '../OfferSimilarOffers'
import RegisterModal from '../RegisterModal'
import UserMessaging from '../UserMessaging'
import { app } from '../../config'

import styles from './styles'


const useStyles = createUseStyles(styles)

const OfferDetails = (props) => {
  const classes = useStyles(props)
  const {
    className,
    offer,
    isConnected,
    isPremium,
    appliedAt,
    isOwner,
    applyFormProps,
    contactLabel,
    openChat,
    chat,
  } = props

  const { t } = useTranslation()

  const isPublic = useMemo(() => offer?.type === offerType.OFFER_TYPE_CALL_FOR_TENDER, [offer])
  const deadlineDate = useMemo(() => {
    const d = moment(offer?.deadline, 'YYYY-MM-DD')

    return d.isValid() && d.toDate()
  }, [offer])

  const publicationDate = useMemo(() => {
    const d = moment(offer?.date, 'YYYY-MM-DD')

    return d.isValid() && d.toDate()
  }, [offer])

  const appliedAtDate = useMemo(() => {
    const d = moment(appliedAt, 'YYYY-MM-DD')

    return d.isValid() && d.toDate()
  }, [appliedAt])

  const [modalOpen, setModalOpen] = useState(false)
  const handleOpenModal = useCallback(() => setModalOpen(true), [])
  const handleCloseModal = useCallback(() => setModalOpen(false), [])
  const applyToOffer = useSelector(OfferSelectors.applyToOffer)

  useEffect(() => {
    if (applyToOffer.success) {
      setModalOpen(false)
    }
  }, [applyToOffer.success])

  // CHAT

  const [isChatOpen, setIsChatOpen] = useState(false)

  const chatRef = useRef()

  const handleOpenChat = () => {
    setIsChatOpen(true)
    openChat()
  }

  const renderAction = useCallback(() => {
    if (!isConnected) {
      return (
        <ActionButton
          tag="h3"
          className={classes.apply}
          onClick={handleOpenModal}
          label={t('offerDetails_apply')}
          icon={iconsKeys.SmallArrowRight}
          iconRight
          colorIcon={colors.pureWhite}
          colorIconHover={colors.darkBlue}
        />
      )
    }

    if (!isPremium) {
      return (
        <ActionButton
          className={classes.apply}
          label={isPublic ? t('offerDetails_link') : t('offerDetails_apply')}
          icon={iconsKeys.SmallArrowRight}
          iconRight
          colorIcon={colors.pureWhite}
          colorIconHover={colors.darkBlue}
          target={isPublic ? '_blank' : null}
          route={isPublic ? offer?.link : app.REGISTER_URL}
        />
      )
    }

    if (Object.prototype.hasOwnProperty.call(offer, 'isActive') && !offer.isActive) {
      return null
    }

    return isPublic ? (
      <ActionButton
        className={classes.apply}
        label={t('offerDetails_link')}
        icon={iconsKeys.SmallArrowRight}
        iconRight
        colorIcon={colors.pureWhite}
        colorIconHover={colors.darkBlue}
        link={offer?.link}
        target="_blank"
      />
    ) : (
      isOwner ? (
        <ActionButton
          className={classes.owner}
          label={t('offerDetails_owner')}
          route={routes.userOffer.name}
          routeParams={{ id: offer?.reference }}
          icon={iconsKeys.SmallArrowRight}
          iconRight
          colorIcon={colors.pureWhite}
          colorIconHover={colors.darkBlue}
        />
      ) : (
        appliedAt ? (
          <ActionButton
            className={classes.applied}
            label={t('offerDetails_applied', { date: appliedAtDate })}
            route={routes.userCandidacy.name}
            routeParams={{ id: offer?.reference }}
            icon={iconsKeys.SmallArrowRight}
            iconRight
            colorIcon={colors.pureWhite}
            colorIconHover={colors.darkBlue}
          />
        ) : (
          <ActionButton
            className={classes.apply}
            onClick={handleOpenModal}
            label={t('offerDetails_apply')}
            icon={iconsKeys.SmallArrowRight}
            iconRight
            colorIcon={colors.pureWhite}
            colorIconHover={colors.darkBlue}
          />
        )
      )
    )
  }, [
    appliedAt,
    appliedAtDate,
    classes.applied,
    classes.apply,
    classes.owner,
    handleOpenModal,
    isConnected,
    isOwner,
    isPremium,
    isPublic,
    offer,
    t,
  ])

  return (
    <div className={cx(classes.container, className)}>
      <Banner
        image={t('offerDetails_banner_image')}
        className={classes.banner}
        title={offer?.title}
        format={bannerTypes.HEADER_TYPE_SM}
        tags={(
          <>
            {offer?.categories?.map((category, key) => (
              <CategoryTag
                className={classes.category}
                key={key}
                {...category}
              />
            ))}
          </>
        )}
      />
      <div className={classes.wrapper}>
        <div className={classes.main}>
          <MarkdownText
            tag="h2"
            className={classes.introduction}
            text={offer?.introduction}
          />
          <MarkdownText
            className={classes.description}
            text={offer?.description}
          />
          {isConnected && Boolean(offer?.files?.files?.length) && (
            <>
              <h1 className={classes.filesTitle}>{t('offerDetails_files')}</h1>
              <FileLibrary
                {...offer?.files}
                ctaLabel={t('offerDetails_see_file')}
                className={classes.files}
              />
            </>
          )}
          {renderAction()}
        </div>
        <div className={classes.side}>
          <div className={classes.card}>
            <div className={classes.cardTop}>
              {isPublic ? (
                <>
                  <div className={classes.cardTitle}>{offer?.companyName ?? offer?.user?.fullName}</div>
                  <div className={classes.cardSubtitle}>{t('offerDetails_card_subtitle')}</div>
                </>
              ) : (
                <>
                  <div className={classes.cardTitle}>{t('offerDetails_card_title')}</div>
                  {/* <div className={classes.cardSubtitle}>{t('offerDetails_card_market', { ref: '123456' })}</div> */}
                  <div className={classes.cardSubtitle}>{t('offerDetails_card_subtitle')}</div>
                </>
              )}
            </div>
            <div className={classes.cardContent}>
              {!!offer?.budget && <div className={classes.budget}>{offer?.budget && t('offerDetails_budget', { budget: offer.budget })}</div>}
              {!!deadlineDate && (
                isPublic
                  ? (
                    <div className={classes.deadlinePublic}>{t('offerDetails_deadline_public', { date: deadlineDate })}</div>)
                  : (
                    <MarkdownText
                      className={classes.deadline}
                      text={t('offerDetails_deadline', { date: deadlineDate })}
                      inline
                    />
                  )
              )}
              {isPublic && !!publicationDate && (
                <div className={classes.publication}>
                  <div className={classes.publicationLabel}>{t('offerDetails_publication_label')}</div>
                  <div className={classes.publicationDate}>{t('offerDetails_publication_date', { date: publicationDate })}</div>
                </div>
              )}
              <div className={classes.city}>
                <Icon
                  icon={iconsKeys.Pinpoint}
                  className={classes.cityIcon}
                />
                <div className={classes.cityLabel}>{offer?.city}</div>
              </div>
            </div>
          </div>
          {isPremium && (
            <div className={classes.contactAuthor}>
              <ActionButton
                isOutlined
                iconRight
                label={contactLabel}
                color="primary"
                icon={iconsKeys.Chat}
                colorIcon={colors.darkOrange}
                onClick={() => handleOpenChat()}
              />
            </div>
          )}
          <div className={classes.keywords}>
            {offer?.keywords?.map((keyword, key) => (
              <div
                className={classes.keyword}
                key={key}
              >
                {keyword}
              </div>
            ))}
          </div>
        </div>
      </div>
      {isChatOpen && (
        <div
          className={classes.chat}
          ref={chatRef}
        >
          {chat && chat.pubnub && (
            <PubNubProvider client={chat.pubnub}>
              <UserMessaging
                channels={[chat.currentChannel]}
                currentChannel={chat.currentChannel}
                onChannelSwitched={chat.onChannelSwitched}
                messagePlaceholder={t('messaging_message_placeholder')}
                users={chat.membersOfChannel}
                sendButtonLabel={t('messaging_send_button_label')}
                currentChannelMeta={chat.currentChannelMeta}
                channelsWithMetadata={isOwner ? chat.userChannelsWithMeta : null}
                noMessage={t('messaging_noMessage')}
                onSend={chat.onSendMessage}
              />
            </PubNubProvider>
          )}
        </div>
      )}
      {!!offer?.similar && offer?.similar?.length > 0 && (
        <OfferSimilarOffers
          cards={offer?.similar}
          title={t('offerDetails_similar_title')}
        />
      )}
      {isConnected ? (
        <ModalWithHeader
          title={t('offerDetails_apply_title')}
          className={classes.modal}
          isOpen={modalOpen}
          onClose={handleCloseModal}
        >
          <div className={classes.modalCategories}>
            {
              offer?.categories?.map((category, key) => (
                <CategoryTag
                  className={classes.modalCategory}
                  key={key}
                  {...category}
                />
              ))
            }
          </div>
          <MarkdownText
            className={classes.modalTitle}
            text={offer?.title}
            inline
          />
          <OfferApplyForm {...applyFormProps} />
        </ModalWithHeader>
      ) : (
        <RegisterModal
          modalTitle={t('offerDetails_apply_title_unauthenticated')}
          modalText={t('offerDetails_apply_text_unauthenticated')}
          modalCta={t('offerDetails_apply_cta_unauthenticated')}
          isOpen={modalOpen}
          onClose={handleCloseModal}
          premiumStep
        />
      )}
    </div>
  )
}

OfferDetails.propTypes = {
  className: PropTypes.string,
  offer: PropTypes.shape(Offer.propTypes),
  isConnected: PropTypes.bool,
  isPremium: PropTypes.bool,
  isOwner: PropTypes.bool,
  appliedAt: PropTypes.string,
  applyFormProps: PropTypes.shape({
    //
  }),
  contactLabel: PropTypes.string,
  openChat: PropTypes.func,
  chat: PropTypes.objectOf(PropTypes.shape({
    pubnub: PropTypes.object,
    currentChannel: PropTypes.arrayOf(PropTypes.string),
    membersOfChannel: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      eTag: PropTypes.string,
      email: PropTypes.string,
      externalId: PropTypes.string,
      profileUrl: PropTypes.string,
      updated: PropTypes.string,
      custom: PropTypes.objectOf(PropTypes.shape({
        company: PropTypes.string,
      })),
    })),
    currentChannelMeta: PropTypes.objectOf(PropTypes.shape({
      description: PropTypes.string,
      eTag: PropTypes.string,
      email: PropTypes.string,
      externalId: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      profileUrl: PropTypes.string,
      updated: PropTypes.string,
      custom: PropTypes.objectOf(PropTypes.shape({
        company: PropTypes.object,
      })),
    })),
    onChannelSwitched: PropTypes.func,
    onSendMessage: PropTypes.func,
  })),
}

OfferDetails.defaultProps = {
  className: '',
  offer: null,
  isConnected: false,
  isPremium: false,
  isOwner: false,
  appliedAt: null,
  applyFormProps: null,
  contactLabel: '',
  openChat: null,
  chat: null,
}

export default withMemo()(OfferDetails)
