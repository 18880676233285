export default {
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: 9000,
    top: 0,
    left: 0,
  },
  background: {
    backgroundColor: '#000',
    opacity: 0.7,
    position: 'fixed',
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  wrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflowY: 'auto',
    margin: 'auto',
    width: '100%',
    height: '100%',
  },
  content: {
    position: 'relative',
    padding: '2rem',
    zIndex: 2,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
