import { colors, fonts, placeholder } from '../../../../theme'


export default {
  container: {},
  wrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
  },
  label: {
    marginBottom: '1rem',
  },
  inputContainer: {
    border: [[1, 'solid', 'transparent']],
    borderColor: ({ focused, error }) => (
      (focused && !error && colors.primary)
      || (error && colors.error)
      || colors.gainsboro
    ),
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '0',
  },
  input: {
    background: 'transparent',
    flex: 1,
    border: 0,
    height: '100%',
    padding: [['1.1rem', ' 1.5rem']],
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.4rem',
    color: ({ disabled }) => (
      (disabled && colors.silver)
      || colors.nightRider
    ),
    ...placeholder(({ disabled }) => (
      (disabled && colors.silver)
      || colors.grey
    )),
  },
  ornament: {
    padding: [['0', '1.5rem']],
  },
  startOrnament: {
    paddingLeft: 0,
  },
  endOrnament: {
    paddingRight: 0,
    '& *': {
      display: 'block',
    },
  },
  errorText: {},
  helpText: {},
}
