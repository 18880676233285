import { colors, fonts, svgGlobal, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    padding: '2rem',
    background: colors.pureWhite,
    borderRadius: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    transition: 'all .2s ease',
    '&:hover': {
      boxShadow: '0px 5px 30px rgba(84, 84, 84, 0.2)',
      transform: 'translateY(-2px)',
    },
  },
  wrapper: {
    textDecoration: 'none',
    width: '100%',
  },
  head: {
    width: '100%',
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '2rem',
    borderBottom: `1px solid ${colors.gainsboro}`,
    position: 'relative',
  },
  boxOgcn: {
    position: 'absolute',
    right: '1rem',
    border: '2px solid black',
    borderRadius: '50%',
  },
  iconOgcn: {
    display: 'flex',
    justifyContent: 'center',
    width: '3.5rem',
    height: '3.5rem',
    '& svg': {
      display: 'flex',
      alignSelf: 'center',
      width: '2.5rem',
      height: '2.5rem',
    },
  },
  type: {
    ...fonts.FiraSansBold,
    color: colors.darkBlue,
    marginBottom: '0.75rem',
  },
  top: {
    display: 'flex',
    flexWrap: 'wrap',
    ...tablet({
      justifyContent: 'flex-end',
      width: '100%',
    }),
  },
  tag: {
    borderRadius: '0.5rem',
    background: colors.aliceBlue,
    padding: '.75rem 1rem',
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 1rem 0',
  },
  tagIcon: {
    width: '2.4rem',
    height: '2.4rem',
    margin: '-0.35rem 5 -0.35rem 0',
  },
  tagName: {
    ...fonts.FiraSansBold,
    color: colors.nightRider,
    fontSize: '1.4rem',
    lineHeight: '120%',
    marginLeft: '1rem',
  },
  categories: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
  },
  title: {
    width: '100%',
    ...fonts.FiraSansBold,
    fontSize: '1.8rem',
    lineHeight: '120%',
    color: colors.nightRider,
    textAlign: 'left',
    marginTop: '0',
    marginBottom: '1rem',
  },
  introduction: {
    width: '100%',
    ...fonts.FiraSansRegular,
    fontSize: '1.5rem',
    lineHeight: '120%',
    color: colors.nightRider,
    textAlign: 'left',
    marginBottom: '2rem',
  },
  infos: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    ...tablet({
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    }),
  },
  publicationDate: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    lineHeight: '120%',
    color: colors.grey,
    paddingTop: '2rem',
    ...tablet({
      padding: 0,
    }),
  },
  city: {
    display: 'flex',
    alignItems: 'center',
  },
  cityIcon: {},
  cityLabel: {
    marginLeft: '1rem',
    ...fonts.FiraSansRegular,
    fontWeight: fontWeights.regular,
    fontSize: '1.4rem',
    lineHeight: '120%',
    color: colors.darkBlue,
  },
  footer: {
    display: 'flex',
    paddingTop: '2rem',
    width: '100%',
    flexDirection: 'column',
    ...tablet({
      flexDirection: 'row',
    }),
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...tablet({
      flex: 1,
    }),
  },
  deadline: {
    display: 'flex',
    alignItems: 'center',
  },
  deadlineIcon: {
    ...svgGlobal(colors.gainsboro),
  },
  deadlineLabel: {
    ...fonts.FiraSansBold,
    fontSize: '1.5rem',
    lineHeight: '120%',
    color: colors.darkBlue,
    marginLeft: '1rem',
  },
  budget: {
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '120%',
    color: colors.nightRider,
  },
  more: {
    display: 'flex',
    alignItems: 'center',
    ...fonts.FiraSansBold,
    fontSize: '1.4rem',
    lineHeight: '120%',
    color: colors.darkBlue,
    paddingTop: '2rem',
    ...tablet({
      justifyContent: 'flex-end',
      width: '20rem',
      padding: 0,
    }),
  },
  moreIcon: {
    marginLeft: '0.4rem',
    ...svgGlobal(colors.tangerine),
  },
}
