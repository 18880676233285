import { colors, desktop, fonts, svgGlobal, transition } from '../../theme'


export default {

  // LineBar states

  lineBarClosed: {
    content: '\'\'',
    width: 0,
    height: '.2rem',
    backgroundColor: colors.tangerine,
    marginTop: '.6rem',
    ...transition('width'),
    borderRadius: '10rem',
  },
  lineBarOpened: {
    content: '\'\'',
    width: '1.5rem',
    height: '.2rem',
    backgroundColor: colors.tangerine,
    marginTop: '.6rem',
    borderRadius: '10rem',
  },

  lineBarMobileClosed: {
    position: 'absolute',
    left: '2.2rem',
    content: '\'\'',
    height: 0,
    width: '.2rem',
    backgroundColor: colors.tangerine,
    ...transition('height'),
    borderRadius: '10rem',
  },
  lineBarMobileOpened: {
    position: 'absolute',
    left: '2.2rem',
    content: '\'\'',
    height: '2.4rem',
    width: '.2rem',
    backgroundColor: colors.tangerine,
    borderRadius: '10rem',
  },

  lineBarDarkClosed: {
    content: '\'\'',
    width: 0,
    height: '.3rem',
    backgroundColor: colors.pureWhite,
    marginTop: '.3rem',
    ...transition('width'),
    borderRadius: 0,
  },
  lineBarDarkOpened: {
    content: '\'\'',
    width: 'calc(100% + .6rem)',
    height: '.3rem',
    backgroundColor: colors.pureWhite,
    marginTop: '.3rem',
    borderRadius: 0,
  },

  // Classes

  wrapper: {
    position: 'relative',
  },
  container: {
    padding: '0 2rem',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1rem',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: colors.grey,
    textAlign: 'center',
    ...transition('color'),
    '&:after': {
      extend: 'lineBarClosed',
    },
    '&:hover': {
      color: colors.darkBlue,
      '&:after': {
        extend: 'lineBarOpened',
      },
    },
  },
  label: {
    display: 'flex',
    position: 'relative',
  },
  chevron: {
    position: 'absolute',
    ...svgGlobal(colors.tangerine),
    right: '-1.5rem',
    top: '0.4rem',
    '& svg': {
      transform: 'rotate(90deg)',
      transition: 'transform .2s ease-in-out',
    },
  },
  chevronOpen: {
    '& svg': {
      transform: 'rotate(-90deg)',
    },
  },
  selected: {
    color: colors.darkBlue,
    '&:after': {
      extend: 'lineBarOpened',
    },
    '&$dark': {
      opacity: 1,
      '&:after': {
        extend: 'lineBarDarkOpened',
      },
    },
    '&$isMenuMobile': {
      color: colors.darkBlue,
      paddingLeft: '3.5rem',
      '&:before': {
        extend: 'lineBarMobileOpened',
      },
    },
  },
  dark: {
    padding: '0 1rem',
    textTransform: 'uppercase',
    ...fonts.FiraSansBold,
    fontSize: '1rem',
    lineHeight: '1rem',
    color: colors.pureWhite,
    opacity: 0.7,
    ...transition('opacity'),
    '&:after': {
      extend: 'lineBarDarkClosed',
    },
    '&:hover': {
      color: colors.pureWhite,
      opacity: 1,
      '&:after': {
        extend: 'lineBarDarkOpened',
      },
    },
  },
  isMenuMobile: {
    position: 'relative',
    padding: '.8rem 2rem',
    color: colors.nightRider,
    fontSize: '1.8rem',
    lineHeight: '2.6rem',
    textAlign: 'left',
    flexDirection: 'row',
    width: '100%',
    ...transition('padding-left'),
    '&:after': {
      display: 'none',
    },
    '&:before': {
      extend: 'lineBarMobileClosed',
    },
    '&:hover': {
      color: colors.darkBlue,
      paddingLeft: '3.5rem',
      '&:before': {
        extend: 'lineBarMobileOpened',
      },
    },
  },
  entries: {
    display: 'none',
  },
  isSubMenuMobile: {
    display: 'flex',
    flexDirection: 'column',
  },
  isOpen: {
    '& $labelIcon': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
    '& $entries': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      ...desktop({
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '4rem',
        padding: '1rem',
        left: '1rem',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.pureWhite,
        borderRadius: '0.5rem',
        '& > a': {
          marginBottom: '1rem',
        },
      }),
    },
  },
}
