import { colors, desktop, sizes, tablet } from '../../theme'


export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.darkBlue,
    paddingBottom: '3rem',
  },
  colWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: sizes.desktop,
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    maxWidth: sizes.desktop,
  },
  legals: {
    padding: '0 3rem',
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    padding: '3rem',
  },
  right: {
    display: 'none',
    ...tablet({
      width: '100%',
      padding: '3rem',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '2.5rem',
    }),
    ...desktop({
      paddingLeft: '3rem',
    }),
  },
  logo: {
    width: '16rem',
    height: '4rem',
  },
  top: {
    flexDirection: 'column',
    width: '100%',
    height: '10rem',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    display: 'none',
    ...tablet({
      display: 'flex',
      justifyContent: 'flex-start',
    }),
  },
  topRight: {
    alignItems: 'flex-end',
  },
  cciLogo: {
    '& > svg': {
      width: '13rem',
      height: '5rem',
    },
  },
  bottomLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  rss: {},
  contact: {
    marginBottom: '1.5rem',
    color: colors.pureWhite,
  },
  social: {},
  menu: {
    paddingRight: '5rem',
    ...desktop({
      paddingRight: '6rem',
    }),
  },
}
