/* eslint-disable max-lines */
import { fonts, colors, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    ...tablet({
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    }),
  },
  headerData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    ...tablet({
      alignItems: 'flex-start',
      marginBottom: '4.5rem',
    }),
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '1rem',
    ...tablet({
      justifyContent: 'flex-start',
    }),
  },
  type: {
    marginRight: '1rem',
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2rem',
    lineHeight: '2.6rem',
    textAlign: 'center',
    color: colors.nightRider,
    marginBottom: '2rem',
    ...tablet({
      maxWidth: '46rem',
      textAlign: 'left',
      fontSize: '2.4rem',
      lineHeight: '2.6rem',
      marginBottom: '.7rem',
    }),
  },
  companyName: {
    display: 'none',
    ...tablet({
      display: 'block',
      fontFamily: fonts.FiraSansRegular.fontFamily,
      fontWeight: fontWeights.regular,
      fontSize: '1.1rem',
      lineHeight: '1.3rem',
      textAlign: 'left',
      textTransform: 'uppercase',
      color: colors.nightRider,
    }),
  },
  sharing: {
    marginBottom: '2rem',
    ...tablet({
      width: '19rem',
    }),
  },
  requirements: {
    background: colors.honeydew,
    borderRadius: '1rem',
    padding: '1.2rem 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  requirement: {
    margin: '0 1rem',
    ...tablet({
      minWidth: '21rem',
    }),
  },
  requirementLabel: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.1rem',
    lineHeight: '1.2rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.darkBlue,
    ...tablet({
      fontSize: '1.2rem',
      lineHeight: '1.4rem',
      textAlign: 'left',
    }),
  },
  requirementValue: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.1rem',
    lineHeight: '1.2rem',
    textAlign: 'center',
    color: colors.nightRider,
    marginTop: '.8rem',
    ...tablet({
      textAlign: 'left',
    }),
  },
  skills: {
    marginTop: '3rem',
  },
  skillsTitle: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.8rem',
    lineHeight: '2.2rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.nightRider,
    marginBottom: '1.3rem',
    ...tablet({
      textAlign: 'left',
      fontSize: '2rem',
      lineHeight: '2.4rem',
    }),
  },
  skillsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  skill: {
    marginRight: '1rem',
    marginBottom: '1rem',
  },
  description: {
    marginTop: '6rem',
  },
  descriptionTitle: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.8rem',
    lineHeight: '2.2rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.nightRider,
    marginBottom: '1.3rem',
    ...tablet({
      textAlign: 'left',
      fontSize: '2rem',
      lineHeight: '2.4rem',
    }),
  },
  descriptionWrapper: {
    padding: 0,
  },
  descriptionContent: {
    '& h2': {
      fontSize: '2rem',
      lineHeight: '2.4rem',
    },
    '& p': {
      fontSize: '1.5rem',
      lineHeight: '2.2rem',
    },
  },
  pertinence: {
    background: colors.aliceBlue,
    borderRadius: '1rem',
    margin: 0,
    padding: '1.2rem',
    ...tablet({
      minWidth: '21rem',
      width: '33%',
    }),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',

  },
  pertinenceLabel: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontSize: '1.1rem',
    lineHeight: '1.2rem',
    color: colors.nightRider,
    width: '50%',
    ...tablet({
      fontSize: '1.2rem',
      lineHeight: '1.4rem',
    }),
  },
  pertinenceValue: {
    fontFamily: fonts.FiraSansExtraLight.fontFamily,
    fontWeight: fonts.FiraSansExtraLight.fontWeight,
    fontSize: '3rem',
    color: colors.darkBlue,
  },
}
