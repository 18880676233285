import Color from 'color'

import { fonts, colors, svgGlobal, transition, tablet, desktop, placeholder, sizes } from '../../theme'


export default {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 1rem',
    position: 'relative',
    ...tablet({
      padding: '0 3.4rem',
    }),
  },
  wrapper: {
    borderRadius: '4rem',
    backgroundColor: colors.pureWhite,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '1220px',
    padding: '1.5rem',
    ...tablet({
      padding: '2.5rem',
      borderRadius: '8rem',
    }),
    ...desktop({
      padding: '4rem',
    }),
  },
  title: {
    textTransform: 'uppercase',
    color: colors.darkBlue,
    ...fonts.FiraSansBold,
    fontSize: '1.4rem',
    textAlign: 'center',
    paddingBottom: '1rem',
    ...tablet({
      fontSize: '2rem',
    }),
  },
  stats: {
    margin: '0 auto',
    borderTop: `1px solid ${colors.gainsboro}`,
    width: '100%',
    maxWidth: `calc(${sizes.desktop}px - 4rem)`,
    display: 'none',
    ...tablet({
      display: 'flex',
    }),
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1rem',
    marginBottom: '2rem',
  },
  stat: {
    fontSize: '1.2rem',
    display: 'flex',
    flexDirection: 'row',
  },
  statValue: {
    color: colors.nightRider,
    ...fonts.FiraSansBold,
    paddingRight: '0.5rem',
  },
  statLabel: {
    color: colors.grey,
    ...fonts.FiraSansRegular,
    textTransform: 'uppercase',
  },
  form: {
    border: `1px solid ${colors.darkBlue}`,
    borderRadius: '2rem',
    display: 'flex',
    flexDirection: 'column',
    ...tablet({
      flexDirection: 'row',
      borderRadius: '4rem',
    }),
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.8rem',
    ...tablet({
      padding: '1.8rem',
    }),
    ...desktop({
      padding: '2rem',
    }),
  },
  mainSection: {
    flex: 1,
  },
  separator: {
    borderTop: `1px solid ${colors.darkBlue}`,
    ...tablet({
      borderTop: 0,
      borderLeft: `1px solid ${colors.darkBlue}`,
    }),
  },
  icon: {},
  iconAsset: {
    ...svgGlobal(colors.darkBlue),
    width: '2.4rem',
    height: '2.4rem',
  },
  input: {
    textAlign: 'center',
    flex: 1,
    border: 0,
    marginLeft: '1rem',
    height: '100%',
    padding: [['1.1rem', '0rem', '1.1rem']],
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    marginRight: '3rem',
    ...tablet({
      textAlign: 'left',
      marginRight: 0,
    }),
    ...desktop({
      fontSize: '1.4rem',
      lineHeight: '1.4rem',
    }),
    color: colors.pureBlack,
    ...placeholder(colors.nightRider),
  },
  button: {
    color: colors.pureWhite,
    backgroundColor: colors.darkBlue,
    padding: '2rem',
    fontSize: '1.4rem',
    borderBottomLeftRadius: '1.9rem',
    borderBottomRightRadius: '1.9rem',
    ...tablet({
      fontSize: '1.6rem',
      borderBottomLeftRadius: 0,
      borderTopRightRadius: '4rem',
      borderBottomRightRadius: '4rem',
    }),
    ...desktop({
      fontSize: '1.8rem',
    }),
    ...transition('backgroundColor'),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: Color(colors.darkBlue).darken(0.2).toString(),
    },
  },
  buttonLabel: {
    ...fonts.FiraSansRegular,
    fontSize: '1.8rem',
    lineHeight: '2.2rem',
    ...tablet({
      display: 'none',
    }),
    ...desktop({
      display: 'block',
    }),
  },
  buttonIcon: {
    display: 'none',
    ...tablet({
      display: 'block',
      width: '3.2rem',
      height: '1.8rem',
    }),
    ...desktop({
      display: 'none',
    }),
  },
  buttonIconAsset: {
    width: '100%',
  },
}
