import { colors, fonts, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    //
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  file: {
    minHeight: '9.4rem',
    padding: '1rem',
    backgroundColor: colors.honeydew,
    borderRadius: '0.5rem',
    marginBottom: '.7rem',
    display: 'flex',
    flexDirection: 'row',
    ...tablet({
      width: '22rem',
      marginRight: '1rem',
    }),
  },
  fileImage: {
    minWidth: '7rem',
    maxWidth: '7rem',
    height: '10rem',
    marginRight: '1.5rem',
  },
  fileBody: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'center',
  },
  fileName: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    color: colors.nightRider,
    fontSize: '1.6rem',
    lineHeight: '2.0rem',
    marginBottom: '0.8rem',
    wordBreak: 'break-word',
  },
  fileSee: {
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
  },
}
