/* eslint-disable max-lines */
import { colors, fonts, svgGlobal, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    maxWidth: '94rem',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: '4rem 2rem',
    ...tablet({
      flexDirection: 'row',
    }),
  },
  main: {
    display: 'flex',
    flexDirection: ' column',
    alignItems: 'flex-start',
    width: '100%',
    ...tablet({
      flex: 1,
      paddingRight: '5rem',
    }),
  },
  introduction: {
    ...fonts.FiraSansRegular,
    fontSize: '1.8rem',
    lineHeight: '120%',
    color: colors.darkBlue,
    marginTop: '0',
    marginBottom: '3rem',
    '& p': {
      color: colors.darkBlue,
      fontSize: '1.8rem',
    },
  },
  description: {
    ...fonts.FiraSansRegular,
    fontSize: '1.8rem',
    lineHeight: '120%',
    marginBottom: '3rem',
    '& p': {
      fontSize: '1.8rem',
    },
  },
  filesTitle: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    textAlign: 'left',
    color: colors.nightRider,
    marginBottom: '1rem',
    textTransform: 'uppercase',
  },
  files: {
    marginBottom: '8rem',
  },
  apply: {
    marginTop: '0',
    marginBottom: '3rem',
  },
  side: {
    width: '100%',
    ...tablet({
      marginTop: '-9rem',
      width: '30rem',
      position: 'relative',
    }),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '2rem',
    boxShadow: '0px 5px 30px rgba(84, 84, 84, 0.2)',
    textAlign: 'center',
    ...tablet({
      width: '30rem',
    }),
  },
  cardTop: {
    backgroundColor: colors.darkBlue,
    width: '100%',
    padding: '2rem',
  },
  cardContent: {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  cardTitle: {
    ...fonts.FiraSansBold,
    fontSize: '2.2rem',
    lineHeight: '120%',
    color: colors.pureWhite,
  },
  cardSubtitle: {
    marginTop: '1rem',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '120%',
    color: colors.pureWhite,
  },
  budget: {
    ...fonts.FiraSansBold,
    fontWeight: fontWeights.regular,
    color: colors.darkBlue,
    fontSize: '2.6rem',
    lineHeight: '120%',
    marginBottom: '1rem',
  },
  deadline: {
    ...fonts.FiraSansBold,
    color: colors.nightRider,
    fontSize: '1.8rem',
    lineHeight: '120%',
    marginBottom: '1rem',
    '& strong': {
      color: colors.darkBlue,
    },
  },
  deadlinePublic: {
    ...fonts.FiraSansBold,
    fontSize: '3.6rem',
    lineHeight: '120%',
    color: colors.darkBlue,
    marginBottom: '1rem',
  },
  publication: {
    ...fonts.FiraSansBold,
    fontSize: '1.8rem',
    lineHeight: '120%',
    color: colors.nightRider,
    marginBottom: '1rem',
  },
  publicationLabel: {},
  publicationDate: {},
  city: {
    display: 'flex',
    alignItems: 'center',
  },
  cityIcon: {
    ...svgGlobal(colors.nightRider),
  },
  cityLabel: {
    marginLeft: '1rem',
    ...fonts.FiraSansRegular,
    fontWeight: fontWeights.regular,
    fontSize: '1.4rem',
    lineHeight: '120%',
  },
  contactAuthor: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '3rem',
  },
  keywords: {
    marginTop: '3rem',
    display: 'flex',
    flexWrap: 'wrap',
  },
  keyword: {
    padding: '5px 10px',
    backgroundColor: colors.nightRider,
    color: colors.pureWhite,
    margin: '0.5rem',
    borderRadius: '0.4rem',
    ...fonts.FiraSansBold,
    fontSize: '1.2rem',
    lineHeight: '120%',
  },
  chat: {
    width: '100%',
    marginBottom: '7rem',
  },
  modal: {
    width: '100%',
    background: colors.pureWhite,
    borderRadius: '2rem',
    padding: '2.5rem 3rem',
    margin: '0 auto',
    maxWidth: '60rem',
  },
  modalCategories: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    marginBottom: '1rem',
  },
  modalTitle: {
    width: '100%',
    ...fonts.FiraSansBold,
    fontSize: '1.8rem',
    lineHeight: '120%',
    color: colors.nightRider,
    textAlign: 'left',
    marginBottom: '1rem',
  },
  registerContainer: {
    textAlign: 'center',
    padding: '1rem 0',
  },
  modalText: {
    padding: '1rem 0',
    width: '100%',
    ...fonts.FiraSansRegular,
  },
}
