import { colors, fonts } from '../../../theme'


export default {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    ...fonts.FiraSansRegular,
    fontSize: '1.6rem',
    position: 'relative',
  },
  avatar: {
    display: 'block',
    width: '5rem',
    margin: '2px 2.1rem 0 0',
    height: '3.8rem',
  },
  avatarImage: {
    width: '5rem',
    borderRadius: '0.5rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  name: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: colors.darkBlue,
  },
  time: {
    fontSize: '1.1rem',
    color: '#9b9b9b',
    marginLeft: '1rem',
  },
  message: {
    display: 'block',
    marginBottom: '1rem',
    padding: '1.6rem',
    boxSizing: 'border-box',
    fontSize: '1.3rem',
    color: colors.nightRider,
    backgroundAttachment: 'scroll',
    backgroundClip: 'border-box',
    backgroundColor: colors.white,
    backgroundOrigin: 'padding-box',
    boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '1rem',
  },
  file: {
    cursor: 'pointer',
  },
  fileImage: {
    width: '5rem',
    height: '5rem',
    borderRadius: '0.5rem',
  },
  fileDoc: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '20rem',
  },
  fileIcon: {
    marginRight: '0.5rem',
    '& svg': {
      width: '5rem',
      height: '5rem',
    },
  },
  fileName: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    color: colors.nightRider,
    marginTop: '1rem',
    wordBreak: 'break-word',
    textAlign: 'center',
    marginRight: '1rem',
  },
  downloadIcon: {
    display: 'flex',
    alignSelf: 'center',
  },
}
