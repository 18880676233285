import moment from 'moment'


export const parseDate = (str, format = 'YYYY-MM-DD') => (
  str ? moment(str, format).toDate() : null
)
export const formatDate = (date, format = 'YYYY-MM-DD') => (
  date ? moment(date).format(format) : ''
)
export const dateToUnix = (date) => (
  date ? moment(date).unix() : null
)
export const isValidDate = (d) => (
  d && moment(d).isValid()
)
