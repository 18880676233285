import { colors, desktop, fonts, fullDesktop, tablet, transition } from '../../theme'


export default {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 5,
    boxShadow: '0 1px 8px rgba(20,20,20,.05)',
  },
  content: {
    backgroundColor: colors.pureWhite,
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '6.4rem',
    ...desktop({
      padding: '1.5rem 4rem',
      minHeight: '8rem',
    }),
  },
  logo: {
    flex: 1,
    marginLeft: '2.5rem',
    ...desktop({
      flex: 'none',
      marginLeft: 0,
    }),
  },
  logoAsset: {
    minHeight: '3rem',
    width: '11rem',
    height: '3rem',
    ...tablet({
      width: '15rem',
      height: '4rem',
    }),
  },
  burgerMenuButton: {
    display: 'flex',
    ...desktop({
      display: 'none',
    }),
  },
  userButton: {
    padding: '.8rem',
    ...tablet({
      padding: '1rem 1.5rem',
    }),
    ...desktop({
      marginLeft: '1rem',
    }),
  },
  userLoggedButton: {
    ...desktop({
      marginLeft: '1.5rem',
    }),
  },
  isMobile: {
    display: 'inline-flex',
    ...tablet({
      display: 'none',
    }),
  },
  isDesktop: {
    display: 'none',
    ...tablet({
      display: 'inline-flex',
    }),
  },
  menu: {
    backgroundColor: colors.pureWhite,
    position: 'fixed',
    top: '6.4rem',
    left: '-100%',
    width: '100%',
    height: 'calc(100% - 6.4rem)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'auto',
    ...transition('left'),
    ...desktop({
      position: 'relative',
      top: 'auto',
      left: 'auto',
      flex: 1,
      overflow: 'visible',
    }),
  },
  menuOpened: {
    left: 0,
    // paddingTop: '1rem',
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    ...desktop({
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
    }),
    ...fullDesktop({
      justifyContent: 'center',
    }),
  },
  menuEntry: {
    ...desktop({
      padding: '1rem 0 0',
    }),
    ...fullDesktop({
      padding: '1rem 2rem 0',
    }),
  },
  footerMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: colors.honeydew,
    width: '100%',
    padding: '2.5rem 2rem',
    marginTop: '3rem',
    ...desktop({
      display: 'none',
    }),
  },
  footerSocialLabel: {
    ...fonts.FiraSansBold,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    color: colors.grey,
    textTransform: 'uppercase',
    marginBottom: '1.5rem',
    letterSpacing: '.1rem',
  },
  footerLinks: {
    marginTop: '3.5rem',
    textAlign: 'center',
  },
  footerSeparator: {
    padding: '0.5rem 0',
  },
  footerSeparatorLabel: {
    ...fonts.FiraSansBold,
    fontSize: '1.2rem',
    color: colors.nightRider,
    padding: '0 .6rem',
    textAlign: 'center',
    width: '100%',
  },
  footerLink: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    color: colors.nightRider,
    padding: '0 .4rem',
    '&:not(:last-of-type):after': {
      content: '\' - \'',
      paddingLeft: '.8rem',
    },
  },
}
