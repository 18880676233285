import { colors, fonts } from '../../../../theme'


export default {
  container: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
  },
  label: {},
  select: {},
  helpText: {},
  errorText: {},
}

export const selectStyles = (props) => ({
  control: (provided, state) => ({
    ...provided,
    borderWidth: 1,
    borderRadius: '1rem',
    height: '4rem',
    borderStyle: 'solid',
    borderColor: colors.gainsboro,
    background: colors.pureWhite,
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.4rem',
    padding: '0 1.5rem',
    ...state.isFocused && {
      borderColor: colors.darkBlue,
      boxShadow: 'none',
    },
    ...state.menuIsOpen && {
      borderColor: colors.darkBlue,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      boxShadow: 'none',
    },
    ...!!props.error && {
      borderColor: colors.error,
    },
  }),
  container: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'transparent',
    position: 'relative',
    '& svg': {
      opacity: 0,
      display: 'none',
    },
    '&:after': {
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
      transformOrigin: 'center center',
      content: '""',
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '7px 6px 0 6px',
      borderColor: `${colors.darkBlue} transparent transparent transparent`,
      borderRadius: 3,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: 12,
      height: 7,
      margin: 'auto',
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    margin: 0,
    height: '100%',
    overflow: 'scroll',
    boxSizing: 'content-box',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: colors.nightRider,
    padding: 0,
    margin: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: colors.grey,
    padding: 0,
    margin: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    border: `solid 1px ${colors.darkBlue}`,
    borderTop: 0,
    borderColor: colors.darkBlue,
    overflow: 'hidden',
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    color: colors.nightRider,
    padding: '10px',
    textAlign: 'left',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.4rem',
    ...state.isSelected && {
      backgroundColor: colors.aliceBlue,
    },
    ...state.isFocused && {
      backgroundColor: colors.honeydew,
    },
    '&:active': {
      backgroundColor: colors.honeydew,
    },
  }),
})
