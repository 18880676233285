import { desktop } from '../../theme'


export default {
  container: {
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    minHeight: '32rem',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  headerWrapper: {
    display: 'flex',
    flex: 1,
    width: '100%',
    maxWidth: '94rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '2rem 1.5rem 8rem',
    ...desktop({
      padding: '2rem 1.5rem 3rem',
    }),
  },
  headerButton: {
    marginBottom: '3rem',
  },
  wrapper: {
    padding: '5rem 1.5rem 0rem',
    ...desktop({
      padding: '5rem 1.5rem 0rem',
    }),
  },
  main: {
    //
  },
  sidebar: {
    ...desktop({
      marginTop: '-9rem',
    }),
  },
}
