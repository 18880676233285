import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { routeParamSlug } from '../../helpers/UrlHelpers'
import routes, { Link as RouterLink } from '../../routes'

import styles from './styles'


let Link = null

let LinkParamsTransformer = (props) => props

const setLinkComponent = (linkComponent) => {
  Link = linkComponent
}

const setLinkParamsTransformer = (linkParamsTransformer) => {
  LinkParamsTransformer = linkParamsTransformer
}

export const setupLink = () => {
  setLinkComponent(RouterLink)
  setLinkParamsTransformer((props) => {
    let route = props.route || props.href
    const prefetch = props?.prefetch

    if (!route || route.indexOf('http') === 0) {
      return false
    }

    const params = { ...props.routeParams || {} }

    let registeredRoute = routes[route]

    if (!registeredRoute) {
      return {
        route: route.indexOf('/') === 0 ? route : `/${route}`,
        params,
        prefetch,
      }
    }

    if (registeredRoute.name === routes.page.name && !props?.routeParams?.slug) {
      route = routes.home.name
      registeredRoute = routes.home
      delete params.slug
    } else {
      const slug = routeParamSlug(props?.routeParams?.slug) || ''

      if (slug) {
        params.slug = slug
      }
    }

    const matches = registeredRoute.path.match(/:([a-z0-9?]+)/gi)

    if (matches) {
      const missingKeys = Array.from(matches)
        ?.map((m) => m.substr(1))
        .filter((m) => m && m.indexOf('?') === -1 && (!params || !params[m]))

      if (missingKeys.length) {
        console.warn(`[A] param "${missingKeys[0]}" is not defined`, { route }, props.children)
        return false
      }
    }

    return {
      route,
      params,
      prefetch,
    }
  })
}

const useStyles = createUseStyles(styles)

const A = (props) => {
  const classes = useStyles(props)
  const {
    children,
    className,
    onClick,
    disabled,
    href,
    route,
    target,
    title,
    rel,
    enabled,
    routeParams,
    ...otherProps
  } = props

  const renderDefault = useMemo(() => (
    <div
      className={className}
      onClick={!disabled ? onClick : undefined}
    >
      {children}
    </div>
  ), [className, disabled, onClick, children])

  if (!Link) {
    setupLink()
  }

  const params = LinkParamsTransformer(props)

  const renderAsLink = (href && (href.match(/^(h|\/\/)/) || href.match(/^mailto:/)))
    || !Link
    || !params ? (
      <a
        {...otherProps}
        title={title}
        href={href}
        aria-label={title}
        target={target}
        className={cx(classes.link, className)}
        onClick={onClick}
        rel={rel}
      >
        {children}
      </a>
    ) : (
      <Link {...params}>
        <a
          {...otherProps}
          title={title}
          aria-label={title}
          target={target}
          className={cx(classes.link, className)}
          onClick={onClick}
          role="presentation"
        >
          {children}
        </a>
      </Link>
    )

  return <>{(href || route) && !disabled ? renderAsLink : renderDefault}</>
}

A.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
  route: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  routeParams: PropTypes.object,
  target: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  enabled: PropTypes.bool,
  rel: PropTypes.string,
}

A.defaultProps = {
  className: null,
  onClick: () => undefined,
  href: null,
  route: null,
  routeParams: null,
  target: null,
  title: null,
  disabled: false,
  enabled: false,
  rel: 'nofollow noreferrer',
}

export default withMemo()(A)
