const transformErrors = (error) => {
  if (error?.extensions?.category === 'validation') {
    const validation = error?.extensions[error?.extensions?.category]

    return Object.keys(validation).reduce((r, v) => ({
      field: v,
      label: validation[v][0],
    }), {})
  }
  if (error?.extensions?.category === 'auth') {
    return {
      label: error?.extensions?.reason,
    }
  }
  return {}
}

const Errors = (errors) => (
  (
    errors
    && Array.isArray(errors)
    && errors.map(transformErrors)
  ) || []
)

export default Errors
