import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import objectFitImages from 'object-fit-images'
import { createUseStyles } from 'react-jss'

import { imageTypes } from '../CloudinaryImage/types'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Image = (props) => {
  const { lazyLoad, children, className, covered, type, src, srcSet, alt } = props
  const classes = useStyles(props)
  const lazyRef = useRef()
  const [visible, setVisible] = useState(!lazyLoad)
  const [isObjectFitSupported, setObjectFitSupported] = useState(true)

  const shouldUseIntersectionManager = useCallback(
    () => lazyLoad && 'IntersectionObserver' in window && !(typeof IntersectionObserver === 'undefined'),
    [lazyLoad]
  )

  const objectFitPolyfill = useCallback(() => {
    if (!isObjectFitSupported && lazyRef) {
      objectFitImages(lazyRef.current.querySelector('img'))
    }
  }, [isObjectFitSupported, lazyRef])

  useEffect(() => {
    const testImg = window.document.createElement('img')

    setObjectFitSupported('object-fit' in testImg.style)

    if (shouldUseIntersectionManager()) {
      const io = new IntersectionObserver((entries) => {
        const entry = entries[0]

        if (entry && entry.isIntersecting && lazyRef.current) {
          io.unobserve(lazyRef.current)
          io.disconnect()
          setVisible(true)
        }
      })

      io.observe(lazyRef.current)

      return () => io.disconnect()
    }
    setVisible(true)
  }, [shouldUseIntersectionManager])

  useEffect(() => {
    if (visible) {
      objectFitPolyfill()
    }
  }, [objectFitPolyfill, visible])

  const computeAlt = () => (alt !== null
    ? alt
    : src
      .split('/')
      .pop()
      .split('-')
      .join(' ')
      .split('.')[0])

  const renderImage = () => (
    <img
      src={src}
      {...(srcSet ? { srcSet } : {})}
      alt={computeAlt()}
      className={classes.img}
    />
  )

  let contentNode = <>{type === imageTypes.Background && children}</>

  if (visible) {
    contentNode = (
      <>
        {children}
        {type !== imageTypes.Background && renderImage()}
      </>
    )
  }

  let divStyle = {}

  if (visible && type === imageTypes.Background) {
    divStyle = {
      backgroundImage: `url('${src}')`,
    }

    if (covered) {
      divStyle = {
        ...divStyle,
        backgroundSize: 'cover',
      }
    }
  }

  return (
    <div
      ref={lazyRef}
      className={classNames(className, classes.image, { covered })}
      style={divStyle}
    >
      {contentNode}
    </div>
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  children: PropTypes.node,
  alt: PropTypes.string,
  className: PropTypes.string,
  lazyLoad: PropTypes.bool,
  covered: PropTypes.bool,
  type: PropTypes.string,
}

Image.defaultProps = {
  covered: false,
  className: null,
  srcSet: '',
  children: null,
  lazyLoad: false,
  alt: null,
  type: imageTypes ? imageTypes.Image : 'image',
}

export default withMemo()(Image)
