import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import Immutable from 'seamless-immutable'
import ReactSelect from 'react-select'

import FormHelpText from '../../FormHelpText'
import FormErrorText from '../../FormErrorText'
import FormLabel from '../../FormLabel'
import { generateUniqueId } from '../../../../utils/ComponentUtils'
import withMemo from '../../../../decorators/WithMemo'

import styles, { selectStyles } from './styles'


const useStyles = createUseStyles(styles)

function SelectMulti(props) {
  const {
    className,
    classNames,
    label,
    name,
    placeholder,
    options,
    value,
    disabled,
    isSearchable,
    required,
    error,
    help,
    onChange,
    onFocus,
    onBlur,
    closeMenuOnSelect,
    id,
    noOptions,
  } = props

  const [thisId] = useState(`form_select_${generateUniqueId()}`)
  const classes = useStyles(props)

  const handleChange = (selectedOption) => {
    if (onChange !== null) {
      onChange({ name, value: selectedOption?.map((option) => option.value) || [] })
    }
  }

  const handleFocus = (event) => {
    if (onFocus !== null) {
      onFocus({ name, event })
    }
  }

  const handleBlur = (event) => {
    if (onBlur !== null) {
      onBlur({ name, event })
    }
  }

  const selectedOption = useMemo(
    () => (value && options ? options?.filter((option) => value?.indexOf(option.value) !== -1) : []),
    [options, value]
  )

  const mutableOptions = options && Immutable.isImmutable(options)
    ? Immutable.asMutable(options, { seep: true })
    : options

  return (
    <div className={cx(classes.container, className, classNames.container)}>
      <label
        className={cx(classes.wrapper, classNames.wrapper)}
        htmlFor={id || thisId}
      >
        <FormLabel
          className={cx(classes.label, classNames.label)}
          required={required}
          error={!!error}
        >
          {label}
        </FormLabel>
      </label>
      <div className={cx(classes.container, className)}>
        <ReactSelect
          className={cx(classes.select)}
          classNamePrefix="react-select"
          styles={selectStyles(props)}
          aria-label={name}
          name={name}
          inputId={id || thisId}
          instanceId={`${name}_instance`}
          placeholder={placeholder}
          options={mutableOptions}
          value={selectedOption}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          isDisabled={disabled}
          isMulti
          isSearchable={isSearchable}
          closeMenuOnSelect={closeMenuOnSelect}
          blurInputOnSelect={closeMenuOnSelect}
          noOptionsMessage={(inputValue) => (typeof noOptions === 'function' ? noOptions(inputValue) : noOptions)}
        />
      </div>
      <FormHelpText
        className={classes.helpText}
        text={help}
      />
      <FormErrorText
        className={classes.errorText}
        text={error}
      />
    </div>
  )
}

SelectMulti.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  classNames: PropTypes.objectOf(PropTypes.string),

  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,

  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  help: PropTypes.string,

  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,

  noOptions: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
}

SelectMulti.defaultProps = {
  className: '',
  classNames: {},

  id: null,
  placeholder: '',
  label: '',

  value: [],
  error: null,
  help: null,

  required: false,
  disabled: false,
  isSearchable: false,
  closeMenuOnSelect: false,

  onChange: null,
  onFocus: null,
  onBlur: null,

  noOptions: null,
}

SelectMulti.defaultValue = []

export default withMemo()(SelectMulti)
