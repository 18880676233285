import { fonts, colors, tablet, desktop } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'transparent',
    borderRadius: '2rem',
    marginTop: '4rem',
    ...tablet({
      marginTop: '0',
      maxWidth: '20rem',
      borderRadius: '0 0 2rem 2rem',
      background: 'transparent',
    }),
  },
  logo: {
    display: 'none',
    width: '100%',
    height: '18rem',
    overflow: 'hidden',
    borderRadius: '2rem',
    background: colors.pureWhite,
    boxShadow: '0 1px 8px rgba(20,20,20,.05)',
    padding: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '4rem',
    zIndex: '1',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'cover',
      width: '100%',
      borderRadius: '1.5rem',
    },
    ...desktop({
      display: 'flex',
    }),
  },
  title: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.1rem',
    lineHeight: '1.3rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.nightRider,
    margin: '1.3rem 0 1.7rem',
    ...tablet({
      display: 'none',
    }),
  },
  metrics: {
    display: 'none',
    ...desktop({
      display: 'flex',
      marginBottom: '2rem',
    }),
  },
  details: {
    width: '100%',
    marginBottom: '4rem',
  },
  map: {
    width: '100%',
    ...tablet({
      height: '19rem',
      borderRadius: '2rem 2rem 0 0',
    }),
  },
  address: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: colors.aliceBlue,
    padding: '1.2rem 1.2rem 2rem',
    ...tablet({
      alignItems: 'flex-start',
    }),
  },
  addressTitle: {
    width: '100%',
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    textAlign: 'left',
    textTransform: 'uppercase',
    marginBottom: '.8rem',
    color: colors.darkCerulean,
  },
  addressLink: {
    width: '100%',
  },
  fullAddress: {
    width: '100%',
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    textAlign: 'center',
    color: colors.darkBlue,
    ...tablet({
      textAlign: 'left',
    }),
  },
  phone: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: colors.aliceBlue,
    padding: '0.3rem 1.2rem 2rem',
    ...tablet({
      alignItems: 'flex-start',
    }),
  },
  phoneLabel: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    textAlign: 'left',
    marginBottom: '.8rem',
    color: colors.nightRider,
  },
  phoneText: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.1rem',
    lineHeight: '1.6rem',
    textAlign: 'left',
    marginBottom: '.8rem',
    color: colors.darkBlue,
  },
  social: {
    background: colors.darkBlue,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '1.2rem 1.2rem 2.6rem',
    color: colors.pureWhite,
    borderRadius: '0 0 2rem 2rem',
  },
  socialTitle: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: '1.3rem',
  },
  socialIcons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2.4rem',
  },
  socialIcon: {
    margin: '0 1rem',
  },
  website: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
