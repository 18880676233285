import { colors, fonts } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    padding: '6rem 1.5rem',
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.4rem',
    lineHeight: '2.6rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.darkBlue,
    marginTop: '0',
    marginBottom: '4rem',
  },
  cardContainer: {
    margin: '0 auto',
    maxWidth: '94rem',
    position: 'relative',
    overflow: 'hidden',
    listStyle: 'none',
    padding: 0,
    zIndex: 1,
  },
  cardWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    transitionProperty: 'transform',
    boxSizing: 'content-box',
    transform: 'translate3d(0px, 0, 0)',
    marginBottom: ['4rem', '!important'],
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '13rem',
    position: 'relative',
    transitionProperty: 'transform',
    '& img': {
      width: '13rem',
    },
  },
}
