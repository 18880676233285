import { fonts, colors, tablet, sizes } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    background: colors.gainsboro,
    borderRadius: '2rem',
    padding: '2.2rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...tablet({
      alignItems: 'flex-start',
      padding: '2.2rem 3rem',
      maxWidth: `${sizes.desktop / 10}rem`,
    }),
  },
  title: {
    fontFamily: fonts.FiraSansMedium.fontFamily,
    fontWeight: fontWeights.medium,
    fontSize: '1.8rem',
    lineHeight: '2.2rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.darkBlue,
    marginBottom: '1.4rem',
    ...tablet({
      textAlign: 'left',
    }),
  },
  subtitle: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.1rem',
    lineHeight: '1.6rem',
    textAlign: 'center',
    color: colors.grey,
  },
  data: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '2.8rem 0 1.5rem',
    width: '100%',
    ...tablet({
      flexDirection: 'row',
    }),
  },
  graph: {
    flex: 1,
    position: 'relative',
    width: '100%',
    overflowX: 'auto',
    alignSelf: 'flex-start',
    ...tablet({
      width: 'calc(100% - 24rem)',
    }),
    '& .recharts-cartesian-axis-tick': {
      fontFamily: fonts.FiraSansRegular.fontFamily,
      fontWeight: fontWeights.regular,
      fontSize: '1.1rem',
    },
    '& .recharts-surface': {
      '& .recharts-bar:nth-last-child(2)': {
        '& .recharts-bar-rectangle': {
          transform: 'translateX(2.5rem)',
        },
      },
      '& .recharts-bar:last-child': {
        '& .recharts-bar-rectangle': {
          transform: 'translateX(-3rem)',
        },
      },
    },
    '& .recharts-xAxis': {
      '& .recharts-cartesian-axis-line': {
        stroke: colors.darkBlue,
      },
    },
    '& .recharts-yAxis': {
      '& .recharts-cartesian-axis-line': {
        stroke: colors.darkBlue,
      },
    },
  },
  graphContainer: {
    marginLeft: '-4rem',
    marginTop: '.5rem',
    width: '70rem !important',
    overflowX: 'auto',
    height: '25.5rem !important',
  },
  graphLabel: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.1rem',
  },
  graphTitle: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.1rem',
    color: colors.darkBlue,
    position: 'absolute',
    top: '4.9rem',
    left: '-4rem',
    transform: 'rotate(-90deg)',
    textTransform: 'uppercase',
  },
  graphTooltip: {
    background: colors.pureWhite,
    padding: '2rem',
    borderRadius: '2rem',
  },
  graphTooltipTitle: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.6rem',
  },
  profilesLabel: {
    fill: colors.nightRider,
  },
  offersLabel: {
    fill: colors.pureWhite,
  },
  infos: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...tablet({
      justifyContent: 'space-between',
      width: '22rem',
    }),
  },
  labels: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '3rem 0',
    width: '100%',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '1rem',
  },
  labelColor: {
    width: '2rem',
    height: '1rem',
    marginRight: '1.2rem',
  },
  labelValue: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.1rem',
    lineHeight: '1.6rem',
  },
  offers: {
    background: colors.darkBlue,
  },
  profiles: {
    background: colors.darkOrange,
    opacity: '.4',
  },
  text: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.1rem',
    lineHeight: '1.6rem',
    color: colors.grey,
  },
}
