import { colors, fonts, desktop, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    background: colors.aliceBlue,
    padding: '3.2rem 0 5rem',
    ...desktop({
      padding: '5rem 0 8rem',
    }),
  },
  text: {
    margin: 0,
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    color: colors.nightRider,
    textAlign: 'left',
    fontSize: '1.5rem',
    lineHeight: '2.2rem',
    padding: '0 1rem',
    marginBottom: '4rem',
    ...tablet({
      maxWidth: '62rem',
      marginBottom: '5.7rem',
    }),
    ...desktop({
      maxWidth: '94rem',
      marginBottom: '8.9rem',
      fontSize: '1.8rem',
      lineHeight: '2.6rem',
    }),
  },
  promisesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '0 auto',
  },
  promise: {
    margin: '0 1.5rem',
  },
}
