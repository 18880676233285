import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import withStyles from 'react-jss'

import PlayerYoutube from './PlayerYoutube'
import styles from './styles'


@withStyles(styles)
export default class VideoPlayer extends Component {

  static instances = []

  static stopAll(except) {
    VideoPlayer.instances.forEach((player) => {
      if (player !== except) {
        player.stop()
      }
    })
  }

  constructor(props) {
    super(props)
    VideoPlayer.instances.push(this)
  }

  handlePlay = () => {
    VideoPlayer.stopAll(this)
    this.props.onPlay()
  }

  handleComplete = () => {
    this.props.onComplete()
  }

  play() {
    if (this.player) {
      this.player.play()
    }
  }

  stop() {
    if (this.player) {
      this.player.stop()
    }
  }

  playerRef = (ref) => {
    this.player = ref
  }

  render() {
    const { classes, video, allowCookies, autoplay } = this.props

    return (
      <div className={cx(classes.container)}>
        <div className={classes.wrapper}>
          <div className={classes.video}>
            <PlayerYoutube
              video={video}
              allowCookies={allowCookies}
              onPlay={this.handlePlay}
              onEnd={this.handleComplete}
              ref={this.playerRef}
              preload
              autoplay={autoplay}
            />
          </div>
        </div>
      </div>
    )
  }

}

VideoPlayer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  video: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
  allowCookies: PropTypes.bool,
  onComplete: PropTypes.func,
  onPlay: PropTypes.func,
}

VideoPlayer.defaultProps = {
  classes: {},
  autoplay: false,
  allowCookies: false,
  onComplete: () => null,
  onPlay: () => null,
}
