import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import CloudinaryImage from '../CloudinaryImage'
import VideoPlayer from '../VideoPlayer'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const CompanyGalleryVideo = (props) => {
  const classes = useStyles(props)
  const { className, thumbnail, video } = props

  const [showPlayer, setShowPlayer] = useState(false)

  const renderVideo = () => (
    <div className={classes.video}>
      <VideoPlayer
        video={video}
        autoplay
      />
    </div>
  )

  const renderThumbnail = () => (
    <div
      className={classes.thumbnailContainer}
      onClick={() => setShowPlayer(true)}
      role="presentation"
    >
      <CloudinaryImage
        id={thumbnail}
        className={classes.thumbnail}
        options={{
          width: '700',
          height: '394',
          crop: 'fill',
        }}
        lazyLoad
      />
      <CloudinaryImage
        id="/images/youtube-play-icon.png"
        className={classes.playIcon}
        lazyLoad
      />
    </div>
  )

  return (
    <div className={cx(classes.container, className)}>
      {showPlayer ? renderVideo() : renderThumbnail()}
    </div>
  )
}

CompanyGalleryVideo.propTypes = {
  className: PropTypes.string,
  thumbnail: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
}

CompanyGalleryVideo.defaultProps = {
  className: '',
}

export default withMemo()(CompanyGalleryVideo)
