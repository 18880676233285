import { colors, desktop, fonts } from '../../theme'


export default {
  container: {
    width: '100%',
    maxWidth: '70rem',
    margin: '0 auto',
  },
  introduction: {
    ...fonts.FiraSansBold,
    marginBottom: '3rem',
    fontSize: '2.0rem',
    lineHeight: '2.6rem',
    color: colors.darkBlue,
  },
  descriptionContainer: {
    padding: 0,
  },
  description: {
    marginBottom: '3rem',
    ...desktop({
      fontSize: '1.5rem',
      lineHeight: '2rem',
    }),
  },
  programLabel: {
    ...fonts.FiraSansBold,
    fontSize: '2.0rem',
    lineHeight: '2.4rem',
    color: colors.darkBlue,
    textTransform: 'uppercase',
    marginTop: '4rem',
  },
  programContainer: {
    marginTop: '1.5rem',
    padding: 0,
  },
  program: {
    ...fonts.FiraSansRegular,
    fontSize: '1.5rem',
    lineHeight: '2.0em',
    ...desktop({
      fontSize: '1.5rem',
      lineHeight: '2rem',
    }),
  },
  cta: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2rem',
    ...desktop({
      display: 'none',
    }),
  },
}
