import { colors, fonts, tablet, sizes, textStyles } from '../../theme'


export default {
  container: {
    width: '100%',
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    padding: '3rem 0',
  },
  title: {
    ...fonts.FiraSansBold,
    color: colors.egyptianBlue,
    fontSize: '2.4rem',
    lineHeight: '2.6rem',
    textTransform: 'uppercase',
    marginBottom: '1.5rem',
    padding: '0 2rem',
  },
  intro: {
    ...textStyles.paragraph,
    ...fonts.FiraSansRegular,
    color: colors.grey,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    marginBottom: '3rem',
    padding: '0 2rem',
  },
  sectors: {
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
    ...tablet({
      flexDirection: 'row',
    }),
  },
  sectorsEntry: {
    flex: 1,
    padding: '0 1rem',
    marginBottom: '2rem',
  },
  text: {
    padding: '0 2rem',
    ...textStyles.paragraph,
    ...fonts.FiraSansRegular,
    color: colors.grey,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    marginBottom: '2rem',
  },
}
