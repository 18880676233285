import { colors, fonts, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    borderRadius: '2rem',
    border: `1px solid ${colors.gainsboro}`,
    width: '100%',
    minHeight: '30rem',
    padding: '2rem',
    backgroundColor: colors.pureWhite,
    ...tablet({
      width: '30rem',
    }),
    cursor: 'pointer',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '1.5rem',
    marginBottom: '1.5rem',
    borderBottom: `1px solid ${colors.gainsboro}`,
  },
  image: {
    width: '5.6rem',
    height: '5.6rem',
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '1rem',
      objectFit: 'cover',
    },
  },
  infos: {
    display: 'flex',
    gap: '1rem',
    maxWidth: '22.4rem',
  },
  name: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.4rem',
    color: colors.nightRider,
  },
  job: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.2rem',
    color: colors.darkBlue,
    lineHeight: '1.8rem',
  },
  company: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.1rem',
    color: colors.grey,
    lineHeight: '1.6rem',
  },
  jobCategory: {
    borderRadius: '50%',
    height: 'max-content',
    '& svg': {
      padding: '0.5rem',
      height: '3rem',
      width: '3rem',
    },
  },
  description: {
    maxHeight: '6.6rem',
    minHeight: '2rem',
    overflow: 'hidden',
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.5rem',
    lineHeight: '2.2rem',
    color: colors.nightRider,
    width: '100%',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 3,
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  bottom: {
    borderTop: `1px solid ${colors.gainsboro}`,
    paddingTop: '2rem',
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    alignItems: 'center',
  },
  favoriteCta: {
    width: 'max-content',
  },
  contactCta: {
    width: 'max-content',
  },
}
