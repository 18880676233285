import { colors, fonts, desktop } from '../../theme'


export default {
  container: {
    position: 'relative',
    backgroundColor: colors.darkBlue,
    borderRadius: '2rem',
    padding: '3rem 2rem',
    overflow: 'hidden',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    opacity: 0.3,
  },
  content: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    lineHeight: '2.4rem',
    color: colors.pureWhite,
    textTransform: 'uppercase',
    marginBottom: '1.7rem',
    minHeight: '7rem',
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    ...desktop({
      flexDirection: 'row',
    }),
    '& > div:not(.no-border)': {
      '&:first-child': {
        ...desktop({
          borderRight: `.1rem solid ${colors.pureWhite}`,
        }),
      },
      '&:last-child': {
        ...desktop({
          paddingLeft: '2rem',
        }),
      },
    },
    '& > div': {
      '&:last-child': {
        '& $fieldEntry': {
          '&:last-child': {
            marginBottom: 0,
          },
        },
      },
    },
    '& div': {
      flex: 1,
      '& $fieldEntry': {
        '&:last-child': {
          ...desktop({
            marginBottom: 0,
          }),
        },
      },
    },
  },
  fieldEntry: {
    marginBottom: '2.5rem',
    '& > span': {
      ...fonts.FiraSansBold,
      fontSize: '1.8rem',
      lineHeight: '2.4rem',
      color: colors.darkOrange,
      textTransform: 'uppercase',
    },
    '& > p': {
      ...fonts.FiraSansRegular,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      color: colors.gainsboro,
    },
  },
}
