import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import { imageTypes } from '../CloudinaryImage/types'
import EventCard from '../EventCard'

import styles from './styles'


const useStyles = createUseStyles(styles)

const EventGroup = (props) => {
  const classes = useStyles(props)
  const { className, image, title, cards, ctaLabel, ctaRoute, ctaRouteParams, ctaLink } = props

  const renderCard = useCallback((card, i) => (
    <React.Fragment key={i}>
      <EventCard
        {...card}
        className={classes.card}
        style={{ flex: 1 / (cards.length + 1), ...(i === 0 && { marginTop: 0 }) }}
      />
    </React.Fragment>
  ), [cards.length, classes.card])

  const renderCards = useCallback(() => cards.map(renderCard), [cards, renderCard])

  return (
    <CloudinaryImage
      lazyLoad
      id={image}
      type={imageTypes.Background}
      className={cx(
        classes.container,
        className
      )}
    >
      <div className={classes.content}>
        <div className={classes.title}>{title}</div>
        <div className={classes.body}>
          <div className={classes.cards}>
            {cards && renderCards()}
          </div>
          {(ctaLink || ctaRoute) && ctaLabel && (
          <ActionButton
            label={ctaLabel}
            link={ctaLink}
            route={ctaRoute}
            routeParams={ctaRouteParams}
            color="primary"
            className={classes.cta}
          />
          )}
        </div>
      </div>
    </CloudinaryImage>
  )
}

EventGroup.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(EventCard.propTypes)),
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaRoute: PropTypes.string,
  ctaRouteParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
}

EventGroup.defaultProps = {
  className: '',
  image: null,
  title: null,
  subtitle: null,
  cards: [],
  ctaLink: null,
  ctaLabel: null,
  ctaRoute: null,
  ctaRouteParams: null,
}

export default withMemo()(EventGroup)
