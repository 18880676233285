/* eslint-disable max-lines */
import { colors, desktop, fonts, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    minHeight: '32rem',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  headerWrapper: {
    display: 'flex',
    flex: 1,
    width: '100%',
    maxWidth: '94rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '2rem 1.5rem 8rem',
    ...desktop({
      padding: '2rem 1.5rem 3rem',
    }),
  },
  headerButton: {
    marginBottom: '3rem',
  },
  wrapper: {
    padding: '5rem 1.5rem 0rem',
    ...desktop({
      padding: '5rem 1.5rem 0rem',
    }),
  },
  main: {
    //
  },
  sidebar: {
    ...desktop({
      marginTop: '-9rem',
    }),
  },
  commentsContainer: {
    backgroundColor: colors.honeydew,
  },
  commentsWrapper: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '94rem',
    padding: '5rem 2rem 12rem',
  },
  commentsHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '5rem',
    ...tablet({
      flexDirection: 'row',
      marginBottom: '3.2rem',
    }),
  },
  commentsTitle: {
    display: 'flex',
    flex: 1,
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    color: colors.nightRider,
    fontSize: '3.0rem',
    lineHeight: '3.6rem',
    marginBottom: '2rem',
    ...tablet({
      marginBottom: 0,
    }),
  },
  commentsPublishCta: {
    //
  },
  comments: {
    //
  },
  noComments: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    color: colors.nightRider,
    fontSize: '2.4rem',
    lineHeight: '2.6rem',
    margin: '2rem',
    marginTop: '10rem',
    textAlign: 'center',
  },
  comment: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.pureWhite,
    border: `1px solid ${colors.gainsboro}`,
    borderRadius: '2rem',
    marginBottom: '2rem',
    ...tablet({
      flexDirection: 'row',
    }),
  },
  commentLeft: {
    display: 'flex',
    flexDirection: 'row',
    borderRight: 'none',
    // borderBottom: `1px solid ${colors.gainsboro}`,
    padding: '1.9rem 3rem 3.5rem 1.9rem',
    ...tablet({
      width: '30rem',
      flexDirection: 'column',
      borderRight: `1px solid ${colors.gainsboro}`,
    }),
  },
  commentAvatar: {
    display: 'block',
    width: '11rem',
    height: '11rem',
    marginBottom: '0rem',
    marginRight: '2rem',
    ...tablet({
      marginBottom: '1.7rem',
      marginRight: '0rem',
    }),
  },
  commentAvatarImage: {
    borderRadius: '8px',
    overflow: 'hidden',
  },
  commentInfoText: {
    marginTop: '0.3rem',
  },
  commentName: {
    display: 'block',
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    color: colors.darkBlue,
    fontSize: '1.8rem',
    lineHeight: '2.0rem',
    marginBottom: '0.6rem',
  },
  commentFunctionDescription: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    color: colors.nightRider,
    fontSize: '1.5rem',
    lineHeight: '2.0rem',
    marginBottom: '0.3rem',
    ...tablet({
      marginBottom: '2.8rem',
    }),
  },
  commentCompany: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2.8rem',
  },
  commentCompanyIcon: {
    width: '2.4rem',
    height: '2.4rem',
    marginRight: '1.0rem',
  },
  commentCompanyText: {
    display: 'block',
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    color: colors.darkBlue,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
  },
  commentRight: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '2.2rem',
    justifyContent: 'space-between',
  },
  commentText: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    color: colors.nightRider,
    fontSize: '1.8rem',
    lineHeight: '2.6rem',
  },
  bottomInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '4.5rem',
  },
  commentRecommendation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  commentRecommendationIcon: {
    width: '2.4rem',
    height: '2.4rem',
    marginRight: '1.0rem',
  },
  commentRecommendationText: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    color: colors.darkBlue,
    fontSize: '1.8rem',
    lineHeight: '2.6rem',
  },
  deleteButton: {
    marginLeft: '2rem',
  },
  modal: {
    maxWidth: '55rem',
  },
  modalText: {
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    padding: '1rem 0',
    color: colors.nightRider,
  },
  modalButtonsWrapper: {},
  confirmButton: {
    margin: '1rem',
  },
  cancelButton: {
    margin: '1rem',
  },
}
