export const fontWeights = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
}

export const declareFont = ({ name, basename, ...params }) => {
  const path = `/fonts/${basename}/${basename}`

  return {
    ...params,
    fontDisplay: 'block',
    fallbacks: [
      {
        src: `url("${path}.eot")`,
      },
      {
        src: [
          `url("${path}.eot?#iefix") format("embedded-opentype")`,
          `url("${path}.woff2") format("woff2")`,
          `url("${path}.woff") format("woff")`,
          `url("${path}.ttf") format("truetype")`,
          `url("${path}.svg") format("svg")`,
        ],
      },
    ],
  }
}
