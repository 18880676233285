import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import CloudinaryImage from '../CloudinaryImage'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const RegisterPushItem = (props) => {
  const classes = useStyles(props)
  const { className, image, title, text } = props

  return (
    <div className={cx(classes.container, className)}>
      <CloudinaryImage
        id={image}
        className={classes.image}
        lazyLoad
      />
      {title && <div className={classes.title}>{title}</div>}
      {text && <div className={classes.text}>{text}</div>}
    </div>
  )
}

export const propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
}

RegisterPushItem.propTypes = propTypes

RegisterPushItem.defaultProps = {
  className: '',
  text: null,
}

export default withMemo()(RegisterPushItem)
