import React, { Component } from 'react'
import PropTypes from 'prop-types'
/* global YT */

let YTLoader = {
  load: () => {
  },
}

if (process.browser) {
  YTLoader = {
    loading: false,
    loaded: false,
    _callbacks: [],
    load(cb, props) {
      this._addCallback(cb)
      if (this.loading) {
        return
      }
      if (this.loaded) {
        return cb()
      }
      window.onYouTubePlayerAPIReady = this.onLoaded.bind(this)
      const tag = document.createElement('script')

      tag.src = 'https://www.youtube.com/player_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]

      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    },
    onLoaded() {
      this.loaded = true
      this.loading = false
      this._callbacks.forEach((cb) => cb())
      this._callbacks = []
    },
    _addCallback(cb) {
      if (this._callbacks.indexOf(cb) === -1) {
        this._callbacks.push(cb)
      }
    },
  }
}

class PlayerYoutube extends Component {

  constructor(props) {
    super(props)

    this.id = this._parseID(props.video)
    this.loaded = false
    this.inited = false
    this.waitingForPlay = false
    this.waitingForPreload = false
    this.video = null
    YTLoader.load(this._onLoaded.bind(this), props)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.video !== this.props.video) {
      this.id = this._parseID(this.props.video)
      if (this.player) {
        this.player.loadVideoById(this.id)
      } else {
        this.init(true)
      }
    }
  }

  //
  // Public methods
  //
  get imageURL() {
    return Promise.resolve(`//img.youtube.com/vi/${this.id}/hqdefault.jpg`)
  }

  playerVars = (autoplay = false) => {
    const { allowCookies } = this.props

    return {
      height: '100%',
      width: '100%',
      videoId: this.id,
      host: `https://www.${allowCookies ? 'youtube' : 'youtube-nocookie'}.com`,
      playerVars: {
        controls: 1,
        showinfo: 1,
        autoplay: autoplay ? 1 : 0,
        origin: window.location.origin,
        playsinline: 1,
        rel: 0,
        modestbranding: 1,
      },
      events: {
        onReady: (event) => this._onPlayerReadyYoutube(event, autoplay),
        onStateChange: this._onPlayerStateChangeYoutube,
      },
    }
  }

  preload() {
    if (this.loaded) {
      if (!this.player) {
        this.init(this.props.autoplay)
      }
    } else {
      this.waitingForPreload = true
    }
  }

  play() {
    if (this.loaded) {
      if (!this.player) {
        this.init(true)
      } else {
        this.player.playVideo()
      }
    } else {
      this.waitingForPlay = true
    }
  }

  init(autoplay) {
    if (!this.inited) {
      this.inited = true
    } else {
      this.container = document.getElementById('player-youtube')
      this.container.remove()
    }

    this.container = document.createElement('div')
    this.container.setAttribute('id', 'player-youtube')
    if (this.video) {
      this.video.appendChild(this.container)
    }
    /* eslint-disable-next-line */
    new YT.Player(this.container, this.playerVars(autoplay))
  }

  stop() {
    if (this.player) {
      this.player.stopVideo()
      this.player.destoy()
      this.player = null
    }
  }

  destroy() {
    if (this.player) {
      this.player.destroy()
    }
  }

  //
  // Private methods
  //

  _onPlayerStateChangeYoutube = (event) => {
    if ((event.data === YT.PlayerState.PLAYING)) {
      this.props.onPlay()
      this.waitingForPlay = false
    }
    if (event.data === YT.PlayerState.ENDED) {
      this.props.onEnd()
    }
  }

  _onPlayerReadyYoutube = (event, play = true) => {
    this.player = event.target
    if (play) {
      this.player.playVideo()
    }
  }

  _onLoaded = () => {
    this.loaded = true
    if (this.waitingForPlay) {
      this.play()
    } else if (this.waitingForPreload) {
      this.preload()
    }
  }

  setVideoRef = (element) => {
    this.video = element
    const { preload } = this.props

    if (this.video && preload) {
      this.preload()
    }
  }

  render() {
    return (<div ref={this.setVideoRef} />)
  }

  //
  // UTILS
  //
  _parseID(id) {
    if (!id) {
      return id
    }

    if (id.indexOf('/') === -1) {
      if (id.indexOf('&') === -1) {
        return id
      }
      return id.substring(0, id.indexOf('&'))
    }

    /* eslint-disable-next-line */
    const exp = new RegExp('(?:youtube(?:-nocookie)?\.com/(?:[^/]+/.+/|(?:v|e(?:mbed)?)/|.*[?&]v=)|youtu\.be/)([^"&?/ ]{11})', 'i')
    const match = id.match(exp)

    if (match) {
      return match[1]
    }
    return id
  }

}

PlayerYoutube.propTypes = {
  video: PropTypes.string.isRequired,
  allowCookies: PropTypes.bool,
  onPlay: PropTypes.func,
  onEnd: PropTypes.func,
  preload: PropTypes.bool,
  autoplay: PropTypes.bool,
}

PlayerYoutube.defaultProps = {
  allowCookies: true,
  onPlay: () => null,
  onEnd: () => null,
  preload: false,
  autoplay: false,
}

export default PlayerYoutube
