import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/WithMemo'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'
import Icon from '../Icon'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FilterRelationBoxEntry = (props) => {
  const classes = useStyles(props)
  const {
    className,
    name,
    label,
    options,
    value,
    onChange,
    isMulti,
    isOpen,
    toggleSelect,
    i,
    allTranslationForFilter,
  } = props

  const handleToggleSelect = useCallback(() => {
    if (toggleSelect !== null) {
      toggleSelect(i)
    }
  }, [toggleSelect, i])

  // eslint-disable-next-line complexity
  const handleChange = useCallback((cname) => {
    const val = Array.from(value) || []
    const index = val.indexOf(cname)

    const hasAll = val.indexOf(allTranslationForFilter) !== -1

    if (cname === null) {
      val.splice(0)
    } else if (isMulti) {
      if (index === -1) {
        if (hasAll || cname === allTranslationForFilter) {
          val.splice(0)
          val.push(cname)
        } else {
          val.push(cname)
        }
      } else {
        if (hasAll) {
          val.splice(0)
          val.push(cname)
        }
        val.splice(index, 1)
      }
    } else if (index === -1) {
      val.splice(0)
      val.push(cname)
    }

    onChange({
      name,
      value: isMulti ? val : val[0],
    })
  }, [value, allTranslationForFilter, isMulti, onChange, name])

  const getIsSelected = useCallback((v) => Array.from(value)
    .includes(v) || value === v || (!Array.from(value)?.length && v === null), [value])

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div
          className={classes.label}
          onClick={handleToggleSelect}
          role="presentation"
        >
          {label}
          {/* eslint-disable-next-line max-len */}
          <div className={classes.count}>{`(${value.length === 1 && value[0] === allTranslationForFilter ? options.length : value.length})`}</div>
        </div>
        {isOpen && (
          <div className={classes.entries}>
            {options.map((option, index) => (
              <li
                className={cx(classes.li, getIsSelected(option.value) && classes.liSelected)}
                key={index}
                onClick={() => handleChange(option.value)}
                role="presentation"
              >
                {option.label}
                {getIsSelected(option.value) && (
                  <Icon
                    icon={iconsKeys.Check}
                    color={colors.primary}
                    className={classes.icon}
                  />
                )}
              </li>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

FilterRelationBoxEntry.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isOpen: PropTypes.bool,
  toggleSelect: PropTypes.func,
  i: PropTypes.number,
  allTranslationForFilter: PropTypes.string,
}

FilterRelationBoxEntry.defaultProps = {
  className: '',
  name: '',
  label: '',
  options: [],
  value: [],
  onChange: null,
  isMulti: false,
  isOpen: false,
  toggleSelect: null,
  i: 0,
  allTranslationForFilter: '',
}

export default withMemo()(FilterRelationBoxEntry)
