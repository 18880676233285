import { colors, fonts, tablet } from '../../theme'


export default {
  container: {
    backgroundColor: colors.honeydew,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '3rem',
    lineHeight: '3.6rem',
    color: colors.primary,
    marginBottom: '2rem',
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > div': {
      marginBottom: '3rem',
    },
    ...tablet({
      flexDirection: 'row',
      flexWrap: 'wrap',
      '& > div': {
        marginRight: '2rem',
      },
    }),
  },
}
