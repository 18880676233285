import { colors, desktop, fonts } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '-9rem',
    ...desktop({
      marginTop: 0,
      maxWidth: '20rem',
    }),
  },
  avatar: {
    backgroundColor: colors.pureWhite,
    border: `0.5rem solid ${colors.pureWhite}`,
    borderRadius: '8px',
    width: '14rem',
    height: '14rem',
    marginBottom: '2.0rem',
    ...desktop({
      width: '19rem',
      height: '19rem',
      marginBottom: '0.2rem',
    }),
  },
  avatarImage: {
    borderRadius: '3px',
    overflow: 'hidden',
  },
  name: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.4rem',
    lineHeight: '2.6rem',
    textAlign: 'center',
    marginBottom: '3rem',
    color: colors.nightRider,
    ...desktop({
      display: 'none',
    }),
  },
  title: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    textAlign: 'center',
    marginBottom: '3rem',
    padding: '0.2rem 0.8rem',
    borderRadius: '3px',
    color: colors.darkBlue,
    backgroundColor: colors.aliceBlue,
    ...desktop({
      display: 'none',
    }),
  },
  social: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '1.2rem',
    marginBottom: '4rem',
  },
  socialTitle: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: '1.3rem',
  },
  socialIcons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  socialIcon: {
    margin: '0 1rem',
    width: '2.4rem',
    height: '2.4rem',
  },
  companyDescription: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.5rem',
    lineHeight: '2.0rem',
    margin: '0 1rem 3rem',
    textAlign: 'center',
    color: colors.nightRider,
    ...desktop({
      display: 'none',
    }),
  },
  companyContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.aliceBlue,
    borderRadius: '8px',
    padding: '1.8rem 1.8rem 2.2rem ',
    marginBottom: '12rem',
  },
  companyLogo: {
    borderRadius: '3px',
    overflow: 'hidden',
    backgroundColor: colors.pureWhite,
  },
  companyName: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    textTransform: 'uppercase',
    color: colors.nightRider,
    marginTop: '2.5rem',
  },
  companyLocation: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    textTransform: 'uppercase',
    color: colors.nightRider,
    marginTop: '0.6rem',
  },
  companyAddress: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    color: colors.darkBlue,
    marginTop: '0.8rem',
  },
  companyCta: {
    marginTop: '1.2rem',
    display: 'flex',
    alignSelf: 'flex-start',
  },
}
