import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'

import styles from './styles'
import PaginationPrevNext from './PaginationPrevNext'
import PaginationPage from './PaginationPage'


const useStyles = createUseStyles(styles)

const Pagination = (props) => {
  const classes = { ...useStyles(props), ...props.classes }
  const { totalPage, currentPage, onClick, className } = props

  const handleClick = (event, page) => {
    onClick({ event, page: parseInt(page, 10) })
  }

  const handlePage = (pageIndex) => {
    const page = pageIndex + 1

    const limit = 8
    const preservedDistanceToEdge = 4
    const distanceToLastPage = Math.abs(totalPage - page)
    const distanceToCurrent = Math.abs(page - currentPage)
    const isEdgePage = page === totalPage || page === 1
    const isLastPreservedRange = (
      (totalPage - currentPage) < preservedDistanceToEdge
      && (distanceToLastPage <= preservedDistanceToEdge)
    )
    const isFirstPreservedRange = page <= preservedDistanceToEdge + 1
      && currentPage <= preservedDistanceToEdge + 1
      && !(currentPage === preservedDistanceToEdge + 1
        && totalPage > limit)

    const configs = {
      page,
      disabled: page === currentPage,
    }

    // Truncated ?
    if (totalPage >= limit
      && currentPage !== page
      && !isEdgePage
      && !isFirstPreservedRange
      && !isLastPreservedRange
      && distanceToCurrent > 1) {
      return (
        <li
          className="dots truncated"
          key={page}
        >
          ...
        </li>
      )
    }

    return (
      <PaginationPage
        {...configs}
        {...props}
        key={page}
        onClick={(e) => handleClick(e, page)}
      />
    )
  }

  const renderPages = () => {
    const pageArray = Array.from(Array(totalPage).keys())

    return pageArray.map((page) => handlePage(page))
  }

  if (totalPage === 0) {
    return null
  }

  return (
    <div className={ClassNames(classes.container, className)}>
      <ul>
        <PaginationPrevNext
          {...props}
          page={currentPage - 1}
          icon="PaginationFirst"
          classes={classes}
          disabled={(currentPage - 1) <= 0}
          onClick={(e) => handleClick(e, currentPage - 1)}
        />
        {renderPages()}
        <PaginationPrevNext
          {...props}
          page={currentPage + 1}
          icon="PaginationLast"
          classes={classes}
          disabled={(currentPage + 1) > totalPage}
          onClick={(e) => handleClick(e, currentPage + 1)}
        />
      </ul>
    </div>
  )
}

Pagination.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  route: PropTypes.string,
  routeBase: PropTypes.string,
  routeParams: PropTypes.any, // eslint-disable-line
  className: PropTypes.string,
  onClick: PropTypes.func,
}

Pagination.defaultProps = {
  classes: null,
  currentPage: 0,
  totalPage: 0,
  route: '',
  routeBase: '',
  routeParams: {},
  className: '',
  onClick: () => undefined,
}

export default withMemo()(Pagination)
