export const relationsPrefix = 'relationsResults'

export const relationsFiltersConfig = (t, services, industry, building, trade) => [
  {
    isMulti: true,
    isPath: false,
    name: 'services',
    slug: 'services',
    prefix: 'filterServices',
    options: services?.domains?.map((s) => ({
      label: s.name,
      value: s.id,
      name: s.id,
      id: s.id,
    })),
  },
  {
    isMulti: true,
    isPath: false,
    name: 'industry',
    slug: 'industrie',
    prefix: 'filterIndustry',
    options: industry?.domains?.map((s) => ({
      label: s.name,
      value: s.id,
      name: s.id,
      id: s.id,
    })),
  },
  {
    isMulti: true,
    isPath: false,
    name: 'building',
    slug: 'contruction',
    prefix: 'filterBuilding',
    options: building?.domains?.map((s) => ({
      label: s.name,
      value: s.id,
      name: s.id,
      id: s.id,
    })),
  },
  {
    isMulti: true,
    isPath: false,
    name: 'trade',
    slug: 'commerce',
    prefix: 'filterTrade',
    options: trade?.domains?.map((s) => ({
      label: s.name,
      value: s.id,
      name: s.id,
      id: s.id,
    })),
  },
]
