import cx from 'classnames'
import MarkdownIt from 'markdown-it'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'

import styles from './styles'
import { configure } from './utils'


const useStyles = createUseStyles(styles)

const MarkdownText = (props) => {
  const classes = useStyles(props)
  const { className, children, text, tag, inline, autoBlankExternalLinks, rendererProps, ...otherProps } = props

  const md = useMemo(() => configure(
    new MarkdownIt({
      breaks: true,
      html: true,
      ...rendererProps,
    }), { autoBlankExternalLinks }
  ), [rendererProps, autoBlankExternalLinks])

  let markdownText = text || children

  // Hack to prevent html and <br> to mess up the renderer
  markdownText = markdownText?.replace(/\n\n<br>/g, '<br><br>')

  const html = markdownText && (inline ? md.renderInline(markdownText) : md.render(markdownText))
  const Tag = tag || 'div'

  return (
    <Tag
      {...otherProps}
      className={cx(classes.container, className)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

MarkdownText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  text: PropTypes.string,
  tag: PropTypes.elementType,
  inline: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  rendererProps: PropTypes.object,
  autoBlankExternalLinks: PropTypes.bool,
}

MarkdownText.defaultProps = {
  className: null,
  children: null,
  text: '',
  tag: 'div',
  inline: false,
  rendererProps: null,
  autoBlankExternalLinks: true,
}

export default withMemo()(MarkdownText)
