import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import CloudinaryImage from '../CloudinaryImage'
import A from '../A'
import Map from '../Map'
import ActionButton from '../ActionButton'
import Icon from '../Icon'
import { colors } from '../../theme'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const defaultLogo = 'defaults/logo'
const useStyles = createUseStyles(styles)

const OfferDetailsSidebar = (props) => {
  const classes = useStyles(props)
  const {
    className, title, logo, map, address, website, websiteLabel,
    socialIconsLabel, link, route, routeParams, links,
  } = props

  const fullAddress = address ? `${address.street}<br />${address.zip} ${address.city}` : null

  const renderCompanySocialIcons = () => links.map((element) => {
    const { id, icon } = element

    return (
      <Icon
        key={id}
        icon={icon}
        link={element.link}
        color={colors.pureWhite}
        className={classes.socialIcon}
      />
    )
  })

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.logo}>
        <A
          href={link}
          route={route}
          routeParams={routeParams}
          title={title}
        >
          <CloudinaryImage
            id={logo || defaultLogo}
            options={{
              width: '200',
              height: '140',
              crop: 'lpad',
            }}
            lazyLoad
          />
        </A>
      </div>
      <div className={classes.title}>{title}</div>
      <div className={classes.details}>
        <Map
          {...map}
          className={classes.map}
        />
        {!!address && (
          <div className={classes.address}>
            <div className={classes.addressTitle}>{address.title}</div>
            <A
              href={address.link}
              target="_blank"
            >
              <div
                className={classes.fullAddress}
                dangerouslySetInnerHTML={{ __html: fullAddress }}
              />
            </A>
          </div>
        )}
        {((!!links && links.length >= 1) || (!!website && !!websiteLabel)) && (
          <div className={classes.social}>
            <div className={classes.socialTitle}>{socialIconsLabel}</div>
            <div className={classes.socialIcons}>
              {renderCompanySocialIcons()}
            </div>
            {!!website && !!websiteLabel && (
              <div className={classes.website}>
                <ActionButton
                  label={websiteLabel}
                  link={website}
                  color="white"
                  isOutlined
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

OfferDetailsSidebar.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  socialIconsLabel: PropTypes.string,
  websiteLabel: PropTypes.string,
  logo: PropTypes.string,
  map: PropTypes.shape(Map.propTypes),
  link: PropTypes.string,
  address: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    street: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
  }),
  links: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.string,
  })),
  website: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
}

OfferDetailsSidebar.defaultProps = {
  className: '',
  socialIconsLabel: '',
  websiteLabel: '',
  logo: '',
  link: '',
  website: '',
  links: [],
  address: null,
  map: null,
  route: null,
  routeParams: null,
}

export default withMemo()(OfferDetailsSidebar)
