import { colors, fonts, transition } from '../../theme'


export default {

  // LineBar states

  lineBarClosed: {
    content: '\'\'',
    width: 0,
    height: '.2rem',
    backgroundColor: colors.tangerine,
    marginTop: '.6rem',
    ...transition('width'),
    borderRadius: '10rem',
  },
  lineBarOpened: {
    content: '\'\'',
    width: '1.5rem',
    height: '.2rem',
    backgroundColor: colors.tangerine,
    marginTop: '.6rem',
    borderRadius: '10rem',
  },

  lineBarMobileClosed: {
    position: 'absolute',
    left: '2.2rem',
    content: '\'\'',
    height: 0,
    width: '.2rem',
    backgroundColor: colors.tangerine,
    ...transition('height'),
    borderRadius: '10rem',
  },
  lineBarMobileOpened: {
    position: 'absolute',
    left: '3.5rem',
    content: '\'\'',
    height: '2.4rem',
    width: '.2rem',
    backgroundColor: colors.tangerine,
    borderRadius: '10rem',
  },

  lineBarDarkClosed: {
    content: '\'\'',
    width: 0,
    height: '.3rem',
    backgroundColor: colors.pureWhite,
    marginTop: '.3rem',
    ...transition('width'),
    borderRadius: 0,
  },
  lineBarDarkOpened: {
    content: '\'\'',
    width: 'calc(100% + .6rem)',
    height: '.3rem',
    backgroundColor: colors.pureWhite,
    marginTop: '.3rem',
    borderRadius: 0,
  },

  // Classes

  container: {
    padding: '0 2rem',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1rem',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: colors.grey,
    textAlign: 'center',
    ...transition('color'),
    '&:after': {
      extend: 'lineBarClosed',
    },
    '&:hover': {
      color: colors.darkBlue,
      '&:after': {
        extend: 'lineBarOpened',
      },
    },
  },

  dark: {
    padding: '0 1rem',
    textTransform: 'uppercase',
    ...fonts.FiraSansBold,
    fontSize: '1rem',
    lineHeight: '1rem',
    color: colors.pureWhite,
    opacity: 0.7,
    ...transition('opacity'),
    '&:after': {
      extend: 'lineBarDarkClosed',
    },
    '&:hover': {
      color: colors.pureWhite,
      opacity: 1,
      '&:after': {
        extend: 'lineBarDarkOpened',
      },
    },
  },
  isMenuMobile: {
    position: 'relative',
    padding: '.8rem 5rem',
    color: colors.nightRider,
    fontSize: '1.5rem',
    lineHeight: '2.2rem',
    textAlign: 'left',
    flexDirection: 'row',
    width: '100%',
    ...transition('padding-left'),
    '&:after': {
      display: 'none',
    },
    '&:before': {
      extend: 'lineBarMobileClosed',
    },
    '&:hover': {
      color: colors.darkBlue,
      paddingLeft: 'rem',
      '&:before': {
        extend: 'lineBarMobileOpened',
      },
    },
  },
  selected: {
    color: colors.darkBlue,
    '&:after': {
      extend: 'lineBarOpened',
    },
    '&$dark': {
      opacity: 1,
      '&:after': {
        extend: 'lineBarDarkOpened',
      },
    },
    '&$isMenuMobile': {
      color: colors.darkBlue,
      paddingLeft: '5rem',
      '&:before': {
        extend: 'lineBarMobileOpened',
      },
    },
  },

}
