import { useCallback, useEffect } from 'react'
import cx from 'classnames'

import withMemo from '../../../../decorators/WithMemo'
import { renderComponent } from '../../../../helpers/FormHelpers'

// eslint-disable-next-line complexity
export const Field = withMemo()((props) => {
  const {
    fieldConfig,
    fieldsProps,
    formikProps,
    renderer = renderComponent,
    className,
    ...fieldProps
  } = props
  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    submitCount,
    setFieldValue,
  } = formikProps

  const fieldsPropsOnchange = fieldsProps && fieldConfig && fieldsProps[fieldConfig.name]
    && fieldsProps[fieldConfig.name].onChange
  const fieldConfigOnChange = fieldConfig && fieldConfig.onChange
  const fieldPropsOnChange = fieldProps && fieldProps.onChange
  const mask = fieldConfig && fieldConfig.mask

  const onChange = useCallback(
    ({ name, value }) => {
      if (fieldsPropsOnchange) {
        fieldsPropsOnchange({ name, value })
      }
      if (fieldConfigOnChange) {
        fieldConfigOnChange({ name, value })
      }
      if (fieldPropsOnChange) {
        fieldPropsOnChange({ name, value })
      }
      setFieldValue(name, mask ? mask(value) : value)
    },
    [fieldsPropsOnchange, fieldConfigOnChange, fieldPropsOnChange, setFieldValue, mask]
  )

  const onBlur = useCallback(({ name }) => handleBlur(name), [handleBlur])

  const error = ((touched[fieldConfig.name] || isSubmitting || submitCount > 0) && errors[fieldConfig.name])
    ? errors[fieldConfig.name] : ''

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-unused-expressions
      window?.scrollTo?.({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [error])

  const val = fieldConfig && values[fieldConfig.name]

  if (!fieldConfig) {
    return null
  }

  return renderer({
    ...fieldConfig.props,
    ...(fieldsProps && fieldsProps[fieldConfig.name]),
    ...fieldProps,
    className: cx(
      fieldsProps && fieldsProps[fieldConfig.name]?.className,
      fieldConfig.props?.className,
      className
    ),
    disabled: fieldConfig?.disabled,
    name: fieldConfig.name,
    required: fieldConfig.validation && fieldConfig.validation.indexOf('required') !== -1,
    value: val,
    onChange,
    onBlur,
    error:
      (touched[fieldConfig.name] || isSubmitting || submitCount > 0) && errors[fieldConfig.name]
        ? errors[fieldConfig.name]
        : '',
  }, fieldConfig.component)
})

export default withMemo()(Field)
