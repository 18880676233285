/* eslint-disable max-lines */
import { colors, fonts } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    background: colors.pureWhite,
    borderRadius: '2rem',
    padding: '2.5rem 3rem',
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: (props) => (props.title ? 'space-between' : 'flex-end'),
    marginBottom: '1rem',
  },
  close: {
    marginRight: '-.5rem',
    maxWidth: '2.4rem',
    maxHeight: '2.4rem',
  },
  icon: {
    cursor: 'pointer',
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    textTransform: 'uppercase',
    color: colors.nightRider,
  },
}
