import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import Header from '../Header'
import Breadcrumb from '../Breadcrumb'
import Footer from '../Footer'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const PageWrapper = (props) => {
  const classes = useStyles(props)
  const { className, header, disableBreadcrumbs, breadcrumbs, children, footer } = props

  return (
    <div className={cx(classes.container, className)}>
      <Header {...header} />
      {!disableBreadcrumbs && <Breadcrumb {...breadcrumbs} />}
      {children}
      <Footer {...footer} />
    </div>
  )
}

PageWrapper.propTypes = {
  className: PropTypes.string,
  disableBreadcrumbs: PropTypes.bool,
  header: PropTypes.shape(Header.propTypes).isRequired,
  breadcrumbs: PropTypes.shape(Breadcrumb.propTypes),
  footer: PropTypes.shape(Footer.propTypes).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.any)]),
}

PageWrapper.defaultProps = {
  className: '',
  disableBreadcrumbs: false,
  breadcrumbs: null,
  children: [],
}

export default withMemo()(PageWrapper)
