import { fonts, colors } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  wrapper: {
    textDecoration: 'none',
  },
  container: {
    maxWidth: '14rem',
    margin: '0 1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderBottom: ['.1rem', 'solid', colors.gainsboro],
    paddingBottom: '.6rem',
  },
  logo: {
    width: '14rem',
    height: '8rem',
    background: colors.pureWhite,
    borderRadius: '2rem',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '.7rem',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    },
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.1rem',
    lineHeight: '1.3rem',
    color: colors.darkBlue,
    textTransform: 'uppercase',
    marginBottom: '1.7rem',
    textAlign: 'center',
  },
  location: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    color: colors.grey,
    marginBottom: '.7rem',
  },
  offers: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1rem',
    lineHeight: '1.3rem',
    color: colors.darkBlue,
  },
}
