import { fontWeights } from '../../utils/FontUtils'
import { colors, desktop, fonts, tablet } from '../../theme'


export default {
  container: {},
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3rem',
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2rem',
    color: colors.gray2,
    lineHeight: '2.4rem',
  },
  buttons: {
    display: 'flex',
    gap: '1rem',
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    ...tablet({
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
    }),
    ...desktop({
      gridTemplateColumns: 'repeat(3, 1fr)',
    }),
  },
}
