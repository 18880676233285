/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import Resumable from 'resumablejs'
import cx from 'classnames'

import FormLabel from '../../FormLabel'
import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import ActionButton from '../../../ActionButton'
import CloudinaryImage from '../../../CloudinaryImage'
import MarkdownText from '../../../MarkdownText'
import withMemo from '../../../../decorators/WithMemo'
import { mergeStyles } from '../../../../utils/StyleUtils'
import { formatSize } from '../../../../helpers/NumberHelpers'

import styles from './styles'


const useStyles = createUseStyles(styles)

const UploadImage = (props) => {
  const classesComp = useStyles(props)
  const classes = mergeStyles(classesComp, props.classes)
  const {
    onStateChange,
    onError,
    config: {
      minWidth = UploadImage.defaultProps.config.minWidth,
      maxWidth = UploadImage.defaultProps.config.maxWidth,
      minHeight = UploadImage.defaultProps.config.minHeight,
      maxHeight = UploadImage.defaultProps.config.maxHeight,
      minFileSize,
      maxFileSize = UploadImage.defaultProps.config.maxFileSize,
      token,
      accept,
      endpoint,
      chunkSize,
      ...conf
    },
    name,
    onChange,
    className,
    label,
    browseLabel,
    legend,
    help,
    error,
    id,
    required,
    value,
    placeholderImage,
    cloudinaryConfig,
    errorMessages,
  } = props

  const [thisId] = useState('uploadImage')

  const [progress, setProgress] = useState(0)
  const [file, setFile] = useState(value)
  const dropNode = useRef(null)
  const buttonNode = useRef(null)
  const imageNode = useRef(null)

  const [localError, setLocalError] = useState()
  const localErrorText = useMemo(() => (localError
    ? errorMessages[localError?.type]?.replace('{{maxFileSize}}', formatSize(maxFileSize))
    : null), [localError, errorMessages, maxFileSize])

  const updateValue = useCallback(
    (f) => {
      onChange({ name, value: { id: f?.id || file?.id, path: f?.path || file?.path } })
    },
    [file, name, onChange]
  )

  useEffect(() => {
    setFile(value)
  }, [value])

  useEffect(() => {
    let mounted = true
    const setError = (type, data) => {
      setLocalError({ type, data })
      onError({ type, data })
    }

    const minFileSizeErrorCallback = (f) => {
      setError(UploadImage.errorTypes.MIN_FILE_SIZE_ERROR)
      setError(UploadImage.errorTypes.MIN_FILE_SIZE_ERROR, {
        size: f.size,
        minFileSize,
      })
    }

    const maxFileSizeErrorCallback = (f) => {
      console.log('max filesize error', f)
      setError(UploadImage.errorTypes.MAX_FILE_SIZE_ERROR, {
        size: f.size,
        maxFileSize,
      })
    }

    const maxFilesErrorCallback = () => {

    }

    const res = new Resumable({
      ...token && { headers: { Authorization: `Bearer ${token}` } },
      target: endpoint,
      chunkSize,
      method: 'POST',
      fileType: accept,
      maxFileSize,
      minFileSize,
      minFileSizeErrorCallback,
      maxFileSizeErrorCallback,
      maxFilesErrorCallback,
      maxFiles: 1,
      ...conf,
    })

    //
    res.assignBrowse(imageNode.current)
    res.assignBrowse(buttonNode.current)
    res.assignDrop(dropNode.current)

    //
    res.on('fileAdded', (f, event) => {
      onStateChange(UploadImage.states.IN_PROGRESS)
      setFile(f)
      setProgress(0)

      res.upload()
    })

    res.on('fileSuccess', (f, event) => {
      let result

      try {
        result = JSON.parse(event)
      } catch (e) {
        result = {}
      }
      setError(null)

      const path = URL.createObjectURL(f.file)
      const img = new Image()

      img.onload = () => {
        if (mounted) {
          if (img.width < minWidth
            || img.width > maxWidth
            || img.height < minHeight
            || img.height > maxHeight) {
            setError(UploadImage.errorTypes.IMAGE_SIZE_ERROR, img)
          } else {
            setFile({ ...f, id: result.file, path })
            updateValue({ ...f, id: result.file, path })
          }
        }
      }
      img.src = path
    })

    res.on('fileError', (f, event) => {
      setError(UploadImage.errorTypes.FILE_ERROR, {
        file: f,
        event,
      })
    })

    res.on('fileProgress', (f, event) => {
    })

    res.on('progress', () => {
      setProgress(res.progress())
    })

    res.on('complete', () => {
      onStateChange(UploadImage.states.COMPLETE)
    })

    if (value) {
      setFile(value)
    }

    return () => {
      mounted = false
      if (res.isUploading()) {
        res.cancel()
      }
    }
    // eslint-disable-next-line
  }, [])


  const imagePath = (file && file.path) || value?.path || placeholderImage

  return (
    <div
      ref={dropNode}
      className={cx(classes.container, className)}
    >
      {label !== '' && (
        <label
          className={classes.wrapper}
          htmlFor={id || thisId}
        >
          <FormLabel
            className={classes.label}
            required={required}
            error={!!error}
          >
            {label}
          </FormLabel>
        </label>
      )}

      <div className={classes.wrapper}>

        <div
          className={classes.imageContainer}
          ref={imageNode}
        >
          {imagePath && (
            <CloudinaryImage
              id={imagePath}
              className={classes.image}
              options={{
                width: '300',
                height: '210',
                ...cloudinaryConfig,
              }}
              lazyLoad
            />
          )}
          <div
            className={classes.progress}
            style={{ transform: `scaleX(${progress})` }}
          />
        </div>

        <div className={classes.sideContainer}>
          <MarkdownText
            className={classes.legend}
            inline
            text={legend}
          />
          <div
            ref={buttonNode}
            className={classes.browse}
          >
            <ActionButton
              isOutlined
              color="primary"
              className={classes.browseButton}
              label={browseLabel}
            />
          </div>
        </div>
      </div>

      <FormErrorText
        className={classes.errorText}
        text={localErrorText}
      />
      <FormErrorText
        className={classes.errorText}
        text={error}
      />
      <FormHelpText
        className={classes.helpText}
        text={help}
        error={!!error}
      />

    </div>
  )
}

UploadImage.errorTypes = {
  MIN_FILE_SIZE_ERROR: 'min_file_size_error',
  MAX_FILE_SIZE_ERROR: 'max_file_size_error',
  IMAGE_SIZE_ERROR: 'image_size_error',
  FILE_ERROR: 'file_error',
}

UploadImage.states = {
  EMPTY: 'empty',
  COMPLETE: 'complete',
  IN_PROGRESS: 'in_progress',
  ERROR: 'error',
}

UploadImage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  browseLabel: PropTypes.string,
  legend: PropTypes.string,
  help: PropTypes.string,
  error: PropTypes.string,
  placeholderImage: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.string,
    path: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onStateChange: PropTypes.func,
  required: PropTypes.bool,
  config: PropTypes.shape({
    token: PropTypes.string,
    endpoint: PropTypes.string.isRequired,
    assetPath: PropTypes.string,
    chunkSize: PropTypes.number,
    accept: PropTypes.array,
    minFileSize: PropTypes.number,
    maxFileSize: PropTypes.number,
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    minHeight: PropTypes.number,
    maxHeight: PropTypes.number,
  }),
  errorMessages: PropTypes.objectOf(PropTypes.string),
  cloudinaryConfig: PropTypes.object,
}

UploadImage.defaultValue = null

UploadImage.defaultProps = {
  classes: {},
  className: null,
  id: null,
  browseLabel: null,
  label: null,
  legend: null,
  help: null,
  error: null,
  placeholderImage: null,
  value: null,
  onChange: () => undefined,
  onError: () => undefined,
  onStateChange: () => undefined,
  required: false,
  config: {
    token: null,
    chunkSize: 1024 * 512,
    accept: ['jpg', 'png', 'gif'],
    minFileSize: 0,
    maxFileSize: 5 * 1024 * 1024,
    minWidth: 0,
    maxWidth: Number.MAX_SAFE_INTEGER,
    minHeight: 0,
    maxHeight: Number.MAX_SAFE_INTEGER,
  },
  errorMessages: {
    [UploadImage.errorTypes.MAX_FILE_SIZE_ERROR]: 'Le fichier est trop volumineux, veuillez respecter la limite de {{maxFileSize}}.',
  },
  cloudinaryConfig: {
    crop: 'fill',
    gravity: 'face',
  },
}

export default withMemo()(UploadImage)
