import { colors, desktop, fonts, sizes, tablet } from '../../theme'


export default ({
  container: {
    position: 'relative',
    width: '100%',
    background: colors.honeydew,
    padding: '2rem',
    margin: '3rem 0 4rem 0',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '2rem',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '2rem',
    lineHeight: '2.4rem',
    color: colors.nightRider,
    width: '100%',
    textAlign: 'left',
  },
  buttonBox: {
    width: '100%',
    display: 'flex',
  },
  submenu: {
    width: '100%',
    padding: '0.5rem 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    maxWidth: sizes.desktop,
    flexWrap: 'wrap',
  },
  filter: {
    width: '100%',
    ...tablet({
      width: '50%',
    }),
    ...desktop({
      width: 'auto',
      flex: 1,
    }),
  },
})
