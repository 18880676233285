import { fonts, colors } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '30rem',
    borderRadius: '2rem',
    background: colors.honeydew,
    padding: '2.7rem 4rem 4.3rem',
  },
  title: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    textAlign: 'left',
    color: colors.darkBlue,
    marginBottom: '2.5rem',
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  link: {
    width: '100%',
    '&:not(:last-child)': {
      marginBottom: '1.5rem',
    },
  },
}
