import { fonts, colors, tablet } from '../../../../theme'


export default {
  container: {},
  label: {
    marginBottom: '1rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    ...tablet({
      flexDirection: 'row',
      justifyContent: 'left',
    }),
  },
  imageContainer: {
    border: `1px solid ${colors.gainsboro}`,
    borderColor: ({ error }) => (error ? colors.error : colors.gainsboro),
    position: 'relative',
    width: '100%',
    height: '21rem',
    maxWidth: '30rem',
    flexShrink: 0,
  },
  image: {
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  progress: {
    left: 0,
    bottom: 0,
    right: 0,
    height: 2,
    zIndex: 0,
    position: 'absolute',
    transform: 'scaleX(0)',
    transformOrigin: 'left',
    transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    pointerEvents: 'none',
    backgroundColor: colors.darkBlue,
    opacity: 1,
  },
  sideContainer: {
    padding: '1rem 0',
    ...tablet({
      padding: '0 1rem',
      paddingLeft: '3rem',
    }),
  },
  legend: {
    color: colors.nightRider,
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    marginBottom: '2rem',
  },
  browse: {
    display: 'inline-flex',
  },
  browseButton: {},
}
