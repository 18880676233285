import Color from 'color'

import { fonts, colors, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    borderRadius: '1rem',
    overflow: 'hidden',
    width: '100%',
  },
  header: {
    marginBottom: '.2rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '9rem',
  },
  tab: {
    padding: '1.4rem 1rem 1rem',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: colors.honeydew,
    height: '100%',
    cursor: 'pointer',
    userSelect: 'none',
    color: colors.secondary,
    '&:not(:first-child)': {
      marginLeft: '.1rem',
    },
    ...tablet({
      alignItems: 'flex-start',
      padding: '1.4rem 2.7rem',
    }),
    '&:not($active):hover': {
      background: Color(colors.secondary).fade(0.9).string(),
    },
  },
  title: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    ...tablet({
      textAlign: 'left',
    }),
  },
  amount: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.7rem',
    lineHeight: '2.2rem',
    marginTop: '.4rem',
    opacity: '.5',
  },
  disabled: {
    opacity: '0.7',
    cursor: 'default',
    color: colors.grey,
  },
  active: {
    background: colors.secondary,
    color: colors.pureWhite,
    ...tablet({
      padding: '1.4rem 2.7rem',
    }),
    '& $title': {
      color: colors.pureWhite,
    },
  },
  content: {
    //
  },
}
