import React, { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'

import { hasParent } from '../../utils/DomUtils'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Modal = (props) => {
  const classes = { ...useStyles(props), ...props.classes }
  const {
    isOpen,
    shouldLockBodyScroll,
    onOpen,
    onClose,
    onRequestClose,
    shouldCloseOnEsc,
    children,
    shouldCloseOnClick,
  } = props

  const content = useRef(null)
  const [previousOverflow, setPreviousOverflow] = useState('visible')

  const handleKeyUp = useCallback(
    (e) => {
      if (shouldCloseOnEsc && e.keyCode === 27) {
        onRequestClose()
      }
    },
    [onRequestClose, shouldCloseOnEsc]
  )

  const handleOpen = useCallback(() => {
    if (shouldLockBodyScroll) {
      document.querySelector('body').style.overflow = 'hidden'
    }
    document.addEventListener('keyup', handleKeyUp)
    onOpen()
  }, [handleKeyUp, onOpen, shouldLockBodyScroll])

  const handleClose = useCallback(() => {
    if (shouldLockBodyScroll) {
      document.querySelector('body').style.overflow = previousOverflow || 'visible'
    }
    document.removeEventListener('keyup', handleKeyUp)
    onClose()
  }, [shouldLockBodyScroll, handleKeyUp, onClose, previousOverflow])

  const handleClick = (e) => {
    if (
      shouldCloseOnClick
    && (e.target === content.current || !hasParent(e.target, content.current))
    ) {
      onRequestClose()
    }
  }

  useEffect(() => {
    setPreviousOverflow(document.querySelector('body').style.overflow)
    if (isOpen) {
      handleOpen()
    }
    return () => {
      handleClose()
    }
  }, [handleClose, handleOpen, isOpen])

  useEffect(() => {
    if (isOpen) {
      handleOpen()
    } else {
      handleClose()
    }
  }, [handleClose, handleOpen, isOpen])

  if (!isOpen) {
    return null
  }

  return (
    <div
      className={cx(classes.container)}
      onClick={handleClick}
      role="presentation"
    >
      <div className={cx(classes.background)} />
      <div className={cx(classes.wrapper)}>
        <div
          className={cx(classes.content)}
          ref={content}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  isOpen: PropTypes.bool,
  shouldLockBodyScroll: PropTypes.bool,
  shouldCloseOnClick: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

Modal.defaultProps = {
  classes: {},
  isOpen: false,
  shouldLockBodyScroll: true,
  shouldCloseOnClick: true,
  shouldCloseOnEsc: true,
  onRequestClose: () => null,
  onOpen: () => null,
  onClose: () => null,
  children: null,
}
export default withMemo()(Modal)
