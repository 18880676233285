import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'

import ActionButton from '../../ActionButton'
import withMemo from '../../../decorators/WithMemo'
import { iconsKeys } from '../../Icon/Icon.assets'
import { colors } from '../../../theme'

import styles from './styles'


const useStyles = createUseStyles(styles)
const Submit = (props) => {
  const {
    className, label, successLabel,
    disabledLabel, isSuccess, isDisabled, isPending, pendingLabel,
    onClick,
    ...otherProps
  } = props
  const classes = useStyles(props)

  const labelText = isPending && pendingLabel ? pendingLabel
    : isSuccess && successLabel ? successLabel
      : isDisabled && disabledLabel ? disabledLabel
        : label

  return (
    <div
      className={cx(
        classes.container,
        className
      )}
    >
      {/* eslint-disable-next-line react/button-has-type */}
      <button
        type={(isDisabled || onClick) ? 'button' : 'submit'}
        {...(!isDisabled && !!onClick && { onClick })}
      >
        <ActionButton
          color="primary"
          isOutlined
          label={labelText}
          className={classes.button}
          isDisabled={isDisabled}
          isSuccess={isSuccess || isPending}
          icon={isPending ? iconsKeys.Refresh : isSuccess ? iconsKeys.Check : null}
          colorIcon={colors.primary}
          {...otherProps}
        />
      </button>
    </div>
  )
}


Submit.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  successLabel: PropTypes.string,
  disabledLabel: PropTypes.string,
  pendingLabel: PropTypes.string,
  isSuccess: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isPending: PropTypes.bool,
  onClick: PropTypes.func,
}

Submit.defaultProps = {
  className: '',
  label: '',
  successLabel: '',
  disabledLabel: '',
  pendingLabel: '',
  isSuccess: false,
  isDisabled: false,
  isPending: false,
  onClick: null,
}

export default withMemo()(Submit)
