import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import ReactSwitch from 'react-switch'

import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import { generateUniqueId } from '../../../../utils/ComponentUtils'
import { colors } from '../../../../theme'
import withMemo from '../../../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function Switch(props) {
  const {
    className,
    id,
    name,
    value,
    label,
    help,
    required,
    disabled,
    error,
    readOnly,
    inputProps,
    onChange,
    onBlur,
    onFocus,
  } = props

  const [thisId] = useState(`form_checkbox_${generateUniqueId()}`)
  const [focused, setFocused] = useState(false)

  const handleChange = (checked) => {
    onChange({ name, value: checked || undefined })
  }

  const handleFocus = (event) => {
    setFocused(true)
    if (inputProps && inputProps.onFocus) {
      inputProps.onFocus(event)
    }
    onFocus({ name, event })
  }

  const handleBlur = (event) => {
    setFocused(false)
    if (inputProps && inputProps.onBlur) {
      inputProps.onBlur(event)
    }
    onBlur({ name, event })
  }

  const classes = { ...useStyles({ ...props, focused }), ...props.classes }

  return (
    <div
      className={cx(
        classes.container,
        className
      )}
    >
      <label
        htmlFor={id || thisId}
        className={classes.label}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {label && <span className={classes.text}>{label}</span>}
        <ReactSwitch
          onChange={handleChange}
          checked={!!value}
          disabled={disabled || readOnly}
          aria-label={label}
          onColor={colors.darkBlue}
          uncheckedIcon={false}
          checkedIcon={false}
          id={id || thisId}
          className={classes.input}
          required={required}
        />
      </label>
      <FormErrorText
        className={classes.errorText}
        text={error}
      />
      <FormHelpText
        className={classes.helpText}
        text={help}
        error={!!error}
      />
    </div>
  )
}

Switch.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.string,
  help: PropTypes.string,
  id: PropTypes.string,
}

Switch.defaultProps = {
  classes: {},
  className: null,
  label: null,
  value: false,
  required: false,
  disabled: false,
  readOnly: false,
  inputProps: null,
  onChange: () => null,
  onBlur: () => null,
  onFocus: () => null,
  error: null,
  help: null,
  id: null,
}

Switch.defaultValue = false


export default withMemo()(Switch)
