import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import A from '../A'
import OfferType from '../OfferType'
import OfferSource from '../OfferSource'
import Sharing from '../Sharing'
import Paragraph from '../Paragraph'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


export const offerPropTypes = {
  companyName: PropTypes.string.isRequired,
  type: PropTypes.shape(OfferType.propTypes).isRequired,
  source: PropTypes.shape(OfferSource.propTypes).isRequired,
  title: PropTypes.string.isRequired,
  requirements: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  skillsTitle: PropTypes.string,
  pertinence: PropTypes.number,
  pertinenceLabel: PropTypes.string,
  companyLink: PropTypes.string,
  companyRoute: PropTypes.string,
  companyRouteParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  skills: PropTypes.arrayOf(PropTypes.string).isRequired,
  descriptionTitle: PropTypes.string,
  description: PropTypes.string,
}

const useStyles = createUseStyles(styles)

const OfferDetailsMain = (props) => {
  const classes = useStyles(props)
  const { className, offer, sharing, isConnected } = props
  const {
    companyName, type, source, title, requirements, skills, skillsTitle,
    description, descriptionTitle, companyLink, companyRoute, companyRouteParams, pertinence, pertinenceLabel,
  } = offer

  const renderHeader = () => (
    <div className={classes.header}>
      <div className={classes.headerData}>
        <div className={classes.tags}>
          <OfferType
            {...type}
            className={classes.type}
          />
          <OfferSource {...source} />
        </div>
        <h1 className={classes.title}>{title}</h1>
        <A
          href={companyLink}
          route={companyRoute}
          routeParams={companyRouteParams}
          title={companyName}
        >
          <div className={classes.companyName}>{companyName}</div>
        </A>
      </div>
      <div className={classes.sharing}>
        <Sharing {...sharing} />
      </div>
    </div>
  )

  const renderRequirements = () => requirements.map((requirement, index) => {
    const { label, value } = requirement

    if (!label || !value) {
      return null
    }

    return (
      <div
        className={classes.requirement}
        key={`requirements-${index}`}
      >
        <div className={classes.requirementLabel}>{label}</div>
        <div className={classes.requirementValue}>{value}</div>
      </div>
    )
  })


  return (
    <div className={cx(classes.container, className)}>
      {renderHeader()}
      <div className={classes.requirements}>
        {renderRequirements()}
      </div>
      {isConnected && pertinence > 0 && (
        <div className={classes.pertinence}>
          <div className={classes.pertinenceLabel}>{pertinenceLabel}</div>
          <div className={classes.pertinenceValue}>
            {`${pertinence}%`}
          </div>
        </div>
      )}
      {skills && skills.length > 0
      && (
        <div className={classes.skills}>
          <h2 className={classes.skillsTitle}>{skillsTitle}</h2>
          <div className={classes.skillsWrapper} />
        </div>
      )}
      {isConnected && description && (
        <div className={classes.description}>
          <h2 className={classes.descriptionTitle}>{descriptionTitle}</h2>
          <Paragraph
            text={description}
            className={classes.descriptionContent}
            classNames={{ parentContainer: classes.descriptionWrapper }}
          />
        </div>
      )}
    </div>
  )
}

OfferDetailsMain.propTypes = {
  className: PropTypes.string,
  offer: PropTypes.shape(offerPropTypes),
  sharing: PropTypes.shape(Sharing.propTypes),
  isConnected: PropTypes.bool,
}

OfferDetailsMain.defaultProps = {
  className: '',
  offer: null,
  sharing: null,
  isConnected: false,
}

export default withMemo()(OfferDetailsMain)
