import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'


export const RefPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.any }),
])

/**
 * Memoizes a simple empty object
 * @returns {*}
 */
export function useDictionary() {
  return useMemo(() => ({}), [])
}

/**
 * Simpled usage for case with const a = useMemo(() => b(x), [x])
 * @param fn
 * @param args
 * @returns {*}
 */
export function useSimpleMemo(fn, ...args) {
  return useMemo(() => fn(...args), [args, fn])
}

/**
 * Calls the passed parameter only if type is function
 * @param fn
 * @param args
 */
export function safeCall(fn, ...args) {
  if (typeof fn === 'function') {
    return fn(...args)
  }
}

/**
 * Safe calls all the functions in passed array with specified arguments
 * @param arr
 * @param args
 */
export function safeCallArray(arr, ...args) {
  if (Array.isArray(arr)) {
    arr.forEach((fn) => safeCall(fn, ...args))
  }
}

/**
 * Memoizes a safe calls the passed function with the passed arguments if present, if not, calls it
 * with the callback arguments
 * @param fn
 * @param args
 * @returns {function(...[*]): void}
 */
export function useSafeCall(fn, ...args) {
  return useCallback(
    (...callbackArgs) => safeCall(fn, ...(args.length !== 0 ? args : callbackArgs)),
    [args, fn]
  )
}

/**
 * Allows to get the ref from Animated.X components for example, useful for Animated.ScrollView
 * see https://github.com/facebook/react-native/issues/19650
 * see https://stackoverflow.com/questions/42051368/scrollto-is-undefined-on-animated-scrollview/48786374
 * @param ref
 * @returns {ReactNativeView|ReactNativeText|ReactNativeImage|ReactNativeScrollView|*}
 */
export const extractNativeRef = (ref) => {
  if (ref.getNode) {
    return ref.getNode()
  }
  return ref
}

/**
 * check if current browser is a touch device
 * @returns {boolean}
 */
export function useIsTouchDevice() {
  const [state, setState] = useState(false)

  useEffect(() => {
    setState(
      'ontouchstart' in document.documentElement
        || navigator.maxTouchPoints > 0
        || navigator.msMaxTouchPoints > 0
    )
  }, [])
  return state
}
