import { colors, tablet, textStyles } from '../../../theme'


export default {
  container: {
    '&:after': {
      visibility: (props) => (props.required ? 'inherit' : 'hidden'),
      content: '" *"',
      color: colors.darkOrange,
    },
    color: colors.darkBlue,
    ...textStyles.Formlabel,
    width: '100%',
    ...tablet({
      paddingBottom: '1rem',
    }),
  },
}
