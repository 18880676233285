import React from 'react'
import { createUseStyles } from 'react-jss'

import Icon from '../../../Icon'
import { iconsKeys } from '../../../Icon/Icon.assets'
import colors from '../../../../theme/colors'
import Text from '../Text'
import withMemo from '../../../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Password = (props) => {
  const classes = useStyles(props)
  const { name, ...others } = props

  const [display, setDisplay] = React.useState(false)

  return (
    <Text
      {...others}
      name={name}
      type={display ? 'text' : 'password'}
      endOrnament={(
        <Icon
          color={colors.darkBlue}
          icon={display ? iconsKeys.EyeOff : iconsKeys.Eye}
          onClick={() => setDisplay(!display)}
          classname={classes.icon}
        />
      )}
    />
  )
}

Password.styles = styles

Password.propTypes = Text.propTypes

Password.defaultProps = Text.defaultProps

export default withMemo()(Password)
