import { fonts, tablet, sizes } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    padding: '3rem 2rem',
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2rem',
    lineHeight: '2.4rem',
    textTransform: 'uppercase',
    marginBottom: '2rem',
    ...tablet({
      fontSize: '2.4rem',
      lineHeight: '2.6rem',
    }),
  },
  skillsWrapper: {
    ...tablet({
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginBottom: '3rem',
    }),
  },
  skill: {
    marginTop: '-.1rem',
    ...tablet({
      width: 'calc(50% - 3rem)',
      display: 'inline-block',
    }),
  },
}
