const filtersSlugTypes = [
  'services',
  'industrie',
  'contruction',
  'commerce',
]

const getFilterSlugRightField = {
  services: 'services',
  industrie: 'industrie',
  contruction: 'contruction',
  commerce: 'commerce',
}

export const regexRelationsFilters = new RegExp(`(?:\\/(?:(?:(${filtersSlugTypes.join('|')})-([\\w\\d-]+))))`, 'g')

export const transformRelationsSlugFilters = (asPath) => {
  let matches
  const slugFilters = {}

  while ((matches = regexRelationsFilters.exec(asPath)) !== null) {
    const property = getFilterSlugRightField[matches[1]]

    if (!Object.prototype.hasOwnProperty.call(slugFilters, property)) {
      slugFilters[property] = matches[2]
    } else {
      if (!Array.isArray(slugFilters[property])) {
        slugFilters[property] = [slugFilters[property]]
      }
      slugFilters[property].push(matches[2])
    }
  }
  return slugFilters
}

export const getAllValue = (t) => t('filter_all')
