import { useEffect, useState } from 'react'


export const useIsPWA = () => {
  const [isPWA, setIsPWA] = useState(false)

  const handleSetIsPWA = (value) => {
    setIsPWA(value)
    if (value) {
      window.localStorage.setItem('isPWA', 'true')
    } else {
      window.localStorage.removeItem('isPWA')
    }
  }

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    // For iOS
    if ('standalone' in window.navigator && window.navigator.standalone) {
      handleSetIsPWA(true)
    }

    // For Android
    // Cannot use window.matchMedia('(display-mode: standalone)') because it's acting weird on Samsung Browser (Android)
    if (window.location.search.includes('mode=standalone')) {
      handleSetIsPWA(true)
    }

    // Check localStorage
    if (window.localStorage.getItem('isPWA') === 'true') {
      handleSetIsPWA(true)
    }

    // When app is installed
    window.addEventListener('appinstalled', () => {
      handleSetIsPWA(true)
    })
  }, [])

  return isPWA
}
