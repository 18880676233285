import { colors, fonts } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '30rem',
    padding: '2rem',
    overflow: 'hidden',
  },
  card: {
    borderRadius: '2rem',
    maxWidth: '28rem',
    background: colors.pureWhite,
    transition: 'all .2s ease',
    '&:hover': {
      boxShadow: '0px 5px 30px rgba(84, 84, 84, 0.2)',
      transform: 'translateY(-2px)',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.5rem',
    width: '100%',
  },
  date: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.semiBold,
    textTransform: 'uppercase',
    color: colors.darkBlue,
    fontSize: '1.6rem',
    lineHeight: '2.4rem',
    marginBottom: '0.75rem',
  },
  day: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.regular,
    textTransform: 'uppercase',
    color: colors.darkBlue,
    fontSize: '3rem',
    lineHeight: '2.4rem',
  },
  month: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.semiBold,
    textTransform: 'uppercase',
    color: colors.darkBlue,
    fontSize: '2rem',
    lineHeight: '2.4rem',
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: colors.gainsboro,
    borderRadius: 1,
    marginBottom: '1.5rem',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: '1.5rem',
  },
  type: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    color: colors.nightRider,
    borderRadius: '1.3rem',
    border: `1px solid ${colors.nightRider}`,
    padding: '0.5rem 1.3rem',
    alignSelf: 'flex-start',
    wordBreak: 'break-word',
  },
  logo: {
    height: '4rem',
    justifyContent: 'flex-end',
    width: '60%',
    '& > img': {
      width: '75%',
    },
  },
  title: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.semiBold,
    fontSize: '1.6rem',
    lineHeight: '2.2rem',
    color: colors.nightRider,
    marginTop: '0',
    marginBottom: '2rem',
    width: '100%',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  place: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    textTransform: 'uppercase',
    color: colors.darkBlue,
    fontWeight: fontWeights.bold,
    fontSize: '1.4rem',
    lineHeight: '2.2rem',
  },
  icon: {
    width: '1.5em',
    height: '1.2rem',
  },
  boxOgcn: {
    border: '2px solid black',
    borderRadius: '50%',
  },
  iconOgcn: {
    display: 'flex',
    justifyContent: 'center',
    width: '3.5rem',
    height: '3.5rem',
    '& svg': {
      display: 'flex',
      alignSelf: 'center',
      width: '2.5rem',
      height: '2.5rem',
    },
  },
}
