import { colors, fonts, tablet } from '../../theme'


export default {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: colors.nightRider,
    padding: '2rem 1.5rem',
    ...tablet({
      padding: '3rem 6rem',
    }),
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    maxWidth: '94rem',
    margin: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    ...tablet({
      justifyContent: 'center',
    }),
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  blackFade: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  logo: {
    width: '20rem',
    height: '5rem',
    marginBottom: '1rem',
    ...tablet({
      width: '32rem',
      height: '8rem',
      marginBottom: '4.4rem',
    }),
  },
  logoAsset: {
    //
  },
  text: {
    ...fonts.FiraSansBold,
    maxWidth: '35rem',
    color: colors.pureWhite,
    fontSize: '2.4rem',
    lineHeight: '2.9rem',
    marginBottom: '1rem',
    ...tablet({
      maxWidth: '54rem',
      fontSize: '3.2rem',
      lineHeight: '3.9rem',
      marginBottom: '3rem',
    }),
  },
  date: {
    marginBottom: '6.5rem',
    ...tablet({
      marginBottom: '10rem',
    }),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  dateBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateSeparator: {
    backgroundColor: colors.pureWhite,
    width: '1px',
    height: '3rem',
    margin: '0 2rem',
    ...tablet({
      height: '6rem',
      margin: '0 4.5rem',
    }),
  },
  dateBig: {
    ...fonts.FiraSansExtraLight,
    color: colors.pureWhite,
    fontSize: '4rem',
    lineHeight: '4.8rem',
    ...tablet({
      fontSize: '6rem',
      lineHeight: '7.2rem',
    }),
  },
  dateSmall: {
    ...fonts.FiraSansExtraLight,
    color: colors.pureWhite,
    fontSize: '1.8rem',
    lineHeight: '2.2rem',
    marginLeft: '2rem',
    ...tablet({
      fontSize: '2.8rem',
      lineHeight: '3.4rem',
      marginLeft: '1.5rem',
    }),
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  copyright: {
    ...fonts.FiraSansBold,
    color: colors.pureWhite,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
  },
  link: {
    display: 'none',
    ...fonts.FiraSansRegular,
    color: colors.pureWhite,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    marginLeft: '2rem',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}
