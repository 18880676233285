import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import MarkdownText from '../MarkdownText'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Tabs = (props) => {
  const classes = useStyles(props)
  const { className, tabs, onTabChange, contentRenderer } = props
  const [activeTab, setActiveTab] = useState(0)

  const handleTabChange = (index) => {
    setActiveTab(index)
    if (index !== activeTab) {
      onTabChange(tabs[index], index)
    }
  }

  const renderTabHeaders = () => tabs && tabs.map((tab, index) => {
    const { title, amount } = tab
    const activeClass = index === activeTab ? classes.active : null
    const disabledClass = null

    return (
      <div
        className={cx(classes.tab, activeClass, disabledClass)}
        role="presentation"
        onClick={() => handleTabChange(index)}
        key={`tab-${index}`}
      >
        <MarkdownText
          className={classes.title}
          text={title}
          inline
        />
        {amount && <div className={classes.amount}>{amount}</div>}
      </div>
    )
  })

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.header}>
        {renderTabHeaders()}
      </div>
      <div className={classes.content}>
        {
          contentRenderer && contentRenderer(tabs[activeTab], activeTab)
        }
        {
          !contentRenderer && tabs[activeTab] && tabs[activeTab].content
        }
      </div>
    </div>
  )
}

Tabs.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    content: PropTypes.node,
  })),
  onTabChange: PropTypes.func,
  contentRenderer: PropTypes.func,
}

Tabs.defaultProps = {
  className: '',
  tabs: [],
  onTabChange: () => null,
  contentRenderer: null,
}

export default withMemo()(Tabs)
