export function parseId(id) {
  if (!id) {
    return id
  }

  if (id.indexOf('/') === -1) {
    if (id.indexOf('&') === -1) {
      return id
    }
    return parseId(id.substring(0, id.indexOf('&')))
  }

  /* eslint-disable-next-line */
  const exp = new RegExp('(?:youtube(?:-nocookie)?\.com/(?:[^/]+/.+/|(?:v|e(?:mbed)?)/|.*[?&]v=)|youtu\.be/)([^"&?/ ]{11})', 'i')
  const match = id.match(exp)

  if (match) {
    return match[1]
  }
  return id
}

export function isValidId(id) {
  const exp = new RegExp('[a-zA-Z0-9_-]{11}', 'i')
  const match = parseId(id).match(exp)

  return match
}
