import { colors, fonts } from '../../theme'


export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  containerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    ...fonts.FiraSansBold,
    fontSize: '1.4rem',
    textTransform: 'uppercase',
    color: colors.pureWhite,
    marginBottom: '1.4rem',
  },
  titleRow: {
    marginBottom: 0,
  },
}
