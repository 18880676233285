import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/WithMemo'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import FilterBoxEntry from '../FilterBoxEntry'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FilterBox = (props) => {
  const classes = useStyles(props)
  const {
    className,
    placeholder,
    filters,
    values,
    onChange,
    isOpen,
    toggleSelect,
    containerRef,
    submenuRef,
  } = props

  const renderFilters = useMemo(() => filters?.map((filter, i) => {
    const currentValue = values.find((item) => item.name === filter.name)

    if (filter.visible === false) {
      return null
    }

    return (
      <FilterBoxEntry
        {...filter}
        value={currentValue?.value}
        onChange={onChange}
        key={i}
        isMulti={filter?.isMulti}
        className={classes.filter}
      />
    )
  }), [filters, values, onChange, classes.filter])

  if (!filters) {
    return null
  }

  return (
    <div
      className={cx(classes.container, className)}
      ref={containerRef}
    >
      <div className={classes.buttonContainer}>
        <div
          className={classes.button}
          onClick={toggleSelect}
          role="presentation"
        >
          <div
            className={classes.buttonLabel}
            dangerouslySetInnerHTML={{ __html: placeholder }}
          />
          <Icon
            icon={iconsKeys.Unfold}
            className={classes.buttonIcon}
          />
        </div>
      </div>
      {isOpen && (
        <div
          className={classes.submenu}
          ref={submenuRef}
        >
          {renderFilters}
        </div>
      )}
    </div>
  )
}

FilterBox.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['checkbox', 'radio']),
    isMulti: PropTypes.bool,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    name: PropTypes.string,
  })),
  values: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  })),
  onChange: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleSelect: PropTypes.func,
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]),
  submenuRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any,
    }),
  ]),
}

FilterBox.defaultProps = {
  className: '',
  filters: [],
  values: [],
  onChange: null,
  isOpen: false,
  toggleSelect: () => undefined,
  containerRef: null,
  submenuRef: null,
}

export default withMemo()(FilterBox)
