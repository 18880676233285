import { colors, fonts } from '../../theme'


export default {
  container: {
    background: colors.pureWhite,
    overflow: 'hidden',
    borderRadius: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    transition: 'all .2s ease',
    '&:hover': {
      boxShadow: '0px 5px 30px rgba(84, 84, 84, 0.2)',
      transform: 'translateY(-2px)',
    },
  },
  wrapper: {
    width: '100%',
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: ({ category }) => category?.color || colors.darkBlue,
    padding: '2rem',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '1rem',
    marginTop: '-1rem',
  },
  tagName: {
    ...fonts.FiraSansBold,
    color: colors.pureWhite,
    fontSize: '1.4rem',
    lineHeight: '120%',
  },
  tagIcon: {
    padding: '0.25rem',
    borderRadius: '100%',
    border: '1px solid #FFF',
  },
  title: {
    width: '100%',
    ...fonts.FiraSansRegular,
    fontSize: '1.8rem',
    lineHeight: '120%',
    color: colors.pureWhite,
    textAlign: 'left',
    marginTop: '0',
    marginBottom: '1rem',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '2rem',
  },
  deadlineLabel: {
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '120%',
    color: colors.grey,
    marginBottom: '1rem',
  },
  deadlineDate: {
    ...fonts.FiraSansBold,
    fontSize: '1.6rem',
    lineHeight: '120%',
    color: colors.darkBlue,
  },
}
