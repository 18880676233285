import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import { imageTypes } from '../CloudinaryImage/types'
import EventCard from '../EventCard'
import EventDetailsMain from '../EventDetailsMain'
import EventDetailsSidebar from '../EventDetailsSidebar'
import { iconsKeys } from '../Icon/Icon.assets'
import Map from '../Map'
import SideColumnLayout from '../SideColumnLayout'

import styles from './styles'


const useStyles = createUseStyles(styles)

const EventDetails = (props) => {
  const classes = useStyles(props)
  const { className, event, cta } = props
  const {
    backLabel,
    backLink,
    backRoute,
    backRouteParams,
    cover,
    title,
    mapLabel,
    address,
    place,
    phoneLabel,
    phone,
    linkedEventsLabel,
    linkedEvents,
  } = event

  const renderSideContent = () => (
    <EventDetailsSidebar
      event={event}
      cta={cta}
      className={classes.sidebar}
    />
  )

  const renderMain = () => (
    <EventDetailsMain
      event={event}
      cta={cta}
    />
  )

  const renderAddress = () => {
    let fullAddress = ''

    if (address) {
      if (address.street) {
        fullAddress += address.street
      }

      if (address.zip) {
        if (fullAddress) {
          fullAddress += '<br /><br />'
        }
        fullAddress += `${address.zip}`
      }

      if (address.city) {
        fullAddress += ` ${address.city}`
      }

      if (phoneLabel && phone) {
        if (fullAddress) {
          fullAddress += '<br /><br />'
        }
        fullAddress += `${phoneLabel}`

        fullAddress += `<br /><a href="tel:${phone}" style="color: inherit !important">${phone}</a>`
      }
    }

    if (!fullAddress) {
      return null
    }

    return (
      <div className={classes.mapAddressContainer}>
        <div className={classes.mapAddressBig}>{place}</div>
        <div
          className={classes.mapAddress}
          dangerouslySetInnerHTML={{ __html: fullAddress }}
        />
      </div>
    )
  }

  return (
    <div className={cx(classes.container, className)}>
      <CloudinaryImage
        id={cover}
        type={imageTypes.Background}
        className={classes.headerContainer}
      >
        <div className={classes.headerGradient}>
          <div className={classes.headerWrapper}>
            {backLabel !== null && backLink !== null && (
              <ActionButton
                label={backLabel}
                link={backLink}
                route={backRoute}
                routeParams={backRouteParams}
                color="white"
                icon={iconsKeys.SmallArrowLeft}
                colorIcon={colors.darkBlue}
                colorIconHover={colors.darkBlue}
                className={classes.headerButton}
              />
            )}
            <h1 className={classes.headerTitle}>{title}</h1>
          </div>
        </div>
      </CloudinaryImage>
      <div className={classes.wrapper}>
        <SideColumnLayout
          reverse
          largerColumn
          columnPosition="right"
          columnContent={renderSideContent()}
          mainContent={renderMain()}
        />
        <div className={classes.mapBlock}>
          <div className={classes.mapLabel}>{mapLabel}</div>
          <div className={classes.mapContainer}>
            {renderAddress()}
            <Map
              {...address}
              linkClassName={classes.mapLink}
              className={classes.map}
            />
          </div>
        </div>
        <div className={classes.linkedEventsContainer}>
          <div className={classes.linkedEventsWrapper}>
            <div className={classes.linkedEventsTitle}>
              {linkedEventsLabel}
            </div>
            <div className={classes.linkedEvents}>
              {!!linkedEvents && linkedEvents.map((linkedEvent, i) => (
                <EventCard
                  key={linkedEvent.id}
                  {...linkedEvent}
                  asCard
                  className={cx(classes.linkedEvent, i !== 0 && classes.linkedEventMargin)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

EventDetails.propTypes = {
  className: PropTypes.string,
  event: PropTypes.shape(EventDetailsMain.propTypes),
  cta: PropTypes.shape(ActionButton.propTypes),
}

EventDetails.defaultProps = {
  className: '',
  event: null,
  cta: null,
}

export default withMemo()(EventDetails)
