import { fonts, colors, sizes } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    maxWidth: sizes.desktop,
    height: '30rem',
    padding: '2.4rem 2.4rem 2rem',
    borderRadius: '2rem',
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'space-between',
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(90deg, #373CF5 0%, rgba(55, 60, 245, 0) 30rem)',
    top: 0,
    left: 0,
  },
  logo: {
    position: 'relative',
    width: '8rem',
    height: '8rem',
    background: colors.pureWhite,
    borderRadius: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem .6rem',
    marginBottom: '2.4rem',
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    },
  },
  title: {
    position: 'relative',
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    color: colors.pureWhite,
    marginBottom: '1rem',
    textTransform: 'uppercase',
    maxWidth: '22rem',
  },
  sector: {
    position: 'relative',
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    color: colors.pureWhite,
    textTransform: 'uppercase',
    marginBottom: '1rem',
    maxWidth: '22rem',
  },
  location: {
    position: 'relative',
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    color: colors.pureWhite,
    maxWidth: '22rem',
    marginBottom: '.8rem',
  },
  offers: {
    position: 'relative',
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    color: colors.pureWhite,
    marginBottom: '3.3rem',
  },
  featured: {
    position: 'absolute',
    top: '2rem',
    right: '2rem',
    width: '3rem',
    height: '3rem',
  },
  iconAsset: {
    position: 'relative',
    minWidth: '3rem',
    minHeight: '3rem',
  },
  ctaButton: {
    position: 'relative',
  },
}
