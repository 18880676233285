import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import Tabs from '../Tabs'
import MatchingFilters from '../MatchingFilters'
import CandidateCard from '../CandidateCard'
import ActionButton from '../ActionButton'
import { iconsKeys } from '../Icon/Icon.assets'
import { statusKeys } from '../OfferDashboard/statuses'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const OfferNotifierManager = (props) => {
  const classes = useStyles(props)
  const {
    className,
    i18n,
    candidates,
    onNotify,
    onDiscard,
    onOpen,
  } = props
  const {
    availableLabel,
    notifiedLabel,
    discardedLabel,
    cvLabel,
    filterLabel,
    selectAllLabel,
    positiveActionLabel,
    negativeActionLabel,
    restoreActionLabel,
  } = i18n
  const {
    available,
    positive,
    negative,
  } = candidates

  const [selected, setSelected] = useState([])


  const filterSteps = [100, 90, 80, 70]

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected((before) => before.filter((value) => value !== id))
    } else {
      setSelected((before) => [...before, id])
    }
  }

  const handleSelectAll = (ids) => setSelected(ids)
  const handleNotify = (id) => onNotify(id ? [id] : selected)
  const handleDiscard = (id) => onDiscard(id ? [id] : selected)
  const handleClearSelected = () => setSelected([])


  const renderCardList = (cards, origin, step, amount) => {
    const filteredCards = cards.filter((card) => card.percentage >= step)
    const visibleIds = filteredCards.map((card) => card.id)

    return (
      <React.Fragment key={origin}>
        <div className={classes.candidateList}>
          {filteredCards.map((card, index) => (
            <React.Fragment key={`${origin}-${index}-${card.id}`}>
              <CandidateCard
                cvLabel={cvLabel}
                candidate={card}
                className={classes.candidateCard}
                isSelected={selected.includes(card.id)}
                onSelectClick={() => handleSelect(card.id)}
                onContactClick={() => handleNotify(card.id)}
                onDiscardClick={() => handleDiscard(card.id)}
                onCvClick={() => onOpen(card.id)}
                isNotified={origin === 'positive'}
                isDiscarded={origin === 'negative'}
              />
            </React.Fragment>
          ))}
        </div>
        {amount > 0 && origin !== statusKeys.POSITIVE && (
          <div className={classes.actions}>
            <ActionButton
              label={selectAllLabel}
              color="primary"
              isOutlined
              className={classes.selectAll}
              onClick={() => handleSelectAll(visibleIds)}
            />
            <div className={classes.batchActions}>
              <ActionButton
                label={positiveActionLabel}
                color="primary"
                isOutlined
                onClick={() => handleNotify(null)}
                className={classes.action}
                icon={iconsKeys.Chat}
                isDisabled={!selected.length}
              />
              <ActionButton
                label={origin === statusKeys.NEGATIVE ? restoreActionLabel : negativeActionLabel}
                color="primary"
                isOutlined
                onClick={() => handleDiscard(null)}
                className={classes.action}
                icon={iconsKeys.Decline}
                isDisabled={!selected.length}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }

  const renderTabContent = (source, tab, origin) => {
    const filterTabs = filterSteps.map((step, index) => {
      const amount = source.filter((candidate) => candidate.percentage >= step).length

      return {
        id: `${tab}-filter-index-${index}`,
        title: `${filterLabel} ${step}%`,
        amount,
        content: renderCardList(source, origin, step, amount),
      }
    })

    return (
      <div className={classes.tabContent}>
        <MatchingFilters
          tabs={filterTabs}
          onTabChange={handleClearSelected}
        />
      </div>
    )
  }

  const mainTabs = [
    {
      id: 'tab1',
      title: availableLabel,
      amount: available.length,
      content: renderTabContent(available, 'tab1', 'available'),
    },
    {
      id: 'tab2',
      title: notifiedLabel,
      amount: positive.length,
      content: renderTabContent(positive, 'tab2', 'positive'),
    },
    {
      id: 'tab3',
      title: discardedLabel,
      amount: negative.length,
      content: renderTabContent(negative, 'tab3', 'negative'),
    },
  ]

  return (
    <div className={cx(classes.container, className)}>
      <Tabs
        tabs={mainTabs}
        onTabChange={handleClearSelected}
      />
    </div>
  )
}

OfferNotifierManager.propTypes = {
  className: PropTypes.string,
  i18n: PropTypes.shape({
    availableLabel: PropTypes.string,
    notifiedLabel: PropTypes.string,
    discardedLabel: PropTypes.string,
    cvLabel: PropTypes.string,
    filterLabel: PropTypes.string,
    selectAllLabel: PropTypes.string,
    positiveActionLabel: PropTypes.string,
    negativeActionLabel: PropTypes.string,
    restoreActionLabel: PropTypes.string,
  }).isRequired,
  candidates: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    jobLabel: PropTypes.string,
    name: PropTypes.string,
    link: PropTypes.string,
    percentage: PropTypes.number,
    phone: PropTypes.string,
    email: PropTypes.string,
    picture: PropTypes.string,
    status: PropTypes.string,
  }))).isRequired,
  onNotify: PropTypes.func,
  onDiscard: PropTypes.func,
  onOpen: PropTypes.func,
}

OfferNotifierManager.defaultProps = {
  className: '',
  onNotify: () => null,
  onDiscard: () => null,
  onOpen: () => null,
}

export default withMemo()(OfferNotifierManager)
