import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import CloudinaryImage from '../CloudinaryImage'
import CompanyModal from '../CompanyModal'
import Modal from '../Modal'

import styles from './styles'


const useStyles = createUseStyles(styles)

const CompanyGallery = (props) => {
  const classes = useStyles(props)
  const { className, pictures } = props
  const [pictureIndex, setPictureIndex] = useState(null)

  const handleModalPrev = () => {
    const newIndex = pictureIndex === 0 ? (pictures.length - 1) : pictureIndex - 1

    setPictureIndex(newIndex)
  }

  const handleModalNext = () => {
    const newIndex = pictureIndex === (pictures.length - 1) ? 0 : pictureIndex + 1

    setPictureIndex(newIndex)
  }

  const handlePictureClick = useCallback((index) => () => setPictureIndex(index), [])

  const renderPictures = () => pictures.map((item, index) => {
    const { id, picture } = item

    return (
      <div
        className={classes.picture}
        onClick={handlePictureClick(index)}
        role="presentation"
        key={id}
      >
        <CloudinaryImage
          id={picture}
          options={{
            width: '220',
            height: '160',
            crop: 'fill',
          }}
          lazyLoad
        />
      </div>
    )
  })

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        {renderPictures()}
      </div>
      {pictureIndex !== null && (
        <Modal
          isOpen={pictureIndex !== null}
          onRequestClose={() => setPictureIndex(null)}
        >
          <CompanyModal
            src={pictures[pictureIndex].picture}
            onCloseClick={() => setPictureIndex(null)}
            onPrevClick={handleModalPrev}
            onNextClick={handleModalNext}
          />
        </Modal>
      )}
    </div>
  )
}

CompanyGallery.propTypes = {
  className: PropTypes.string,
  pictures: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    picture: PropTypes.string,
  })),
}

CompanyGallery.defaultProps = {
  className: '',
  pictures: [],
}

export default withMemo()(CompanyGallery)
