import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import MarkdownText from '../MarkdownText'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const MatchingFilters = (props) => {
  const classes = useStyles(props)
  const { className, tabs, onTabChange, contentRenderer } = props

  const [activeTab, setActiveTab] = useState(0)

  const handleTabChange = (index) => {
    setActiveTab(index)
    if (index !== activeTab) {
      onTabChange(tabs[index], index)
    }
  }

  const renderTabHeaders = () => tabs.map((tab, index) => {
    const { title, amount } = tab
    const activeClass = index === activeTab ? classes.active : null

    return (
      <div
        className={cx(classes.tab, activeClass)}
        role="presentation"
        onClick={() => handleTabChange(index)}
        key={index}
      >
        <MarkdownText
          className={classes.title}
          text={title}
          inline
        />
        <div className={classes.amount}>{amount}</div>
      </div>
    )
  })

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.header}>
        {renderTabHeaders()}
      </div>
      <div className={classes.content}>
        {
          contentRenderer && contentRenderer(tabs[activeTab], activeTab)
        }
        {
          !contentRenderer && tabs[activeTab] && tabs[activeTab].content
        }
      </div>
    </div>
  )
}

MatchingFilters.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.node,
  })),
  contentRenderer: PropTypes.func,
  onTabChange: PropTypes.func,
}

MatchingFilters.defaultProps = {
  className: '',
  tabs: [],
  contentRenderer: null,
  onTabChange: (tab, tabIndex) => null,
}

export default withMemo()(MatchingFilters)
