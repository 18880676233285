import React, { useLayoutEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import withMemo from '../decorators/WithMemo'


const Tooltip = (props) => {
  const {
    ...otherProps
  } = props

  const [shouldRender, setShouldRender] = useState(false)

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      if (typeof window.__tooltipsInitialized !== 'number') {
        window.__tooltipsInitialized = 0
      }
      window.__tooltipsInitialized++
      setShouldRender(window.__tooltipsInitialized === 1)
    }

    return () => {
      window.__tooltipsInitialized--
    }
  }, [])

  return shouldRender ? (
    <ReactTooltip {...otherProps} />
  ) : null
}

Tooltip.propTypes = {
  //
}

Tooltip.defaultProps = {
  //
}

export default withMemo()(Tooltip)
