import cx from 'classnames'
import _values from 'lodash/values'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import routes from '../../routes'
import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import { imageTypes } from '../CloudinaryImage/types'
import MarkdownText from '../MarkdownText'
import Linkable from '../../data/models/Linkable'

import styles from './styles'
import { bannerTypes } from './types'


const useStyles = createUseStyles(styles)

const Banner = (props) => {
  const classes = useStyles(props)
  const {
    className,
    image,
    title,
    subtitle,
    ctaLabel,
    ctaLink,
    ctaLinkable,
    ctaType,
    format,
    children,
    hasSearch,
    hasSearchTitle,
    hasSearchStats,
    tags,
  } = props

  if (!image || image === null) {
    return null
  }

  function getClassName() {
    if (format === bannerTypes.HEADER_TYPE_HOMEPAGE) {
      return classes.containerHomepage
    }
    if (format === bannerTypes.HEADER_TYPE_SM) {
      return classes.containerSM
    }
    if (format === bannerTypes.HEADER_TYPE_XL) {
      return classes.containerXL
    }

    return classes.containerMD
  }

  return (
    <CloudinaryImage
      id={image}
      type={imageTypes.Background}
      className={cx(
        classes.container,
        className
      )}
      lazyLoad
    >
      <div className={cx(classes.wrapper)}>
        <div className={cx(
          classes.content,
          getClassName(),
          hasSearch && 'hasSearch',
          hasSearchTitle && 'hasTitle',
          hasSearchStats && 'hasStats'
        )}
        >
          {tags && (
            <div className={classes.tags}>{tags}</div>
          )}
          {(title || subtitle) && (
            <h1 className={classes.title}>
              {title}
              {subtitle && (
                <MarkdownText
                  tag="span"
                  inline
                  className={classes.subtitle}
                  text={subtitle}
                />
              )}
            </h1>
          )}
          {ctaLabel && ctaLink && (
            <ActionButton
              label={ctaLabel}
              link={ctaType === 'PAGE' ? routes.page.name : ctaLink}
              routeParams={{ ...ctaType === 'PAGE' && { slug: ctaLink } }}
              className={classes.cta}
              color="primary"
            />
          )}
          {ctaLabel && ctaLinkable && (
            <ActionButton
              label={ctaLabel}
              route={ctaLinkable?.route?.name}
              routeParams={ctaLinkable?.route?.params}
              link={ctaLinkable?.link}
              className={classes.cta}
              color="primary"
            />
          )}
          {children && (
            <div className={classes.searchBlock}>
              {children}
            </div>
          )}
        </div>
      </div>
    </CloudinaryImage>
  )
}

Banner.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  format: PropTypes.oneOf(_values(bannerTypes)),
  image: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaType: PropTypes.string,
  ctaLinkable: Linkable.propTypes,
  children: PropTypes.node,
  tags: PropTypes.node,
  hasSearch: PropTypes.bool,
  hasSearchTitle: PropTypes.bool,
  hasSearchStats: PropTypes.bool,
}

Banner.defaultProps = {
  className: '',
  format: 'md',
  title: null,
  subtitle: null,
  ctaLabel: null,
  ctaLink: null,
  ctaType: null,
  ctaLinkable: null,
  children: null,
  tags: null,
  hasSearch: false,
  hasSearchTitle: false,
  hasSearchStats: false,
}

export default withMemo()(Banner)
