import { desktop } from '../../theme'


export default {
  container: {
    width: '100%',
    maxWidth: '94rem',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...desktop({
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
    }),
  },
  containerReverse: {
    flexDirection: 'column-reverse',
    ...desktop({
      flexDirection: 'row',
    }),
  },
  sideColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '4rem',
    order: 2,
    ...desktop({
      order: 0,
      width: '22rem',
      margin: '0 2rem',
    }),
  },
  sideColumnRight: {
    ...desktop({
      order: 2,
    }),
  },
  sideColumnLarger: {
    ...desktop({
      width: '34rem',
    }),
  },
  main: {
    width: '100%',
    flex: 1,
    ...desktop({
      margin: '0 2rem',
    }),
  },
}
