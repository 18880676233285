import { fontWeights } from '../utils/FontUtils'


export const declaration = {
  FiraSansBold: {
    basename: 'FiraSans-Bold',
    fontFamily: 'Fira Sans',
    fontWeight: fontWeights.bold,
  },
  FiraSansMedium: {
    basename: 'FiraSans-Medium',
    fontFamily: 'Fira Sans',
    fontWeight: fontWeights.medium,
  },
  FiraSansRegular: {
    basename: 'FiraSans-Regular',
    fontFamily: 'Fira Sans',
    fontWeight: fontWeights.regular,
  },
  FiraSansExtraLight: {
    basename: 'FiraSans-ExtraLight',
    fontFamily: 'Fira Sans',
    fontWeight: fontWeights.extraLight,
  },
}

const getFont = (font) => ({
  fontFamily: `"${font.fontFamily}", sans-serif`,
  fontWeight: font?.fontWeight || fontWeights.regular,
  fontStyle: font?.fontStyle,
})

export default {
  FiraSansBold: getFont(declaration.FiraSansBold),
  FiraSansMedium: getFont(declaration.FiraSansMedium),
  FiraSansRegular: getFont(declaration.FiraSansRegular),
  FiraSansExtraLight: getFont(declaration.FiraSansExtraLight),
}
