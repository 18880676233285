import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'

import withMemo from '../../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function FormLabel(props) {
  const {
    className,
    children,
    required,
    error,
    classes: propsClasses,
    ...others
  } = props

  const classes = { ...useStyles(props), ...props.classes }

  if (!children) {
    return null
  }

  return (
    <span
      {...others}
      className={cx(
        classes.container,
        className
      )}
    >
      {children}
    </span>
  )
}

FormLabel.styles = styles

FormLabel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool,
  error: PropTypes.bool,
}

FormLabel.defaultProps = {
  classes: {},
  className: null,
  children: '',
  required: false,
  error: false,
}

export default withMemo()(FormLabel)
