import { colors, desktop, fonts, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    maxWidth: '70rem',
    margin: '0 auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    ...tablet({
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    }),
  },
  headerData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    ...tablet({
      alignItems: 'flex-start',
      marginBottom: '4rem',
    }),
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '3.2rem',
    lineHeight: '3.8rem',
    marginBottom: '1.0rem',
    marginRight: '10.0rem',
    color: colors.nightRider,
    display: 'none',
    ...desktop({
      display: 'block',
    }),
  },
  headerDetails: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  companyFunction: {
    background: colors.aliceBlue,
    borderRadius: '.4rem',
    textAlign: 'center',
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    color: colors.darkBlue,
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
    padding: '.4rem 1rem',
    marginBottom: '.7rem',
    display: 'none',
    ...desktop({
      display: 'flex',
      alignItems: 'center',
    }),
  },
  messageCTA: {},
  companyDescriptionContainer: {
    padding: 0,
  },
  companyDescription: {
    marginBottom: '3rem',
    display: 'none',
    ...desktop({
      fontSize: '1.5rem',
      lineHeight: '2rem',
      display: 'block',
    }),
  },
  contactHeading: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.0rem',
    lineHeight: '2.4rem',
    marginBottom: '1.2rem',
    color: colors.nightRider,
    textTransform: 'uppercase',
  },
  contactBlocks: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '5rem',
    ...desktop({
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
    }),
  },
  contactBlock: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.5rem',
    lineHeight: '2.0rem',
    color: colors.nightRider,
    width: '30rem',
    marginBottom: '2rem',
  },
  contactTitle: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.5rem',
    lineHeight: '2.0rem',
    marginBottom: '0.1rem',
    color: colors.nightRider,
  },
  contactValue: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.5rem',
    lineHeight: '2.0rem',
    color: colors.darkBlue,
  },
  relationsHeading: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2.0rem',
    lineHeight: '2.4rem',
    marginBottom: '1.2rem',
    color: colors.nightRider,
    textTransform: 'uppercase',
  },
  relationsDescription: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    marginBottom: '1.2rem',
    color: colors.grey,
  },
  domains: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: '13.7rem',
  },
  domain: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: '0.4rem 1rem',
    borderRadius: '5px',
    alignItems: 'center',
    overflow: 'hidden',
    marginRight: '1.3rem',
    marginBottom: '1.3rem',
  },
  domainIcon: {
    width: '2.4rem',
    height: '2.4rem',
    marginRight: '0.5rem',
  },
  domainLabel: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    color: colors.pureWhite,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
  },
  currentNeedsDescription: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    marginBottom: '5rem',
    color: colors.grey,
  },
}
