/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react'

import { app } from '../config'

import { useIsPWA } from './useIsPWA'


let globalPrompt = null

const usePWAInstallPrompt = (
  onAccepted,
  onRefused
) => {
  useIsPWA()
  const showButton = app.USE_PWA
  const [deferred, setDeferred] = useState(globalPrompt)

  const prompt = useCallback(() => {
    console.log('PWA_INSTALLER', 'prompt', !!deferred)
    if (!deferred) {
      return null
    }
    (async() => {
      deferred.prompt()
      setDeferred(null)
      globalPrompt = null
      const { outcome } = await deferred.userChoice

      console.log('PWA_INSTALLER', 'outcome', outcome)
      // Act on the user's choice
      if (outcome === 'accepted' && onAccepted) {
        onAccepted()
      } else if (outcome === 'dismissed' && onRefused) {
        onRefused()
      }
    })()
  }, [onAccepted, onRefused, deferred])

  useEffect(() => {
    console.log('PWA_INSTALLER', 'inited with deferred ?', !!globalPrompt)
    const onBeforeInstall = async(e) => {
      console.log('PWA_INSTALLER', 'received the beforeinstallprompt', e)
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault()
      setDeferred(e)
      globalPrompt = e
    }

    window.addEventListener('beforeinstallprompt', onBeforeInstall)
    return () => {
      window.removeEventListener('beforeinstallprompt', onBeforeInstall)
    }
  }, [])

  return {
    prompt: showButton && !!deferred ? prompt : null,
  }
}

export default usePWAInstallPrompt
