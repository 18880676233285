/* eslint-disable max-lines */
/* eslint-disable prefer-template */
const routes = {
  // Emploi
  event: {
    component: '/Event',
    path: '/evenement/:slug',
    name: 'event',
  },
  profile: {
    component: '/Profile',
    path: '/profil/:slug',
    name: 'profile',
    auth: true,
  },
  company: {
    component: '/Company',
    path: '/entreprise/:slug',
    name: 'company',
  },
  offer: {
    component: '/Offer',
    path: '/opportunite-business/:slug',
    name: 'offer',
  },
  article: {
    component: '/Article',
    path: '/actualites/:slug',
    name: 'article',
  },
  // post: {
  //   component: '/Post',
  //   path: '/mises-en-reseau/:slug',
  //   name: 'post',
  //   auth: true,
  // },
  userAccount: {
    component: '/UserAccount',
    path: '/mon-compte/parametres',
    name: 'userAccount',
    auth: true,
  },
  userNotifications: {
    component: '/UserNotifications',
    path: '/mon-compte/notifications',
    name: 'userNotifications',
    auth: true,
  },
  userDashboard: {
    component: '/UserDashboard',
    path: '/mon-compte',
    name: 'userDashboard',
    auth: true,
  },
  userCompany: {
    component: '/UserCompany',
    path: '/mon-compte/mon-entreprise',
    name: 'userCompany',
    auth: true,
  },
  userLocations: {
    component: '/UserLocations',
    path: '/mon-compte/mon-entreprise/adresses',
    name: 'userLocations',
    auth: true,
  },
  userProfile: {
    component: '/UserProfile',
    path: '/mon-compte/mon-profil',
    name: 'userProfile',
    auth: true,
  },
  userPayment: {
    component: '/UserPayment',
    path: '/mon-compte/moyen-de-paiement',
    name: 'userPayment',
    auth: true,
  },
  userPublishOffer: {
    component: '/UserPublishOffer',
    path: '/mon-compte/publier-une-opportunite-business',
    name: 'userPublishOffer',
    auth: true,
    premium: true,
  },
  userEditOffer: {
    component: '/UserPublishOffer',
    path: '/mon-compte/editer-opportunite-business/:id?',
    name: 'userEditOffer',
    auth: true,
    premium: true,
  },
  userPublishPost: {
    component: '/UserPublishPost',
    path: '/mon-compte/publier-une-mise-en-reseau',
    name: 'userPublishPost',
    auth: true,
    premium: true,
  },
  userEditPost: {
    component: '/UserPublishPost',
    path: '/mon-compte/editer-mise-en-reseau/:slug?',
    name: 'userEditPost',
    auth: true,
    premium: true,
  },
  userCandidacies: {
    component: '/UserCandidacies',
    path: '/mon-compte/mes-candidatures',
    name: 'userCandidacies',
    auth: true,
    premium: true,
  },
  userResources: {
    component: '/UserResources',
    path: '/mon-compte/ressources/:page?',
    name: 'userResources',
    auth: true,
    premium: true,
  },
  userCandidacy: {
    component: '/UserCandidacy',
    path: '/mon-compte/candidature/:id',
    name: 'userCandidacy',
    auth: true,
    premium: true,
  },
  userPublications: {
    component: '/UserPublications',
    path: '/mon-compte/mes-publications',
    name: 'userPublications',
    auth: true,
    premium: true,
  },
  userOffer: {
    component: '/UserOffer',
    path: '/mon-compte/opportunite-business/:id',
    name: 'userOffer',
    auth: true,
    premium: true,
  },
  userRegisterPremium: {
    component: '/UserRegisterPremium',
    path: '/mon-compte/activation-premium/:discount?',
    name: 'userRegisterPremium',
    auth: true,
  },
  userRegisterWelcome: {
    component: '/UserRegisterWelcome',
    path: '/mon-compte/bienvenue',
    name: 'userRegisterWelcome',
    auth: true,
  },
  userSubscription: {
    component: '/UserSubscription',
    path: '/mon-compte/souscrire',
    name: 'userSubscription',
    auth: true,
  },
  userMessages: {
    component: '/UserMessages',
    path: '/mon-compte/messagerie',
    name: 'userMessages',
    auth: true,
    premium: true,
  },
  userValidateSepa: {
    component: '/UserValidateSepa',
    path: '/mon-compte/validation-sepa',
    name: 'userValidateSepa',
  },
  userEvent: {
    component: '/UserEvent',
    path: '/mon-compte/mes-evenements/:id',
    name: 'userEvent',
    auth: true,
    premium: false,
  },
  userEvents: {
    component: '/UserEvents',
    path: '/mon-compte/mes-evenements',
    name: 'userEvents',
    auth: true,
    premium: false,
  },
  userFavorite: {
    component: '/UserFavorite',
    path: '/mon-compte/mes-membres-favoris',
    name: 'userFavorite',
    auth: true,
    premium: true,
  },
  // Common
  home: {
    component: '/Page',
    path: '/',
    name: 'home',
  },
  emailVerification: {
    component: '/EmailVerification',
    path: '/email-verification',
    name: 'emailVerification',
  },
  emailUserNotifications: {
    component: '/EmailUserNotifications',
    path: '/email/notifications/:type+',
    name: 'emailUserNotifications',
    auth: true,
  },
  pagePaginated: {
    component: '/Page',
    path: '/:slug+/p:page([0-9]+)',
    name: 'pagePaginated',
  },
  // Page Wildcard
  page: {
    component: '/Page',
    path: '/:slug+',
    name: 'page',
  },
}

module.exports = routes
