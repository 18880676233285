import { tablet } from '../../../../theme'


export default {
  container: {},
  label: {
    display: 'block',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    ...tablet({
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: '0 -1rem',
    }),
  },
  field: {
    ...tablet({
      width: `${1 / 3 * 100}%`,
      padding: '0 1rem',
    }),
  },
}
