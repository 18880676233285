/* eslint-disable max-lines */
import cx from 'classnames'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { compileInitialValues, compileRules, defineField } from '../../helpers/FormHelpers'
import { validateAll } from '../../helpers/ValidationHelpers'
import { Field } from '../form/Generator/Field'
import Submit from '../form/Submit'
import MarkdownText from '../MarkdownText'

import styles from './styles'


export const fields = [
  defineField({ name: 'iban', validation: ['required', 'iban'], head: true }),
]

const generatedFields = Array.from(fields).filter((f) => f.head)
const rules = compileRules(fields)

const useStyles = createUseStyles(styles)

const PaymentForm = (props) => {
  const classes = useStyles(props)
  const {
    className,
    title,
    intro,
    explanation,
    sepaSubtitle,
    initialValues,
    submitLabel,
    onSubmit,
    fieldsProps,
    messages,
    submitErrors,
    successText,
    pendingText,
    submitSuccess,
    submitPending,
  } = props

  //
  // Handlers
  //
  const handleValidate = (values) => validateAll(values, rules, messages)
  const handleSubmit = (values) => onSubmit(values)

  //
  // Renderers
  //
  const renderForm = (formikProps) => (
    <Form
      noValidate
      autoComplete="off"
      onSubmit={formikProps.handleSubmit}
    >
      <div className={classes.title}>{title}</div>
      <MarkdownText
        className={classes.intro}
        text={intro}
        inline
      />
      <MarkdownText
        className={classes.explanation}
        text={explanation}
        inline
      />
      <div className={classes.subtitle}>{sepaSubtitle}</div>
      <div className={classes.fields}>
        {
          generatedFields
            .map((field, i) => (
              <div
                className={classes.field}
                key={i}
              >
                <Field
                  fieldConfig={field}
                  fieldsProps={fieldsProps}
                  formikProps={formikProps}
                />
              </div>
            ))
        }
      </div>

      {submitErrors && (
        <div className={classes.errors}>
          {submitErrors.map((error, i) => (
            <div
              className={classes.error}
              key={i}
            >
              {error.label}
            </div>
          ))}
        </div>
      )}

      <div className={classes.submitContainer}>
        <Submit
          isBig
          successLabel={successText}
          pendingLabel={pendingText}
          label={submitLabel}
          isDisabled={!formikProps.dirty}
          isSuccess={!formikProps.dirty && submitSuccess}
          isPending={submitPending}
        />
      </div>
    </Form>
  )

  return (
    <div className={cx(classes.container, className)}>
      <Formik
        enableReinitialize
        validate={handleValidate}
        onSubmit={handleSubmit}
        initialValues={compileInitialValues(fields, initialValues)}
      >
        {renderForm}
      </Formik>
    </div>
  )
}

PaymentForm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  intro: PropTypes.string,
  explanation: PropTypes.string,
  sepaSubtitle: PropTypes.string,
  // eslint-disable-next-line
  initialValues: PropTypes.object,
  submitLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  fieldsProps: PropTypes.objectOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    help: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, value: PropTypes.string })),
  })),
  messages: PropTypes.objectOf(PropTypes.string),
  submitErrors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    field: PropTypes.string,
  })),
  successText: PropTypes.string,
  pendingText: PropTypes.string,
  submitSuccess: PropTypes.bool,
  submitPending: PropTypes.bool,
}

PaymentForm.defaultProps = {
  className: '',
  title: '',
  intro: '',
  explanation: '',
  sepaSubtitle: '',
  initialValues: {},
  fieldsProps: {},
  messages: {},
  submitLabel: '',
  onSubmit: () => null,
  submitErrors: null,
  successText: '',
  pendingText: '',
  submitSuccess: false,
  submitPending: false,
}

export default withMemo()(PaymentForm)
