/* eslint-disable camelcase */
import PropTypes from 'prop-types'

import * as routes from '../../routes/definitions'
import { routeParamSlug } from '../../helpers/UrlHelpers'


const getLink = (linkable) => {
  if (linkable) {
    if (linkable.__typename === 'Page') {
      if (linkable.hash) {
        return `${linkable.full_slug}#${linkable.hash}`
      }
      return linkable.full_slug
    }
    if (linkable.__typename === 'Route') {
      if (linkable.hash) {
        return `/${linkable.slug}#${linkable.hash}`
      }
      return `/${linkable.slug}`
    }
  }
  return null
}

const getRoute = (linkable) => {
  let name
  const params = {}

  if (linkable) {
    if (linkable.__typename === 'Page') {
      name = linkable.hash ? routes.pageHash.name : routes.page.name
      params.slug = routeParamSlug(linkable.full_slug)
    }

    if (linkable.__typename === 'Route') {
      name = routes[linkable.key]
    }

    if (name) {
      if (linkable.hash) {
        params.hash = linkable.hash
      }
      return {
        name,
        params,
      }
    }
  }
  return {}
}

const Linkable = (props) => ({
  link: getLink(props),
  route: getRoute(props),
})

Linkable.propTypes = PropTypes.shape({
  link: PropTypes.string,
  route: PropTypes.shape({
    name: PropTypes.string,
    params: PropTypes.object,
  }),
})

export default Linkable
