import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const CompanyMetrics = (props) => {
  const classes = useStyles(props)
  const { className, metrics } = props

  if (!metrics || !metrics.length >= 1) {
    return null
  }

  return (
    <div className={cx(classes.container, className)}>
      {metrics.map((metric, index) => {
        const { label, value } = metric

        return (
          <div
            className={classes.metric}
            key={`metric-${index}`}
          >
            <div className={classes.label}>{label}</div>
            <div className={classes.value}>{value}</div>
          </div>
        )
      })}
    </div>
  )
}

CompanyMetrics.propTypes = {
  className: PropTypes.string,
  metrics: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  })).isRequired,
}

CompanyMetrics.defaultProps = {
  className: '',
}

export default withMemo()(CompanyMetrics)
