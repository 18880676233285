import { colors, fonts, transition } from '../../../../theme'


export default {
  container: {
    userSelect: 'none',
  },
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  box: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '1.5rem',
    border: `.1rem solid ${colors.silver}`,
    backgroundColor: colors.honeydew,
    position: 'relative',
    overflow: 'hidden',
    flexShrink: 0,
    display: 'flex',
    borderColor: ({ error }) => (
      (error && colors.error)
      || colors.silver
    ),
  },
  boxInner: {
    position: 'relative',
    margin: 'auto',
    width: '.9rem',
    height: '.9rem',
    borderRadius: '1rem',
    backgroundColor: colors.darkBlue,
    ...transition('opacity'),
    opacity: ({ value }) => (
      (value && 1)
      || 0
    ),
  },
  input: {
    position: 'absolute',
    visibility: 'hidden',
    margin: '0',
  },
  text: {
    marginLeft: '1rem',
    ...fonts.FiraSansRegular,
    color: ({ disabled }) => (
      (disabled && colors.gainsboro)
      || colors.nightRider
    ),
    fontSize: '1.2rem',
    lineHeight: '1.6rem',
  },
}
