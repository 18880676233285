import { fonts, colors } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '1.6rem',
    borderRadius: '1rem',
    padding: '.2rem 1rem',
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    color: colors.pureWhite,
    fontSize: '1.1rem',
    lineHeight: '1.4rem',
    textTransform: 'uppercase',
    '&.egyptianBlue': {
      background: colors.egyptianBlue,
    },
    '&.darkBlue': {
      background: colors.darkBlue,
    },
    '&.shamrock': {
      background: colors.shamrock,
    },
    '&.downy': {
      background: colors.downy,
    },
  },
  separator: {
    margin: '0 .5rem',
    width: '.1rem',
    height: '1.4rem',
    content: '""',
    background: colors.pureWhite,
    opacity: '.6',
  },
}
