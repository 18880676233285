import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import A from '../A'


const PaginationPage = (props) => (
  <li className={cx({ current: props.disabled })}>
    {!props.disabled && (
      props.route ? (
        <A
          href={props.page <= 1 ? props.routeBase : props.route}
          routeParams={{ ...props.routeParams, ...(props.page <= 1 ? {} : { page: props.page }) }}
          onClick={props.onClick}
          title={`page ${props.page}`}
        >
          {props.page}
        </A>
      ) : (
        <a
          onClick={props.onClick}
          title={`page ${props.page}`}
          role="presentation"
        >
          {props.page}
        </a>
      )
    )}
    {props.disabled && props.page}
  </li>
)

PaginationPage.propTypes = {
  page: PropTypes.number.isRequired,
  route: PropTypes.string.isRequired,
  routeBase: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  routeParams: PropTypes.any, // eslint-disable-line
  onClick: PropTypes.func,
}
PaginationPage.defaultProps = {
  disabled: false,
  routeParams: {},
  onClick: () => null,
}

export default PaginationPage
