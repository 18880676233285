/* eslint-disable max-lines */
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'
import Tinput from 'react-tagsinput'
import Immutable from 'seamless-immutable'

import { generateUniqueId } from '../../../../utils/ComponentUtils'
import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import FormLabel from '../../FormLabel'
import withMemo from '../../../../decorators/WithMemo'


import styles from './styles'


const useStyles = createUseStyles(styles)

function TagsInput(props) {
  const {
    className,
    inputClassName,
    id,
    name,
    value,
    label,
    help,
    placeholder,
    required,
    error,
    maxTags,
    onChange,
    onBlur,
    onFocus,
  } = props

  const [thisId] = useState(`form_text_${generateUniqueId()}`)
  const [focused, setFocused] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = useCallback((val) => {
    setInputValue(val.replace(',', ''))
  }, [])

  const handleChange = useCallback((tags) => {
    onChange({
      name,
      value: tags,
    })
  }, [name, onChange])

  const handleFocus = (event) => {
    setFocused(true)
    onFocus({ name, event })
  }

  const handleBlur = (event) => {
    setFocused(false)
    onBlur({ name, event })
  }

  const classes = { ...useStyles({ ...props, focused }), ...props.classes }

  return (
    <div
      className={cx(
        classes.container,
        className
      )}
    >
      <>
        {label !== '' && (
          <label
            className={classes.wrapper}
            htmlFor={id || thisId}
          >
            <FormLabel
              className={classes.label}
              required={required}
              error={!!error}
            >
              {label}
            </FormLabel>
          </label>
        )}
        <div className={classes.inputContainer}>
          <Tinput
            inputProps={{
              placeholder,
            }}
            inputValue={inputValue}
            id={id || thisId}
            className={cx(classes.input, inputClassName)}
            name={name}
            value={Immutable.asMutable(value, { deep: true })}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            addKeys={[9, 13, 188]}
            maxTags={maxTags}
          />
        </div>
      </>
      <FormHelpText
        className={classes.helpText}
        text={help}
      />
      <br />
      <FormErrorText
        className={classes.errorText}
        text={error}
      />
    </div>
  )
}

TagsInput.styles = styles

TagsInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  error: PropTypes.string,
  help: PropTypes.string,
  placeholder: PropTypes.string,
  maxTags: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  onChange: PropTypes.func, // {name, value, event}
  onBlur: PropTypes.func, // {name, event}
  onFocus: PropTypes.func, // {name, event}
}

TagsInput.defaultProps = {
  classes: {},
  className: null,
  id: null,
  inputClassName: null,
  value: [],
  label: '',
  required: false,
  error: null,
  help: null,
  placeholder: null,
  maxTags: -1,
  onChange: () => undefined,
  onBlur: () => undefined,
  onFocus: () => undefined,
}

TagsInput.defaultValue = []

export default withMemo()(TagsInput)
