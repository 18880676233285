import { colors, fonts } from '../../theme'


export default {
  modal: {
    width: '100%',
    background: colors.pureWhite,
    borderRadius: '2rem',
    padding: '2.5rem 3rem',
    margin: '0 auto',
    maxWidth: '44rem',
    textAlign: 'left',
  },
  modalTitle: {
    width: '100%',
    ...fonts.FiraSansBold,
    fontSize: '1.8rem',
    lineHeight: '120%',
    color: colors.nightRider,
    textAlign: 'left',
    marginBottom: '1rem',
  },
  registerContainer: {
    textAlign: 'center',
    padding: '1rem 0',
  },
  modalText: {
    padding: '1rem 0',
    width: '100%',
    ...fonts.FiraSansRegular,
  },
}
