/* eslint-disable max-lines */

import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'

import withMemo from '../../decorators/WithMemo'
import { selectors as authSelectors } from '../../redux/auth/redux'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import CompanyGallery from '../CompanyGallery'
import CompanyGalleryVideo from '../CompanyGalleryVideo'
import CompanyMetrics from '../CompanyMetrics'
import CompanyOffers from '../CompanyOffers'
import FileLibrary from '../FileLibrary'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import Map from '../Map'
import Paragraph from '../Paragraph'
import Sharing from '../Sharing'
import RegisterModal from '../RegisterModal'

import styles from './styles'


const defaultLogo = 'defaults/logo'
const useStyles = createUseStyles(styles)

const CompanyDetailsMain = (props) => {
  const classes = useStyles(props)
  const { className, company, sharing, isConnected, handleModalOpen } = props
  const isPremium = useSelector(authSelectors.isActivePremium)
  const { t } = useTranslation()

  const [isModalOpen, setModalOpen] = useState(false)

  const {
    title,
    logo,
    domains,
    description,
    metrics,
    video,
    gallery,
    files,
    offers,
    certifications,
    certificationsLabel,
    isOwnCompany,
    contacts,
    contactsLabel,
    contactMembers,
    contactMembersLabel,
    filesLabel,
    filesSeeLabel,
    plainCertifications,
    plainCertificationsLabel,
    references,
    referencesLabel,
  } = company

  const renderDomains = () => (
    <div className={classes.domains}>
      {domains.map((domain) => (
        <div
          className={classes.domain}
          key={`domain-${domain.id}`}
        >
          {domain.name}
        </div>
      ))}
    </div>
  )

  const renderHeader = () => (
    <div className={classes.header}>
      <div className={classes.logo}>
        <CloudinaryImage
          id={logo || defaultLogo}
          options={{
            width: '120',
            height: '106',
            crop: 'lpad',
          }}
          lazyLoad
        />
      </div>
      <div className={classes.headerData}>
        <h1 className={classes.title}>{title}</h1>
        {!!domains && renderDomains()}
      </div>
      {!!sharing
      && (
        <div className={classes.sharing}>
          <Sharing {...sharing} />
        </div>
      )}
    </div>
  )

  const renderDescription = () => (
    <Paragraph
      text={isConnected ? description : `${description.substring(0, 100)}...`}
      className={classes.description}
      classNames={{ parentContainer: classes.descriptionContainer }}
    />
  )

  const renderCertifications = () => (
    <div className={classes.certificationsContainer}>
      <div className={classes.certificationsTitleContainer}>
        <Icon
          icon={iconsKeys.Label}
          classname={classes.certificationsIcon}
        />
        <h1 className={classes.certificationsTitle}>{certificationsLabel}</h1>
      </div>
      <div className={classes.certifications}>
        {certifications.map((certification) => (
          <div
            key={certification.id}
            className={classes.certifications}
          >
            <div className={classes.certification}>
              {certification.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const renderPlainCertifications = () => (
    <div className={classes.certificationsContainer}>
      <div className={classes.certificationsTitleContainer}>
        <h1 className={classes.certificationsTitle}>{plainCertificationsLabel}</h1>
      </div>
      <div className={classes.certifications}>
        {plainCertifications.map((certification, index) => (
          <div
            key={index}
            className={classes.certifications}
          >
            <div className={classes.certification}>
              {certification}
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const renderReferences = () => (
    <div className={classes.certificationsContainer}>
      <div className={classes.certificationsTitleContainer}>
        <h1 className={classes.certificationsTitle}>{referencesLabel}</h1>
      </div>
      <div className={classes.certifications}>
        {references.map((reference, index) => (
          <div
            key={index}
            className={classes.certifications}
          >
            <div className={classes.certification}>
              {reference}
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const canSeeContacts = isOwnCompany || isPremium

  const renderContacts = () => (
    <div className={classes.contactsContainer}>
      <h1 className={classes.contactsTitle}>{contactsLabel}</h1>
      {!canSeeContacts ? (
        <div className={classes.contactsOnlyPremium}>
          <div className={classes.onlyPremium}>{t('companyDetails_premiumRestricted')}</div>
          <div>
            <ActionButton
              label={t('companyDetails_premiumRestricted_cta')}
              onClick={() => setModalOpen(true)}
            />
          </div>
          <RegisterModal
            modalTitle={t('companyDetails_premiumRestricted_title')}
            modalText={t('companyDetails_premiumRestricted_text')}
            modalCta={t('companyDetails_premiumRestricted_cta_modal')}
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
          />
        </div>
      ) : contacts.map((contact) => (
        <div className={classes.contacts}>
          <div
            key={contact?.id}
            className={classes.contacts}
          >
            <div className={classes.contact}>
              <div className={classes.contactInfoBold}>{`${contact?.first_name} ${contact?.last_name}`}</div>
              <div className={classes.contactInfoSimple}>{contact?.customCompanyFunction ? contact?.customCompanyFunction : contact?.companyFunction?.name}</div>
              <div className={classes.contactInfoBold}>{contact?.email}</div>
              <div className={classes.contactInfoBold}>{contact?.phone}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )

  const renderContactMembers = () => (
    <div className={classes.contactMembersContainer}>
      <h1 className={classes.contactMembersTitle}>{contactMembersLabel}</h1>
      <div className={classes.contactMembers}>
        {!canSeeContacts ? (
          <div className={classes.onlyPremium}>{t('companyDetails_premiumRestricted')}</div>
        ) : contactMembers.map((contactMember) => (
          <div
            key={contactMember.id}
            className={classes.contactMember}
          >
            <div className={classes.contactMemberName}>
              {`${contactMember.first_name} ${contactMember.last_name}`}
            </div>
            <div className={classes.contactMemberFunctionDescription}>{contactMember.companyFunction?.name}</div>
            {contactMember.seeProfileLink !== null && contactMember.seeProfileLabel !== null && (
              <ActionButton
                noBg
                iconRight
                label={contactMember.seeProfileLabel}
                link={contactMember.seeProfileLink}
                route={contactMember.seeProfileRoute}
                routeParams={contactMember.seeProfileRouteParams}
                color="white"
                icon={iconsKeys.ArrowRight}
                colorIcon={colors.darkBlue}
                className={classes.contactMemberSeeProfile}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <>
      {isConnected ? (
        <div className={cx(classes.container, className)}>
          {renderHeader()}
          {!!metrics
              && (
                <CompanyMetrics
                  metrics={metrics}
                  className={classes.metrics}
                />
              )}
          {!!description && renderDescription()}
          {!!certifications && certifications.length > 0 && !!certificationsLabel && renderCertifications()}
          {!!plainCertifications && plainCertifications.length > 0 && !!plainCertificationsLabel
              && renderPlainCertifications()}
          {!!references && references.length > 0 && !!referencesLabel && renderReferences()}
          {!!contacts && contacts.length > 0 && !!contactsLabel && renderContacts()}
          {!!contactMembers && contactMembers.length > 0 && !!contactMembersLabel && renderContactMembers()}
          {!!video && (
            <CompanyGalleryVideo
              {...video}
              className={classes.video}
            />
          )}
          {!!gallery && (
            <CompanyGallery
              {...gallery}
              className={classes.gallery}
            />
          )}
          {Boolean(files?.files?.length) && (
            <>
              <h1 className={classes.filesTitle}>{filesLabel}</h1>
              <FileLibrary
                {...files}
                ctaLabel={filesSeeLabel}
                className={classes.files}
              />
            </>
          )}
          {!!offers && <CompanyOffers {...offers} />}
        </div>
      ) : (
        <div className={cx(classes.container, className)}>
          {renderHeader()}
          {!!metrics
              && (
                <CompanyMetrics
                  metrics={metrics}
                  className={classes.metrics}
                />
              )}
          {!!description && renderDescription()}
          <ActionButton
            className={classes.apply}
            onClick={handleModalOpen}
            label={t('companyDetails_comment_unauthenticated')}
            icon={iconsKeys.SmallArrowRight}
            iconRight
            colorIcon={colors.pureWhite}
            colorIconHover={colors.darkBlue}
          />
        </div>
      )}
    </>
  )
}

CompanyDetailsMain.propTypes = {
  className: PropTypes.string,
  company: PropTypes.shape({
    title: PropTypes.string,
    logo: PropTypes.string,
    description: PropTypes.string,
    metrics: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })),
    isOwnCompany: PropTypes.bool,
    canComment: PropTypes.bool,
    commentMadeByUser: PropTypes.object,
    comments: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      content: PropTypes.string,
      is_recommendation: PropTypes.bool,
      user: PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string,
        avatar: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        anonymous: PropTypes.bool,
        company: PropTypes.shape({
          name: PropTypes.string,
          slug: PropTypes.string,
        }),
        companyFunction: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      }),
    })),
    certifications: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      logo: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
    })),
    contacts: PropTypes.arrayOf(PropTypes.shape({
      companyFunction: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      customCompanyFunction: PropTypes.string,
      email: PropTypes.string,
      first_name: PropTypes.string,
      id: PropTypes.string,
      last_name: PropTypes.string,
      phone: PropTypes.string,
    })),
    contactMembers: PropTypes.arrayOf(PropTypes.shape({
      companyFunction: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      first_name: PropTypes.string,
      id: PropTypes.string,
      last_name: PropTypes.string,
      slug: PropTypes.string,
    })),
    backLabel: PropTypes.string,
    backLink: PropTypes.string,
    backRoute: PropTypes.string,
    backRouteParams: PropTypes.shape({
      slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }),
    banner: PropTypes.string,
    phone: PropTypes.string,
    domains: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })),
    map: PropTypes.shape(Map.propTypes),
    addresses: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      street: PropTypes.string,
      zip: PropTypes.string,
      city: PropTypes.string,
    })),
    links: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      link: PropTypes.string,
      icon: PropTypes.string,
    })),
    certificationsLabel: PropTypes.string,
    contactsLabel: PropTypes.string,
    contactMembersLabel: PropTypes.string,
    filesLabel: PropTypes.string,
    filesSeeLabel: PropTypes.string,
    phoneLabel: PropTypes.string,
    websiteLabel: PropTypes.string,
    socialIconsLabel: PropTypes.string,
    website: PropTypes.string,
    video: PropTypes.shape(CompanyGalleryVideo.propTypes),
    gallery: PropTypes.shape(CompanyGallery.propTypes),
    files: PropTypes.shape(FileLibrary.propTypes),
    offers: PropTypes.shape(CompanyOffers.propTypes),
    plainCertifications: PropTypes.arrayOf(PropTypes.string),
    plainCertificationsLabel: PropTypes.string,
    references: PropTypes.arrayOf(PropTypes.string),
    referencesLabel: PropTypes.string,
  }),
  sharing: PropTypes.shape(Sharing.propTypes),
  isConnected: PropTypes.bool,
  handleModalOpen: PropTypes.func,
}

CompanyDetailsMain.defaultProps = {
  className: '',
  company: null,
  sharing: null,
  isConnected: true,
  handleModalOpen: null,
}

export default withMemo()(CompanyDetailsMain)
