import cx from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import { imageTypes } from '../CloudinaryImage/types'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

function capitalize(s) {
  if (typeof s !== 'string') {
    return ''
  }

  return s.charAt(0).toUpperCase() + s.slice(1)
}

const PreniumEventCardHighlight = (props) => {
  const classes = useStyles(props)
  const {
    className,
    beginDate,
    endDate,
    type,
    cover,
    title,
    place,
    ctaLabel,
    handleGoBack,
    ...otherProps
  } = props

  const date = useMemo(() => {
    const beginMoment = moment(beginDate)
    const endMoment = moment(endDate)

    const sameDay = beginMoment.format('D') === endMoment.format('D') && beginMoment.format('M') === endMoment.format('M') && beginMoment.format('YYYY') === endMoment.format('YYYY')
    const dateFormat = 'dddd D MMMM YYYY'

    if (sameDay) {
      return `${capitalize(beginMoment.format(dateFormat))}`
    }
    return `Du ${beginMoment.format(dateFormat)} au ${endMoment.format(dateFormat)}`
  }, [beginDate, endDate])

  const time = useMemo(() => {
    const beginMoment = moment(beginDate)
    const endMoment = moment(endDate)
    const sameDay = beginMoment.format('D') === endMoment.format('D') && beginMoment.format('M') === endMoment.format('M') && beginMoment.format('YYYY') === endMoment.format('YYYY')

    if (beginMoment.isSame(endDate)) {
      return `${capitalize(beginMoment.format('LT'))}`
    }
    if (sameDay) {
      return `de ${capitalize(beginMoment.format('LT'))} à ${endMoment.format('LT')}`
    }
    return `de ${capitalize(beginMoment.format('LT'))} à ${endMoment.format('LT')}`
  }, [beginDate, endDate])

  return (
    <CloudinaryImage
      {...otherProps}
      lazyLoad
      id={cover}
      type={imageTypes.Background}
      className={cx(classes.container, className)}
      options={{
        crop: 'fill',
        height: 500,
      }}
    >
      <div className={classes.gradient} />
      <div className={cx(classes.content)}>
        <div className={classes.left}>
          <ActionButton
            label={ctaLabel}
            onClick={handleGoBack}
            icon={iconsKeys.ArrowLeft}
            color="white"
            iconLeft
            colorIcon={colors.darkBlue}
            className={classes.cta}
          />
          <div className={classes.title}>{title}</div>
        </div>
        <div className={classes.right}>
          <div className={classes.infoRow}>
            <div className={classes.dateText}>{date}</div>
            <div className={classes.timeText}>{time}</div>
          </div>
          <div className={classes.locationRow}>
            <div className={classes.type}>{type}</div>
            <div className={classes.location}>
              <Icon
                icon={iconsKeys.Location}
                color={colors.nightRider}
                className={classes.infoIcon}
              />
              <div className={classes.place}>{place}</div>
            </div>
          </div>
        </div>
      </div>
    </CloudinaryImage>
  )
}

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  place: PropTypes.string,
  beginDate: PropTypes.string,
  endDate: PropTypes.string,
  cover: PropTypes.string,
  ctaLabel: PropTypes.string,
  handleGoBack: PropTypes.func,
}

PreniumEventCardHighlight.propTypes = propTypes

PreniumEventCardHighlight.defaultProps = {
  className: '',
  title: null,
  type: null,
  place: null,
  beginDate: null,
  endDate: null,
  cover: null,
  ctaLabel: null,
  handleGoBack: () => {},
}

export default withMemo()(PreniumEventCardHighlight)
