import { colors, tablet, desktop } from '../../theme'


export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBottom: '5.8rem',
    background: colors.honeydew,
    ...desktop({
      paddingBottom: '8.2rem',
    }),
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...tablet({
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
      marginBottom: '1.5rem',
    }),
  },
  cta: {},
}
