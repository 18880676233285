/* eslint-disable max-lines */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import FilterBox from '../FilterBox'
import MarkdownText from '../MarkdownText'
import Pagination from '../Pagination'
import PostCard from '../PostCard'
import PushMessage from '../PushMessage'
import PushPremium from '../PushPremium'

import styles from './styles'


const useStyles = createUseStyles(styles)

const PostsResults = (props) => {
  const {
    className,
    isConnected,
    isPremium,
    title,
    text,
    filterBoxProps,
    posts,
    emptyPostsText,
    pagination,
    handleSubmit,
    pushPublishProps,
    isCurrentOGCN,
  } = props

  const [filterValues, setFilterValues] = useState(filterBoxProps.values || [])
  const [isFilterOpen, setFilterOpen] = useState(false)
  const [timerId, setTimerId] = useState(null)
  const [needsUpdate, setNeedsUpdate] = useState(false)
  const classes = useStyles(props)

  const handleFilterChange = useCallback((name, value) => {
    setFilterValues((before) => [
      ...before.filter((item) => item.name !== name),
      {
        name,
        value,
      },
    ])
    setNeedsUpdate(true)
  }, [])

  const doSearch = useCallback(() => {
    if (timerId) {
      clearTimeout(timerId)
    }
    setTimerId(setTimeout(() => {
      handleSubmit({
        filterValues,
      })
    }, 500))
  }, [handleSubmit, filterValues, timerId])

  const handleToggleFiltering = () => {
    setFilterOpen(!isFilterOpen)
  }

  const renderPosts = () => posts.map((post, i) => (
    <PostCard
      key={i}
      {...post}
      isConnected={isConnected}
      isPremium={isPremium}
      className={classes.postsListItemContainer}
      isCurrentOGCN={isCurrentOGCN}
    />
  ))

  const renderEmptyPosts = () => (
    <p
      className={classes.emptyPosts}
      dangerouslySetInnerHTML={{ __html: emptyPostsText }}
    />
  )

  const filterContainerRef = React.createRef()
  const filterSubmenuRef = React.createRef()

  const hasParent = (target, parent, currentRef) => {
    if (!target) {
      return false
    }
    if (target === parent || target.parentElement === currentRef) {
      return true
    }
    return hasParent(target.parentElement, parent, currentRef)
  }

  const handleWindowClick = (e) => {
    if (isFilterOpen) {
      if (!hasParent(e.target, filterContainerRef.current, filterSubmenuRef.current)) {
        setFilterOpen(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleWindowClick)

    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  })

  useEffect(() => {
    if (needsUpdate) {
      setNeedsUpdate(false)
      doSearch()
    }
  }, [needsUpdate, doSearch])

  return (
    <div className={cx(classes.container, className)}>
      <FilterBox
        {...filterBoxProps}
        className={classes.filterBlock}
        isOpen={isFilterOpen}
        toggleSelect={() => handleToggleFiltering()}
        values={filterValues}
        onChange={({ name, value }) => handleFilterChange(name, value)}
        containerRef={filterContainerRef}
        submenuRef={filterSubmenuRef}
      />
      <div className={classes.content}>

        {title !== null && (
          <MarkdownText
            text={title}
            className={classes.title}
          />
        )}
        {text !== null && (
          <MarkdownText
            text={text}
            className={classes.text}
          />
        )}

        <div className={classes.results}>
          {posts.length >= 1 && (
            <div className={classes.postsList}>
              {renderPosts()}
            </div>
          )}
          {posts.length === 0 && emptyPostsText !== null && renderEmptyPosts()}
        </div>

        {posts.length >= 1 && (
          <Pagination {...pagination} />
        )}
      </div>

      {Boolean(pushPublishProps) && (
        <PushPremium
          {...pushPublishProps}
        />
      )}
    </div>
  )
}

PostsResults.propTypes = {
  className: PropTypes.string,
  isConnected: PropTypes.bool,
  isPremium: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  filterBoxProps: PropTypes.shape(FilterBox.propTypes),
  posts: PropTypes.arrayOf(PropTypes.shape(PostCard.propTypes)),
  emptyPostsText: PropTypes.string,
  pagination: PropTypes.shape(Pagination.propTypes),
  handleSubmit: PropTypes.func,
  order: PropTypes.string,
  pushPublishProps: PropTypes.shape(PushMessage.propTypes),
  isCurrentOGCN: PropTypes.bool,
}

PostsResults.defaultProps = {
  className: '',
  isConnected: false,
  isPremium: false,
  title: null,
  text: null,
  filterBoxProps: null,
  posts: [],
  emptyPostsText: null,
  pagination: null,
  handleSubmit: () => undefined,
  order: '',
  pushPublishProps: null,
  isCurrentOGCN: false,
}

export default withMemo()(PostsResults)
