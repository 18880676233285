import { colors, desktop, fonts } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '50rem',
    alignSelf: 'center',
    ...desktop({
      maxWidth: '30rem',
      alignSelf: 'stretch',
    }),
  },
  block: {
    boxShadow: '0px 5px 30px rgba(84, 84, 84, 0.2)',
    borderRadius: '2rem',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem 2rem',
    textAlign: 'center',
    borderTopLeftRadius: '2rem',
    borderTopRightRadius: '2rem',
    color: colors.pureWhite,
    backgroundColor: colors.darkBlue,
  },
  beginDate: {
    ...fonts.FiraSansBold,
    fontSize: '2.2rem',
    lineHeight: '2.6rem',
  },
  endDate: {
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    lineHeight: '1.7rem',
    marginTop: '0.9rem',
  },
  body: {
    padding: '2rem 3rem',
    borderBottomLeftRadius: '2rem',
    borderBottomRightRadius: '2rem',
    backgroundColor: colors.pureWhite,
  },
  indication: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.8rem',
    lineHeight: '2.2rem',
    color: colors.darkBlue,
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
  place: {
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeIcon: {
    width: '2.4rem',
    height: '2.4rem',
    marginRight: '1rem',
  },
  placeText: {
    ...fonts.FiraSansRegular,
    fontSize: '1.5rem',
    lineHeight: '2.0rem',
  },
  cta: {
    margin: '0 2.5rem',
    marginTop: '4.3rem',
    display: 'none',
    ...desktop({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }),
  },
}
