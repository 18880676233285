import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import ActionButton from '../ActionButton'
import EventCard from '../EventCard'
import Paragraph from '../Paragraph'

import styles from './styles'


const useStyles = createUseStyles(styles)

const EventDetailsMain = (props) => {
  const classes = useStyles(props)
  const { className, event, cta } = props
  const {
    introduction,
    description,
    programLabel,
    program,
  } = event

  const renderIntroduction = () => (
    <div className={classes.introduction}>
      {introduction}
    </div>
  )

  const renderDescription = () => (
    <Paragraph
      text={description}
      className={classes.description}
      classNames={{ parentContainer: classes.descriptionContainer }}
    />
  )

  const renderProgram = () => (
    <>
      <div className={classes.programLabel}>{programLabel}</div>
      <Paragraph
        text={program}
        className={classes.program}
        classNames={{ parentContainer: classes.programContainer }}
      />
    </>
  )

  const renderCTA = () => (
    <div className={classes.cta}>
      <ActionButton {...cta} />
    </div>
  )

  return (
    <div className={cx(classes.container, className)}>
      {!!introduction && renderIntroduction()}
      {!!description && renderDescription()}
      {!!program && renderProgram()}
      {!!cta && renderCTA()}
    </div>
  )
}

EventDetailsMain.propTypes = {
  className: PropTypes.string,
  event: PropTypes.shape({
    title: PropTypes.string,
    introduction: PropTypes.string,
    description: PropTypes.string,
    programLabel: PropTypes.string,
    program: PropTypes.string,
    beginDate: PropTypes.string,
    endDate: PropTypes.string,
    indicationLabel: PropTypes.string,
    place: PropTypes.string,
    cover: PropTypes.string,
    backLabel: PropTypes.string,
    backLink: PropTypes.string,
    backRoute: PropTypes.string,
    backRouteParams: PropTypes.shape({
      slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }),
    link: PropTypes.string,
    mapLabel: PropTypes.string,
    address: PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      street: PropTypes.string,
      zip: PropTypes.string,
      city: PropTypes.string,
    }),
    phoneLabel: PropTypes.string,
    phone: PropTypes.string,
    linkedEventsLabel: PropTypes.string,
    linkedEvents: PropTypes.arrayOf(PropTypes.shape(EventCard.propTypes)),
  }),
  cta: PropTypes.shape(ActionButton.propTypes),
}

EventDetailsMain.defaultProps = {
  className: '',
  event: null,
  cta: null,
}

export default withMemo()(EventDetailsMain)
