import { fonts, colors } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: colors.nightRider,
    marginBottom: '1rem',
  },
  icon: {
    margin: '0 1rem',
    cursor: 'pointer',
    '& svg': {
      width: '1.7rem',
      height: '1.7rem',
      maxWidth: '100%',
      maxHeight: '100%',
      minWidth: '1.7rem',
      minHeight: '1.7rem',
    },
  },
}
