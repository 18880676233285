import { fonts, colors, tablet, desktop } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    padding: '4rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '94rem',
    margin: '0 auto',
  },
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '2rem',
    lineHeight: '2.6rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: '2rem',
    color: colors.darkBlue,
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...tablet({
      display: 'grid',
      gridGap: '2rem',
      gridTemplateColumns: '22rem 22rem 22rem',
    }),
    ...desktop({
      gridTemplateColumns: '22rem 22rem 22rem 22rem',
    }),
  },
  card: {
    marginBottom: '2rem',
  },
}
