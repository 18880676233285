import { colors, desktop, fonts } from '../../theme'


export default {
  container: (props) => ({
    width: '100%',
    ...props.isCard ? {
      borderRadius: '2rem',
      padding: '2.9rem 3.7rem',
    } : {
      padding: '2rem 1rem',
      ...desktop({
        padding: '2.4rem 0 2.7rem',
      }),
    },
    background: colors.pureWhite,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'center',
  }),
  title: (props) => ({
    ...fonts.FiraSansBold,
    color: colors.darkBlue,
    maxWidth: '62rem',
    ...props.isCard ? {
      fontSize: '1.4rem',
      lineHeight: '1.7rem',
      textTransform: 'none',
      marginBottom: '1rem',
      ...desktop({
        fontSize: '2.2rem',
        lineHeight: '2.6rem',
        marginBottom: '1.3rem',
      }),
    } : {
      fontSize: '1.8rem',
      lineHeight: '2.2rem',
      textTransform: 'uppercase',
      marginBottom: '1rem',
      ...desktop({
        fontSize: '2rem',
        lineHeight: '2.4rem',
        marginBottom: '1rem',
      }),
    },
  }),
  textContainer: {
    padding: 0,
  },
  text: {
    ...fonts.FiraSansRegular,
    maxWidth: '62rem',
    fontSize: '1.2rem',
    lineHeight: (props) => (props.isCard ? '1.8rem' : '2rem'),
    textAlign: 'center',
    marginBottom: '3rem',
    color: colors.nightRider,
    '& p': {
      color: colors.nightRider,
    },
    ...desktop({
      fontSize: '1.5rem',
      lineHeight: (props) => (props.isCard ? '1.8rem' : '2rem'),
    }),
  },
  modal: {
    width: '100%',
    background: colors.pureWhite,
    borderRadius: '2rem',
    padding: '2.5rem 3rem',
    margin: '0 auto',
    maxWidth: '44rem',
    textAlign: 'left',
  },
  modalTitle: {
    width: '100%',
    ...fonts.FiraSansBold,
    fontSize: '1.8rem',
    lineHeight: '120%',
    color: colors.nightRider,
    textAlign: 'left',
    marginBottom: '1rem',
  },
  registerContainer: {
    textAlign: 'center',
    padding: '1rem 0',
  },
  modalText: {
    padding: '1rem 0',
    width: '100%',
    ...fonts.FiraSansRegular,
  },
}
