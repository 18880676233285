import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'
import ReactSelect from 'react-select'
import Immutable from 'seamless-immutable'

import { generateUniqueId } from '../../utils/ComponentUtils'
import withMemo from '../../decorators/WithMemo'

import styles, { selectStyles } from './styles'


const useStyles = createUseStyles(styles)

function SearchSelect(props) {
  const {
    className,
    name,
    label,
    options,
    value,
    onChange,
    id,
  } = props

  const [thisId] = useState(`select_${generateUniqueId()}`)
  const classes = useStyles(props)

  const handleChange = (selectedOption) => {
    if (onChange !== null) {
      onChange({ name, value: selectedOption.value })
    }
  }

  let selectedOption = null

  let newOptions = options

  if (value !== null && value !== '') {
    const isMutable = Immutable.isImmutable(options)

    if (isMutable) {
      newOptions = Immutable.asMutable(options)
    }

    selectedOption = newOptions.reduce((res, option) => {
      if (option.value === value) {
        return option
      }
      return res
    }, null)
  }

  return (
    <div className={cx(classes.container, className)}>
      <label
        htmlFor={`${id || thisId}_input`}
        className={classes.label}
      >
        {label}
      </label>
      <ReactSelect
        className={cx(classes.select)}
        classNamePrefix="react-select"
        styles={selectStyles(props)}
        aria-label={name}
        name={name}
        placeholder={label}
        id={`${id || thisId}`}
        inputId={`${id || thisId}_input`}
        instanceId={`${id || thisId}_instance`}
        options={options}
        value={selectedOption?.value !== 'empty' ? selectedOption : null}
        onChange={handleChange}
        isMulti={false}
        isSearchable={false}
      />
    </div>
  )
}

SearchSelect.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
}

SearchSelect.defaultProps = {
  className: '',
  id: null,
  label: null,
  value: null,
  onChange: null,
}

export default withMemo()(SearchSelect)
