import { tablet } from '../../theme'


export default {
  container: {
    maxWidth: '70rem',
    margin: '0 auto',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    justifyContent: 'center',
    marginLeft: '-1rem',
    marginRight: '-1rem',
    '& > *': {
      maxWidth: 'calc(50% - 2rem)',
      marginLeft: '1rem',
      marginRight: '1rem',
      marginBottom: '1rem',
      overflow: 'hidden',
      ...tablet({
        maxWidth: 'calc(33.3% - 2rem)',
      }),
    },
  },
  picture: {
    cursor: 'pointer',
    '& img': {
      maxWidth: '100%',
    },
  },
}
