import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import Color from 'color'
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, LabelList } from 'recharts'

import { colors } from '../../theme'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const AttractivenessGraph = (props) => {
  const classes = useStyles(props)
  const { className, title, subtitle, text, offersLabel, profilesLabel, yLabel, data } = props

  const renderLabels = (values) => {
    const offersAmount = values && values.find((value) => value.name === 'offers').value
    const profilesAmount = values && values.find((value) => value.name === 'profiles').value

    return (
      <div className={classes.labels}>
        <div className={classes.label}>
          <div className={cx(classes.labelColor, classes.offers)} />
          <div
            className={classes.labelValue}
            style={{ color: colors.darkBlue }}
          >
            {offersAmount && `${offersAmount} `}
            {offersLabel}
          </div>
        </div>
        <div className={classes.label}>
          <div className={cx(classes.labelColor, classes.profiles)} />
          <div
            className={classes.labelValue}
            style={{ color: colors.grey }}
          >
            {profilesAmount && `${profilesAmount} `}
            {profilesLabel}
          </div>
        </div>
      </div>
    )
  }

  const ChartLabel = (chartLabelProps) => (
    <text
      x={chartLabelProps.x}
      y={chartLabelProps.y}
      width={chartLabelProps.width}
      height={chartLabelProps.height}
      className={cx(classes.graphLabel, chartLabelProps.isOffers ? classes.offersLabel : classes.profilesLabel)}
      textAnchor="middle"
      transform={`rotate(270, ${chartLabelProps.x}, ${chartLabelProps.y})`}
    >
      <tspan
        x={chartLabelProps.x}
        dx="-15px"
        dy={chartLabelProps.isOffers ? '-11px' : '58px'}
      >
        {chartLabelProps.value}
      </tspan>
    </text>
  )

  const GraphTooltip = (graphTooltipProps) => {
    if (graphTooltipProps.active) {
      return (
        <div className={classes.graphTooltip}>
          <div className={classes.graphTooltipTitle}>{graphTooltipProps.label}</div>
          {renderLabels(graphTooltipProps.payload)}
        </div>
      )
    }

    return null
  }

  const XAxisLabel = (XAxisProps) => {
    const { x, y, payload } = XAxisProps
    const textArr = payload.value.split('.')

    return (
      <g transform={`translate(${x}, ${y})`}>
        <text
          x={-13}
          y={7}
          fill="#333333"
          fontWeight="bold"
        >
          {textArr[0]}
        </text>
        <text
          x={0}
          y={7}
          fill="#333333"
        >
          {`.${textArr[1]}`}
        </text>
      </g>
    )
  }

  const renderChart = () => {
    const orange = Color(colors.darkOrange).fade(0.6).string()

    return (
      <ResponsiveContainer
        width="100%"
        height={250}
        className={classes.graphContainer}
      >
        <BarChart data={data}>
          <XAxis
            dataKey="period"
            interval={0}
            tickLine={false}
            tick={<XAxisLabel />}
          />
          <YAxis tick={false} />
          <Tooltip content={<GraphTooltip />} />
          <Bar
            dataKey="profiles"
            fill={orange}
            barSize={39}
            minPointSize={30}
          >
            <LabelList
              dataKey="profiles"
              position="right"
              content={<ChartLabel />}
            />
          </Bar>
          <Bar
            dataKey="offers"
            fill={colors.darkBlue}
            barSize={23}
            minPointSize={30}
          >
            <LabelList
              dataKey="offers"
              position="right"
              content={<ChartLabel isOffers />}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    )
  }

  return (
    <div className={cx(classes.container, className)}>
      {title && <div className={classes.title}>{title}</div>}
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
      <div className={classes.data}>
        <div className={classes.graph}>
          <div className={classes.graphTitle}>{yLabel}</div>
          {renderChart()}
        </div>
        <div className={classes.infos}>
          {renderLabels()}
          {text && <div className={classes.text}>{text}</div>}
        </div>
      </div>
    </div>
  )
}

AttractivenessGraph.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  yLabel: PropTypes.string.isRequired,
  offersLabel: PropTypes.string.isRequired,
  profilesLabel: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    period: PropTypes.string,
    offers: PropTypes.number,
    profiles: PropTypes.number,
  })).isRequired,
}

AttractivenessGraph.defaultProps = {
  className: '',
  title: null,
  subtitle: null,
  text: null,
}

export default withMemo()(AttractivenessGraph)
