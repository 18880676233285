import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import A from '../A'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const RssFeedButton = (props) => {
  const classes = useStyles(props)
  const { className, label, link } = props

  return (
    <A
      className={cx(classes.container, className)}
      href={link}
      target="_blank"
      title={label}
    >
      <Icon
        icon={iconsKeys.Rss}
        color={colors.pureWhite}
        className={classes.icon}
      />
      {label && <div className={classes.label}>{label}</div>}
    </A>
  )
}

RssFeedButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

RssFeedButton.defaultProps = {
  className: '',
}

export default withMemo()(RssFeedButton)
