const colors = {
  pureBlack: '#000',
  pureWhite: '#FFF',
  error: '#E50043',
  primary: '#EF7D00',
  secondary: '#164194',
  // CCI colors
  neonBlue: '#373CF5',
  royalBlue: '#3769FF',
  darkBlue: '#164194',
  egyptianBlue: '#141EA5',
  brightBlue: '#7295FD',
  icebergBlue: '#7BADDD',
  aliceBlue: '#F3FAFF',
  burntOrange: '#FA742B',
  tangerine: '#EF7D00',
  darkOrange: '#FA9202',
  nightRider: '#333333',
  grey: '#767676',
  whiteGrey: '#F9F9F9',
  silver: '#C5C5C5',
  gainsboro: '#E0E0E0',
  honeydew: '#F6F7F6',
  crimson: '#E50043',
  darkCerulean: '#00437A',
  grayTranslucent: 'rgba(0,0,0,.4)',
  gray2: '#4F4F4F',
  gray4: '#BDBDBD',
  gray6: '#F2F2F2',
  downy: '#70D2CC',
  shamrock: '#34E786',
  transparent: 'rgba(0,0,0,0)',
  frenchBlue: '#0075BF',
  spanishOrange: '#EB6109',
  orangePeel: '#F59C00',
  brightNavyBlue: '#3973B9',

  // Category
  categoryCommerce: '#F5A623',
  categoryCommunication: '#F5A623',
  categoryCotraitance: '#F5A623',
  categoryEspaceverts: '#F5A623',
  categoryGestion: '#CF25AA',
  categoryInformatique: '#CF25AA',
  categoryJuridique: '#EF7D00',
  categorySecurite: '#EF7D00',
  categoryTravaux: '#EF7D00',
}

export default colors
