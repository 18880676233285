import nextRoutes from 'next-routes'

import routes from './definitions'


const router = nextRoutes()

Object.values(routes)
  .map(({ component, path, name }) => router.add({
    page: component,
    name,
    pattern: path,
  }))

const defaultParams = {}

router.Router.getRouteUrl = (route, params = {}) => {
  if (!route || !route.name) {
    return ''
  }
  let routeUrl

  const p = { ...(route?.path?.indexOf(':lang') !== -1 ? defaultParams : {}), ...params }

  try {
    routeUrl = router.findAndGetUrls(route.name, p)?.urls?.as
  } catch (e) {
    console.warn('findAndGetUrls Error', route, e)
    return ''
  }

  Object.keys(p).forEach((key) => {
    routeUrl = routeUrl.replace(new RegExp(`:${key}\\??`, 'gm'), p[key])
    routeUrl = decodeURIComponent(routeUrl)
    routeUrl = routeUrl.replace('//', '/')
  })
  return routeUrl
}

export default routes
export const Link = router.Link
export const Router = router.Router
