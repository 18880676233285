import QueryString from './QueryString'


const queryStringConfig = { arrayFormat: 'comma' }

export const getFilterValues = (params, filterOptions) => filterOptions
  .filter((f) => !!params[f?.slug])
  .map((f) => ({
    name: f.slug,
    value: f.isMulti
      ? (Array.isArray(params[f.slug]) ? params[f.slug] : params[f.slug].split(',')).filter((v) => f?.options?.find((s) => s.value === v)) || null
      : f?.options?.find((s) => s.value === params[f.slug])?.value || null,
  }))

export const composeQuery = (params, query, pagination, resetPage, add = {}) => {
  const queryFilters = {
    ...params?.filterValues?.reduce((obj, f) => ({ ...obj, [f?.name]: f.value }), {}),
    ...add,
  }

  if (params.sortValue) {
    queryFilters.ordre = params.sortValue
  }

  const qs = QueryString.stringify(queryFilters, queryStringConfig)
  const slug = query.slug
  const page = resetPage || !pagination ? 1 : pagination.currentPage

  return `/${slug}${page > 1 ? `/p${page}` : ''}${qs ? `?${qs}` : ''}`
}

export const parseQueryParams = (path, slug) => {
  const qs = path.replace(`/${slug}`, '').replace(/.*\?/, '')

  return { ...QueryString.parse(qs) }
}

export const getPagination = (pagination, path, query, params) => {
  const qs = path.replace(/.*\?/, '')
  const paginationSlug = query?.slug && query.slug.replace(`?${qs}`, '')
  const slugsPagination = paginationSlug ? paginationSlug.split('/') : query?.slug
  const paramsPagination = params

  Object.keys(paramsPagination).forEach(
    (key) => paramsPagination[key] === null && delete paramsPagination[key]
  )

  return {
    ...pagination,
    routeParams: {
      slug: slugsPagination,
      ...paramsPagination,
    },
  }
}

export const getFilterEntryIdFromSlug = (slugs, slug) => {
  const entry = slugs.find((s) => s.value === slug)

  return entry?.id || entry?.value
}

export const getFilterOptions = (t, config, prefix) => config?.map((f) => ({
  visible: f.visible !== false,
  type: f.type,
  isMulti: f.isMulti,
  name: f.slug,
  label: t(`${prefix}_filter_${f.name}`),
  value: f.isMulti ? [] : '',
  options: [
    {
      label: t('filter_all'),
      value: null,
    },
    ...(f.options
      ? f.options?.map((option) => ({
        label: option.label || t(option.key),
        value: option.value,
      }))
      : []),
  ],
}))

export const getRelationFilterOptions = (t, config, prefix) => config?.map((f) => ({
  visible: f.visible !== false,
  type: f.type,
  isMulti: f.isMulti,
  name: f.slug,
  label: t(`${prefix}_filter_${f.name}`),
  value: f.isMulti ? [] : '',
  options: [
    {
      label: t('filter_all'),
      value: t('filter_all'),
    },
    ...(f.options
      ? f.options?.map((option) => ({
        label: option.label || t(option.key),
        value: option.value,
      }))
      : []),
  ],
}))

export const getSortOptions = (t, config, prefix) => config?.map((sort) => ({
  label: t(`${prefix}_sort_${sort.name}`),
  value: sort.slug,
}))

export const getFilterValuesFormQueryParams = (queryParams, filterConfig) => {
  const filterSlugs = filterConfig.map((f) => f.slug || f.name)
  const filtersBySlug = filterConfig.reduce((obj, f) => ({ ...obj, [f.slug || f.name]: f }), {})

  if (queryParams) {
    return Object.keys(queryParams)
      .filter((k) => filterSlugs.includes(k))
      .reduce((obj, k) => {
        const conf = filtersBySlug[k]
        const isMulti = conf?.isMulti

        return {
          ...obj,
          [conf.name]: isMulti
            ? queryParams[k]
              .split(',')
              .map((slug) => getFilterEntryIdFromSlug(conf.options, slug))
              .filter((id) => !!id)
            : getFilterEntryIdFromSlug(conf.options, queryParams[k]),
        }
      }, {})
  }
  return null
}

export const getSortValueFromQueryParams = (queryParams, sortConfig) => {
  let item

  if (queryParams && queryParams?.ordre) {
    item = sortConfig?.find((entry) => entry.slug === queryParams.ordre)
  }

  if (!item) {
    item = sortConfig?.find((entry) => entry.default) || sortConfig[0]
  }

  if (item) {
    return {
      field: item.field,
      order: item.order,
    }
  }
  return null
}

export const getSortSlugFromValue = (item, sortConfig) => item
  && sortConfig?.find((entry) => entry?.field === item?.field && entry?.order === item?.order)?.slug

export const transformFiltersFromSlug = (asPath, regex) => {
  let matches
  const slugFilters = {}

  // eslint-disable-next-line no-cond-assign
  while ((matches = regex.exec(asPath)) !== null) {
    const property = matches[1]

    if (!Object.prototype.hasOwnProperty.call(slugFilters, property)) {
      slugFilters[property] = matches[2]
    } else {
      if (!Array.isArray(slugFilters[property])) {
        slugFilters[property] = [slugFilters[property]]
      }
      slugFilters[property].push(matches[2])
    }
  }

  return slugFilters
}

export const getSlugFiltersRegex = (slugs) => new RegExp(`(?:\\/(?:(?:(${slugs.join('|')})-([\\w\\d-+]+))))`, 'g')

export const getFilterValuesFromQueryString = (slugFilters, filterConfig) => {
  const filterValuesFromQueryString = {}

  Object.keys(slugFilters).forEach((k) => {
    const conf = filterConfig?.find((f) => f.slug === k)

    if (conf) {
      filterValuesFromQueryString[conf.name] = Array.isArray(slugFilters[k])
        ? slugFilters[k]?.map((e) => conf?.options?.find((o) => o.value === e)?.id)
        : conf?.isMulti
          ? [conf?.options?.find((o) => o.value === slugFilters[k])?.id]
          : conf?.options ? conf?.options?.find((o) => o.value === slugFilters[k])?.id : slugFilters[k]
    }
  })

  return filterValuesFromQueryString
}

export const buildQueryWithSlug = (route, filterConfig, pagination, p, resetPage) => {
  const query = route?.query
  const slugFiltersList = filterConfig?.filter((f) => f.isPath)?.map((f) => f.slug)
  const queryFilters = {
    ...p?.filterValues
      ?.filter((f) => !slugFiltersList.includes(f.name))
      ?.reduce((obj, f) => ({ ...obj, [f?.name]: f.value }), {}),
  }
  const slugFilters = p?.filterValues?.filter((f) => slugFiltersList.includes(f.name))

  if (p.sortValue) {
    queryFilters.ordre = p.sortValue
  }

  const qs = QueryString.stringify(queryFilters, queryStringConfig)
  const regex = getSlugFiltersRegex(slugFiltersList)
  const slugs = slugFilters?.filter((f) => f.value !== undefined)?.reduce((str, f) => (
    [...str, ...Array.isArray(f.value)
      ? f.value.map((v) => `/${f.name}-${v}`)
      : [`/${f.name}-${f.value}`]]
  ), [])
  const slug = query?.slug?.replace(regex, '')
    + Array.from(slugs).sort().join('')
  const page = resetPage || !pagination ? 1 : pagination.currentPage

  const href = `/${slug}${page > 1 ? `/p${page}` : ''}${qs ? `?${qs}` : ''}`

  return href
}
