import { colors, fonts, transition } from '../../theme'


export default {
  container: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  cta: {
    ...fonts.FiraSansMedium,
    color: colors.pureWhite,
    opacity: 0.7,
    fontSize: '1rem',
    lineHeight: '1rem',
    textTransform: 'uppercase',
    ...transition('opacity'),
    '&:hover': {
      opacity: 1,
    },
    '&.is-current': {
      opacity: 1,
    },
  },
  icon: {
    width: '.3rem',
    height: '.5rem',
    marginLeft: '.5rem',
    marginRight: '.5rem',
    display: 'inline-flex',
    alignItems: 'center',
    alignSelf: 'center',
  },
  iconAsset: {
    width: '0.65rem',
  },
}
