import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'

import { generateUniqueId } from '../../../../utils/ComponentUtils'
import withMemo from '../../../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function Input(props) {
  const {
    className,
    type,
    id,
    name,
    value,
    placeholder,
    required,
    disabled,
    maxChar,
    inputRef,
    readOnly,
    inputProps,
    onChange,
    onBlur,
    onFocus,
  } = props

  const thisId = useMemo(() => `form_input_${generateUniqueId()}`, [])
  const [focused, setFocused] = useState(false)

  const handleChange = useCallback((event) => {
    if (inputProps && inputProps.onChange) {
      inputProps.onChange(event)
    }
    onChange({
      name,
      value: event.currentTarget.value,
      event,
    })
  }, [inputProps, onChange, name])

  const handleFocus = useCallback((event) => {
    setFocused(true)
    if (inputProps && inputProps.onFocus) {
      inputProps.onFocus(event)
    }
    onFocus({ name, event })
  }, [inputProps, onFocus, name])

  const handleBlur = useCallback((event) => {
    setFocused(false)
    if (inputProps && inputProps.onBlur) {
      inputProps.onBlur(event)
    }
    onBlur({ name, event })
  }, [inputProps, onBlur, name])

  const classes = { ...useStyles({ ...props, focused }), ...props.classes }

  return (
    <input
      {...inputProps}
      id={id || thisId}
      ref={inputRef}
      className={cx(classes.input, className)}
      type={type}
      name={name}
      aria-label={name}
      value={value || ''}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      maxLength={maxChar}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  )
}

Input.styles = styles

Input.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.oneOf(['email', 'password', 'text', 'number']),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  maxChar: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  onChange: PropTypes.func, // {name, value, event}
  onBlur: PropTypes.func, // {name, event}
  onFocus: PropTypes.func, // {name, event}
  inputRef: PropTypes.func,
}

Input.defaultProps = {
  classes: {},
  className: null,
  id: null,
  inputClassName: null,
  value: '',
  type: 'text',
  required: false,
  disabled: false,
  readOnly: false,
  error: null,
  placeholder: null,
  maxChar: null,
  inputProps: null,
  onChange: () => undefined,
  onBlur: () => undefined,
  onFocus: () => undefined,
  inputRef: () => undefined,
}

export default withMemo()(Input)
