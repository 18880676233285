import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import Icon from '../Icon'
import RssButton from '../RssButton'

import styles from './styles'


const useStyles = createUseStyles(styles)

function FooterSocial(props) {
  const { className, rssButton, isInMobileMenu, links, onClickLink } = props

  const classes = useStyles(props)

  return (
    <div className={cx(classes.container, className)}>
      {
        links.map((p, i) => (
          <div
            className={classes.link}
            key={i}
          >
            <Icon
              {...p}
              target="_blank"
              onClick={onClickLink}
              color={isInMobileMenu ? colors.darkBlue : colors.pureWhite}
              iconProps={{ className: classes.iconAsset }}
            />
          </div>
        ))
      }
      {rssButton && isInMobileMenu && (
        <RssButton {...rssButton} />
      )}
    </div>
  )
}

FooterSocial.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape(Icon.propTypes)),
  isInMobileMenu: PropTypes.bool,
  rssButton: PropTypes.shape(RssButton.propTypes),
  onClickLink: PropTypes.func,
}

FooterSocial.defaultProps = {
  className: null,
  links: [],
  isInMobileMenu: false,
  rssButton: null,
  onClickLink: () => undefined,
}

export default withMemo()(FooterSocial)
