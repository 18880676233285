import { fonts, colors, desktop } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: (props) => ({
    width: '100%',
    maxWidth: '70rem',
    margin: '3rem auto',
    padding: '3rem 1rem 3rem',
    ...desktop({
      padding: '4.1rem 1rem 3rem',
    }),
    background: colors.honeydew,
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }),
  title: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    color: colors.nightRider,
  },
  textContainer: {
    padding: 0,
  },
  text: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    textAlign: 'center',
    color: colors.nightRider,
    marginBottom: '2rem',
    width: '100%',
  },
}
