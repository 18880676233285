/* eslint-disable max-lines */
import cx from 'classnames'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import NoSSR from 'react-no-ssr'

import withMemo from '../../../../decorators/WithMemo'
import { generateUniqueId } from '../../../../utils/ComponentUtils'
import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import FormLabel from '../../FormLabel'

import styles from './styles'


const RichTextEditor = process.browser ? (require('react-rte').default) : dynamic(
  () => import('react-rte'),
  { ssr: false }
)


const useStyles = createUseStyles(styles)

function RichText(props) {
  const {
    className,
    format,
    id,
    name,
    value,
    label,
    help,
    placeholder,
    required,
    disabled,
    error,
    readOnly,
    onChange,
    onBlur,
    onFocus,
  } = props

  const [thisId] = useState(`form_text_${generateUniqueId()}`)
  const [focused, setFocused] = useState(false)

  const [richValue, setRichValue] = useState(null)

  useEffect(() => {
    if (!richValue) {
      setRichValue(RichTextEditor.createValueFromString(value, format))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Remove useEffect to avoid break on iOS. I don't know why it was here in the first place.

  const handleChange = (nextRichValue) => {
    setRichValue(nextRichValue)
    onChange({
      name,
      value: nextRichValue.toString(format) || '',
      plain: nextRichValue.getEditorState()
        .getCurrentContent()
        .getPlainText('')
        .replace(/(?:\r\n|\r|\n)/g, '')
        .trim(),
      state: nextRichValue,
    })
  }

  const handleFocus = (event) => {
    setFocused(true)
    onFocus({ name, event })
  }

  const handleBlur = (event) => {
    setFocused(false)
    onBlur({ name, event })
  }

  const classes = { ...useStyles({ ...props, focused }), ...props.classes }

  const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD' },
      { label: 'Italic', style: 'ITALIC' },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: 'Normal', style: 'unstyled' },
      // { label: 'Heading Large', style: 'header-one' },
      { label: 'H2', style: 'header-two' },
      { label: 'H3', style: 'header-three' },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' },
    ],
  }

  return (
    <div
      className={cx(
        classes.container,
        className
      )}
    >
      <>
        <label
          className={classes.wrapper}
          htmlFor={id || thisId}
        >
          <FormLabel
            className={classes.label}
            required={required}
            error={!!error}
          >
            {label}
          </FormLabel>
        </label>
        <div className={classes.inputContainer}>
          <NoSSR>
            {Boolean(richValue) && (
              <RichTextEditor
                placeholder={placeholder}
                readOnly={readOnly || disabled}
                value={richValue}
                onChange={handleChange}
                className={classes.input}
                editorClassName={classes.content}
                toolbarClassName={classes.toolbar}
                toolbarConfig={toolbarConfig}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            )}
          </NoSSR>
        </div>
      </>
      <FormErrorText
        className={classes.errorText}
        text={error}
      />
      <FormHelpText
        className={classes.helpText}
        text={help}
        error={!!error}
      />
    </div>
  )
}

RichText.styles = styles

RichText.defaultValue = ''

RichText.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  format: PropTypes.oneOf(['markdown', 'html']),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
  help: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func, // {name, value, event}
  onBlur: PropTypes.func, // {name, event}
  onFocus: PropTypes.func, // {name, event}
}

RichText.defaultProps = {
  classes: {},
  className: null,
  id: null,
  value: '',
  label: '',
  format: 'markdown',
  required: false,
  disabled: false,
  readOnly: false,
  error: null,
  help: null,
  placeholder: null,
  onChange: () => undefined,
  onBlur: () => undefined,
  onFocus: () => undefined,
}

export default withMemo()(RichText)
