// import fonts from './fonts'
// import colors from './colors'
// import { tablet } from './mixins'

import { fonts } from './index'


const textStyles = {
  Formlabel: {
    ...fonts.FiraSansMedium,
    fontSize: '1.6rem',
  },
  paragraph: {
    '& p': {
      marginBottom: '1rem',
    },
  },
  mobileStandardParagraph: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
  },
  copy12: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
  },
  H1: {
    ...fonts.FiraSansBold,
    fontSize: '3.2rem',
  },
}

export default textStyles
