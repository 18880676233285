import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import CloudinaryImage from '../CloudinaryImage'
import ActionButton from '../ActionButton'
import MarkdownText from '../MarkdownText'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const RegisterPushCard = (props) => {
  const classes = useStyles(props)
  const { className, text, ctaLink, ctaLabel, ctaRoute, ctaRouteParams } = props

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.inner}>
        <CloudinaryImage
          id="/images/icone_cci.png"
          className={classes.icon}
          lazyLoad
        />
        {text && (
          <MarkdownText
            text={text}
            className={classes.text}
          />
        )}
        {ctaLabel && ctaLink && (
          <ActionButton
            link={ctaLink}
            label={ctaLabel}
            route={ctaRoute}
            routeParams={ctaRouteParams}
            color="white"
            isOutlined
            className={classes.cta}
          />
        )}
      </div>
      <div className={classes.background} />
    </div>
  )
}

RegisterPushCard.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaRoute: PropTypes.string,
  ctaRouteParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
}

RegisterPushCard.defaultProps = {
  className: '',
  text: null,
  ctaLink: null,
  ctaLabel: null,
  ctaRoute: null,
  ctaRouteParams: null,
}

export default withMemo()(RegisterPushCard)
