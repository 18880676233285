import PropTypes from 'prop-types'

import { iconsKeys } from '../../components/Icon/Icon.assets'


const icons = {
  commerce: iconsKeys.CategoryCommerce,
  communication: iconsKeys.CategoryCommunication,
  cotraitance: iconsKeys.CategoryCotraitance,
  espaceverts: iconsKeys.CategoryEspaceverts,
  gestion: iconsKeys.CategoryGestion,
  informatique: iconsKeys.CategoryInformatique,
  juridique: iconsKeys.CategoryJuridique,
  securite: iconsKeys.CategorySecurite,
  travaux: iconsKeys.CategoryTravaux,
}

const Category = (props) => (props && {
  id: props.id,
  name: props.name,
  slug: props?.slug,
  color: props?.color,
  icon: icons[props?.slug],
  offerType: props?.offerType,
})

Category.propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
})

export default Category
