import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import colors from '../../theme/colors'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const OfferApplicants = (props) => {
  const classes = useStyles(props)
  const { className, applicants } = props

  return (
    <div className={cx(classes.container, className)}>
      <Icon
        icon={iconsKeys.Profil}
        color={colors.silver}
        className={classes.icon}
      />
      <div className={classes.number}>{applicants}</div>
    </div>
  )
}

OfferApplicants.propTypes = {
  className: PropTypes.string,
  applicants: PropTypes.number.isRequired,
}

OfferApplicants.defaultProps = {
  className: '',
}

export default withMemo()(OfferApplicants)
