/* eslint-disable max-lines */
import cx from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import { cutString } from '../../utils/StringUtils'
import A from '../A'
import CloudinaryImage from '../CloudinaryImage'
import ConfirmationModal from '../ConfirmationModal'
import Checkbox from '../form/fields/Checkbox'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import { ActionButton } from '../index'

import styles from './styles'


const useStyles = createUseStyles(styles)

const CandidateCard = (props) => {
  const classes = useStyles(props)
  const {
    className,
    candidate,
    isConnected,
    cvLabel,
    cvLinkProps,
    contactLabel,
    discardLabel,
    isSelected,
    isContacted,
    isDiscarded,
    isNotified,
    isArchived,
    onContactClick,
    onDiscardClick,
    onSelectClick,
    disableActions,
    isCurrentOGCN,
  } = props

  const {
    id,
    name,
    city,
    picture,
    appliedAt,
    message,
    files,
    is_ogcn,
  } = candidate

  const { t } = useTranslation()
  const [messagePopinOpen, setMessagePopinOpen] = useState(false)

  const openMessagePopin = useCallback(() => {
    setMessagePopinOpen(true)
  }, [])

  const closeMessagePopin = useCallback(() => {
    setMessagePopinOpen(false)
  }, [])

  const handleSelect = useCallback(() => {
    onSelectClick(id)
  }, [id, onSelectClick])

  return (
    <div className={cx(classes.container, className, isConnected && classes.connected)}>
      <div className={classes.wrapper}>
        <CloudinaryImage
          id={isConnected && picture ? picture : 'defaults/avatar'}
          className={classes.picture}
          options={{
            width: '90',
            height: '80',
            crop: 'pad',
            radius: '20',
          }}
          lazyLoad
        />
        <div className={classes.infos}>
          <div className={classes.infosDetails}>
            <div className={classes.name}>{name}</div>
            {city && city !== '' && (
              <div className={classes.city}>
                <Icon
                  icon={iconsKeys.Pinpoint}
                  className={classes.cityIcon}
                />
                <span className={classes.cityLabel}>{city}</span>
              </div>
            )}
          </div>
          {isCurrentOGCN && is_ogcn && (
            <div className={classes.boxOgcn}>
              <Icon
                icon={iconsKeys.OgcnLight}
                className={classes.iconOgcn}
              />
            </div>
          )}
        </div>
        <div className={classes.right}>
          {!isArchived && !isNotified && (
            <Checkbox
              id={`checkbox_${id}`}
              onChange={handleSelect}
              name="selected"
              value={isSelected}
            />
          )}
        </div>
      </div>
      <div className={classes.body}>
        <span className={classes.bodyMessage}>
          {cutString(message, 30, '[...]')}
        </span>
        <ActionButton
          isSmall
          icon={iconsKeys.Chat}
          colorIcon={colors.pureWhite}
          label={t('userCandidacy_detailButton')}
          className={classes.messageButton}
          onClick={openMessagePopin}
        />
      </div>
      <div className={classes.footer}>
        <A
          target="_blank"
          className={classes.link}
          {...cvLinkProps}
        >
          {cvLabel}
          {' '}
          <Icon
            icon={iconsKeys.SmallArrowRight}
            color={colors.darkBlue}
          />
        </A>
        <div className={classes.actions}>
          {!isArchived && !isNotified && !isContacted && (
            <ActionButton
              className={cx(classes.button, isContacted && classes.restore)}
              label={contactLabel}
              isSmall
              onClick={() => onContactClick(id)}
              isOutlined
              color="secondary"
              isDisabled={disableActions}
            />
          )}
          {!isArchived && (isConnected || !isNotified) && !isDiscarded && (
            <ActionButton
              className={cx(classes.button, isContacted && classes.restore)}
              label={discardLabel}
              isSmall
              onClick={() => onDiscardClick(id)}
              isOutlined
              isDisabled={disableActions}
            />
          )}
        </div>
      </div>
      <ConfirmationModal
        title={t('userCandidacy_message_title')}
        text={name}
        files={files}
        filesLabel={t('userCandidacy_files')}
        seeFileLabel={t('userCandidacy_see_file')}
        subtext={moment(appliedAt).format('dddd Do MMMM - H[h]mm')}
        description={message}
        confirmLabel={t('userCandidacy_message_confirm')}
        onConfirm={closeMessagePopin}
        onClose={closeMessagePopin}
        isOpen={messagePopinOpen}
      />
    </div>
  )
}

CandidateCard.propTypes = {
  className: PropTypes.string,
  candidate: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    city: PropTypes.string,
    picture: PropTypes.string,
    appliedAt: PropTypes.string,
    message: PropTypes.string,
    files: PropTypes.array,
    is_ogcn: PropTypes.bool,
  }).isRequired,
  isConnected: PropTypes.bool,
  isSelected: PropTypes.bool,
  isContacted: PropTypes.bool,
  isDiscarded: PropTypes.bool,
  isNotified: PropTypes.bool,
  isArchived: PropTypes.bool,
  cvLabel: PropTypes.string,
  cvLinkProps: PropTypes.object,
  contactLabel: PropTypes.string,
  discardLabel: PropTypes.string,
  onContactClick: PropTypes.func,
  onDiscardClick: PropTypes.func,
  onSelectClick: PropTypes.func,
  disableActions: PropTypes.bool,
  isCurrentOGCN: PropTypes.bool,
}

CandidateCard.defaultProps = {
  className: '',
  isConnected: false,
  isSelected: false,
  isContacted: false,
  isDiscarded: false,
  isNotified: false,
  isArchived: false,
  cvLabel: null,
  cvLinkProps: null,
  contactLabel: null,
  discardLabel: null,
  onContactClick: () => null,
  onDiscardClick: () => null,
  onSelectClick: () => null,
  disableActions: false,
  isCurrentOGCN: false,
}

export default withMemo()(CandidateCard)
