import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import { generateUniqueId } from '../../../../utils/ComponentUtils'
import withMemo from '../../../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function Radio(props) {
  const {
    className,
    id,
    name,
    value,
    label,
    help,
    required,
    disabled,
    error,
    inputRef,
    readOnly,
    inputProps,
    onChange,
    onBlur,
    onFocus,
  } = props

  const [thisId] = useState(`form_radio_${generateUniqueId()}`)
  const [focused, setFocused] = useState(false)

  const handleChange = (event) => {
    onChange({ name, value: event.target.checked, event })
  }

  const handleFocus = (event) => {
    setFocused(true)
    if (inputProps && inputProps.onFocus) {
      inputProps.onFocus(event)
    }
    onFocus({ name, event })
  }

  const handleBlur = (event) => {
    setFocused(false)
    if (inputProps && inputProps.onBlur) {
      inputProps.onBlur(event)
    }
    onBlur({ name, event })
  }

  const classes = { ...useStyles({ ...props, focused }), ...props.classes }

  return (
    <div
      className={cx(
        classes.container,
        className
      )}
    >
      <label
        htmlFor={id || thisId}
        className={classes.label}
      >
        <input
          type="radio"
          id={id || thisId}
          name={name}
          value={value}
          checked={value}
          disabled={disabled}
          readOnly={readOnly}
          ref={inputRef}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          required={required}
          className={classes.input}
        />
        <div className={classes.box}>
          <div className={classes.boxInner} />
        </div>
        {label && <span className={classes.text}>{label}</span>}
      </label>
      <FormErrorText
        className={classes.errorText}
        text={error}
      />
      <FormHelpText
        className={classes.helpText}
        text={help}
        error={!!error}
      />
    </div>
  )
}

Radio.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.string,
  help: PropTypes.string,
  id: PropTypes.string,
  inputRef: PropTypes.func,
}

Radio.defaultProps = {
  classes: {},
  className: null,
  label: null,
  value: false,
  required: false,
  disabled: false,
  readOnly: false,
  inputProps: null,
  onChange: () => null,
  onBlur: () => null,
  onFocus: () => null,
  error: null,
  help: null,
  id: null,
  inputRef: () => null,
}

Radio.defaultValue = false


export default withMemo()(Radio)
