import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import withMemo from '../../decorators/WithMemo'
import { actions as AppActions, pageType, selectors as AppSelectors } from '../../redux/app/redux'
import { iconsKeys } from '../Icon/Icon.assets'

import Footer from './index'


function FooterWrapper() {
  const { t } = useTranslation()

  const menus = useSelector(AppSelectors.menus)
  const pagesSelector = useSelector(AppSelectors.pages)
  const route = useSelector(AppSelectors.route)
  const dispatch = useDispatch()

  const getSpecificRoute = useCallback((entry) => {
    if (
      route.asPath.indexOf(pagesSelector[pageType.OFFERS].link) === 0
      || route.asPath.indexOf(pagesSelector[pageType.DIRECTORY].link) === 0
    ) {
      return route.asPath.indexOf(entry?.link) === 0
    }
    return route.asPath === entry.link
  }, [route, pagesSelector])

  const handleGDPRClick = useCallback(() => dispatch(AppActions.openCookiesSettings()), [dispatch])

  const transform = useCallback((entry) => ({
    ...entry,
    isSelected: getSpecificRoute(entry),
    title: entry.label,
    ...entry.entries && {
      links: entry.entries.map(transform),
    },
    ...entry.type === 'COOKIES' && ({
      onClick: handleGDPRClick,
    }),
  }), [getSpecificRoute, handleGDPRClick])


  const footerLinks = useMemo(() => menus?.FOOTER?.map(transform), [menus, transform])

  const footerProps = useMemo(() => ({
    socialProps: {
      label: t('social_title'),
      links: [
        {
          id: 'FACEBOOK',
          icon: iconsKeys.FacebookIcon,
          link: t('social_facebook_link'),
          seoTitle: t('social_facebook_title'),
        },
        {
          id: 'TWITTER',
          icon: iconsKeys.TwitterIcon,
          link: t('social_twitter_link'),
          seoTitle: t('social_twitter_title'),
        },
        {
          id: 'LINKEDIN',
          icon: iconsKeys.LinkedInIcon,
          link: t('social_linkedin_link'),
          seoTitle: t('social_linkedin_title'),
        },
        {
          id: 'YOUTUBE',
          icon: iconsKeys.YoutubeIcon,
          link: t('social_youtube_link'),
          seoTitle: t('social_youtube_title'),
        },
      ],
    },
    contactProps: {
      title: t('footer_contact_label'),
      address: t('footer_contact_address'),
      tel: t('footer_contact_phone'),
      telLabel: t('footer_contact_phone_label'),
      telLegend: t('footer_contact_phone_legend'),
      mail: t('footer_contact_mail'),
    },
  }), [t])

  return (
    <Footer
      {...footerProps}
      menus={footerLinks}
    />
  )
}

FooterWrapper.propTypes = {}

FooterWrapper.defaultProps = {}

export default withMemo()(FooterWrapper)
