import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import MarkdownText from '../MarkdownText'
import A from '../A'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function RssButton(props) {
  const classes = useStyles(props)
  const { className, label, link, route, routeParams, isInMobileMenu, onClick } = props

  return (
    <A
      className={cx(classes.container, className, isInMobileMenu && classes.mobileMenu)}
      onClick={onClick}
      href={link}
      route={route}
      routeParams={routeParams}
      title={label}
    >
      <Icon
        icon={iconsKeys.Rss}
        classname={classes.icon}
        iconProps={{ className: classes.iconAsset }}
      />
      <MarkdownText
        className={classes.label}
        text={label}
      />
    </A>
  )
}

RssButton.styles = styles

RssButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  isInMobileMenu: PropTypes.bool,
  onClick: PropTypes.func,
}

RssButton.defaultProps = {
  className: '',
  label: '',
  link: null,
  route: null,
  routeParams: null,
  isInMobileMenu: false,
  onClick: () => {
  },
}

export default withMemo()(RssButton)
