import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import OfferCard from '../OfferCard'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const RelatedOffers = (props) => {
  const classes = useStyles(props)
  const { className, title, cards } = props

  const renderCards = () => cards.map((card, index) => (
    <OfferCard
      {...card}
      key={`companyOffer-${index}`}
      className={classes.card}
    />
  ))

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.title}>{title}</div>
      <div className={classes.cards}>
        {renderCards()}
      </div>
    </div>
  )
}

RelatedOffers.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape(OfferCard.propTypes)),
}

RelatedOffers.defaultProps = {
  className: '',
  cards: [],
}

export default withMemo()(RelatedOffers)
