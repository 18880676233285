import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import {
  ChannelList as PubNubChannelList
} from '@pubnub/react-chat-components/dist/index'

import withMemo from '../../../decorators/WithMemo'
import { sortChannelsByTimetoken } from '../../../helpers/PubNubHelpers'

import styles from './styles'


const useStyles = createUseStyles(styles)

const ChannelList = (props) => {
  const classes = useStyles(props)
  const {
    className,
    currentChannel,
    channelsWithMetadata,
    onChannelSwitched,
  } = props


  return (
    <PubNubChannelList
      channels={channelsWithMetadata}
      sort={sortChannelsByTimetoken}
      channelRenderer={(channelProps) => (
        <div
          key={channelProps.id}
          className={cx(className, 'pn-channel', classes.channel, {
            [classes.channelActive]: channelProps.id === currentChannel,
          })}
          onClick={() => {
            onChannelSwitched(channelProps)
          }}
          role="button"
          tabIndex={0}
        >
          <div className={classes.channelContent}>
            <div className={classes.channelAvatar}>
              <img
                alt={channelProps.name}
                src={channelProps.profileUrl}
              />
            </div>
            <div className={classes.channelInfo}>
              <p className={classes.channelName}>{channelProps.name}</p>
              <p className={classes.channelDescription}>{channelProps.description}</p>
            </div>
          </div>
          {channelProps.unreadCount > 0 && (
            <div className={classes.unreadItem}>
              <p
                className={classes.unreadCount}
              >
                {channelProps.unreadCount}
              </p>
            </div>
          )}
        </div>
      )}
    />
  )
}

ChannelList.propTypes = {
  className: PropTypes.string,
  currentChannel: PropTypes.string,
  channelsWithMetadata: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    externalId: PropTypes.string,
    profileUrl: PropTypes.string,
    email: PropTypes.string,
    custom: PropTypes.shape({
      profileUrl: PropTypes.string,
      thumb: PropTypes.string,
    }),
    updated: PropTypes.string,
    eTag: PropTypes.string,
    unreadCount: PropTypes.number,
    description: PropTypes.string,
  })),
  onChannelSwitched: PropTypes.func,
}

ChannelList.defaultProps = {
  className: '',
  currentChannel: '',
  channelsWithMetadata: [],
  onChannelSwitched: () => {},
}

export default withMemo()(ChannelList)
