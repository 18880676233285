import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import ActionButton from '../ActionButton'
import Paragraph from '../Paragraph'
import { iconsKeys } from '../Icon/Icon.assets'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const CompletionPush = (props) => {
  const classes = useStyles(props)
  const { className, title, text, ctaLabel, ctaLink } = props

  return (
    <div className={cx(classes.container, className)}>
      {title && <div className={classes.title}>{title}</div>}
      {text && (
        <Paragraph
          classNames={{ parentContainer: classes.textContainer }}
          className={classes.text}
          text={text}
        />
      )}
      <ActionButton
        label={ctaLabel}
        link={ctaLink}
        color="primary"
        className={classes.cta}
        icon={iconsKeys.ArrowRight}
        iconRight
      />
    </div>
  )
}

CompletionPush.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  ctaLabel: PropTypes.string.isRequired,
  ctaLink: PropTypes.string.isRequired,
}

CompletionPush.defaultProps = {
  className: '',
  text: null,
}

export default withMemo()(CompletionPush)
