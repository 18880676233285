/**
 * @param {*} value
 * @returns {boolean}
 */
export const isString = (value) => typeof value === 'string' || value instanceof String

/**
 * @param {string} str
 * @param {Number} max
 * @param {string} finisher
 * @returns {string}
 */
export const cutString = (str, max, finisher = '...') => (str.length <= max ? str : `${str.slice(0, max)}${finisher}`)


/**
 * @param {string} str
 * @returns {string}
*/
export const lowerCase = (str) => str.toLowerCase()
