import { colors, sizes } from '../../theme'


export default {
  container: {
    background: colors.nightRider,
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    padding: '1.2rem 2rem',
    flexFlow: 'row wrap',
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
  },
}
