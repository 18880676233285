import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import SearchSelect from '../SearchSelect'
import Input from '../form/fields/Input'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function Search(props) {
  const classes = useStyles(props)
  const { className, title, placeholder, stats, label, filters, value, onChange, onSubmit } = props

  //
  // HANDLERS
  //
  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit()
  }

  const handleChange = (e) => {
    onChange({ value: { ...value, [e.name]: e.value } })
  }

  //
  // RENDERING
  //
  const renderStats = stats && (
    <div className={classes.stats}>
      {Object.values(stats).map((stat, i) => (
        <div
          className={classes.stat}
          key={i}
        >
          <div className={classes.statValue}>{stat.value}</div>
          <div className={classes.statLabel}>{stat.label}</div>
        </div>
      ))}
    </div>
  )

  const renderTitle = title && (
    <div className={classes.title}>
      <div className={classes.titleValue}>{title}</div>
    </div>
  )

  const renderFilters = filters && filters.map((filter, i) => (
    <React.Fragment key={i}>
      <SearchSelect
        {...filter}
        id={`search_select_${filter.name}`}
        value={value[filter.name]}
        onChange={handleChange}
      />
    </React.Fragment>
  ))

  const renderForm = (
    <form
      className={classes.form}
      onSubmit={handleSubmit}
    >
      <div className={cx(classes.section, classes.mainSection)}>
        <Icon
          icon={iconsKeys.Search}
          className={classes.icon}
          iconProps={{ className: classes.iconAsset }}
        />
        <Input
          name="keyword"
          id="search_keyword"
          className={classes.input}
          placeholder={placeholder}
          value={value.keyword}
          onChange={handleChange}
        />
      </div>
      {renderFilters}
      <button
        className={classes.button}
        type="submit"
      >
        <span className={classes.buttonLabel}>{label}</span>
        <Icon
          icon={iconsKeys.ArrowRight}
          className={classes.buttonIcon}
        />
      </button>
    </form>
  )

  return (
    <div className={cx(classes.container, className)}>
      <div className={cx(classes.wrapper)}>
        {renderTitle}
        {renderStats}
        {renderForm}
      </div>
    </div>
  )
}

Search.styles = styles

Search.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  stats: PropTypes.objectOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  filters: PropTypes.arrayOf(PropTypes.shape(SearchSelect.propTypes)),
  value: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
}

Search.defaultProps = {
  className: '',
  title: '',
  placeholder: '',
  label: '',
  stats: null,
  filters: null,
  value: {},
  onChange: () => null,
  onSubmit: () => null,
}

export default withMemo()(Search)
