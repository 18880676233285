import cx from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'

import { app } from '../../config'
import withMemo from '../../decorators/WithMemo'
import { selectors as authSelectors } from '../../redux/auth/redux'
import { Router } from '../../routes'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import ConfirmationModal from '../ConfirmationModal'
import EventDetailsMain from '../EventDetailsMain'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

const EventDetailsSidebar = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { className, event, cta } = props
  const { beginDate, endDate, indicationLabel, place } = event
  const isPremium = useSelector(authSelectors.isActivePremium)

  const [premiumPopinOpen, setPremiumPopinOpen] = useState(false)
  const handlePremiumPopinOpen = useCallback(() => setPremiumPopinOpen(true), [])
  const handlePremiumPopinClose = useCallback(() => setPremiumPopinOpen(false), [])
  const handlePremium = useCallback(() => {
    const goPremiumUrl = app.REGISTER_URL

    Router.pushRoute(goPremiumUrl)
  }, [])

  const beginMoment = moment(beginDate)
  const endMoment = moment(endDate)

  const sameDay = beginMoment.format('D') === endMoment.format('D')
    && beginMoment.format('M') === endMoment.format('M')
    && beginMoment.format('YYYY') === endMoment.format('YYYY')

  let topLabel = `Le ${beginMoment.format('dddd DD MMMM YYYY')}`

  let bottomLabel = `De ${beginMoment.format('LT')} à ${endMoment.format('LT')}`

  if (!sameDay) {
    topLabel = `Du ${beginMoment.format('dddd DD MMMM YYYY')}`
    bottomLabel = `Au ${endMoment.format('dddd DD MMMM YYYY')}`
  }

  const renderCTA = () => {
    const buttonProps = (event.isFreeForPremium && !isPremium) ? { link: null, onClick: handlePremiumPopinOpen } : {}

    return (
      <div className={classes.cta}>
        <ActionButton
          {...cta}
          {...buttonProps}
        />
      </div>
    )
  }

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.block}>
        <div className={classes.header}>
          <div className={classes.beginDate}>
            {topLabel}
          </div>
          <div className={classes.endDate}>
            {bottomLabel}
          </div>
        </div>
        <div className={classes.body}>
          {indicationLabel && (
            <div className={classes.indication}>
              {indicationLabel}
            </div>
          )}
          <div className={classes.place}>
            <Icon
              icon={iconsKeys.Location}
              color={colors.nightRider}
              className={classes.placeIcon}
            />
            <div className={classes.placeText}>
              {place}
            </div>
          </div>
        </div>
      </div>
      {!!cta && renderCTA()}
      <ConfirmationModal
        title={t('eventDetails_premium_title')}
        text={t('eventDetails_premium_text')}
        confirmLabel={t('eventDetails_premium_confirm')}
        cancelLabel={t('eventDetails_premium_cancel')}
        onClose={handlePremiumPopinClose}
        onConfirm={handlePremium}
        isOpen={premiumPopinOpen}
      />
    </div>
  )
}

EventDetailsSidebar.propTypes = {
  className: PropTypes.string,
  event: PropTypes.shape(EventDetailsMain.propTypes),
  cta: PropTypes.shape(ActionButton.propTypes),
}

EventDetailsSidebar.defaultProps = {
  className: '',
  event: null,
  cta: null,
}

export default withMemo()(EventDetailsSidebar)
