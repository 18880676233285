import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import CheckboxGroup from '../form/fields/CheckboxGroup'
import RadioGroupWithLabel from '../form/fields/RadioGroupWithLabel'
import Switch from '../form/fields/Switch'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const AuthNotificationPreferencesForm = (props) => {
  const classes = useStyles(props)
  const {
    className, title, text, notifications, preferences, onPreferenceChange,
    submitSuccess,
    successText,
    onSpecificPreferenceChange,
    onSubSpecificPreferenceChange,
    checkBoxTitle,
  } = props

  const renderSwitch = (preference) => {
    const { id, label } = preference
    const selected = preferences[id]

    return (
      <Switch
        label={label}
        id={`auth-notification-switch-${id}`}
        name={id}
        value={selected}
        onChange={(value) => onPreferenceChange(id, value)}
      />
    )
  }

  const renderCheckbox = (notification) => {
    const { id, options } = notification

    const selected = []

    preferences.notificationPreferences.map(
      (notificationPref) => notificationPref.is_active && selected.push(notificationPref.type)
    )

    const subCheckBox = []

    // eslint-disable-next-line max-len
    selected.forEach((select) => notifications.find((notif) => notif.id === `notificationPreferences_${select}_sub` && subCheckBox.push(notif)))

    const subCheckBoxSelected = []

    preferences.notificationPreferences.map(
      (notificationPref) => notificationPref.is_active && subCheckBoxSelected.push(notificationPref.subtypes)
    )

    return (
      <div className={classes.checkBoxGroup}>
        <CheckboxGroup
          options={options}
          value={selected}
          onChange={(value) => onSpecificPreferenceChange(id, value)}
          id={`auth-notification-checkbox-${id}`}
          name={id}
          className={classes.checkBox}
        />
        {subCheckBox.map((sub, key) => (
          `${id}_sub` === sub.id && (
            <div className={classes.subFieldContainerItem}>
              <CheckboxGroup
                options={sub.options}
                value={subCheckBoxSelected[key]}
                onChange={(value) => onSubSpecificPreferenceChange(sub.id, value)}
                id={`auth-notification-checkbox-${sub.id}`}
                name={sub.id}
                className={classes.subCheckBox}
              />
            </div>
          )
        ))}
      </div>
    )
  }

  const renderRadio = (preference) => {
    const { id, label, options } = preference
    const selected = preferences[id]

    return (
      <RadioGroupWithLabel
        label={label}
        options={options}
        value={selected}
        onChange={(value) => onPreferenceChange(id, value)}
        id={`auth-notification-radio-${id}`}
        name={id}
      />
    )
  }

  const renderPreferences = () => {
    let renderFunction

    return notifications.map((notification, index) => {
      switch (notification.type) {
        case 'checkbox':
          renderFunction = renderCheckbox
          break
        case 'switch':
          renderFunction = renderSwitch
          break
        case 'radio':
          renderFunction = renderRadio
          break
        default:
          renderFunction = () => {
          }
          break
      }
      return (
        !notification.subCheckBoxGroup && (
          notification.type === 'checkbox'
            ? (
              <>
                {index === 0 && <span className={classes.checkBoxTitle}>{checkBoxTitle}</span>}
                <div
                  className={classes.fieldContainerCheckBox}
                  key={`notification-preference-${index}`}
                >
                  {renderFunction(notification)}
                </div>
              </>
            ) : (
              <div
                className={classes.fieldContainer}
                key={`notification-preference-${index}`}
              >
                <div className={classes.fieldContainerItem}>
                  {renderFunction(notification)}
                </div>
              </div>
            )
        )
      )
    })
  }

  return (
    <div className={cx(classes.container, className)}>
      <div>
        <div className={classes.title}>{title}</div>
        <div className={classes.text}>{text}</div>
        {renderPreferences()}
      </div>
      {submitSuccess && (
        <div className={classes.success}>
          <Icon
            icon={iconsKeys.Check}
            color={colors.darkBlue}
            iconProps={{ className: classes.successIconAsset }}
          />
          <div
            className={classes.successText}
            dangerouslySetInnerHTML={{ __html: successText }}
          />
        </div>
      )}
    </div>
  )
}

AuthNotificationPreferencesForm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
  })),
  preferences: PropTypes.object,
  onPreferenceChange: PropTypes.func,
  submitSuccess: PropTypes.bool,
  successText: PropTypes.string,
  onSpecificPreferenceChange: PropTypes.func,
  onSubSpecificPreferenceChange: PropTypes.func,
  checkBoxTitle: PropTypes.string,
}

AuthNotificationPreferencesForm.defaultProps = {
  className: '',
  notifications: () => [],
  preferences: () => {
  },
  onPreferenceChange: () => {
  },
  submitSuccess: false,
  successText: '',
  onSpecificPreferenceChange: () => {
  },
  onSubSpecificPreferenceChange: () => {
  },
  checkBoxTitle: '',
}

export default withMemo()(AuthNotificationPreferencesForm)
