export function hasParent(target, parent, recurse = true) {
  if (!target || !parent) {
    return false
  }
  if (target === parent) {
    return true
  }
  if (recurse === false) {
    return false
  }
  return hasParent(target.parentElement, parent, recurse)
}

export function hasChild(parent, target, recurse = true) {
  return hasParent(target, parent, recurse)
}
