import cx from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import { imageTypes } from '../CloudinaryImage/types'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'

import styles from './styles'


const useStyles = createUseStyles(styles)

function capitalize(s) {
  if (typeof s !== 'string') {
    return ''
  }

  return s.charAt(0).toUpperCase() + s.slice(1)
}

const EventCardHighlight = (props) => {
  const classes = useStyles(props)
  const {
    className,
    beginDate,
    endDate,
    type,
    cover,
    title,
    place,
    link,
    hasPage,
    route,
    routeParams,
    href,
    ctaLabel,
    highlightLabel,
    ...otherProps
  } = props

  const date = useMemo(() => {
    const beginMoment = moment(beginDate)
    const endMoment = moment(endDate)
    const sameDay = beginMoment.format('D') === endMoment.format('D')
      && beginMoment.format('M') === endMoment.format('M')
      && beginMoment.format('YYYY') === endMoment.format('YYYY')
    const dateFormat = 'dddd D MMMM YYYY'

    if (beginMoment.isSame(endDate)) {
      return `${capitalize(beginMoment.format('LLLL'))}`
    }
    if (sameDay) {
      return `${capitalize(beginMoment.format(dateFormat))} de ${beginMoment.format('LT')} à ${endMoment.format('LT')}`
    }
    return `Du ${beginMoment.format('LLLL')} au ${endMoment.format('LLLL')}`
  }, [beginDate, endDate])

  return (
    <CloudinaryImage
      {...otherProps}
      lazyLoad
      id={cover}
      type={imageTypes.Background}
      className={cx(classes.container, className)}
      options={{
        crop: 'fill',
        height: 500,
      }}
    >
      <div className={classes.gradient} />
      <div className={cx(classes.content)}>
        <div className={classes.left}>
          <div className={classes.highlightBadge}>
            <Icon
              icon={iconsKeys.FlagOn}
              color={colors.pureWhite}
              className={classes.badgeIcon}
            />
            <div className={classes.badgeText}>
              {highlightLabel}
            </div>
          </div>
          <div className={classes.title}>{title}</div>
        </div>
        <div className={classes.right}>
          <div className={classes.infoRow}>
            <Icon
              icon={iconsKeys.Calendar}
              color={colors.darkBlue}
              className={classes.infoIcon}
            />
            <div className={classes.infoText}>
              {date}
            </div>
          </div>
          <div className={classes.infoRow}>
            <Icon
              icon={iconsKeys.Location}
              color={colors.darkBlue}
              className={classes.infoIcon}
            />
            <div className={classes.infoText}>
              {place}
            </div>
          </div>
          <ActionButton
            label={ctaLabel}
            {...(hasPage
              ? { link: href }
              : { link })}
            icon={iconsKeys.ArrowRight}
            color="secondary"
            isOutlined
            iconRight
            target="_blank"
            colorIcon={colors.darkBlue}
            colorIconHover={colors.pureWhite}
            className={classes.cta}
          />
          {type === 'EVENT_OGCN' && (
            <div className={classes.boxOgcn}>
              <Icon
                icon={iconsKeys.OgcnLight}
                className={classes.iconOgcn}
              />
            </div>
          )}
        </div>
      </div>
    </CloudinaryImage>
  )
}

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  place: PropTypes.string,
  beginDate: PropTypes.string,
  endDate: PropTypes.string,
  cover: PropTypes.string,
  hasPage: PropTypes.bool,
  href: PropTypes.string,
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  ctaLabel: PropTypes.string,
  highlightLabel: PropTypes.string,
}

EventCardHighlight.propTypes = propTypes

EventCardHighlight.defaultProps = {
  className: '',
  title: null,
  type: null,
  place: null,
  beginDate: null,
  endDate: null,
  cover: null,
  hasPage: true,
  href: null,
  link: null,
  route: null,
  routeParams: null,
  ctaLabel: null,
  highlightLabel: null,
}

export default withMemo()(EventCardHighlight)
