import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import CloudinaryImage from '../CloudinaryImage'
import { ActionButton, MarkdownText } from '..'

import styles from './styles'


const useStyles = createUseStyles(styles)

const RelationHeader = (props) => {
  const classes = useStyles(props)
  const {
    className,
    title,
    text,
    image,
    cta,
  } = props

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.wrapper}>
        <div className={classes.left}>
          <MarkdownText
            className={classes.title}
            text={title}
          />
          {text && (
            <div className={classes.text}>
              {text}
            </div>
          )}
          {cta && (
            <ActionButton
              className={classes.cta}
              color="secondary"
              {...cta}
            />
          )}
        </div>
        {image && (
          <CloudinaryImage
            id={image}
            className={classes.image}
          />
        )}
      </div>
    </div>
  )
}

RelationHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  cta: PropTypes.shape({
    label: PropTypes.string,
    route: PropTypes.string,
    routeParams: PropTypes.shape({
      slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }),
  }),
}

RelationHeader.defaultProps = {
  className: '',
  title: '',
  text: '',
  image: '',
  cta: null,
}

export default withMemo()(RelationHeader)
