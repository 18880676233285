import { colors, fonts, transition } from '../../theme'


export default {
  container: {
    padding: '3rem 0',
    '& ul': {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      margin: 0,
      '& li': {
        ...fonts.FiraSansRegular,
        fontSize: '1.4rem',
        lineHeight: '1.7rem',
        position: 'relative',
        textTransform: 'uppercase',
        color: colors.darkBlue,
        backgroundColor: colors.honeydew,
        display: 'flex',
        minWidth: '2.8rem',
        justifyContent: 'center',
        margin: '0 .4rem',
        borderRadius: '.5rem',
        '& a': {
          color: colors.darkBlue,
          textDecoration: 'none',
          extend: transition('all'),
          borderRadius: '.5rem',
          '&:hover': {
            color: colors.pureWhite,
            backgroundColor: colors.darkBlue,
          },
        },
        '&:not(.dots):not(:first-child):not(:last-child)': {
          '& a': {
            padding: '.6rem .3rem .5rem .3rem',
            flex: 1,
            textAlign: 'center',
          },
        },
        '&.dots': {
          padding: '.6rem .3rem .5rem .3rem',
        },
        '&.current': {
          position: 'relative',
          color: colors.pureWhite,
          backgroundColor: colors.darkBlue,
          padding: '.6rem .3rem .5rem .3rem',
          '& a': {
            color: colors.pureWhite,
            textDecoration: 'none',
          },
        },
        '&.truncated': {
          '&+ .truncated': {
            display: 'none',
          },
        },
        '&:first-child': {
          backgroundColor: colors.aliceBlue,
          '& a': {
            padding: '.6rem .4rem .5rem .2rem',
            flex: 1,
            textAlign: 'center',
          },
          '&.disable': {
            padding: '.6rem .4rem .5rem .2rem',
          },
        },
        '&:last-child': {
          backgroundColor: colors.aliceBlue,
          '& a': {
            padding: '.6rem .2rem .5rem .4rem',
            flex: 1,
            textAlign: 'center',
          },
          '&.disable': {
            padding: '.6rem .2rem .5rem .4rem',
          },
        },
        '&:first-child, &:last-child': {
          '&.disable': {
            '& span': {
              opacity: 0.6,
            },
          },
        },
        '&:not(.disable):not(.current):not(.dots)': {
          '&:hover': {
            '& $icon': {
              '& svg': {
                width: '100%',
                height: '100%',
              },
              '& *[fill]:not([fill="none"]):not(.no-fill)': {
                fill: colors.pureWhite,
              },
              '& *[stroke]:not([stroke="none"]):not(.no-stroke)': {
                stroke: colors.pureWhite,
              },
              '& *[fill]:not([fill="none"]):not(.no-fill), & *[stroke]:not([stroke="none"]):not(.no-stroke)': {
                ...transition(['fill', 'stroke']),
              },
            },
          },
        },
      },
    },
  },
  iconContainer: {
  },
  icon: {
    margin: [0, 'auto'],
    width: '1.2rem',
    height: '1rem',
  },
}
