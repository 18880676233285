import { fonts, colors, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    borderTop: ['.1rem', 'solid', colors.gray4],
    borderBottom: ['.1rem', 'solid', colors.gray4],
    padding: '3rem 0',
    ...tablet({
      padding: '3rem 2rem',
    }),
  },
  label: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    color: colors.darkBlue,
    ...tablet({
      fontSize: '1.4rem',
      lineHeight: '1.7rem',
    }),
  },
}
