import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'


import SectorCard from '../SectorCard'
import MarkdownText from '../MarkdownText'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function SectorContainer(props) {
  const classes = useStyles(props)
  const { title, introduction, sectors, text } = props

  const renderSectors = () => sectors.map((sector, i) => (
    <div
      className={classes.sectorsEntry}
      key={i}
    >
      <SectorCard {...sector} />
    </div>
  ))

  return (
    <div className={classes.container}>
      {title && (
        <MarkdownText
          className={classes.title}
          text={title}
        />
      )}
      {introduction && (
        <MarkdownText
          className={classes.intro}
          text={introduction}
        />
      )}
      {sectors.length >= 1 && (
        <div className={classes.sectors}>
          {renderSectors()}
        </div>
      )}
      {text && (
        <MarkdownText
          className={classes.text}
          text={text}
        />
      )}
    </div>
  )
}

SectorContainer.styles = styles

SectorContainer.propTypes = {
  title: PropTypes.string,
  introduction: PropTypes.string,
  sectors: PropTypes.arrayOf(PropTypes.shape(SectorCard.propTypes)),
  text: PropTypes.string,
}

SectorContainer.defaultProps = {
  title: null,
  introduction: null,
  sectors: [],
  text: null,
}

export default withMemo()(SectorContainer)
