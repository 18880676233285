/* eslint-disable max-lines */
import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import A from '../A'
import Icon from '../Icon'
import withMemo from '../../decorators/WithMemo'
import Category from '../../data/models/Category'

import styles from './styles'


const disableIcon = true

const useStyles = createUseStyles(styles)

const OfferSmallCard = (props) => {
  const {
    className,
    link,
    route,
    routeParams,
    title,
    categories,
    deadline,
  } = props

  const { t } = useTranslation()
  const deadlineDate = useMemo(() => {
    const d = moment(deadline, 'YYYY-MM-DD')

    return d.isValid() && d.toDate()
  }, [deadline])

  const category = useMemo(() => categories && categories[0], [categories])
  const classes = useStyles({ ...props, category })

  return (
    <div className={cx(classes.container, className)}>
      <A
        href={link}
        route={route}
        routeParams={routeParams}
        className={cx(classes.wrapper)}
        title={title}
      >
        <div className={classes.top}>
          <div className={classes.tag}>
            <div className={classes.tagName}>{category?.name}</div>
            {!disableIcon && !!category.icon && (
              <Icon
                icon={category.icon}
                className={classes.tagIcon}
              />
            )}
          </div>
          <h2 className={classes.title}>{title}</h2>
        </div>
        {deadlineDate && (
          <div className={classes.bottom}>
            <div className={classes.deadlineLabel}>{t('offerSmallCard_deadline_label')}</div>
            <div className={classes.deadlineDate}>{t('offerSmallCard_deadline_date', { date: deadlineDate })}</div>
          </div>
        )}
      </A>
    </div>
  )
}

OfferSmallCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  deadline: PropTypes.string,
  categories: PropTypes.arrayOf(Category.propTypes),
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
}

OfferSmallCard.defaultProps = {
  className: '',
  title: null,
  deadline: null,
  categories: null,
  link: null,
  route: null,
  routeParams: null,
}

export default withMemo()(OfferSmallCard)
