/* eslint-disable max-lines */
import { colors, fonts } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    width: '100%',
    background: colors.pureWhite,
    borderRadius: '2rem',
    padding: '2.5rem 3rem',
    margin: '0 auto',
    maxWidth: '52rem',
  },
  text: {
    ...fonts.FiraSansRegular,
    fontSize: '1.8rem',
    lineHeight: '120%',
    color: colors.darkBlue,
    margin: '2rem 0',
  },
  subtext: {
    ...fonts.FiraSansRegular,
    fontSize: '1.3rem',
    lineHeight: '2.5rem',
    color: colors.nightRider,
    marginTop: '-2rem',
    marginBottom: '2rem',
  },
  description: {
    ...fonts.FiraSansRegular,
    fontSize: '1.5rem',
    lineHeight: '2.0rem',
    color: colors.nightRider,
    margin: '2rem 0',
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginBottom: '1rem',
    '&:first-child': {
      marginRight: '1rem',
    },
  },
  filesTitle: {
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    textAlign: 'left',
    color: colors.nightRider,
    marginBottom: '2.5rem',
    textTransform: 'uppercase',
  },
}
