import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import ActionButton from '../ActionButton'
import CloudinaryImage from '../CloudinaryImage'
import { imageTypes } from '../CloudinaryImage/types'
import { iconsKeys } from '../Icon/Icon.assets'
import ProfileDetailsMain from '../ProfileDetailsMain'
import ProfileDetailsSidebar from '../ProfileDetailsSidebar'
import SideColumnLayout from '../SideColumnLayout'

import styles from './styles'


const useStyles = createUseStyles(styles)

const ProfileDetails = (props) => {
  const classes = useStyles(props)
  const { className, profile } = props

  const {
    backLabel,
    backClick,
  } = profile

  const getDataLayerScript = () => `
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        type_entreprise: '${profile.company.domains[0]?.name}',
        'nom-entreprise': '${profile.company.title.toLocaleLowerCase()}',
        departement_entreprise: '${profile.company.address?.zip?.substring(0, 2)}',
      })
    `

  const renderSideContent = () => (
    <ProfileDetailsSidebar
      profile={profile}
      className={classes.sidebar}
    />
  )

  const renderMain = () => (
    <ProfileDetailsMain
      profile={profile}
      className={classes.main}
    />
  )

  const banner = profile?.company?.banner

  return (
    <div className={cx(classes.container, className)}>
      {!!banner && (
        <CloudinaryImage
          id={banner}
          type={imageTypes.Background}
          className={classes.headerContainer}
        >
          <div className={classes.headerWrapper}>
            {backLabel !== null && backClick !== null && (
              <ActionButton
                label={backLabel}
                onClick={backClick}
                color="white"
                icon={iconsKeys.SmallArrowLeft}
                colorIcon={colors.darkBlue}
                colorIconHover={colors.darkBlue}
                className={classes.headerButton}
              />
            )}
          </div>
        </CloudinaryImage>
      )}
      <div className={classes.wrapper}>
        <SideColumnLayout
          reverse
          columnContent={renderSideContent()}
          mainContent={renderMain()}
        />
      </div>
      <script dangerouslySetInnerHTML={{ __html: getDataLayerScript() }} />
    </div>
  )
}

ProfileDetails.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.shape(ProfileDetailsMain.propTypes),
}

ProfileDetails.defaultProps = {
  className: '',
  profile: null,
}

export default withMemo()(ProfileDetails)
