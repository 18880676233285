import reset from './reset'
import fontsFaces from './fontsFaces'
import overlayScrollbars from './overlayScrollbars'


export { default as fonts } from './fonts'
export { default as reset } from './reset'
export { default as colors } from './colors'
export { default as grids } from './grids'
export {
  default as sizes,
  desktopMedia,
  desktopMediaQuery,
  tabletMedia,
  tabletMediaQuery,
  fullDesktopMedia,
  fullDesktopMediaQuery,
  maxDesktopMedia,
  maxDesktopMediaQuery
} from './sizes'
export { default as textStyles } from './textStyles'

export { transition, svgGlobal, tablet, desktop, fullDesktop, maxDesktop, placeholder, aspectRatio } from './mixins'

export const global = {
  '@global': {
    ...reset,
    ...fontsFaces,
    ...overlayScrollbars,
  },
}
