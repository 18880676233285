import hoist from 'hoist-non-react-statics'
import React, { useState } from 'react'


export const chainDecorators = (decorators = [], initialElement) => hoist(decorators
  .slice(0)
  .reverse()
  .reduce((element, decorator) => decorator(element), initialElement), initialElement)

export function memoized(WrappedComponent) {
  return hoist(React.memo(WrappedComponent), WrappedComponent)
}

export const getLocalStorageValue = (key, initialValue) => {
  if (typeof window === 'undefined') {
    return initialValue
  }
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key)
    // Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : initialValue
  } catch (error) {
    // If error also return initialValue
    window.localStorage.removeItem(key)
    return initialValue
  }
}

export const setLocalStorageValue = (key, value) => {
  if (typeof window === 'undefined') {
    return
  }
  try {
    // Save to local storage
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    // A more advanced implementation would handle the error case
    console.log(error)
  }
}

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => getLocalStorageValue(key, initialValue))

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value

    // Save state
    setStoredValue(valueToStore)

    setLocalStorageValue(key)
  }

  const resetValue = () => {
    setValue(initialValue)
  }

  return [storedValue, setValue, resetValue]
}
