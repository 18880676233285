import cx from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import A from '../A'
import CloudinaryImage from '../CloudinaryImage'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const EventCard = (props) => {
  const classes = useStyles(props)
  const {
    className,
    beginDate,
    endDate,
    type,
    logo,
    title,
    place,
    asCard,
    hasPage,
    href,
    link,
    route,
    routeParams,
    isCurrentOGCN,
    isOGCN,
  } = props

  const { t } = useTranslation()

  // eslint-disable-next-line complexity
  const renderDate = useCallback(() => {
    const beginMoment = moment(beginDate)
    const endMoment = moment(endDate)

    const sameDay = beginMoment.format('D') === endMoment.format('D')
      && beginMoment.format('M') === endMoment.format('M')
      && beginMoment.format('YYYY') === endMoment.format('YYYY')
    const sameMonth = beginMoment.format('M') === endMoment.format('M')
      && beginMoment.format('YYYY') === endMoment.format('YYYY')

    if (!asCard) {
      return (
        <div className={classes.date}>
          {sameDay
            ? `${beginMoment.format('DD MMMM YYYY')}`
            : sameMonth
              ? `${beginMoment.format('DD')}-${endMoment.format('DD MMMM YYYY')}`
              : `${beginMoment.format('DD MMMM YYYY')} - ${endMoment.format('DD MMMM YYYY')}` }
        </div>
      )
    }
    return (
      <>
        {(sameDay || sameMonth) && (
          <div className={classes.day}>
            {sameDay
              ? `${beginMoment.format('DD')}`
              : `${beginMoment.format('DD')}-${endMoment.format('DD')}`}
          </div>
        )}
        <div className={classes.month}>
          {sameDay
            ? `${beginMoment.format('MMMM YYYY')}`
            : sameMonth
              ? `${beginMoment.format('MMMM YYYY')}`
              : `${beginMoment.format('DD MMMM YYYY')} - ${endMoment.format('DD MMMM YYYY')}`}
        </div>
      </>
    )
  }, [asCard, beginDate, classes.date, classes.day, classes.month, endDate])

  const renderNormalHeader = useCallback(() => (
    <>
      {renderDate()}
      <div className={classes.separator} />
      <div className={classes.type}>{t(`eventType_${type}`)}</div>
    </>
  ), [classes.separator, classes.type, renderDate, t, type])

  const renderInvertedHeader = useCallback(() => (
    <>
      <div className={classes.top}>
        <div className={classes.type}>{t(`eventType_${type}`)}</div>
        <CloudinaryImage
          lazyLoad
          id={logo}
          className={classes.logo}
          options={{
            width: '82',
            height: '41',
            crop: 'fill',
          }}
        />
      </div>
      <div className={classes.separator} />
      {renderDate()}
    </>
  ), [classes.logo, classes.separator, classes.top, classes.type, logo, renderDate, t, type])

  const renderOgcnHeader = useCallback(() => (
    <>
      <div className={classes.top}>
        <div className={classes.type}>{t(`eventType_${type}`)}</div>
        <div className={classes.boxOgcn}>
          <Icon
            icon={iconsKeys.OgcnLight}
            className={classes.iconOgcn}
          />
        </div>
      </div>
      <div className={classes.separator} />
      {renderDate()}
    </>
  ), [classes.boxOgcn, classes.iconOgcn, classes.separator, classes.top, classes.type, renderDate, t, type])

  let Component = (componentProps) => <div {...componentProps} />

  if (asCard) {
    Component = (componentProps) => (
      <A
        target="_blank"
        {...componentProps}
        {...(hasPage
          ? { route, routeParams, href }
          : { href: link })}
      />
    )
  }

  return (
    <Component className={cx(classes.container, asCard && classes.card, className)}>
      <div className={classes.header}>
        {isOGCN && isCurrentOGCN ? renderOgcnHeader() : !asCard ? renderNormalHeader() : renderInvertedHeader()}
      </div>
      <MarkdownText
        tag="h2"
        className={classes.title}
        inline
        text={title}
      />
      <div className={classes.bottom}>
        <div className={classes.place}>{place}</div>
        {asCard && (hasPage || link) && (
          <Icon
            icon={iconsKeys.ArrowRight}
            color={colors.darkBlue}
            className={classes.icon}
          />
        )}
      </div>
    </Component>
  )
}

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  place: PropTypes.string,
  beginDate: PropTypes.string,
  endDate: PropTypes.string,
  logo: PropTypes.string,
  href: PropTypes.string,
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  hasPage: PropTypes.bool,
  asCard: PropTypes.bool,
  isCurrentOGCN: PropTypes.bool,
  isOGCN: PropTypes.bool,
}

EventCard.propTypes = propTypes

EventCard.defaultProps = {
  className: '',
  title: null,
  type: null,
  place: null,
  beginDate: null,
  endDate: null,
  logo: null,
  href: null,
  link: null,
  route: null,
  routeParams: null,
  hasPage: false,
  asCard: false,
  isCurrentOGCN: false,
  isOGCN: false,
}

export default withMemo()(EventCard)
