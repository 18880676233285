/* eslint-disable max-lines */
import { sizes, colors, fonts, desktop, svgGlobal } from '../../theme'


export default {
  container: {
    width: '100%',
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    padding: '0rem 1.6rem 1.6rem',
    minHeight: '61rem',
    ...desktop({
      padding: 0,
      minHeight: 'auto',
    }),
  },
  noMessage: {
    ...fonts.FiraSansBold,
    color: colors.nightRider,
    fontSize: '1.3rem',
  },
  messagingBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  channelList: {
    height: '100%',
    position: 'absolute',
    zIndex: '4',
    transition: '0.3s ease-in-out',
    width: '100%',
    transform: 'translateX(-110%)',
    ...desktop({
      display: 'flex',
      flexDirection: 'column',
      flexShrink: '0',
      position: 'relative',
      width: '25%',
      transform: 'none',
      zIndex: '1',
    }),
  },
  channelListShown: {
    height: '94%',
    position: 'absolute',
    zIndex: '4',
    transition: '0.3s ease-in-out',
    width: '91%',
    ...desktop({
      display: 'flex',
      flexDirection: 'column',
      flexShrink: '0',
      position: 'relative',
      width: '27%',
      zIndex: '1',
    }),
  },
  close: {
    display: 'block',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: '3',
    right: '1rem',
    top: '1rem',
    ...desktop({
      display: 'none',
    }),
  },
  messageBox: {
    width: '100%',
    height: '50rem',
    borderRadius: '2rem',
    position: 'relative',
    backgroundColor: colors.whiteGrey,
  },
  messageBoxHeader: {
    padding: '4rem 2rem 2rem 3rem',
    ...desktop({
      padding: '2rem 2rem 2rem 3rem',
    }),
  },
  messageBoxHeaderContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  messageBoxHeaderTitle: {
    ...fonts.FiraSansBold,
    fontSize: '1.8rem',
    color: colors.nightRider,
  },
  messageBoxHeaderDescription: {
    ...fonts.FiraSansRegular,
    fontSize: '1.2rem',
    color: colors.grey,
  },
  removeIcon: {
    width: '2.4rem',
    height: '2.4rem',
    marginRight: '1rem',
    cursor: 'pointer',
    ...svgGlobal(colors.crimson),
    '& svg': {
      width: '2.4rem',
      height: '2.4rem',
    },
  },
  separator: {
    marginTop: '2rem',
    border: `0.1px solid ${colors.gainsboro}`,
  },
  hamburger: {
    display: 'inline-block',
    float: 'left',
    fontSize: '24px',
    marginRight: '15px',
    ...desktop({
      display: 'none',
    }),
  },
  messageBoxInput: {
    backgroundColor: colors.whiteGrey,
    height: '6rem',
    borderRadius: '0 0 2rem 2rem',
  },
  '@global': {
    '.pn-msg__main': {
      '--msg__main__maxWidth': '75%',
    },
    '.pn-msg-input': {
      width: '100%',
      borderRadius: '0 0 2rem 2rem',
      marginBottom: '2rem',
      '--msg-input__background': colors.whiteGrey,
      '--msg-input__send__background': colors.neonBlue,
      '--msg-input__send__color': colors.gainsboro,
      '--msg-input__send__borderRadius': '4rem',
      '--msg-input__send__padding': '11px 25px',
      '--msg-input__send__fontFamily': fonts.FiraSansBold.fontFamily,
      '--msg-input__send__fontSize': '1.3rem',
      '--msg-input__textarea__borderRadius': '25px',
      '--msg-input__textarea__padding': '12px 12px',
      '--msg-input__textarea__color': colors.nightRider,
      '--msg-input__textarea__fontFamily': fonts.FiraSansRegular.fontFamily,
      '--msg-input__textarea__fontSize': '1.6rem',
      '--msg-input__textarea__lineHeight': '2rem',
    },
    '.pn-msg-input__wrapper': {
      flexDirection: 'column',
      '& > *': {
        marginBottom: '1rem',
      },
      '@media (min-width: 400px)': {
        flexDirection: 'row',
        '& > *': {
          marginBottom: 'initial',
        },
      },
    },
    '.pn-msg-input__send--active': {
      '--msg-input__send--active__color': colors.pureWhite,
    },
    '.pn-msg-list': {
      ...fonts.FiraSansRegular,
      maxHeight: '35rem',
      overflow: 'scroll',
      borderRadius: '2rem 2rem 0 0',
      '--msg-list__background': colors.whiteGrey,
      '--msg--hover__background': 'transparent',
      '--msg__author__color': colors.darkBlue,
      '--msg__author__fontSize': '1.5rem',
      '--msg__author__padding': 0,
      '--msg__bubble__background': colors.pureWhite,
      '--msg__bubble__boxShadow': '0px 10px 15px rgba(0, 0, 0, 0.05)',
      '--msg__bubble__margin': '0 0 1rem 0',
      '--msg__avatar__size': '5rem',
      '--msg__avatar__borderRadius': '0.5rem',
    },
    '.pn-msg-list__unread': {
      position: 'absolute',
      top: '1rem',
      transform: 'translate(-50%, 0)',
      zIndex: '1',
    },
    '.pn-channel-list': {
      ...fonts.FiraSansBold,
      marginTop: '3rem',
      maxHeight: '90%',
      position: 'relative',
      ...desktop({
        maxHeight: '50rem',
        overflowY: 'auto',
        marginTop: '0',
        borderRadius: '1rem 0 0 1rem',
        '&:hover': {
          borderRadius: '1rem 0 0 1rem',
        },
      }),
      '--channel-list__background': 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
      '--channel__padding': '1.2rem 5rem 1.2rem 2.4rem',
      '--channel--active__background': colors.darkBlue,
      '--channel__name__color': colors.nightRider,
      '--channel--hover__name__color': colors.nightRider,
      '--channel--active__name__color': colors.pureWhite,
      '--channel__name__fontSize': '1.4rem',
      '--channel__description__color': colors.nightRider,
      '--channel--hover__description__color': colors.nightRider,
      '--channel--active__description__color': colors.pureWhite,
      '--channel__thumb__size': '5rem',
      '--channel__thumb__borderRadius': '0.5rem',
    },
    '.pn-channel': {
      ...desktop({
        marginBottom: '1rem',
        borderRadius: '1rem 0 0 1rem',
        '&:hover': {
          borderRadius: '1rem 0 0 1rem',
        },
      }),
    },
    '.pn-channel__thumb': {
      height: '5rem',
    },
    ...desktop({
      '.pn-channel-list': {
        '--channel__padding': '0.5rem 1rem 0.5rem 0.4rem',
        '--channel--active__background': colors.whiteGrey,
        '--channel--hover__background': colors.whiteGrey,
        '--channel--active__name__color': colors.nightRider,
        '--channel--active__description__color': colors.nightRider,
        '--channel__thumb__margin': '0 1rem 0 0',
      },
      '.pn-msg-input': {
        '--msg-input__textarea__fontSize': '1.3rem',
      },
    }),
  },

}
