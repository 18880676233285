import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import FormFieldRadio from '../Radio'
import withMemo from '../../../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function RadioGroup(props) {
  const {
    className,
    name,
    value,
    options,
    help,
    error,
    onChange,
  } = props

  const handleChange = (child) => {
    const val = Array.from(value) || []
    const index = val.indexOf(child.name)

    if (index === -1) {
      val.splice(index, 1)
      val.push(child.name)
    }
    onChange({ name, value: val })
  }

  const classes = { ...useStyles(props), ...props.classes }

  return (
    <div
      className={cx(
        classes.container,
        className
      )}
    >
      {
        options.map((option, i) => (
          <FormFieldRadio
            key={i}
            name={option.value}
            value={Array.from(value).includes(option.value)}
            label={option.label}
            onChange={handleChange}
            className={classes.item}
          />
        ))
      }
      <FormErrorText
        className={classes.errorText}
        text={error}
      />
      <FormHelpText
        className={classes.helpText}
        text={help}
        error={!!error}
      />
    </div>
  )
}

RadioGroup.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  })),
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  error: PropTypes.string,
  help: PropTypes.string,
}

RadioGroup.defaultProps = {
  classes: {},
  className: null,
  name: null,
  options: [],
  value: [],
  onChange: () => null,
  error: null,
  help: null,
}

export default withMemo()(RadioGroup)
