/* eslint-disable max-len */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'

import { selectors as AuthSelectors } from '../../redux/auth/redux'
import withMemo from '../../decorators/WithMemo'
import ActionButton from '../ActionButton'
import Paragraph from '../Paragraph'
import RegisterModal from '../RegisterModal'

import styles from './styles'


const useStyles = createUseStyles(styles)

const PushMessage = (props) => {
  const classes = useStyles(props)
  const { className, title, text, ctaLabel, ctaRoute, ctaRouteParams, ctaLink, ctaProps, isCard, hasModal, modalTitle, modalText, modalCta } = props

  const isPremium = useSelector(AuthSelectors.isActivePremium)
  const canShowModal = hasModal && modalTitle && modalText && modalCta && !isPremium
  const [isOpen, setOpen] = useState(false)

  return (
    <div className={cx(classes.container, className, isCard ? classes.card : null)}>
      {title && <div className={classes.title}>{title}</div>}
      {text && (
        <Paragraph
          classNames={{ parentContainer: classes.textContainer }}
          className={classes.text}
          text={text}
        />
      )}
      {canShowModal && (
        <RegisterModal
          modalTitle={modalTitle}
          modalText={modalText}
          modalCta={modalCta}
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          premiumStep
        />
      )}
      {(ctaLink || ctaRoute) && ctaLabel && (
        canShowModal ? (
          <ActionButton
            label={ctaLabel}
            onClick={() => setOpen(true)}
            routeParams={ctaRouteParams}
            color="primary"
            {...ctaProps}
          />
        ) : (
          <ActionButton
            label={ctaLabel}
            link={ctaLink}
            route={ctaRoute}
            routeParams={ctaRouteParams}
            color="primary"
            {...ctaProps}
          />
        )
      )}
    </div>
  )
}

PushMessage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaRoute: PropTypes.string,
  ctaRouteParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  ctaProps: PropTypes.object,
  isCard: PropTypes.bool,
  hasModal: PropTypes.bool,
  modalTitle: PropTypes.string,
  modalText: PropTypes.string,
  modalCta: PropTypes.string,
}

PushMessage.defaultProps = {
  className: '',
  text: null,
  title: null,
  ctaLink: null,
  ctaLabel: null,
  ctaRoute: null,
  ctaRouteParams: null,
  ctaProps: null,
  isCard: false,
  hasModal: false,
  modalTitle: null,
  modalText: null,
  modalCta: null,
}

export default withMemo()(PushMessage)
