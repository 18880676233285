import { tablet } from '../../theme'


export default {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    extend: tablet({
      justifyContent: 'flex-start',
    }),
  },
  link: {
    marginRight: '2rem',
    transition: 'opacity 0.3s',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      opacity: 0.8,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  iconAsset: {
    height: '2.5rem',
    maxHeight: '2.5rem',
    minHeight: '2.5rem',
    maxWidth: '3.5rem',
    minWidth: '3.5rem',
    width: '3.5rem',
  },
}
