import { colors, fonts } from '../../../../theme'


export default {
  container: {},
  wrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    height: '100%',
    position: 'relative',
  },
  label: {
    marginBottom: '1rem',
  },
  inputContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '0',
    position: 'relative',
    zIndex: 0,
  },
  input: {
    width: '100%',
    borderColor: ({ focused, error }) => (
      (focused && !error && colors.primary)
      || (error && colors.error)
      || colors.gainsboro
    ),
  },
  toolbar: {
    backgroundColor: colors.honeydew,
    margin: 0,
    padding: '0.5rem',
    '& > *': {
      margin: 0,
      paddingRight: '0.5rem',
      marginRight: '0.5rem',
      borderRight: `1px solid ${colors.silver}`,
      '&:last-child': { border: 0, padding: 0, margin: 0 },
      '& button': {
        display: 'inline-flex',
        margin: '0 1px !important',
        border: 0,
        borderRadius: '2px !important',
        background: 'none',
        '&[class*="Active"]': {
          background: colors.silver,
        },
      },
      '& span': {
        borderColor: 'transparent !important',
      },
    },
  },
  content: {
    ...fonts.FiraSansRegular,
  },
  errorText: {},
  helpText: {},
}
