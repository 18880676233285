import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import withMemo from '../../decorators/WithMemo'
import { generateFieldsProps, getFieldTranslations } from '../../helpers/FormHelpers'
import { actions as UserPaymentActions, selectors as UserPaymentSelectors } from '../../pages/UserPayment/redux'
import { selectors as AuthSelectors } from '../../redux/auth/redux'

import PaymentForm, { fields } from './index'


const PaymentFormWrapper = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const user = useSelector(AuthSelectors.user)
  const updatePaymentMethodSelector = useSelector(UserPaymentSelectors.updatePaymentMethod)
  const submitErrors = updatePaymentMethodSelector?.errors
  const submitSuccess = updatePaymentMethodSelector?.success
  const submitPending = updatePaymentMethodSelector?.pending
  const handleSubmit = useCallback((values) => dispatch(UserPaymentActions.updatePaymentMethod(values)), [dispatch])

  const formProps = useMemo(() => ({
    title: t('auth_payment_title'),
    intro: t('auth_payment_intro'),
    explanation: t('auth_payment_explanation'),
    sepaSubtitle: t('auth_payment_sepa'),
    submitLabel: t('auth_payment_submit_label'),
    fieldsProps: generateFieldsProps(fields, (field) => {
      const fieldProps = getFieldTranslations(t, `auth_payment_form_${field.name}`)

      return fieldProps
    }),
    messages: {
      required: t('form_validation_required'),
    },
  }), [t])

  const initialValues = useMemo(() => ({
    iban: user?.company?.activeSepaMandate?.alias ?? '',
  }), [user])

  return (
    <>
      <PaymentForm
        {...formProps}
        initialValues={initialValues}
        submitErrors={submitErrors}
        submitSuccess={submitSuccess}
        submitPending={submitPending}
        successText={t('form_submit_success')}
        pendingText={t('form_submit_pending')}
        onSubmit={handleSubmit}
      />
    </>
  )
}

PaymentFormWrapper.propTypes = {}

PaymentFormWrapper.defaultProps = {}

export default withMemo()(PaymentFormWrapper)
