import { fonts, colors, tablet } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '4rem 1rem 5rem',
    width: '100%',
    maxWidth: '78rem',
    margin: '0 auto',
    ...tablet({
      alignItems: 'flex-start',
      padding: '8rem 0 12rem',
    }),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '1.7rem',
    ...tablet({
      width: '100%',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    }),
  },
  title: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '2.5rem',
    lineHeight: '3rem',
    color: colors.darkBlue,
    marginBottom: '1rem',
    textAlign: 'center',
    ...tablet({
      fontSize: '3.2rem',
      lineHeight: '3.8rem',
      textAlign: 'left',
    }),
  },
  subtitle: {
    fontFamily: fonts.FiraSansRegular.fontFamily,
    fontWeight: fontWeights.regular,
    fontSize: '1.5rem',
    lineHeight: '2.2rem',
    color: colors.darkBlue,
    marginBottom: '2.2rem',
    textAlign: 'center',
    ...tablet({
      fontSize: '1.8rem',
      lineHeight: '2.6rem',
      textAlign: 'left',
    }),
  },
  links: {
    marginTop: '4rem',
    ...tablet({
      marginTop: 0,
    }),
  },
  text: {
    ...tablet({
      marginRight: '2rem',
    }),
  },
  paragraph: {
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    textAlign: 'center',
    '& li': {
      display: 'inline-block',
    },
    ...tablet({
      textAlign: 'left',
      '& li': {
        display: 'block',
      },
    }),
  },
}
