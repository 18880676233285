import { aspectRatio } from '../../theme/mixins'


export default {
  container: {
    width: '100%',
    height: '100%',
    ...aspectRatio(16, 9),
  },
  wrapper: {
    backgroundColor: '#000',
  },
  image: {
    backgroundPosition: '50% 50%',
    backgroundClip: 'cover',
    width: '100%',
    height: '100%',
  },
  video: {
    width: '100%',
    height: '100%',
    '& iframe, & > div, & video': {
      width: '100%',
      height: '100%',
    },
    '& video': {
      background: '#000',
    },
  },


}
