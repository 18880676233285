import PropTypes from 'prop-types'


const Domain = (props) => (props && {
  id: props.id,
  name: props.name,
  slug: props.slug,
  image: props.bloc_image,
  color: props.color ? props.color.charAt(0) === '#' ? props.color : `#${props.color}` : '#333333',
  sector: props?.sector,
})

Domain.propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
  image: PropTypes.string,
  color: PropTypes.string,
  sector: PropTypes.object,
})

export default Domain
