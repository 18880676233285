import { parsePhoneNumberFromString } from 'libphonenumber-js'
import Validator from 'validatorjs'

import { isValidId } from '../utils/YoutubeUtils'

import { isValidIBANNumber } from './PaymentHelpers'


export const validateAll = (values, rules, messages) => {
  const validation = new Validator(values, rules, messages)

  return new Promise((resolve, reject) => {
    validation.passes(() => {
      resolve()
    })

    validation.fails(() => {
      resolve(Object.keys(validation.errors.errors)
        .reduce((arr, key) => {
          let errorMessage = validation.errors.errors[key][0]

          switch (true) {
          case errorMessage?.startsWith('The password confirmation'):
            errorMessage = 'La confirmation du mot de passe doit correspondre'
            break
              // no default
          }

          return (
            {
              ...arr,
              [key]: errorMessage,
            }
          )
        }, {}))
    })
  })
}

Validator.register('checked',
  (value, requirement = '', attribute) => !!value,
  'Ce champ est requis.')

Validator.register('phone', (value, requirement = '', attribute) => { // requirement parameter defaults to null
  const [lang, force] = requirement.split(',')
  const phoneNumber = parsePhoneNumberFromString(value, lang)

  if (phoneNumber) {
    return phoneNumber.isValid() && (!lang || !force || lang === phoneNumber.country)
  }
  return false
}, 'Veuillez renseigner un numéro de téléphone valide.')

Validator.register('youtube',
  (value, requirement = '', attribute) => !!isValidId(value),
  'Veuillez renseigner un ID Youtube valide.')

Validator.register('richText',
  (value, requirement = '', attribute) => value.trim().length > 1,
  'Veuillez renseigner un contenu valide.')

Validator.register('zipCode',
  (value, requirement = '', attribute) => !!value.match(/^[0-9]{5}$/gi),
  'Veuillez renseigner un code postal valide.')

Validator.register('siret',
  (value, requirement = '', attribute) => !!value.match(/^[0-9]{14}$/gi),
  'Veuillez renseigner un SIRET valide.')

Validator.register('password',
  (value, requirement = '', attribute) => !!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)[^ ]{8,}$/),
  'Veuillez renseigner un mot de passe valide.')

Validator.register('skills',
  (value, requirement = 3, attribute) => {
    const count = Object.values(value).reduce((r, v) => r + v.length, 0)

    return count >= +requirement
  },
  'Sélectionnez au moins 3 compétences')

Validator.register('number',
  (value, requirement = '', attribute) => !Number.isNaN(Number(value)),
  'Veuillez renseigner un nombre valide.')

Validator.register('iban', (value, requirement = '', attribute) => isValidIBANNumber(value) === 1, 'Veuillez renseigner un IBAN valide.')
