import Color from 'color'

import { colors, fonts, transition } from '../../theme'
import { fontWeights } from '../../utils/FontUtils'


const getThemeColor = (color) => (
  (color === 'primary' && colors.primary)
  || (color === 'secondary' && colors.secondary)
  || (color === 'white' && colors.pureWhite)
  || color
  || colors.grey
)

const getOutlinedStyles = (props) => ({
  color: getThemeColor(props.color),
  ...transition(['color', 'background-color', 'border-color']),
  '&:hover': {
    backgroundColor: getThemeColor(props.color),
    color: props.color === 'white' ? colors.secondary : colors.pureWhite,
    '& $icon': {
      '& svg': {
        width: '100%',
        height: '100%',
      },
      '& *[fill]:not([fill="none"]):not(.no-fill)': {
        fill: props.colorIconHover,
      },
      '& *[stroke]:not([stroke="none"]):not(.no-stroke)': {
        stroke: props.colorIconHover,
      },
      '& *[fill]:not([fill="none"]):not(.no-fill), & *[stroke]:not([stroke="none"]):not(.no-stroke)': {
        ...transition(['fill', 'stroke']),
      },
    },
  },
})

const getPlainStyles = (props) => ({
  backgroundColor: getThemeColor(props.color),
  color: props.color === 'white' ? colors.secondary : colors.pureWhite,
  ...transition(['color', 'background-color', 'border-color']),
  '&:hover': {
    backgroundColor: Color(getThemeColor(props.color)).darken(0.2).string(),
    borderColor: Color(getThemeColor(props.color)).darken(0.2).string(),
  },
})

export default {
  container: (props) => ({
    padding: (!props.label ? '1rem' : '1rem 2rem'),
    ...props.isSmall && {
      padding: (!props.label ? '.6rem' : '.6rem 1.8rem'),
    },
    userSelect: 'none',
    fontFamily: fonts.FiraSansBold.fontFamily,
    fontWeight: fontWeights.bold,
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '2rem',
    border: `0.1rem solid ${getThemeColor(props.color)}`,
    ...props.isBig && {
      padding: (!props.label ? '1.5rem' : '1.5rem 1.8rem'),
      borderRadius: '3rem',
    },
    ...props.isSmall ? {
      fontSize: '1rem',
      lineHeight: '1.2rem',
    } : {
      fontSize: '1.4rem',
      lineHeight: '1.7rem',
    },
    ...transition(['color', 'background-color', 'border-color'], '.3s'),
    ...props.isSuccess ? {
      cursor: 'no-drop',
      color: colors.primary,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      '&:hover': {
        color: colors.primary,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
    } : props.isDisabled ? {
      cursor: 'no-drop',
      color: props.isOutlined ? colors.silver : colors.pureWhite,
      backgroundColor: props.isOutlined ? 'transparent' : colors.silver,
      borderColor: props.isOutlined ? colors.silver : 'transparent',
      '&:hover': {
        backgroundColor: props.isOutlined ? 'transparent' : colors.silver,
        borderColor: props.isOutlined ? colors.silver : 'transparent',
      },
    } : props.isOutlined ? getOutlinedStyles(props) : getPlainStyles(props),
  }),
  icon: {
    marginRight: (props) => props.label && !props.iconRight && '1rem',
    marginLeft: (props) => props.label && props.iconRight && '1rem',
    width: '1.8rem',
    height: '1.8rem',
  },
  iconAsset: {
    minWidth: '1.8rem',
    maxWidth: '1.8rem',
    minHeight: '1.8rem',
    maxHeight: '1.8rem',
    height: '1.8rem',
  },
  iconRight: {
    flexDirection: 'row-reverse !important',
    justifyContent: 'space-between',
  },
  noBg: {
    padding: '0 !important',
    borderRadius: '0 !important',
    border: 'none !important',
    background: 'none !important',
  },
}
