import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import CloudinaryImage from '../CloudinaryImage'
import Paragraph from '../Paragraph'
import UsefulLinks from '../UsefulLinks'

import styles from './styles'


const useStyles = createUseStyles(styles)

const NotFound = (props) => {
  const classes = useStyles(props)
  const { className, image, title, subtitle, text, usefulLinks } = props

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.image}>
        <CloudinaryImage
          id={image}
          options={{
            width: '172',
            height: '71',
            crop: 'scale',
          }}
          lazyLoad
        />
      </div>
      <div className={classes.content}>
        <div className={classes.text}>
          <div className={classes.title}>{title}</div>
          {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
          {text && (
            <Paragraph
              text={text}
              className={classes.paragraph}
            />
          )}
        </div>
        <UsefulLinks
          {...usefulLinks}
          className={classes.links}
        />
      </div>
    </div>
  )
}

NotFound.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  usefulLinks: PropTypes.shape(UsefulLinks.propTypes).isRequired,
}

NotFound.defaultProps = {
  className: '',
  subtitle: null,
  text: null,
}

export default withMemo()(NotFound)
