import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import { createUseStyles } from 'react-jss'

import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'
import Icon from '../Icon'
import A from '../A'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function BreadcrumbEntry(props) {
  const classes = useStyles(props)
  const { className, style, label, link, route, routeParams, onClick, seoTitle, isCurrent } = props

  if (!label) {
    return null
  }

  const renderIcon = (
    !isCurrent
    && (
      <Icon
        icon={iconsKeys.BreadcrumbSeparator}
        theme={colors.tangerine}
        classname={classes.icon}
        iconProps={{ className: classes.iconAsset }}
      />
    )
  )

  const renderDefault = () => (
    <div
      className={ClassNames(classes.container, className, { 'is-current': isCurrent })}
      style={style}
      onClick={onClick}
      role="presentation"
    >
      <div className={ClassNames(classes.cta, { 'is-current': isCurrent })}>
        {label}
        {renderIcon}
      </div>
    </div>
  )

  const renderAsLink = () => (
    <div
      className={ClassNames(classes.container, className, { 'is-current': isCurrent })}
      style={style}
      onClick={onClick}
      role="presentation"
    >
      <A
        href={link}
        route={route}
        routeParams={routeParams}
        className={ClassNames(classes.cta, { 'is-current': isCurrent })}
        title={seoTitle}
      >
        {label}
        {renderIcon}
      </A>
    </div>
  )

  return (
    <>
      {(link || route) && !isCurrent ? renderAsLink() : renderDefault()}
    </>
  )
}

BreadcrumbEntry.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.string),
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  seoTitle: PropTypes.string,
  isCurrent: PropTypes.bool,
}

BreadcrumbEntry.defaultProps = {
  className: null,
  label: null,
  link: null,
  route: null,
  routeParams: null,
  onClick: () => undefined,
  style: null,
  seoTitle: null,
  isCurrent: false,
}

export default withMemo()(BreadcrumbEntry)
