/* eslint-disable react/prop-types, max-lines */

import cx from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import DayPicker from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import { createUseStyles } from 'react-jss'

import withMemo from '../../../../decorators/WithMemo'
import { colors } from '../../../../theme'
import { generateUniqueId } from '../../../../utils/ComponentUtils'
import { hasParent } from '../../../../utils/DomUtils'
import Icon from '../../../Icon'
import { iconsKeys } from '../../../Icon/Icon.assets'
import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import FormLabel from '../../FormLabel'
import Text from '../Text'

import styles from './styles'


const useStyles = createUseStyles(styles)

let timeout = null
const currentYear = new Date().getFullYear()

function DatePicker(props) {
  const {
    className,
    id,
    name,
    value,
    label,
    help,
    placeholder,
    required,
    disabled,
    error,
    readOnly,
    onChange,
    onBlur,
    onFocus,
    format,
    locale,
    inputProps,
    fromDate,
    toDate,
  } = props

  const formatMyDate = useCallback((date) => moment(date).format(format), [format])

  const parseMyDate = useCallback((str) => moment(str, format).toDate(), [format])

  const [thisId] = useState(`form_text_${generateUniqueId()}`)
  const [focused, setFocused] = useState(false)
  const classes = { ...useStyles({ ...props, focused }), ...props.classes }
  const localRef = useRef(null)
  const overlayRef = useRef(null)
  const dayPickerRef = useRef(null)

  const [currentMonth, setCurrentMonth] = useState((!!value && moment(value).isValid())
    ? moment(value).toDate()
    : new Date())

  const [textDate, setTextDate] = useState((!!value && moment(value).isValid())
    ? formatMyDate(moment(value).toDate())
    : '')

  const focus = useCallback(() => {
    const el = localRef.current.querySelector(`#${id || thisId}`)

    if (el) {
      el.focus()
      setFocused(true)
    }
  }, [id, thisId])

  const handleClose = useCallback((e) => {
    if (!e || !hasParent(e.target, localRef.current)) {
      setFocused(false)
      onBlur({ name })
    }
  }, [name, onBlur])

  const handleFocus = useCallback((event) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    } else {
      setFocused(true)
      onFocus({ name })
    }
  }, [name, onFocus])

  const handleClick = useCallback((e) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
  }, [])

  const handleBlur = useCallback((e) => {
    if (e.currentTarget === e.target) {
      timeout = setTimeout(handleClose)
    }
  }, [handleClose])

  const handleTextChange = useCallback(({ value: val }) => {
    setTextDate(val)
    if (parseMyDate(val)) {
      setCurrentMonth(parseMyDate(val))
    }
  }, [parseMyDate])

  const handleTextBlur = useCallback((event) => {
    onChange({
      name,
      value: parseMyDate(textDate),
    })
  }, [name, onChange, parseMyDate, textDate])

  const handleChange = useCallback((selectedDay) => {
    onChange({
      name,
      value: selectedDay,
    })
    handleClose()
  }, [handleClose, name, onChange])

  useEffect(() => {
    if (value && moment(value).isValid()) {
      setCurrentMonth(moment(value).toDate())
      setTextDate(formatMyDate(moment(value).toDate()))
    } else {
      setTextDate('')
    }
  }, [formatMyDate, value])


  useEffect(() => {
    const wrapper = overlayRef.current

    if (wrapper) {
      const rect = wrapper.getBoundingClientRect()

      if (rect.right > window.innerWidth) {
        wrapper.style.left = 'auto'
        wrapper.style.right = 0
      }
    }
  }, [focused])

  useEffect(() => {
    window.addEventListener('mouseup', handleClose)
    window.addEventListener('touchend', handleClose)
    window.addEventListener('focus', handleClose)

    return () => {
      window.removeEventListener('mouseup', handleClose)
      window.removeEventListener('touchend', handleClose)
      window.removeEventListener('focus', handleClose)
    }
  }, [handleClose])

  const YearMonthForm = useCallback(({ date }) => {
    const months = moment.months()

    const years = []

    for (let i = fromDate.getFullYear(); i <= toDate.getFullYear(); i++) {
      years.push(i)
    }

    const handleMonthChange = (e) => {
      setCurrentMonth(new Date(date.getFullYear(), e.target.value))
    }
    const handleYearChange = (e) => {
      setCurrentMonth(new Date(e.target.value, date.getMonth()))
    }

    return (
      <div className="DayPicker-Caption">
        <select
          name="month"
          onChange={handleMonthChange}
          value={date.getMonth()}
          className={classes.month}
        >
          {months.map((month, i) => (
            <option
              key={i}
              value={i}
            >
              {month}
            </option>
          ))}
        </select>

        <select
          name="year"
          onChange={handleYearChange}
          value={date.getFullYear()}
        >
          {years.map((year) => (
            <option
              key={year}
              value={year}
            >
              {year}
            </option>
          ))}
        </select>
      </div>
    )
  }, [classes.month, fromDate, toDate])

  return (
    <div
      className={cx(
        classes.container,
        className
      )}
    >
      <>
        <label
          className={classes.wrapper}
          htmlFor={id || thisId}
        >
          <FormLabel
            className={classes.label}
            required={required}
            error={!!error}
          >
            {label}
          </FormLabel>
        </label>
        <div
          role="button"
          tabIndex={0}
          className={classes.inputContainer}
          ref={localRef}
          onBlur={handleBlur}
          onClick={handleClick}
        >
          <div className={classes.pickerInput}>
            <Text
              name={name}
              id={id || thisId}
              value={textDate}
              onChange={handleTextChange}
              endOrnament={(
                <Icon
                  icon={iconsKeys.Calendar}
                  color={colors.darkBlue}
                  className={classes.endOrnament}
                  onClick={focus}
                />
              )}
              onFocus={handleFocus}
              onBlur={handleTextBlur}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              inputProps={inputProps}
            />
            <div
              className={classes.overlayWrapper}
              ref={overlayRef}
            >
              {focused && (
                <div className={classes.overlay}>
                  <DayPicker
                    ref={dayPickerRef}
                    selectedDays={value && moment(value).isValid() ? moment(value).toDate() : null}
                    onDayClick={handleChange}
                    locale={locale}
                    localeUtils={MomentLocaleUtils}
                    month={moment(currentMonth).isValid() ? currentMonth : new Date()}
                    captionElement={({ date, localeUtils }) => (
                      <YearMonthForm
                        date={date}
                        localeUtils={localeUtils}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
      <FormErrorText
        className={classes.errorText}
        text={error}
      />
      <FormHelpText
        className={classes.helpText}
        text={help}
        error={!!error}
      />
    </div>
  )
}

DatePicker.styles = styles

DatePicker.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.shape(Date), PropTypes.string]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
  help: PropTypes.string,
  placeholder: PropTypes.string,
  locale: PropTypes.string,
  format: PropTypes.string,
  fromDate: PropTypes.shape(Date),
  toDate: PropTypes.shape(Date),
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  onChange: PropTypes.func, // {name, value, event}
  onBlur: PropTypes.func, // {name, event}
  onFocus: PropTypes.func, // {name, event}
}

DatePicker.defaultProps = {
  classes: {},
  className: null,
  id: null,
  format: 'LL',
  locale: 'fr',
  fromDate: new Date(currentYear - 120, 0, 1),
  toDate: new Date(currentYear + 120, 0, 1),
  value: null,
  label: '',
  required: false,
  disabled: false,
  readOnly: false,
  error: null,
  help: null,
  placeholder: null,
  inputProps: null,
  onChange: () => undefined,
  onBlur: () => undefined,
  onFocus: () => undefined,
}

DatePicker.defaultValue = ''

export default withMemo()(DatePicker)
