import { colors, fonts } from '../../../../theme'


export default {
  container: {
    userSelect: 'none',
  },
  label: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },
  text: {
    paddingRight: '1rem',
    marginBottom: '.5rem',
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    flex: 1,
    color: ({ disabled }) => (
      (disabled && colors.gainsboro)
      || colors.nightRider
    ),
  },
  input: {},
}
