import { colors, fonts, transition } from '../../../../theme'


export default {
  container: {
    userSelect: 'none',
  },
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  box: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '3px',
    border: '.1rem solid transparent',
    position: 'relative',
    overflow: 'hidden',
    flexShrink: 0,
    backgroundColor: ({ value }) => (
      (value && colors.darkBlue)
      || colors.honeydew
    ),
    borderColor: ({ error, value }) => (
      (error && colors.error) || (value && colors.darkBlue) || colors.silver
    ),
  },
  icon: {
    ...transition('opacity'),
    opacity: ({ value }) => (value ? 1 : 0),
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  input: {
    position: 'absolute',
    visibility: 'hidden',
    margin: '0',
  },
  text: {
    marginLeft: '1rem',
    ...fonts.FiraSansRegular,
    color: ({ disabled }) => (
      (disabled && colors.gainsboro)
      || colors.nightRider
    ),
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    '& a': {
      textDecoration: 'none',
      color: colors.darkBlue,
    },
  },
}
