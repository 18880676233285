import { pageTypeKey } from '../../redux/app/redux'
import routes from '../../routes'


const Notification = (props) => props && {
  id: props.id,
  text: props.message || props.text,
  type: props.type,
  payload: props.payload,
  offer_id: props.offer_id,
  isSystem: Object.prototype.hasOwnProperty.call(Notification.notifsAlertsTypes, props.type) ? 'ALERT' : 'INFO',
}

Notification.notifsAlertsTypes = {
  company_incomplete: routes.userCompany, // entreprise
  profile_incomplete: routes.userProfile, // profil
}

Notification.notifsInfosTypes = {
  new_company: pageTypeKey.DIRECTORY, // page
  new_events: pageTypeKey.EVENTS, // page
  new_offers: pageTypeKey.OFFERS, // page
  new_posts: pageTypeKey.POSTS, // page
  missing_links: routes.userCompany, // entreprise
  missing_pictures: routes.userCompany, // entreprise
  missing_offers: routes.userPublishOffer, // offres
  missing_posts: routes.userPublishPost, // actualité
  posts_inactivity: routes.userPublishPost, // actualité
  offers_inactivity: routes.userPublishOffer, // offres
  contact_candidate: routes.userOffer, // offres
  updated_offers: routes.offer, // offre
  go_premium: pageTypeKey.ON_BOARDING, // premium
  price_reduction: pageTypeKey.ON_BOARDING, // premium
}

export default Notification
