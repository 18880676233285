import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import MarkdownText from '../MarkdownText'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

function FooterContact(props) {
  const { title, address, tel, telLabel, telLegend, mail, className } = props
  const classes = useStyles(props)

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.title}>{title}</div>
      <MarkdownText
        className={classes.address}
        text={address}
      />
      <div className={classes.tel}>
        <span className={classes.telLabel}>{telLabel}</span>
        <a
          href={`tel:${tel}`}
          className={classes.link}
        >
          {tel}
        </a>
        <div className={classes.telLegend}>{telLegend}</div>
      </div>
      <div className={classes.mail}>
        <Icon
          icon={iconsKeys.ShareMail}
          classname={classes.mailIcon}
        />
        <a
          href={`mailto:${mail}`}
          className={classes.link}
        >
          {mail}
        </a>
      </div>
    </div>
  )
}


FooterContact.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  address: PropTypes.string,
  tel: PropTypes.string,
  telLabel: PropTypes.string,
  telLegend: PropTypes.string,
  mail: PropTypes.string,
}

FooterContact.defaultProps = {
  className: null,
  title: '',
  address: '',
  tel: '',
  telLabel: '',
  telLegend: '',
  mail: '',
}

export default withMemo()(FooterContact)
