export default {
  container: {
    maxWidth: '70rem',
  },
  thumbnailContainer: {
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
  },
  thumbnail: {
    '& img': {
      width: '100%',
      maxWidth: '100%',
      maxHeight: '39.4rem',
      objectFit: 'cover',
    },
  },
  playIcon: {
    '& img': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '20%',
    },
  },
  video: {
    width: '100%',
    height: '100%',
    '& iframe, & > div, & video': {
      width: '100%',
      height: '100%',
    },
  },
}
