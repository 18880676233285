import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import Errors from '../../data/models/Errors'
import Token from '../../data/models/Token'
import User from '../../data/models/User'
import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'auth'

//
// Initial state
//
const initialState = {
  user: null,
  token: {
    token_type: 'Bearer',
    expires_in: null,
    access_token: null,
    refresh_token: null,
    impersonate: false,
  },
  register: requestInitialState(),
  login: requestInitialState(),
  forgot: requestInitialState(),
  reset: requestInitialState(),
  manager: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setUser: createAction('Auth: setUser', (user) => User(user)),
  setUserNotifications: createAction('Auth: setUserNotifications', (notifs) => notifs),
  setUserOpportunities: createAction('Auth: setUserOpportunities', (opportunities) => opportunities),
  setToken: createAction('Auth: setToken', (token) => Token(token)),
  clear: createAction('Auth: clear'),
  // Register
  register: createAction('Auth: register', (values) => ({ values })),
  registerSuccess: createAction('Auth: registerSuccess', (data) => data),
  registerError: createAction('Auth: registerError', (errors) => Errors(errors)),
  // Login
  login: createAction('Auth: login', (values) => ({ values })),
  loginSuccess: createAction('Auth: loginSuccess', (data) => data),
  loginError: createAction('Auth: loginError', (errors) => Errors(errors)),
  // Logout
  logout: createAction('Auth: logout'),
  // Forgot
  forgot: createAction('Auth: forgot', (values) => ({ values })),
  forgotSuccess: createAction('Auth: forgotSuccess', (data) => data),
  forgotError: createAction('Auth: forgotError', (errors) => Errors(errors)),
  // Reset
  reset: createAction('Auth: reset', (values) => ({ values })),
  resetSuccess: createAction('Auth: resetSuccess', (data) => data),
  resetError: createAction('Auth: resetError', (errors) => Errors(errors)),
  // Manager
  manager: createAction('Auth: manager', (values) => ({ values })),
  managerSuccess: createAction('Auth: managerSuccess', (data) => data),
  managerError: createAction('Auth: managerError', (errors) => Errors(errors)),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setUser]: (state, user) => Immutable.static.merge(state, { user }),
  [actions.setUserNotifications]: (state, notifications) => Immutable.static.merge(state, {
    user: {
      ...state.user,
      notifications,
    },
  }),
  [actions.setUserOpportunities]: (state, opportunities) => Immutable.static.merge(state, {
    user: {
      ...state.user,
      opportunities,
    },
  }),
  [actions.setToken]: (state, token) => Immutable.static.merge(state, { token }),
  [actions.clear]: (state) => Immutable.static.replace(state, initialState),
  ...requestReducers(actions, 'register'),
  ...requestReducers(actions, 'login'),
  ...requestReducers(actions, 'forgot'),
  ...requestReducers(actions, 'reset'),
  ...requestReducers(actions, 'manager'),
}, initialState)

//
// Selectors
//
const root = (state) => state[key]
const user = (state) => root(state).user
const company = (state) => user(state)?.company
const isProfileComplete = (state) => !!user(state)?.phoneProfessional
  && !!user(state)?.companyFunction
  && !!user(state)?.companyFunctionDescription
const isCompanyComplete = (state) => !!company(state)?.name
  && !!company(state)?.address
  && !!company(state)?.zip_code
  && !!company(state)?.city
  && !!company(state)?.siret
  && !!company(state)?.workforce
  && !!company(state)?.description
  && !!company(state)?.phone
  && !!company(state)?.email
  && !!company(state)?.domains?.length
  && !!company(state)?.contactMembers?.length
const register = (state) => root(state).register
const login = (state) => root(state).login
const forgot = (state) => root(state).forgot
const reset = (state) => root(state).reset
const manager = (state) => root(state).manager
const token = (state) => root(state).token
const isConnected = (state) => !!user(state)?.id
const isActivePremium = (state) => isConnected(state) && user(state)?.company?.isActivePremium
const isOwner = (state) => user(state).id === user(state)?.company?.owner?.id
const isOGCN = (state) => user(state)?.company?.is_ogcn
const userFavorites = (state) => (user(state)?.favoritesUsers?.length > 0 ? user(state)?.favoritesUsers?.map((us) => us.id) : [])

export const selectors = {
  user,
  isProfileComplete,
  isCompanyComplete,
  register,
  login,
  forgot,
  reset,
  manager,
  token,
  isConnected,
  isActivePremium,
  isOwner,
  isOGCN,
  userFavorites,
}
