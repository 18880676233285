export const getWindowSizeFromBody = (initWinSize) => {
  let winSize = {
    ...initWinSize,
  }

  if (document.body && document.body.offsetWidth) {
    winSize = {
      ...winSize,
      width: document.body.offsetWidth,
      height: document.body.offsetHeight,
    }
  }

  return winSize
}

export const getWindowSizeCompatMode = (initWinSize) => {
  let winSize = {
    ...initWinSize,
  }

  if (
    document.compatMode === 'CSS1Compat'
    && document.documentElement
    && document.documentElement.offsetWidth
  ) {
    winSize = {
      ...winSize,
      width: document.documentElement.offsetWidth,
      height: document.documentElement.offsetHeight,
    }
  }

  return winSize
}

export const getWindowSizeInner = (initWinSize) => {
  let winSize = {
    ...initWinSize,
  }

  if (window.innerWidth && window.innerHeight) {
    winSize = {
      ...winSize,
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }

  return winSize
}

export const getWindowSize = () => {
  let winSize = {
    width: 630,
    height: 460,
  }

  winSize = getWindowSizeFromBody(winSize)

  winSize = getWindowSizeCompatMode(winSize)

  winSize = getWindowSizeInner(winSize)

  return winSize
}
