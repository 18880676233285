import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import Icon from '../Icon'
import { colors } from '../../theme'
import { iconsKeys } from '../Icon/Icon.assets'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const Sharing = (props) => {
  const classes = useStyles(props)
  const { className, title, url } = props

  const handleFacebook = () => {
    window.open(`https://www.facebook.com/sharer.php?u=${url}`)
  }

  const handleTwitter = () => {
    const { twitterMessage } = props

    window.open(`https://twitter.com/intent/tweet?url=${url}&text=${encodeURIComponent(twitterMessage)}`)
  }

  const handleMail = () => {
    const { mailSubject, mailBody } = props

    window.open(`mailto:?subject=${
      encodeURIComponent(mailSubject)
    }&body=${
      encodeURIComponent(mailBody.replace(/:url/gi, url))
    }`)
  }

  const handleLinkedIn = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`)
  }

  const links = [
    {
      icon: iconsKeys.LinkedInIcon,
      handler: handleLinkedIn,
    },
    {
      icon: iconsKeys.FacebookIcon,
      handler: handleFacebook,
    },
    {
      icon: iconsKeys.TwitterIcon,
      handler: handleTwitter,
    },
    {
      icon: iconsKeys.ShareMail,
      handler: handleMail,
    },
  ]

  const renderLinks = () => links.map((link, index) => (
    <Icon
      key={`sharingLink-${index}`}
      icon={link.icon}
      color={colors.darkBlue}
      className={classes.icon}
      onClick={link.handler}
    />
  ))

  return url && url.length && links.length > 0
    && (
      <div className={cx(classes.container, className)}>
        {title && <div className={classes.title}>{title}</div>}
        <div className={classes.links}>
          {renderLinks()}
        </div>
      </div>
    )
}

Sharing.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  twitterMessage: PropTypes.string,
  mailSubject: PropTypes.string,
  mailBody: PropTypes.string,
  url: PropTypes.string,
}

Sharing.defaultProps = {
  className: '',
  title: '',
  twitterMessage: '',
  mailSubject: '',
  mailBody: '',
  url: '',
}

export default withMemo()(Sharing)
