import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import withMemo from '../../decorators/WithMemo'
import A from '../A'

import styles from './styles'


const useStyles = createUseStyles(styles)

const MenuSubEntry = (props) => {
  const classes = useStyles(props)
  const {
    label,
    link,
    route,
    routeParams,
    isSelected,
    isMenuMobile,
    onClick,
    classnames,
  } = props

  return (
    <A
      href={route || link}
      routeParams={routeParams}
      className={cx(
        classes.container,
        classnames.container,
        isMenuMobile && classes.isMenuMobile,
        isSelected && classes.selected
      )}
      onClick={onClick}
      title={label}
    >
      {label}
    </A>
  )
}

MenuSubEntry.styles = styles

MenuSubEntry.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  route: PropTypes.string,
  routeParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
  isSelected: PropTypes.bool,
  isMenuMobile: PropTypes.bool,
  onClick: PropTypes.func,
  classnames: PropTypes.objectOf(PropTypes.string),
}

MenuSubEntry.defaultProps = {
  label: null,
  link: null,
  route: null,
  routeParams: null,
  isSelected: false,
  isMenuMobile: false,
  onClick: null,
  classnames: {},
}

export default withMemo()(MenuSubEntry)
