import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import { iconsKeys } from '../Icon/Icon.assets'
import Icon from '../Icon'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const OfferSource = (props) => {
  const classes = useStyles(props)
  const { className, classNames, source } = props

  const mapping = {
    default: iconsKeys.Cci,
    external: iconsKeys.External,
    featured: iconsKeys.Featured,
  }

  if (!mapping[source]) {
    return
  }

  return (
    <div className={cx(classes.container, className)}>
      <Icon
        icon={mapping[source]}
        iconProps={{ className: classNames.iconAssetProps }}
      />
    </div>
  )
}

OfferSource.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.objectOf(PropTypes.string),
  source: PropTypes.oneOf(['default', 'external', 'featured']),
}

OfferSource.defaultProps = {
  className: '',
  classNames: {},
  source: 'default',
}

export default withMemo()(OfferSource)
