import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import PushMessage from '../PushMessage'

import styles from './styles'


const useStyles = createUseStyles(styles)

const PushPremium = (props) => {
  const classes = useStyles(props)
  const { className, ...otherProps } = props

  return (
    <PushMessage
      className={cx(classes.container, className)}
      {...otherProps}
    />
  )
}

PushPremium.propTypes = {
  className: PropTypes.string,
}

PushPremium.defaultProps = {
  className: '',
}

export default withMemo()(PushPremium)
