import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import ActionButton from '../ActionButton'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const UsefulLinks = (props) => {
  const classes = useStyles(props)
  const { className, title, links } = props

  const renderLinks = () => links.map((element) => {
    const { id, label, link, route, routeParams } = element

    return (
      <ActionButton
        key={`usefulLink-${id}`}
        label={label}
        link={link}
        route={route}
        routeParams={routeParams}
        color="primary"
        isOutlined
        icon={iconsKeys.ArrowRight}
        colorIcon={colors.primary}
        colorIconHover={colors.pureWhite}
        className={classes.link}
        iconRight
      />
    )
  })

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.title}>{title}</div>
      <div className={classes.links}>
        {renderLinks()}
      </div>
    </div>
  )
}

UsefulLinks.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    link: PropTypes.string,
    route: PropTypes.string,
    routeParams: PropTypes.shape({
      slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }),
  })),
}

UsefulLinks.defaultProps = {
  className: '',
  links: [],
}

export default withMemo()(UsefulLinks)
