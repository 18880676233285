import { colors, transition } from '../../theme'


export default {
  container: {
    zIndex: 1,
    width: '2.2rem',
    height: '1.8rem',
    position: 'relative',
    transform: 'rotate(0deg)',
    cursor: 'pointer',
    outline: 'none',
    extend: transition('all', '0.5s', 'ease-in-out'),
  },
  bar: {
    display: 'block',
    position: 'absolute',
    height: '0.2rem',
    width: '100%',
    backgroundColor: colors.darkBlue,
    borderRadius: '0.2rem',
    opacity: '1',
    right: '0',
    transform: 'rotate(0deg)',
    extend: transition('all', '0.25s', 'ease-in-out'),
    '&:nth-child(1)': {
      top: '0',
    },
    '&:nth-child(2)': {
      top: 'calc(50% - 0.1rem)',
    },
    '&:nth-child(3)': {
      top: 'calc(50% - 0.1rem)',
    },
    '&:nth-child(4)': {
      bottom: '0',
    },
  },
  opened: {
    '&:nth-child(1)': {
      top: 'calc(50% - 0.1rem)',
      width: '0',
      right: '50%',
    },
    '&:nth-child(2)': {
      transform: 'rotate(45deg)',
      width: '100%',
    },
    '&:nth-child(3)': {
      transform: 'rotate(-45deg)',
      width: '100%',
    },
    '&:nth-child(4)': {
      bottom: 'calc(50% - 0.1rem)',
      width: '0',
      right: '50%',
    },
  },
}
