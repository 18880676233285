import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import withMemo from '../../decorators/WithMemo'
import { pageType, selectors as AppSelectors } from '../../redux/app/redux'
import { actions as AuthActions, selectors as AuthSelectors } from '../../redux/auth/redux'
import routes, { Router } from '../../routes'
import { iconsKeys } from '../Icon/Icon.assets'
import { selectors as ChatSelectors } from '../../redux/chat/redux'

import Header from './index'


const getMyEventLink = (t) => ({
  id: 100,
  label: t('auth_account_submenu_events'),
  link: routes.userEvents.path,
  location: 'HEADER',
  menuable: {
    slug: routes.userEvents.path,
    title: t('auth_account_submenu_events'),
    full_slug: routes.userEvents.path,
  },
  order: 100,
  route: routes.userEvents.name,
  type: 'MODEL',
  onlyOnPWA: true,
  __typename: 'MENU',
})

function HeaderWrapper(props) {
  const menus = useSelector(AppSelectors.menus)

  const { t } = useTranslation()

  const dispatch = useDispatch()
  const pageSelector = useSelector(AppSelectors.page)
  const loginPage = pageSelector(pageType.LOGIN).link
  const rssPage = pageSelector(pageType.RSS).link
  const route = useSelector(AppSelectors.route)

  const userDashboardPage = Router.getRouteUrl(routes.userDashboard)

  const isConnected = useSelector(AuthSelectors.isConnected)
  const userData = useSelector(AuthSelectors.user)
  const unreadMessagesCount = useSelector(ChatSelectors.unreadMessagesCount)

  const handleLogout = useCallback(() => dispatch(AuthActions.logout()), [dispatch])

  const path = useMemo(() => route.asPath
    .replace(/\/p([0-9]+)/, '')
    .replace(/\?.*/, ''),
  [route.asPath])

  const getSpecificRoute = useCallback((entry) => {
    if (entry?.link?.length > 1) {
      return path.indexOf(entry?.link) === 0
    }

    return path === entry?.link
  }, [path])

  const transform = useCallback((entry) => (
    {
      ...entry,
      isSelected: getSpecificRoute(entry),
      ...entry.entries && {
        entries: entry.entries.map(transform),
      },
    }
  ), [getSpecificRoute])

  const myEventsLink = useMemo(() => getMyEventLink(t), [t])

  const menuLinks = useMemo(() => [...menus?.HEADER, myEventsLink].map(transform), [menus, transform, myEventsLink])
  const footerLinks = useMemo(() => menus?.FOOTER?.map(transform), [menus, transform])

  const footerSocialProps = useMemo(() => ({
    label: t('social_title'),
    links: [
      {
        id: 'FACEBOOK',
        icon: iconsKeys.FacebookIcon,
        link: t('social_facebook_link'),
        seoTitle: t('social_facebook_title'),
      },
      {
        id: 'TWITTER',
        icon: iconsKeys.TwitterIcon,
        link: t('social_twitter_link'),
        seoTitle: t('social_twitter_title'),
      },
      {
        id: 'LINKEDIN',
        icon: iconsKeys.LinkedInIcon,
        link: t('social_linkedin_link'),
        seoTitle: t('social_linkedin_title'),
      },
      {
        id: 'YOUTUBE',
        icon: iconsKeys.YoutubeIcon,
        link: t('social_youtube_link'),
        seoTitle: t('social_youtube_title'),
      },
    ],
    rssButton: {
      label: t('rss_button_label'),
      link: rssPage,
      route: routes.page.name,
      routeParams: { slug: rssPage },
      isInMobileMenu: true,
    },
  }), [t, rssPage])

  const userProps = useMemo(() => ({
    logo: userData?.avatar,
    accountLink: userDashboardPage,
    fullName: userData?.fullName,
    logoutLabel: t('logout_button'),
    countNotifications: userData?.notifications?.length + unreadMessagesCount,
    handleLogout,
  }), [
    userData,
    userDashboardPage,
    t,
    unreadMessagesCount,
    handleLogout,
  ])

  const headerProps = useMemo(() => ({
    logoLink: '/',
    logoTitle: t('header_logo_title'),
    userLink: userData ? userDashboardPage : loginPage,
    userLabel: t('account_button'),
    userRoute: routes.page.name,
    userRouteParams: { slug: userData ? userDashboardPage : loginPage },
  }), [t, userData, userDashboardPage, loginPage])

  return (
    <Header
      userProps={userProps}
      isLogged={isConnected}
      menuLinks={menuLinks}
      footerLinks={footerLinks}
      footerSocialProps={footerSocialProps}
      {...headerProps}
    />
  )
}

HeaderWrapper.propTypes = {}

HeaderWrapper.defaultProps = {}

export default withMemo()(HeaderWrapper)
