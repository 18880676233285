import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import MenuEntry from '../MenuEntry'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const DomainSwitcher = (props) => {
  const classes = useStyles(props)
  const { links } = props

  const renderLinks = () => links.map((link, i) => (
    <div
      className={classes.linkEntry}
      key={i}
    >
      <MenuEntry
        {...link}
        theming="dark"
      />
    </div>
  ))

  return (
    <div className={classes.container}>
      {links.length >= 1 && renderLinks()}
    </div>
  )
}

DomainSwitcher.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape(MenuEntry.propTypes)),
}

DomainSwitcher.defaultProps = {
  links: [],
}

export default withMemo()(DomainSwitcher)
