import cloudinary from 'cloudinary-core'


const defaultOptions = {
  fetchFormat: 'auto',
  quality: 'auto',
  dpr: 2,
  width: 'auto',
  crop: 'scale',
  gravity: 'center',
}

export const getCloudinaryInstance = () => new cloudinary.Cloudinary({
  cloud_name: process.env.CLOUDINARY_CLOUD_NAME,
  secure: true,
})

const removeFileExtension = (id) => id.split('.')[0]

export const getCloudinaryUrl = (id, autoFormat = true, round = false, options = {}) => {
  if (!id) {
    return ''
  }

  const transformedId = autoFormat && id ? removeFileExtension(id) : id

  if (id.charAt(0) === '/' || id.indexOf('http') >= 0) {
    return id
  }

  // eslint-disable-next-line eqeqeq
  if (options.dpr && options.dpr == 1) {
    // eslint-disable-next-line no-param-reassign
    options.dpr = 2
  }

  const cl = getCloudinaryInstance()

  return cl.url(transformedId, {
    ...defaultOptions,
    ...options,
    ...round && {
      radius: 'max',
    },
  })
}

export const getCloudinarySoundUrl = (id, options = {}) => {
  if (!id) {
    return ''
  }

  const cl = getCloudinaryInstance()

  return cl.url(id, {
    ...defaultOptions,
    ...options,
    resource_type: 'video',
    format: 'mp3',
  })
}
