import { colors, fonts } from '../../theme'


export default {
  container: {
    borderRadius: '0.5rem',
    background: ({ color }) => color || colors.grey,
    padding: '.75rem 1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: '0 1rem 1rem 0',
    alignItems: 'center',
  },
  name: {
    ...fonts.FiraSansBold,
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    color: colors.pureWhite,
    textTransform: 'uppercase',
  },
  icon: {
    width: '2.4rem',
    height: '2.4rem',
    margin: '-0.35rem 0.5rem -0.35rem 0',
  },
}
