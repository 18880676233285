import { colors, fonts, sizes } from '../../theme'


export default {
  container: {
    backgroundColor: colors.honeydew,
  },
  content: {
    width: '100%',
    maxWidth: `${sizes.desktop / 10}rem`,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '3rem 0',
  },
  title: {
    ...fonts.FiraSansBold,
    color: colors.darkBlue,
    fontSize: '2rem',
    lineHeight: '2.4rem',
    textTransform: 'uppercase',
    marginBottom: '2rem',
    padding: '0 2rem',
  },
  text: {
    ...fonts.FiraSansRegular,
    color: colors.grey,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    marginBottom: '5rem',
    padding: '0 2rem',
  },
  results: {
    padding: 0,
  },
  emptyPosts: {
    ...fonts.FiraSansRegular,
    textAlign: 'center',
    padding: '2rem',
  },
  postsList: {
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  postsListItemContainer: {
    margin: '0 1rem',
    marginBottom: '2rem',
    minWidth: '28rem',
  },
  hidden: {
    display: 'flex',
  },
}
