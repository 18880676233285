import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { pageType, selectors as AppSelectors } from '../../redux/app/redux'
import { selectors as AuthSelectors } from '../../redux/auth/redux'
import withMemo from '../../decorators/WithMemo'
import { colors } from '../../theme'
import routes from '../../routes'
import { iconsKeys } from '../Icon/Icon.assets'
import ModalWithHeader from '../ModalWithHeader'
import ActionButton from '../ActionButton'
import MarkdownText from '../MarkdownText'
import { app } from '../../config'

import styles from './styles'


const useStyles = createUseStyles(styles)

const RegisterModal = (props) => {
  const classes = useStyles(props)
  const pages = useSelector(AppSelectors.pages)
  const isConnected = useSelector(AuthSelectors.isConnected)
  const goPremiumPage = app.REGISTER_URL
  const registerPage = pages[pageType.ON_BOARDING]
  const {
    modalTitle,
    modalText,
    modalCta,
    isOpen,
    premiumStep,
    onClose,
  } = props

  const { t } = useTranslation()

  return (
    <ModalWithHeader
      title={modalTitle}
      className={classes.modal}
      isOpen={isOpen}
      onClose={onClose}
    >
      <MarkdownText
        className={classes.modalText}
        text={modalText}
      />
      <div className={classes.registerContainer}>
        <ActionButton
          label={isConnected ? t('registerModal_subscribeCta') : modalCta}
          icon={iconsKeys.SmallArrowRight}
          iconRight
          colorIcon={colors.pureWhite}
          colorIconHover={colors.darkBlue}
          route={isConnected ? null : routes.page.name}
          routeParams={{ slug: registerPage?.link,
            ...premiumStep && {
              type: 'premium',
            } }}
          link={isConnected ? goPremiumPage : null}
        />
      </div>
    </ModalWithHeader>
  )
}

RegisterModal.propTypes = {
  modalTitle: PropTypes.string,
  modalText: PropTypes.string,
  modalCta: PropTypes.string,
  isOpen: PropTypes.bool,
  premiumStep: PropTypes.bool,
  onClose: PropTypes.func,
}

RegisterModal.defaultProps = {
  modalTitle: '',
  modalText: '',
  modalCta: '',
  isOpen: false,
  premiumStep: false,
  onClose: () => {},
}

export default withMemo()(RegisterModal)
