import { colors, fonts, tablet } from '../../../../theme'


export default {
  container: {},
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    ...tablet({
      flexDirection: 'row',
      alignItems: 'center',
    }),
  },
  label: {
    ...fonts.FiraSansRegular,
    fontSize: '1.4rem',
    flex: 1,
    color: ({ disabled }) => (
      (disabled && colors.gainsboro)
      || colors.nightRider
    ),
  },
  inputs: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    padding: '1rem',
  },
  inputLabel: {
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputText: {
    color: colors.darkBlue,
    padding: '0.6rem',
    margin: 0,
  },
}
