import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import withMemo from '../../decorators/WithMemo'
import ActionButton from '../ActionButton'
import RegisterPushItem, { propTypes as registerPushItemPropTypes } from '../RegisterPushItem'

import styles from './styles'


const useStyles = createUseStyles(styles)

const RegisterPushCombo = (props) => {
  const classes = useStyles(props)
  const { className, cards, ctaLabel, ctaLink, ctaRoute, ctaRouteParams } = props

  const renderCards = () => cards.map((card, i) => (
    <React.Fragment key={i}>
      <RegisterPushItem
        {...card}
        className={classes.card}
      />
    </React.Fragment>
  ))

  return (
    <div className={cx(classes.container, className)}>
      <div className={classes.itemContainer}>
        {cards && renderCards()}
      </div>
      {ctaLabel && ctaLink && (
        <ActionButton
          link={ctaLink}
          label={ctaLabel}
          route={ctaRoute}
          routeParams={ctaRouteParams}
          color="burntOrange"
          className={classes.cta}
        />
      )}
    </div>
  )
}

RegisterPushCombo.propTypes = {
  className: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(registerPushItemPropTypes)).isRequired,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaRoute: PropTypes.string,
  ctaRouteParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
}

RegisterPushCombo.defaultProps = {
  className: '',
  ctaLabel: null,
  ctaLink: null,
  ctaRoute: null,
  ctaRouteParams: null,
}

export default withMemo()(RegisterPushCombo)
