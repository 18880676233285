import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import TopCompaniesCard from '../TopCompaniesCard'
import ActionButton from '../ActionButton'
import Swiper from '../../utils/Swiper'
import sizes from '../../theme/sizes'
import withMemo from '../../decorators/WithMemo'

import styles from './styles'


const useStyles = createUseStyles(styles)

const TopCompanies = (props) => {
  const classes = useStyles(props)
  const { className, title, cards, ctaLabel, ctaLink, ctaRoute, ctaRouteParams } = props
  const [swiperInit, setSwiperInit] = useState(null)

  useEffect(() => {
    if (!swiperInit) {
      setSwiperInit(true)
      /* eslint-disable-next-line */
      new Swiper('.swiper-container', {
        wrapperClass: classes.cardWrapper,
        slideClass: classes.card,
        direction: 'horizontal',
        loop: true,
        grabCursor: true,
        autoplay: {
          delay: 3000,
        },
        slidesPerView: 6,
        slidesPerGroup: 1,
        spaceBetween: 20,
        breakpoints: {
          [sizes.tablet - 1]: {
            slidesPerView: 2,
          },
          [sizes.desktop - 1]: {
            slidesPerView: 4,
          },
        },
      })
    }
  }, [classes.card, classes.cardWrapper, props, swiperInit])

  const renderCards = () => cards.map((card, i) => (
    <div
      className={classes.card}
      key={`partner-${i}`}
    >
      <TopCompaniesCard {...card} />
    </div>
  ))

  if (!cards || !cards.length >= 1) {
    return null
  }

  return (
    <div className={cx(classes.container, className)}>
      {title && <div className={classes.title}>{title}</div>}
      <div className={cx('swiper-container', classes.cardContainer)}>
        <div className={classes.cardWrapper}>
          {cards && renderCards()}
        </div>
      </div>
      {ctaLabel && ctaLink && (
        <div className={classes.cta}>
          <ActionButton
            label={ctaLabel}
            link={ctaLink}
            route={ctaRoute}
            routeParams={ctaRouteParams}
            color="primary"
            isOutlined
            className={classes.cta}
          />
        </div>
      )}
    </div>
  )
}

TopCompanies.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  ctaLink: PropTypes.string,
  ctaLabel: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(TopCompaniesCard.propTypes)),
  ctaRoute: PropTypes.string,
  ctaRouteParams: PropTypes.shape({
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
}

TopCompanies.defaultProps = {
  className: '',
  cards: [],
  ctaLink: null,
  ctaLabel: null,
  ctaRoute: null,
  ctaRouteParams: null,
}

export default withMemo()(TopCompanies)
